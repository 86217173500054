import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ClientOrderMarker } from './components/ClientOrderMarker';
import {
  useGetRoundTripsClusterQuery,
  useLazyGetDriverLocationsQuery,
  useGetWarehouseInfoQuery,
  useGetMapMarkersDataQuery,
} from 'common/services/roundtripApi';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { MapWrapper } from '../styles/map';
import { MarkerStep, MarkerVehicle } from 'app/components/MapMarkers';
import { useSubscribeObject } from 'common/hooks/ws';
import { defaultMapStyle } from '../../../../../styles/mapStyles';
import { centroidLabel } from '../../../../../utils/geo';
import { getMostRecentItem } from '../../../../helpers/helpers';
import { LeftMenu } from './components/LeftMenu';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { filterRoundtripBySearch } from 'app/helpers/roundtripHelper';
import { useTranslation } from 'react-i18next';
import { StateSetter } from 'types';
import If from 'app/components/If';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import { useGetClientsOrdersQuery } from 'common/services/roundtripApi';
import cluster from 'assets/img/map/CustomerMap/cluster.svg';
import { themes } from 'styles/theme/themes';
import styled, { keyframes } from 'styled-components';
import { CustomTooltip } from './components/CustomTooltip';
import { DropZone } from './components/DropZone';
import AssignedRoundtripsPopover from './components/AssigneClientOrderPopover';
import { AddClientOrderPopover } from './components/AddClientOrderPopover';
import { RoundtripsType } from '../../data/types';
import { WeatherTag } from './components/WeatherTag';
import { useDebounce } from 'common/hooks/useDebounce';
import { MapActionButton } from './components/MapActionButton';
import { MapZoomButton } from './components/MapZoomButton';
import IsolateRoundtripIcon from 'assets/img/map/buttons/IsolateRoundtrip.svg';
import IsolateRoundtripActive from 'assets/img/map/buttons/IsolateRoundtripActive.svg';
import TrackTruck from 'assets/img/map/buttons/TrackTruck.svg';
import TrackTruckActive from 'assets/img/map/buttons/TrackTruckActive.svg';
import ShowStatus from 'assets/img/map/buttons/ShowStatus.svg';
import ShowStatusActive from 'assets/img/map/buttons/ShowStatusActive.svg';
import PolylinePoints from 'assets/img/map/marker/PolylinePoints.svg';
import PolylineArrow from 'assets/img/map/marker/PolylineArrow.svg';
import UnlinkWarehouse from 'assets/img/map/buttons/UnlinkWarehouse.svg';
import LinkWarehouse from 'assets/img/map/buttons/LinkWarehouse.svg';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { FilterChips } from 'app/components/FilterChips';
import { bindShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import dayjs from 'dayjs';
import {
  Map as MapComponent,
  useMap,
  AdvancedMarker,
} from '@vis.gl/react-google-maps';
import { PolygonComponent } from './components/PolygonComponent';
import { PolylineComponent } from './components/PolylineComponent';
import { CircleComponent } from './components/CircleComponent';
import { RectangleComponent } from './components/RectangleComponent';
import { type Marker, MarkerClusterer } from '@googlemaps/markerclusterer';

export type MapConfig = {
  id: string;
  label: string;
  mapId?: string;
  mapTypeId?: string;
  styles?: google.maps.MapTypeStyle[];
};

const MAP_CONFIG: MapConfig = {
  id: 'light',
  label: 'Light',
  styles: defaultMapStyle,
};

const calculateBearing = (point1, point2) => {
  const lat1 = (point1.lat * Math.PI) / 180;
  const lon1 = (point1.lng * Math.PI) / 180;
  const lat2 = (point2.lat * Math.PI) / 180;
  const lon2 = (point2.lng * Math.PI) / 180;

  const dLon = lon2 - lon1;
  const y = Math.sin(dLon) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  let brng = Math.atan2(y, x);
  brng = ((brng * 180) / Math.PI + 360) % 360;

  return brng;
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

interface ClusterMapQueryDto {
  noStops?: boolean;
  noRipper?: boolean;
  noDriver?: boolean;
  noSales?: boolean;
  noVehicle?: boolean;
  unlinkWarehouse: boolean;
  begin: string;
  end: string;
  sort?: string;
  text?: string;
  weight?: number;
  status?: any;
  orderType?: any;
  orderStatus?: any;
  orderPriority?: any;
  customerStatus?: any;
  vehicleCondition?: string;
}

interface Props {
  clusterId: string;
  defaultLocation?: any;
  onSelectOrder?: Function;
  unassignedOrders?: any;
  onDisplayOrder?: any;
  accordionStatus?: any;
  setAccordionStatus?: any;
  roundTripCopy?: any;
  setRoundTripCopy?: any;
  requestOrderData?: any;
  orders?: any;
  setOrders?: any;
  setOrderInitCount?: any;
  orderInitCount?: any;
  roundTripsCount?: any;
  updateRoundtripStatus?: Function;
  userContext?: AgentCategories;
  handleClickOpen?: () => void;
  handleClickOpenDelete?: () => void;
  viewMode?: boolean;
  search?: string;
  setAddModalOpen?: StateSetter<boolean>;
  LegendComponent?: JSX.Element;
  setRoundtripEdit?: StateSetter<any>;
  date: string;
  filters?: any;
  setFilters?: any;
}

export const Map: React.FC<Props> = ({
  onSelectOrder,
  clusterId,
  defaultLocation,
  unassignedOrders,
  onDisplayOrder,
  accordionStatus,
  setAccordionStatus,
  roundTripCopy,
  setRoundTripCopy,
  requestOrderData,
  orders,
  setOrders,
  setOrderInitCount,
  orderInitCount,
  roundTripsCount,
  updateRoundtripStatus = () => {},
  userContext = AgentCategories.Logistic,
  handleClickOpenDelete = () => {},
  handleClickOpen = () => {},
  viewMode,
  search = '',
  setAddModalOpen,
  LegendComponent,
  setRoundtripEdit = () => {},
  date,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const theme: any = useSelector(selectTheme);

  const map = useMap('ef6369f36e7c6f39');
  const [mapMarkers, setMapMarkers] = useState<{ [key: string]: Marker }>({});
  // const clusterer = useMemo(() => {
  //   if (!map) return null;
  //   return new MarkerClusterer({ map });
  // }, [map]);

  // useEffect(() => {
  //   if (!clusterer) return;

  //   clusterer.clearMarkers();
  //   clusterer.addMarkers(Object.values(mapMarkers));
  // }, [clusterer, mapMarkers]);

  // const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
  //   setMapMarkers(markers => {
  //     if ((marker && markers[key]) || (!marker && !markers[key]))
  //       return markers;

  //     if (marker) {
  //       return { ...markers, [key]: marker };
  //     } else {
  //       const { [key]: _, ...newMarkers } = markers;

  //       return newMarkers;
  //     }
  //   });
  // }, []);

  const [displayedStopKey, setDisplayedStopKey] = useState('');
  const [view, setView] = useState<'poly' | 'map'>('poly');
  const [selectedRoundtrips, setSelectedRoundtrips] = useState<string[]>([]);
  const [unlinkWarehouse, setUnlinkWarehouse] = useState(false);
  const [center, setCenter] = useState({
    lat: defaultLocation?.[1] || 48,
    lng: defaultLocation?.[0] || 2,
  });
  const [zoom, setZoom] = useState(11);

  const wsMsg = useSubscribeObject('roundtrip-cluster', clusterId);
  const wsDateMessage = useSubscribeObject('roundtrip-cluster-date', date);

  const zoomedIn = useSelector(selectIsZoomedIn);

  const debouncedFilters = useDebounce(filters, 500);

  const commonQueryParams = {
    device: 'web',
  };

  const rtQueryParams = useQueryParams({
    ...commonQueryParams,
    assigned: debouncedFilters?.assigned
      ? debouncedFilters?.assigned
          ?.map(filter => {
            return filter.value;
          })
          ?.join(',')
      : '',
    orderPriority: debouncedFilters?.priority
      ? debouncedFilters?.priority
          ?.map(filter => {
            return filter.value;
          })
          ?.join(',')
      : '',
    orderStatus: debouncedFilters?.orderStatus
      ? debouncedFilters?.orderStatus
          ?.map(filter => {
            return filter.value;
          })
          ?.join(',')
      : '',
    customerStatus: debouncedFilters?.clientStatus
      ? debouncedFilters?.clientStatus
          ?.map(filter => {
            return filter.value;
          })
          ?.join(',')
      : '',
    orderType: debouncedFilters?.deliveryType
      ? debouncedFilters?.deliveryType
          ?.map(filter => {
            return filter.value;
          })
          ?.join(',')
      : '',
    vehicleCondition: debouncedFilters?.truckType
      ? debouncedFilters?.truckType
          ?.map(filter => {
            return filter.value;
          })
          ?.join(',')
      : '',
  });

  const {
    data: clusterData,
    refetch,
    isLoading: isloadindCluster,
  } = useGetRoundTripsClusterQuery(`/${clusterId}${rtQueryParams}`, {
    skip: !clusterId,
  });

  const commonMapDataQueryParams: ClusterMapQueryDto = {
    begin: dayjs()?.format('YYYY-MM-DD'),
    end: dayjs()?.format('YYYY-MM-DD'),
    unlinkWarehouse: unlinkWarehouse,
  };

  const MapDataQueryParams = useQueryParams({
    ...commonMapDataQueryParams,
  });

  const { data: mapData, refetch: refetchMapData } = useGetMapMarkersDataQuery(
    { id: clusterId, params: MapDataQueryParams },
    {
      skip: !clusterId,
    },
  );

  useEffect(() => {
    if (!clusterId) return;
    refetchMapData();
  }, [unlinkWarehouse]);

  const {
    data: warehouseData_,
    refetch: refetchWarehouseInfo,
    isLoading: isloadindWarehouseInfo,
  } = useGetWarehouseInfoQuery(
    { id: clusterId },
    {
      skip: !clusterId,
    },
  );

  const [warehouseData, setWarehouseData] = useState(warehouseData_);

  useEffect(() => {
    setWarehouseData(warehouseData_);
  }, [warehouseData_]);

  const [
    getDriverLocations,
    { data: driverLocations, isLoading: isLoadingDriverLocations },
  ] = useLazyGetDriverLocationsQuery();

  useEffect(() => {
    if (wsMsg && clusterId) {
      refetch();
    }
  }, [wsMsg]);

  useEffect(() => {
    if (wsDateMessage && clusterId) {
      refetch();
    }
  }, [wsDateMessage]);

  useEffect(() => {
    if (clusterId) {
      refetch();
      refetchWarehouseInfo();
      refetchMapData();
    }
  }, [clusterId, clusterData?.roundtrips, orders]);

  const ClientsOrdersQuery = useQueryParams({
    limit: '1000',
    _deck: clusterData?._deck?._id,
    clusterId: clusterId,
    application: 'bianta',
    page: 1,
  });
  const {
    data: clientsOrders,
    isLoading: isLoadingClients,
    refetch: refetchClientsOrders,
  } = useGetClientsOrdersQuery(
    { query: ClientsOrdersQuery },
    { skip: !clusterData?._deck?._id },
  );

  useEffect(() => {
    if (!clientsOrders && clusterData?._deck?._id) {
      refetchClientsOrders();
    }
  }, [clientsOrders]);

  const [showOrderStatusActive, setShowOrderStatusActive] = useState(false);

  const markers = useMemo(() => {
    const m: any = {
      vehicles: [],
      routes: [],
      clientsOrders: [],
      unAssignedRoundtrips: {
        center: {
          lng: clusterData?._deck?.location?.geometry?.coordinates[0],
          lat: clusterData?._deck?.location?.geometry?.coordinates[1],
        },
        roundtrips: [],
      },
    };

    if (clusterData?.roundtrips) {
      clusterData?.roundtrips?.forEach(roundtrip => {
        const polygonPath: any = [];
        const canceledPolygonPath: any = [];
        let lastPosition: any =
          roundtrip.status === 'parked'
            ? roundtrip._vehicle?.lastPosition
            : getMostRecentItem(
                [roundtrip._vehicle?.lastPosition, roundtrip.lastAgentPosition],
                'date',
              );

        if (lastPosition?.geometry) {
          m.vehicles.push({
            roundtripId: roundtrip._id,
            numberPlate: roundtrip._vehicle?.numberPlate,
            lastPosition,
            roundtrip,
          });
        }

        // Start place
        if (roundtrip._startPlace && !unlinkWarehouse) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._startPlace.location.geometry.coordinates[1],
            lng: roundtrip._startPlace.location.geometry.coordinates[0],
          });
        } else if (roundtrip._startPlace && roundtrip?.stops?.length === 0) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._startPlace.location.geometry.coordinates[1],
            lng: roundtrip._startPlace.location.geometry.coordinates[0],
          });
        }

        if (!roundtrip.stops?.length) {
          m.unAssignedRoundtrips.roundtrips.push(roundtrip);
        }

        roundtrip.stops.forEach((stop, index) => {
          if (stop._place?.location?.geometry?.coordinates) {
            polygonPath.push({
              type: stop?._order?.status !== 'canceled' ? 'canceled' : '',
              lat: stop._place.location.geometry.coordinates[1],
              lng: stop._place.location.geometry.coordinates[0],
            });
          }

          if (
            stop._place?.location?.geometry?.coordinates &&
            stop?._order?.status?.toLowerCase() === 'canceled'
          ) {
            canceledPolygonPath.push([
              // stop before the canceled
              {
                lat: unlinkWarehouse
                  ? roundtrip.stops[
                      index === 0
                        ? roundtrip.stops?.length - 1
                        : index === 1
                        ? 0
                        : index - 1
                    ]?._place.location.geometry.coordinates[1]
                  : roundtrip.stops[index - 1]?._place.location.geometry
                      .coordinates[1] ||
                    roundtrip._startPlace.location.geometry.coordinates[1],
                lng: unlinkWarehouse
                  ? roundtrip.stops[
                      index === 0
                        ? roundtrip.stops?.length - 1
                        : index === 1
                        ? 0
                        : index - 1
                    ]?._place.location.geometry.coordinates[0]
                  : roundtrip.stops[index - 1]?._place.location.geometry
                      .coordinates[0] ||
                    roundtrip._startPlace.location.geometry.coordinates[0],
              },
              // canceled stop
              {
                lat: stop._place.location.geometry.coordinates[1],
                lng: stop._place.location.geometry.coordinates[0],
              },
              // stop after the canceled
              {
                lat: unlinkWarehouse
                  ? roundtrip.stops[
                      index === 0
                        ? 1
                        : roundtrip.stops?.length - 1 === index
                        ? 0
                        : index + 1
                    ]?._place.location.geometry.coordinates[1]
                  : roundtrip.stops[index + 1]?._place.location.geometry
                      .coordinates[1] ||
                    roundtrip._endPlace.location.geometry.coordinates[1],
                lng: unlinkWarehouse
                  ? roundtrip.stops[
                      index === 0
                        ? 1
                        : roundtrip.stops?.length - 1 === index
                        ? 0
                        : index + 1
                    ]?._place.location.geometry.coordinates[0]
                  : roundtrip.stops[index + 1]?._place.location.geometry
                      .coordinates[0] ||
                    roundtrip._endPlace.location.geometry.coordinates[0],
              },
            ]);
          }
        });

        if (roundtrip._endPlace && !unlinkWarehouse) {
          polygonPath.push({
            type: 'end',
            lat: roundtrip._endPlace.location.geometry.coordinates[1],
            lng: roundtrip._endPlace.location.geometry.coordinates[0],
          });
        } else if (roundtrip._endPlace && roundtrip?.stops?.length === 0) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._endPlace.location.geometry.coordinates[1],
            lng: roundtrip._endPlace.location.geometry.coordinates[0],
          });
        }

        m.routes.push({
          options: {
            fillColor: '#9E9E9E',
            fillOpacity: 0.2,
            strokeColor: '#9E9E9E',
            strokeOpacity: 1,
            strokeWeight: 1,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
          },
          center: centroidLabel(polygonPath, false, false),
          paths: polygonPath,
          canceledPaths: canceledPolygonPath,
          code: roundtrip.code,
          roundtripId: roundtrip._id,
        });
      });

      if (clientsOrders?.docs) {
        clientsOrders?.docs?.forEach(client => {
          m.clientsOrders.push({
            clientId: client?._id,
            position: client?._deck?.location?.geometry?.coordinates || [],
            inSrts: false,
          });
        });
      }
    }
    return m;
  }, [
    clusterData?.roundtrips,
    clientsOrders,
    selectedRoundtrips,
    unlinkWarehouse,
  ]);

  const circleRadius = useMemo(() => {
    const zoomFactor = zoom - 11;
    return 1000 / Math.pow(2, zoomFactor);
  }, [zoom]);

  const circleOptions = {
    strokeColor: 'rgba(0,0,0,0)',
    fillColor: themes.default.primaryActiveColor,
    fillOpacity: 0.4,
    zIndex: 1,
    radius: circleRadius,
  };

  const handleSelectRoundtrip = useCallback(
    (roundtripId: string) => {
      if (selectedRoundtrips.includes(roundtripId)) {
        setSelectedRoundtrips(
          selectedRoundtrips.filter(id => id !== roundtripId),
        );
      } else {
        setSelectedRoundtrips([...selectedRoundtrips, roundtripId]);

        const findVehicle = markers.vehicles.find(
          v => v.roundtripId === roundtripId,
        );

        if (findVehicle) {
          setCenter({
            lat: findVehicle.lastPosition.geometry.coordinates[1],
            lng: findVehicle.lastPosition.geometry.coordinates[0],
          });
        }
      }
    },
    [selectedRoundtrips, markers.vehicles],
  );

  const handleClickVehicle = useCallback(
    (vehicle: any) => {
      if (!selectedRoundtrips.includes(vehicle.roundtripId)) {
        setSelectedRoundtrips([...selectedRoundtrips, vehicle.roundtripId]);
      }

      setCenter({
        lat: vehicle.lastPosition.geometry.coordinates[1],
        lng: vehicle.lastPosition.geometry.coordinates[0],
      });
    },
    [selectedRoundtrips, center],
  );

  const roundtrips = useMemo(() => {
    return clusterData?.roundtrips || [];
  }, [clusterData]);

  const displayRoutes = useMemo(() => {
    return markers.routes;
  }, [markers]);

  const [layerIndex, setLayerIndex] = useState(-1);
  const [pinnedLayers, setPinnedLayers]: any = useState([]);

  const handleOpenLayer = useCallback(
    index => {
      setLayerIndex(index);
    },
    [layerIndex],
  );
  const handleCloseLayer = useCallback(
    index => {
      setLayerIndex(-1);
    },
    [layerIndex],
  );
  const filteredRoundtrips = useMemo(() => {
    if (isEmpty(search)) {
      return roundtrips;
    }

    return roundtrips ? filterRoundtripBySearch(roundtrips, search, t) : [];
  }, [roundtrips, search]);

  const [hoverableRoute, setHoverableRoute] = useState<string | null>(null);

  const [selectedRoundtripDetails, setSelectedRoundtripDetails] =
    useState<any>(null);

  const handleSetSelectedRoundtripDetails = useCallback(
    value => {
      setSelectedRoundtripDetails(value);
    },
    [selectedRoundtripDetails],
  );

  const [roundtripClickOnMap, setRoundtripClickOnMap] = useState<string | null>(
    null,
  );

  const [updatedDisplayRoutes, setUpdatedDisplayRoutes] =
    useState(displayRoutes);

  //On click on roundtrip on map update the selectedRoundtripDetails state to render the details pannel
  const handleSetSelectedRoundtrip = useMemo(() => {
    return () => {
      setRoundtripClickOnMap(null);
      roundtrips.map(rt => {
        if (rt._id === roundtripClickOnMap) {
          setSelectedRoundtripDetails(rt);
          handleRoundtripFocus(rt);
        }
      });
    };
  }, [roundtripClickOnMap]);

  useEffect(() => {
    handleSetSelectedRoundtrip();
  }, [handleSetSelectedRoundtrip]);

  const handleSetSelectedTripDetails = useMemo(() => {
    return () => {
      if (!roundtrips.find(rt => rt._id === selectedRoundtripDetails?._id)) {
        setSelectedRoundtripDetails(null);
      }
    };
  }, [roundtrips]);

  useEffect(() => {
    handleSetSelectedTripDetails();
  }, [handleSetSelectedTripDetails]);

  const ref = useRef(null);
  const [selectedOrders, setSelectedOrders]: any = useState([]);
  const [selectedFullOrders, setSelectedFullOrders]: any = useState([]);

  const handleSelectOrders = useCallback(
    (e, orderId, clientOrder) => {
      if (
        e !== null &&
        selectedOrders.length > 0 &&
        !e?.ctrlKey &&
        !selectedOrders.includes(orderId)
      ) {
        return setSelectedOrders([orderId]);
      } else if (
        e !== null &&
        selectedOrders.length === 1 &&
        selectedOrders.includes(orderId)
      ) {
        return setSelectedOrders([]);
      } else if (
        e !== null &&
        selectedOrders.length > 1 &&
        selectedOrders.includes(orderId)
      ) {
        return setSelectedOrders([orderId]);
      }

      if (selectedOrders.includes(orderId)) {
        setSelectedOrders(prevState => [
          ...prevState.filter(s => s !== orderId),
        ]);
      } else {
        setSelectedOrders(prevState => [...prevState, orderId]);
      }

      if (selectedOrders.includes(clientOrder._id)) {
        setSelectedFullOrders(prevState => [
          ...prevState.filter(s => s._id !== clientOrder._id),
        ]);
      } else {
        setSelectedFullOrders(prevFullOrders => [
          ...prevFullOrders,
          clientOrder,
        ]);
      }
    },
    [selectedOrders, selectedFullOrders],
  );

  const handleLeftMenuOrderClick = useCallback(
    (id: string) => {
      const stop = mapData?.markers?.assignedOrders?.find(s => s._id === id);
      if (stop?._place?.location?.geometry?.coordinates) {
        const coordinates = {
          lat: stop._place.location.geometry.coordinates?.[1],
          lng: stop._place.location?.geometry.coordinates?.[0],
        };
        map?.panTo(coordinates);
      }
      if (!pinnedLayers?.includes(id)) {
        setPinnedLayers([id]);
      }
    },
    [setPinnedLayers, pinnedLayers, mapData?.markers?.assignedOrders, map],
  );

  // useEffect(() => {
  //   setUpdatedDisplayRoutes(displayRoutes);
  // }, [displayRoutes]);

  const [unassignedOrders_, setAssignedOrders_] = useState(orders);

  const handleHighlightOrder = useMemo(() => {
    return () => {
      setAssignedOrders_([]);
      if (unassignedOrders?.length > 0) {
        setAssignedOrders_(prevState => {
          const updatedOrders = unassignedOrders.map(order => {
            let isFilteredOrder = false;
            if (search?.toUpperCase() === order?.code) {
              isFilteredOrder = true;
              const orderLocation: any[] =
                order?._deck?.location?.geometry?.coordinates || [];
              map?.panTo({ lat: orderLocation[1], lng: orderLocation[0] });
            }
            return {
              ...order,
              isFilteredOrder: isFilteredOrder,
            };
          });
          return [...prevState, ...updatedOrders];
        });
      }
    };
  }, [unassignedOrders, search]);

  useEffect(() => {
    handleHighlightOrder();
  }, [handleHighlightOrder]);

  const [contextMenu, setContextMenu] = useState<boolean>(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<any>(null);
  const [openRoundtrip, setOpenRoundtrip] = useState<boolean>(false);
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });

  window.oncontextmenu = e => {
    handleContextMenu(e);
  };

  const handleContextMenu = e => {
    if (selectedOrders.length > 0) {
      setContextMenu(true);
      setContextMenuPosition({ x: e?.clientX, y: e?.clientY });
    }
  };

  const handleOpenContextMenu = useMemo(() => {
    return () => {
      if (selectedOrders.length > 1 && !lastSelectByClick) {
        setContextMenu(true);
        setContextMenuPosition({
          x: lastMousePosition?.x + 249,
          y: lastMousePosition?.y + 76,
        });
      }
    };
  }, [selectedOrders]);

  useEffect(() => {
    // if more than 2 orders are selected by selection rectangle, open the menu
    handleOpenContextMenu();
  }, [handleOpenContextMenu]);

  window.onclick = () => {
    if (contextMenu) {
      setContextMenu(false);
      setContextMenuPosition(null);
    }
  };

  const [startLatLng, setStartLatLng] = useState(null);
  const [gridBoundingBox, setGridBoundingBox] = useState<any>(null);
  const [centerLatLng, setCenterLatLng] = useState<any>(null);
  const weatherLatLng = useDebounce(centerLatLng, 1000);
  const [selectionGridBoundingBox, setSelectionGridBoundingBox] =
    useState<any>(null);
  const [overlayWarehouseVisible, setOverlayWarehouseVisible] = useState(false);

  const handleShowRectangle = useMemo(() => {
    return () => {
      if (map) {
        google.maps.event.addListener(map, 'mousedown', e => {
          if (e?.domEvent?.shiftKey) {
            map.setOptions({ draggable: false });
            setStartLatLng(e.latLng);
            setGridBoundingBox(null);
          }
        });

        google.maps.event.addListener(map, 'mousemove', e => {
          if (
            e?.domEvent?.shiftKey &&
            (e?.domEvent?.button === 1 || e?.domEvent?.buttons === 1) &&
            startLatLng
          ) {
            setLastMousePosition({ x: e?.pixel?.x, y: e?.pixel?.y });
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(startLatLng);
            bounds.extend(e.latLng);
            if (bounds) {
              setSelectionGridBoundingBox({
                bounds: {
                  north: bounds.getNorthEast().lat(),
                  south: bounds.getSouthWest().lat(),
                  east: bounds.getNorthEast().lng(),
                  west: bounds.getSouthWest().lng(),
                },
              });
            }
          } else if (
            (e?.domEvent?.button === 0 || e?.domEvent?.buttons === 0) &&
            startLatLng
          ) {
            setSelectionGridBoundingBox(null);
          }
        });

        google.maps.event.addListener(map, 'mouseup', e => {
          if (!!e.latLng)
            setCenterLatLng({ lat: e?.latLng.lat(), lng: e?.latLng.lng() });

          if (e?.domEvent?.shiftKey && startLatLng) {
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(startLatLng);
            bounds.extend(e.latLng);

            if (bounds) {
              setGridBoundingBox({
                bounds: {
                  north: bounds.getNorthEast().lat(),
                  south: bounds.getSouthWest().lat(),
                  east: bounds.getNorthEast().lng(),
                  west: bounds.getSouthWest().lng(),
                },
              });
            }
            map.setOptions({ draggable: true });
            setSelectionGridBoundingBox(null);
            setLastSelectByClick(false);
          }
        });

        ///Remove rectangle on map click
        google.maps.event.addListener(map, 'click', () => {
          setGridBoundingBox(null);
          setSelectedFullOrders([]);
          setSelectedOrders([]);
          setOverlayWarehouseVisible(false);
        });
      }
    };
  }, [map, startLatLng]);

  ///Multi selection drag -> show rectangle
  useEffect(() => {
    handleShowRectangle();
  }, [handleShowRectangle]);

  const handleShowSelectionBox = useCallback(e => {
    if (map) {
      if (e?.domEvent?.shiftKey && startLatLng) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(startLatLng);
        bounds.extend(e.latLng);
        if (bounds) {
          setGridBoundingBox({
            bounds: {
              north: bounds.getNorthEast().lat(),
              south: bounds.getSouthWest().lat(),
              east: bounds.getNorthEast().lng(),
              west: bounds.getSouthWest().lng(),
            },
          });
        }
        map.setOptions({ draggable: true });
        setSelectionGridBoundingBox(null);
      }
    }
  }, []);

  const handleGridBoundingBox = useMemo(() => {
    return () => {
      if (gridBoundingBox) {
        unassignedOrders?.filter(order => {
          const lat = order?._deck?.location?.geometry?.coordinates[1];
          const lng = order?._deck?.location?.geometry?.coordinates[0];
          const isFilteredOrder =
            lat < gridBoundingBox.bounds.north &&
            lat > gridBoundingBox.bounds.south &&
            lng < gridBoundingBox.bounds.east &&
            lng > gridBoundingBox.bounds.west;

          if (isFilteredOrder) {
            handleSelectOrders(null, order?._id, order);
          }
        });

        mapData?.markers?.assignedOrders?.filter(order => {
          const lat = order?.coordinates[1];
          const lng = order?.coordinates[0];
          const isFilteredOrder =
            lat < gridBoundingBox.bounds.north &&
            lat > gridBoundingBox.bounds.south &&
            lng < gridBoundingBox.bounds.east &&
            lng > gridBoundingBox.bounds.west;

          if (isFilteredOrder) {
            handleSelectOrders(null, order?._order?._id, order?._order);
          }
        });
      }
    };
  }, [gridBoundingBox, unassignedOrders, mapData?.markers?.assignedOrders]);

  useEffect(() => {
    handleGridBoundingBox();
  }, [handleGridBoundingBox]);

  useEffect(() => {
    setAssignedOrders_(orders);
  }, [orders]);

  // Update displayRoutes whenever the search changes
  const handleUpdateDisplayRoutes = useMemo(() => {
    return () => {
      if (
        (search !== '' && search?.startsWith('R')) ||
        search?.startsWith('r')
      ) {
        const filteredCodes = filteredRoundtrips.map(trip => trip.code);
        const updatedDisplayRoutes = displayRoutes.map(trip => ({
          ...trip,
          filtered: filteredCodes.includes(trip.code),
          options: filteredCodes.includes(trip.code)
            ? {
                ...trip.options,
                fillColor: '#2A59FF',
                fillOpacity: 0.2,
                strokeColor: '#2A59FF',
                strokeWeight: 3,
              }
            : { ...trip.options },
        }));

        setUpdatedDisplayRoutes(updatedDisplayRoutes);
        handleRoundtripFocus(filteredRoundtrips[0]);
      } else {
        const updatedDisplayRoutes = displayRoutes.map(trip => ({
          ...trip,
          filtered: false,
        }));

        setUpdatedDisplayRoutes(updatedDisplayRoutes);
      }
    };
  }, [filteredRoundtrips]);

  useEffect(() => {
    handleUpdateDisplayRoutes();
  }, [handleUpdateDisplayRoutes]);

  const [markerPos, setMarkerPos]: any = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [draggingFromRt, setDraggingFromRt] = useState('');
  const [hoveringOverRt, setHoveringOverRt] = useState('');
  const [hoveredStop, setHoveredStop] = useState('');

  const handleSetHoveringOverRt = useCallback(
    value => {
      setHoveringOverRt(value);
    },
    [hoveringOverRt],
  );

  const handleSetHoveredStop = useCallback(
    value => {
      setHoveredStop(value);
    },
    [hoveredStop],
  );

  const [lastInteractedWithOrder, setLastInteractedWithOrder] =
    useState<any>(null);
  const [mouseOverOrder, setMouseOverOrder] = useState<any>(null);

  const handleUnselectAll = useCallback(() => {
    if (selectedRoundtripDetails !== null && pinnedLayers?.length > 0) {
      return setPinnedLayers([]);
    }
    setGridBoundingBox(null);
    setSelectedFullOrders([]);
    setSelectedOrders([]);
    setPinnedLayers([]);
    setRoundtripClickOnMap(null);
    setSelectedRoundtrips([]);
    setSelectedFullOrders([]);
    setSelectedRoundtripDetails(null);
    setMouseOverOrder(null);
  }, []);

  const handlePinnedLayersChange = useMemo(() => {
    return () => {
      if (selectedFullOrders?.length > 0) {
        const order = selectedFullOrders[selectedFullOrders.length - 1];
        setLastInteractedWithOrder(order);
        setMouseOverOrder(order);
        return;
      } else if (pinnedLayers.length === 0 || viewMode) {
        return setLastInteractedWithOrder(null);
      } else {
        const orderId = pinnedLayers[pinnedLayers.length - 1];
        const result1 = orders?.filter(order => order?._id === orderId);
        const result2 = mapData?.markers?.assignedOrders?.filter(
          order => order?._order?._id === orderId,
        );
        const result = [result1[0] || result2[0]?._order];
        setLastInteractedWithOrder(result[0]);
        setMouseOverOrder(result[0]);
      }
    };
  }, [pinnedLayers, selectedFullOrders]);

  useEffect(() => {
    handlePinnedLayersChange();
  }, [handlePinnedLayersChange]);

  const handleOpenOrder = useCallback(() => {
    if (mouseOverOrder) {
      onDisplayOrder(mouseOverOrder);
    } else if (lastInteractedWithOrder) {
      onDisplayOrder(lastInteractedWithOrder);
    }
  }, [mouseOverOrder, lastInteractedWithOrder]);

  const handleSelectStop = useCallback(
    direction => {
      if (
        !selectedRoundtripDetails ||
        selectedRoundtripDetails?.stops?.length === 0
      )
        return;
      const selectedRtStopsIds: any[] = [];
      selectedRoundtripDetails.stops.map(stop => {
        if (stop.type !== 'fuel' && stop.type !== 'maintenance') {
          selectedRtStopsIds.push(stop?._order?._id);
        }
      });

      if (pinnedLayers?.length === 0 && direction === 'right') {
        setPinnedLayers([selectedRtStopsIds[0]]);
      } else if (pinnedLayers?.length === 0 && direction === 'left') {
        setPinnedLayers([selectedRtStopsIds[selectedRtStopsIds?.length - 1]]);
      } else if (pinnedLayers?.length > 0) {
        const lastPinnedLayerId = pinnedLayers[pinnedLayers?.length - 1];
        selectedRtStopsIds.map((id, index) => {
          if (id === lastPinnedLayerId) {
            if (
              direction === 'right' &&
              index === selectedRtStopsIds?.length - 1
            ) {
              setPinnedLayers([selectedRtStopsIds[0]]);
            } else if (direction === 'right') {
              setPinnedLayers([selectedRtStopsIds[index + 1]]);
            } else if (direction === 'left' && index === 0) {
              setPinnedLayers([
                selectedRtStopsIds[selectedRtStopsIds?.length - 1],
              ]);
            } else if (direction === 'left') {
              setPinnedLayers([selectedRtStopsIds[index - 1]]);
            }
          }
        });
      }
    },
    [selectedRoundtripDetails, pinnedLayers],
  );

  const handleRoundtripSelection = useCallback(
    num => {
      roundtrips.map(rt => {
        if (rt?.code) {
          const roundtripNumber = parseInt(rt?.code.replace('R', ''));
          if (roundtripNumber === num) {
            setSelectedRoundtripDetails(rt);
          }
        }
      });
    },
    [roundtrips],
  );

  const handleBindShortcuts = useMemo(() => {
    return () => {
      const shortcuts: any[] = [];
      for (let i = 1; i < 10; i++) {
        shortcuts.push({
          keybinds: [`ctrl+${i}`, `command+${i}`],
          callback: () => handleRoundtripSelection(i),
        });
      }
      shortcuts.map(shortcut => {
        bindShortcut(shortcut.keybinds, shortcut.callback);
      });
    };
  }, [roundtrips]);

  useEffect(() => {
    handleBindShortcuts();
  }, [handleBindShortcuts]);

  const handleRoundtripFocus = useCallback(
    roundtrip => {
      if (!map) return;

      const allOrdersCoordinates: any[] = [];
      roundtrip?.stops?.map(order => {
        if (order?.type === 'fuel' || order?.type === 'maintenance') {
          return allOrdersCoordinates.push(
            order?._place?.location?.geometry?.coordinates,
          );
        }
        allOrdersCoordinates.push(
          order?._order?._deck?.location?.geometry?.coordinates,
        );
      });
      allOrdersCoordinates.push(
        roundtrip?._startPlace?.location?.geometry?.coordinates,
      );

      if (allOrdersCoordinates.length === 0) setMapCenter('');

      const bounds = new window.google.maps.LatLngBounds();
      allOrdersCoordinates.forEach(location => {
        if (!location) return;
        bounds.extend({
          lat: parseFloat(location[1]),
          lng: parseFloat(location[0]),
        });
      });
      map.fitBounds(bounds);
    },
    [map],
  );

  const [mapCenter, setMapCenter] = useState<any>(center);
  const [mapZoom, setMapZoom] = useState<any>(11);

  const handleMapFocus = useCallback(() => {
    if (!map) return;
    const allOrdersCoordinates: any[] = [];

    // add fuel stops coordinates
    mapData?.markers?.fuelStops?.map(stop => {
      allOrdersCoordinates.push(stop.coordinates);
    });

    // add maintenance stops coordinates
    mapData?.markers?.maintenanceStops?.map(stop => {
      allOrdersCoordinates.push(stop.coordinates);
    });

    // add orders stops coordinates
    mapData?.markers?.assignedOrders?.map(stop => {
      allOrdersCoordinates.push(stop.coordinates);
    });

    unassignedOrders?.map(order => {
      allOrdersCoordinates.push(order?._deck?.location?.geometry?.coordinates);
    });

    // warehouse coordinates
    allOrdersCoordinates.push(
      clusterData?._deck?.location?.geometry?.coordinates,
    );

    if (allOrdersCoordinates.length === 0) {
      setMapCenter(center);
      map?.setZoom(11);
    } else if (allOrdersCoordinates.length <= 1) {
      setMapCenter(allOrdersCoordinates[0]);
      map?.setZoom(11);
    } else if (allOrdersCoordinates.length > 1) {
      const bounds = new window.google.maps.LatLngBounds();
      allOrdersCoordinates.forEach(location => {
        if (!location) return;
        bounds.extend({
          lat: parseFloat(location[1]),
          lng: parseFloat(location[0]),
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, mapCenter, mapZoom]);

  useEffect(() => {
    if (!map) return;
    const currentZoom = map?.getZoom();
    if (mapZoom && mapZoom !== currentZoom) {
      map?.setZoom(mapZoom);
    }
  }, [mapZoom]);

  const [count, setCount] = useState(0);

  const handleInitialMapFocus = useMemo(() => {
    return () => {
      if (!map || count > 2 || !mapData) return;
      handleMapFocus();
      setCount(count + 1);
    };
  }, [map, mapData, unassignedOrders, viewMode]);

  useEffect(() => {
    handleInitialMapFocus();
  }, [handleInitialMapFocus]);

  const handleMapZoom = useCallback(
    direction => {
      if (!map) return;
      const currentZoom = map.getZoom() || 1;
      if (direction === 'in') map?.setZoom(currentZoom + 1);
      else if (direction === 'out') map?.setZoom(currentZoom - 1);
    },
    [map],
  );

  const orderCardRef = useRef<any>(null);

  const shortcutsCallbacks = useMemo(() => {
    return {
      unselectAll: () => handleUnselectAll(),
      openOrder: () => handleOpenOrder(),
      selectStop: (direction: string) => handleSelectStop(direction),
      mapFocus: () => handleMapFocus(),
      mapZoom: (direction: string) => handleMapZoom(direction),
    };
  }, [
    handleUnselectAll,
    handleOpenOrder,
    handleSelectStop,
    handleMapFocus,
    handleMapZoom,
  ]);

  const [isolateRoundtrip, setIsolateRoundtrip] = useState(false);
  const [trackTruck, setTrackTruck] = useState(false);
  // const [trackTruckDisabled, setTrackTruckDisabled] = useState(false);

  const toggleIsolateRt = useCallback(() => {
    setIsolateRoundtrip(!isolateRoundtrip);
  }, [isolateRoundtrip]);

  const toggleTrackTruck = useCallback(() => {
    setTrackTruck(!trackTruck);
  }, [trackTruck]);

  useEffect(() => {
    if (!selectedRoundtripDetails) return;
    getDriverLocations({ tripId: selectedRoundtripDetails._id });
  }, [selectedRoundtripDetails]);

  const [truckCoordinates, setTruckCoordinates] = useState<
    { lat: number; lng: number }[]
  >([]);
  const [arrowRotation, setArrowRotation] = useState(0);

  const handleBearingCalculation = useMemo(() => {
    return () => {
      const formattedCoordinates: { lat: number; lng: number }[] = [];
      if (driverLocations?.length > 0) {
        driverLocations?.map(location => {
          formattedCoordinates.push({ lat: location[1], lng: location[0] });
        });
        setTruckCoordinates(formattedCoordinates);
        if (formattedCoordinates.length > 1) {
          const bearing = calculateBearing(
            formattedCoordinates[formattedCoordinates.length - 2],
            formattedCoordinates[formattedCoordinates.length - 1],
          );
          setArrowRotation(bearing);
        }
      }
    };
  }, [driverLocations]);

  useEffect(() => {
    handleBearingCalculation();
  }, [handleBearingCalculation]);

  const trackTruckDisabled = useMemo(() => {
    if (selectedRoundtripDetails && driverLocations?.length > 0) {
      const vehicleInfo = selectedRoundtripDetails?._vehicle;
      if (
        vehicleInfo?.gpsTrackingSystem?.toLowerCase() === 'fleetgo' &&
        selectedRoundtripDetails?.startAt
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [selectedRoundtripDetails, driverLocations]);

  const toggleUnlinkWarehouse = useCallback(() => {
    setUnlinkWarehouse(!unlinkWarehouse);
  }, [unlinkWarehouse]);

  const toggleShowOrderStatus = useCallback(() => {
    setShowOrderStatusActive(!showOrderStatusActive);
  }, [showOrderStatusActive]);

  useEffect(() => {
    if (!selectedRoundtripDetails?._id) return setIsolateRoundtrip(false);
    handleRoundtripFocus(selectedRoundtripDetails);
  }, [selectedRoundtripDetails]);

  useEffect(() => {
    setGridBoundingBox(null);
    setArrowRotation(0);
    setTruckCoordinates([]);
    setTrackTruck(false);
  }, [selectedRoundtripDetails]);

  useEffect(() => {
    if (!map) return;
    map.setOptions({
      keyboardShortcuts: false,
      mapTypeControl: false,
    });
  }, [map]);

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const ordersCount = useMemo(() => {
    var total = 0;
    total += mapData?.markers?.assignedOrders?.length || 0;
    // total += unassignedOrders_?.length || 0;
    return total;
  }, [roundtrips, mapData?.markers?.assignedOrders /*unassignedOrders_*/]);

  // used to help show the itemOrder card over the marker
  const [lastSelectByClick, setLastSelectByClick] = useState<boolean>(false);

  const handleSetLastSelectByClick = useCallback(
    value => {
      setLastSelectByClick(value);
    },
    [lastSelectByClick],
  );

  const AssignedOrdersMarkers = useMemo(() => {
    var viewAssignedOrders = true;
    if (debouncedFilters?.assigned?.length > 0) {
      const result = debouncedFilters?.assigned?.filter(
        filter => filter?.value === 'yes',
      );
      if (result?.length > 0) {
        viewAssignedOrders = true;
      } else {
        viewAssignedOrders = false;
      }
    } else {
      viewAssignedOrders = true;
    }
    return mapData?.markers?.assignedOrders?.map(order => {
      const places = {
        lat: order?.coordinates[1],
        lng: order?.coordinates[0],
      };

      let isRoundtripSelected = false;
      if (selectedRoundtripDetails?._id === order?.roundtripId) {
        isRoundtripSelected = true;
      }
      let isFiltered = false;
      updatedDisplayRoutes.map(p => {
        if (
          p.roundtripId === order?.roundtripId &&
          p.filtered === true &&
          !search?.startsWith('BC')
        ) {
          isFiltered = true;
        }
      });

      let isFilteredStop = false;

      const isSelected = isRoundtripSelected;
      // const isHighlighted = pinnedLayers?.includes(order?._order?._id);
      return (
        <React.Fragment key={order?._order?._id}>
          <ClientOrderMarker
            markerKey={order?._order?._id}
            position={places}
            isSelected={isSelected}
            type={RoundtripsType.Assigned}
            clusterer={clusterData}
            clientsOrders={order?._order}
            stop={order}
            index={order?.stopNumber}
            onDisplayOrder={onDisplayOrder}
            accordionStatus={accordionStatus}
            setAccordionStatus={setAccordionStatus}
            roundTripCopy={clusterData?.roundtrips || roundTripCopy}
            clusterId={clusterId}
            requestOrderData={requestOrderData}
            orders={orders}
            setOrders={setOrders}
            setOrderInitCount={setOrderInitCount}
            orderInitCount={orderInitCount}
            pinnedLayers={pinnedLayers}
            setPinnedLayers={setPinnedLayers}
            roundTripsCount={roundTripsCount}
            selectedOrders={selectedOrders}
            handleSelectOrders={handleSelectOrders}
            setAssignedOrders={() => {}}
            isDragging={isDragging}
            setIsDragging={setIsDragging}
            setDraggingFromRt={setDraggingFromRt}
            hoveredRtId={hoveringOverRt}
            setMouseOverOrder={setMouseOverOrder}
            orderCardRef={orderCardRef}
            viewMode={!!viewMode}
            viewAssignedOrders={viewAssignedOrders}
            lastSelectByClick={lastSelectByClick}
            handleSetLastSelectByClick={handleSetLastSelectByClick}
            showOrderStatusActive={showOrderStatusActive}
            // setMarkerRef={setMarkerRef}
          />
          {isFilteredStop && (
            <PulsingCircle>
              <CircleComponent
                center={places}
                options={{
                  ...circleOptions,
                }}
                map={map}
              />
            </PulsingCircle>
          )}
          {hoveredStop === order?._id && (
            <CircleComponent
              center={places}
              options={{
                ...circleOptions,
              }}
              map={map}
            />
          )}
        </React.Fragment>
      );
    });
  }, [
    mapData,
    pinnedLayers,
    hoverableRoute,
    selectedOrders,
    orders,
    debouncedFilters,
    showOrderStatusActive,
  ]);

  const UnassignedOrdersMarkers = useMemo(() => {
    var viewUnassignedOrders = true;
    if (debouncedFilters?.assigned?.length > 0) {
      const result = debouncedFilters?.assigned?.filter(
        filter => filter?.value === 'no',
      );
      if (result?.length > 0) {
        viewUnassignedOrders = true;
      } else {
        viewUnassignedOrders = false;
      }
    } else {
      viewUnassignedOrders = true;
    }
    return unassignedOrders_?.map((order, index) => {
      const places = {
        lat: order?._deck?.location?.geometry?.coordinates[1],
        lng: order?._deck?.location?.geometry?.coordinates[0],
      };
      return (
        <>
          <ClientOrderMarker
            markerKey={order._id}
            position={places}
            type={RoundtripsType.Unassigned}
            clusterer={clusterData}
            clientsOrders={order}
            stop={order}
            index={index}
            onDisplayOrder={onDisplayOrder}
            accordionStatus={accordionStatus}
            setAccordionStatus={setAccordionStatus}
            roundTripCopy={roundTripCopy}
            clusterId={clusterId}
            requestOrderData={requestOrderData}
            orders={orders}
            setOrders={setOrders}
            setOrderInitCount={setOrderInitCount}
            orderInitCount={orderInitCount}
            pinnedLayers={pinnedLayers}
            setPinnedLayers={setPinnedLayers}
            roundTripsCount={roundTripsCount}
            selectedOrders={selectedOrders}
            handleSelectOrders={handleSelectOrders}
            isDragging={isDragging}
            setIsDragging={setIsDragging}
            setMouseOverOrder={setMouseOverOrder}
            orderCardRef={orderCardRef}
            viewMode={!!viewMode}
            viewUnassignedOrders={viewUnassignedOrders}
            lastSelectByClick={lastSelectByClick}
            handleSetLastSelectByClick={handleSetLastSelectByClick}
            // setMarkerRef={setMarkerRef}
          />
          <If condition={order.isFilteredOrder}>
            <PulsingCircle>
              <CircleComponent
                center={places}
                options={{
                  ...circleOptions,
                }}
                map={map}
              />
            </PulsingCircle>
          </If>
        </>
      );
    });
  }, [
    unassignedOrders_,
    pinnedLayers,
    selectedOrders,
    hoveringOverRt,
    orders,
    debouncedFilters,
  ]);

  const Polygons = useMemo(() => {
    var viewAssignedOrdersRoutes = true;
    if (debouncedFilters?.assigned?.length > 0) {
      const result = debouncedFilters?.assigned?.filter(
        filter => filter?.value === 'yes',
      );
      if (result?.length > 0) {
        viewAssignedOrdersRoutes = true;
      } else {
        viewAssignedOrdersRoutes = false;
      }
    } else {
      viewAssignedOrdersRoutes = true;
    }

    return mapData?.polygons?.map((p, index) => {
      let selected = false;
      if (selectedRoundtripDetails?._id === p.roundtripId) {
        selected = true;
      }
      return (
        <React.Fragment key={index}>
          {(hoverableRoute === p.roundtripId && hoverableRoute !== null) ||
          hoveringOverRt === p.roundtripId ||
          selectedRoundtripDetails?._id === p.roundtripId ? (
            <>
              {p.canceledPaths.length === 0 || !selected ? (
                <PolygonComponent
                  paths={p.paths}
                  fillColor={
                    selected ? themes?.default?.primary : themes?.default?.nobel
                  }
                  fillOpacity={viewAssignedOrdersRoutes ? 0.2 : 0.1}
                  strokeColor={themes?.default?.strokePrimary}
                  strokeOpacity={viewAssignedOrdersRoutes ? 1 : 0.5}
                  strokeWeight={2}
                  map={map}
                  onMouseOver={() => {
                    setHoverableRoute(p.roundtripId);
                    setHoveringOverRt(p.roundtripId);
                  }}
                  onMouseOut={() => {
                    setHoverableRoute(null);
                    setHoveringOverRt('');
                  }}
                  onClick={() => {
                    setRoundtripClickOnMap(p.roundtripId);
                  }}
                  visible={true}
                />
              ) : (
                p.canceledPaths.length > 0 &&
                selected && (
                  <>
                    {p.canceledPaths.map((cp, idx) => (
                      <PolygonComponent
                        key={idx}
                        paths={cp}
                        fillColor={
                          selected ? 'transparent' : themes?.default?.nobel
                        }
                        fillOpacity={0.2}
                        strokeColor={themes?.default?.redA400}
                        strokeOpacity={0.4}
                        strokeWeight={3}
                        map={map}
                        onMouseOut={() => {
                          setHoverableRoute(null);
                          setHoveringOverRt('');
                        }}
                        onClick={() => {
                          setRoundtripClickOnMap(p.roundtripId);
                        }}
                        visible={true}
                      />
                    ))}
                    <PolygonComponent
                      paths={p.paths.filter(path => path.type !== 'canceled')}
                      fillColor={
                        selected
                          ? themes?.default?.primary
                          : themes?.default?.nobel
                      }
                      fillOpacity={0.2}
                      strokeColor={theme.strokePrimary}
                      strokeOpacity={1}
                      strokeWeight={4}
                      zIndex={3}
                      map={map}
                      onMouseOut={() => {
                        setHoverableRoute(null);
                        setHoveringOverRt('');
                      }}
                      onClick={() => {
                        setRoundtripClickOnMap(p.roundtripId);
                      }}
                      visible={true}
                    />
                  </>
                )
              )}
              <CustomTooltip text={p.code} coordinates={p.center} />
              {/* {isDragging && p.paths.length - 2 > 1 ? (
                <DropZone
                  text={p.code}
                  coordinates={p.center}
                  zoom={zoom}
                  orders={orders}
                  setOrders={setOrders}
                  roundtripId={p.roundtripId}
                  draggingFromRt={draggingFromRt}
                  setDraggingFromRt={setDraggingFromRt}
                />
              ) : isDragging && p.paths.length - 2 === 1 ? (
                <DropZone
                  text={p.code}
                  coordinates={p.center}
                  zoom={zoom}
                  orders={orders}
                  setOrders={setOrders}
                  roundtripId={p.roundtripId}
                  draggingFromRt={draggingFromRt}
                  setDraggingFromRt={setDraggingFromRt}
                />
              ) : null} */}
            </>
          ) : !isolateRoundtrip ? (
            <>
              <PolygonComponent
                paths={p.paths}
                fillColor={p?.options?.fillColor}
                fillOpacity={p?.options?.fillOpacity}
                strokeColor={p?.options?.strokeColor}
                strokeOpacity={p?.options?.strokeOpacity}
                strokeWeight={p?.options?.strokeWeight}
                map={map}
                onMouseOver={() => {
                  setHoverableRoute(p.roundtripId);
                  setHoveringOverRt(p.roundtripId);
                }}
                onMouseOut={() => {
                  setHoverableRoute(null);
                  setHoveringOverRt('');
                }}
                visible={true}
              />
              {isDragging && p.paths.length - 2 > 1 ? (
                <DropZone
                  text={p.code}
                  coordinates={p.center}
                  zoom={zoom}
                  orders={orders}
                  setOrders={setOrders}
                  roundtripId={p.roundtripId}
                  draggingFromRt={draggingFromRt}
                  setDraggingFromRt={setDraggingFromRt}
                />
              ) : isDragging && p.paths.length - 2 === 1 ? (
                <DropZone
                  text={p.code}
                  coordinates={p.center}
                  zoom={zoom}
                  orders={orders}
                  setOrders={setOrders}
                  roundtripId={p.roundtripId}
                  draggingFromRt={draggingFromRt}
                  setDraggingFromRt={setDraggingFromRt}
                />
              ) : null}
            </>
          ) : null}
        </React.Fragment>
      );
    });
  }, [
    mapData,
    selectedRoundtripDetails,
    hoverableRoute,
    hoveringOverRt,
    isDragging,
    isolateRoundtrip,
    orders,
    debouncedFilters,
  ]);

  const Polylines = useMemo(() => {
    if (map && trackTruck && truckCoordinates?.length > 0)
      return (
        <>
          <PolylineComponent
            path={truckCoordinates}
            options={{
              strokeColor: themes?.default?.purple400,
              strokeOpacity: 1.0,
              strokeWeight: 1.4,
              zIndex: 4,
            }}
            map={map}
          />
          {truckCoordinates?.length > 0 &&
            truckCoordinates?.map(point => {
              return (
                <AdvancedMarker position={point}>
                  <img
                    style={{ marginBottom: '-8px' }}
                    src={PolylinePoints}
                    width={8}
                    height={8}
                  />
                </AdvancedMarker>
              );
            })}
          <AdvancedMarker
            position={truckCoordinates[truckCoordinates?.length - 1 || 0]}
          >
            <span>
              <img
                src={PolylineArrow}
                alt=""
                style={{
                  transform: `rotate(${arrowRotation}deg)`,
                  transformOrigin: 'bottom',
                }}
              />
            </span>
          </AdvancedMarker>
        </>
      );
    else return <></>;
  }, [
    trackTruck,
    driverLocations,
    truckCoordinates,
    selectedRoundtripDetails,
    arrowRotation,
  ]);

  const VehilcesMarkers = useMemo(() => {
    return mapData?.markers?.vehicles?.map(v => (
      <MarkerVehicle
        key={v._id}
        isSelected={selectedRoundtrips?.includes(v?.roundtripId)}
        vehicle={v}
        onClick={handleClickVehicle}
      />
    ));
  }, [mapData?.markers?.vehicles, selectedRoundtrips]);

  const WarehouseMarker = useMemo(() => {
    return (
      <>
        {mapData?.markers?.warehouses?.map(warehouse => (
          <MarkerStep
            label={warehouse?.shortName}
            key={warehouse?._id}
            type="deck"
            place={warehouse}
            warehouseData={warehouseData}
            roundTripsCount={roundTripsCount}
            ordersCount={ordersCount}
            overlayWarehouseVisible={overlayWarehouseVisible}
            setOverlayWarehouseVisible={setOverlayWarehouseVisible}
          />
        ))}
      </>
    );
  }, [
    mapData?.markers?.warehouses,
    warehouseData,
    ordersCount,
    overlayWarehouseVisible,
  ]);

  const FuelStopsMarkers = useMemo(() => {
    return mapData?.markers?.fuelStops?.map(s => {
      return (
        <MarkerStep
          type="stop"
          stopType={s.type}
          key={s._id}
          onClickOrder={onSelectOrder}
          date={s.estimatedArrivalAt || s.updatedAt}
          status={s._order?.status || s.status}
          place={s}
          order={s._order}
          data={s}
          index={s.place}
          displayedStopKey={displayedStopKey}
          setDisplayedStopKey={setDisplayedStopKey}
          roundTripCopy={roundTripCopy}
          setAssignedOrders={() => {}}
        ></MarkerStep>
      );
    });
  }, [mapData]);

  const MaintenanceStopsMarkers = useMemo(() => {
    return mapData?.markers?.maintenanceStops?.map(s => {
      return (
        <MarkerStep
          type="stop"
          stopType={s.type}
          key={s._id}
          onClickOrder={onSelectOrder}
          date={s.estimatedArrivalAt || s.updatedAt}
          status={s._order?.status || s.status}
          place={s}
          order={s._order}
          data={s}
          index={s.place}
          displayedStopKey={displayedStopKey}
          setDisplayedStopKey={setDisplayedStopKey}
          roundTripCopy={roundTripCopy}
          setAssignedOrders={() => {}}
        ></MarkerStep>
      );
    });
  }, [mapData]);

  const GridBoundingBox = useMemo(() => {
    return (
      gridBoundingBox !== null && (
        <RectangleComponent
          bounds={gridBoundingBox.bounds}
          fillColor={'#2983D5'}
          fillOpacity={0.2}
          strokeColor={'#348BD8'}
          strokeOpacity={1}
          strokeWeight={2}
          map={map}
        />
      )
    );
  }, [gridBoundingBox]);

  const SelectionGridBoundingBox = useMemo(() => {
    return (
      selectionGridBoundingBox !== null && (
        <RectangleComponent
          bounds={selectionGridBoundingBox.bounds}
          fillColor={'#2983D5'}
          fillOpacity={0.1}
          strokeColor={'#348BD8'}
          strokeOpacity={1}
          strokeWeight={2}
          map={map}
          onMouseUp={e => handleShowSelectionBox(e)}
        />
      )
    );
  }, [selectionGridBoundingBox]);

  return !isloadindCluster ? (
    <MapWrapper
      id="mapContainer"
      onDragOver={e => {
        e.preventDefault();
      }}
      onDrop={() => {
        setIsDragging(false);
        setDraggingFromRt('');
      }}
    >
      <Stack direction={'row'} sx={{ height: '100%' }}>
        <If condition={!isloadindCluster}>
          <LeftMenu
            roundtrips={filteredRoundtrips}
            updateRoundtripStatus={updateRoundtripStatus}
            userContext={userContext}
            selectedRoundtrips={selectedRoundtrips}
            handleSelectRoundtrip={handleSelectRoundtrip}
            handleClickOpen={handleClickOpen}
            handleClickOpenDelete={handleClickOpenDelete}
            viewMode={viewMode || false}
            setAddModalOpen={setAddModalOpen}
            selectedRoundtripDetails={selectedRoundtripDetails}
            setSelectedRoundtripDetails={handleSetSelectedRoundtripDetails}
            orders={orders}
            setOrders={setOrders}
            handleOrderClick={handleLeftMenuOrderClick}
            setRoundtripEdit={setRoundtripEdit}
            pinnedOrders={pinnedLayers}
            hoveringOverRt={hoveringOverRt}
            setHoveringOverRt={handleSetHoveringOverRt}
            hoveredStop={hoveredStop}
            setHoveredStop={handleSetHoveredStop}
            assignedOrders={mapData?.markers?.assignedOrders}
          />
        </If>

        <WeatherTagWrapper viewMode={!!viewMode} zoomedIn={!!zoomedIn}>
          <WeatherTag latLng={weatherLatLng || center} />
        </WeatherTagWrapper>
        {/* <DeckGL initialViewState={INITIAL_VIEW_STATE} controller={true}> */}
        {/* <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={mapZoom}
          onLoad={map => {
            setMap(map);
          }}
          options={{
            styles: defaultMapStyle,
            mapTypeControl: false,
            disableDefaultUI: true,
            keyboardShortcuts: false,
          }}
          onZoomChanged={() => {
            setZoom(map?.getZoom() || 11);
            setMapZoom(map?.getZoom() || 11);
          }}
        > */}
        <MapComponent
          id={'ef6369f36e7c6f39'}
          mapId={'ef6369f36e7c6f39'}
          styles={MAP_CONFIG.styles}
          defaultCenter={mapCenter}
          defaultZoom={mapZoom}
          disableDefaultUI={true}
          onZoomChanged={() => {
            setZoom(map?.getZoom() || 11);
            setMapZoom(map?.getZoom() || 11);
          }}
        >
          {!viewMode && (
            <FilterChipsWrapper>
              <FilterChips filters={filters} setFilters={setFilters} />
            </FilterChipsWrapper>
          )}

          {AssignedOrdersMarkers}

          {UnassignedOrdersMarkers}

          {Polygons}

          {Polylines}

          {WarehouseMarker}

          {VehilcesMarkers}

          {FuelStopsMarkers}

          {MaintenanceStopsMarkers}

          {GridBoundingBox}

          {SelectionGridBoundingBox}

          {contextMenu && (
            <AssignedRoundtripsPopover
              contextMenu={contextMenu}
              position={contextMenuPosition}
              setOpenAddRoundtrip={setOpenRoundtrip}
            />
          )}

          {openRoundtrip && (
            <AddClientOrderPopover
              openAddRoundtrip={openRoundtrip}
              setOpenAddRoundtrip={setOpenRoundtrip}
              roundtrips={roundTripCopy}
              selectedFullOrders={selectedFullOrders}
              setSelectedFullOrders={setSelectedFullOrders}
              setSelectedOrders={setSelectedOrders}
            />
          )}

          <MapActionsWrapper viewMode={!!viewMode}>
            {selectedRoundtripDetails ? (
              <>
                <MapActionButton
                  icon={
                    trackTruck ? (
                      <img
                        style={{ height: '16px', width: '16px' }}
                        src={TrackTruckActive}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ height: '16px', width: '16px' }}
                        src={TrackTruck}
                        alt=""
                      />
                    )
                  }
                  action={toggleTrackTruck}
                  active={trackTruck}
                  tooltip={
                    !!trackTruckDisabled
                      ? t('vehicle_not_tracked')
                      : t('track_truck')
                  }
                  disabled={trackTruckDisabled}
                />
                <MapActionButton
                  icon={
                    isolateRoundtrip
                      ? IsolateRoundtripActive
                      : IsolateRoundtripIcon
                  }
                  action={toggleIsolateRt}
                  active={isolateRoundtrip}
                  tooltip={t('map_actions.isolate_roundtrip')}
                />
              </>
            ) : (
              <>
                <MapActionButton
                  icon={
                    unlinkWarehouse ? (
                      <img
                        style={{ height: '22px', width: '22px' }}
                        src={LinkWarehouse}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ height: '22px', width: '22px' }}
                        src={UnlinkWarehouse}
                        alt=""
                      />
                    )
                  }
                  action={toggleUnlinkWarehouse}
                  active={unlinkWarehouse}
                  tooltip={
                    unlinkWarehouse
                      ? t('link_warehouse')
                      : t('unlink_warehouse')
                  }
                />
                <MapActionButton
                  icon={
                    showOrderStatusActive ? (
                      <img
                        style={{ height: '22px', width: '22px' }}
                        src={ShowStatusActive}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ height: '22px', width: '22px' }}
                        src={ShowStatus}
                        alt=""
                      />
                    )
                  }
                  action={toggleShowOrderStatus}
                  active={showOrderStatusActive}
                  tooltip={
                    showOrderStatusActive
                      ? t('hide_stop_status')
                      : t('show_stop_status')
                  }
                />
              </>
            )}
            <MapZoomButton mapZoom={mapZoom} setMapZoom={setMapZoom} />
          </MapActionsWrapper>

          <MapLegendWrapper viewMode={!!viewMode}>
            {React.cloneElement(LegendComponent || <></>, {
              shortcutsCallbacks: shortcutsCallbacks,
            })}
          </MapLegendWrapper>
        </MapComponent>
        {/* </DeckGL> */}
      </Stack>
    </MapWrapper>
  ) : (
    <></>
  );
};

export default React.memo(Map);

const MapLegendWrapper = styled.div<{ viewMode?: boolean }>`
  position: absolute;
  bottom: ${props => (props.viewMode ? '14px' : '0')};
  left: 0;
  right: 0;
  z-index: 100;
`;

export const PulsingCircle = styled.div`
  box-shadow: 0 0 0 0 black;
  animation: pulse 2s infinite;
  cursor: pointer;
  z-index: 99;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 black;
    }
    50% {
      box-shadow: 0 0 0 5px black;
    }
    100% {
      box-shadow: 0 0 0 0 black;
    }
  }
`;

const WeatherTagWrapper = styled.div<{ viewMode: boolean; zoomedIn: boolean }>`
  position: absolute;
  top: ${props => (props.zoomedIn ? '48px' : props.viewMode ? '98px' : '48px')};
  right: 14px;
  z-index: 2000;
`;

const MapActionsWrapper = styled.div<{ viewMode: boolean }>`
  position: absolute;
  width: 32px;
  height: fit-content;
  bottom: ${props => (props.viewMode ? '72px' : '52px')};
  right: 11px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FilterChipsWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 12px;
  > div:first-child {
    padding: 0;
  }
`;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnhancedTableToolbar } from 'app/components/EnhancedTableToolbar';
import { TabsMenu } from 'app/components/TabsMenu';
import { InfoForm } from '../Tabs/InfoForm';
import { Branch } from '../Tabs/Branch';
import { AddIconButton } from 'app/components/AddIconButton';
import { DrawerComponent } from 'app/components/DrawerComponent';
import { BranchDrawer } from './BranchDrawer';

export const CustomerEventsTab = ({ customerDetail }) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('info');
  const [search, setSearch] = useState('');
  const [BranchDrawerOpen, setBranchDrawerOpen] = useState(false);

  const handleOnSwitchTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <DrawerComponent
        title={t('Add Branch')}
        open={BranchDrawerOpen}
        handleClose={() => setBranchDrawerOpen(false)}
        width={'839px'}
      >
        <BranchDrawer
        // onClose={() => setBranchDrawerOpen(false)}
        // customer={{}}
        // onOk={() => {}}
        // afterSubmit={afterSubmit}
        />
      </DrawerComponent>

      <div
        style={{
          position: 'sticky',
          zIndex: 1000,
          marginBottom: '10px',
        }}
      >
        <EnhancedTableToolbar
          search={selectedTab === 'Branches' ? search : undefined}
          setSearch={selectedTab === 'Branches' ? setSearch : undefined}
          leftMenu={
            <TabsMenu
              handleOnSwitchTab={handleOnSwitchTab}
              selectedTab={selectedTab}
              tabs={[
                {
                  value: 'info',
                  label: t('info'),
                },
                {
                  value: 'Branches',
                  label: t('Branches'),
                },
                {
                  value: 'Accounting',
                  label: t('customer_Accounting'),
                },
                {
                  value: 'Financials',
                  label: t('customer_Financials'),
                },
                {
                  value: 'Contracts',
                  label: t('customer_Contracts'),
                },
              ]}
            />
          }
          actionsComponents={
            <>
              {selectedTab === 'Branches' ? (
                <AddIconButton
                  onClick={() => {
                    setBranchDrawerOpen(true);
                  }}
                  tooltip={t(`Add_Customer`)}
                />
              ) : null}
            </>
          }
        />
      </div>

      {selectedTab === 'info' ? (
        <InfoForm customerDetail={customerDetail} />
      ) : selectedTab === 'Branches' ? (
        <Branch />
      ) : null}
    </>
  );
};

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useTranslation } from 'react-i18next';
import { MessageOptionsMenu } from '../MessageOptionsMenu';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface Props {
  messageId: string;
  type: 'sent' | 'received';
  similarToPrevMessage: boolean;
  content: string;
  contentType: string;
  createdAt: string;
  handleMessageActions: Function;
  isEdited: boolean;
}

export const ChatMessage: React.FC<Props> = ({
  messageId,
  type,
  similarToPrevMessage,
  content,
  contentType,
  createdAt,
  handleMessageActions,
  isEdited,
}) => {
  const { t } = useTranslation();
  const formatTimestamp = timestamp => {
    const date = dayjs(timestamp);

    if (date.isToday()) {
      return date.format('HH:mm');
    } else if (date.isYesterday()) {
      return t('yesterday');
    } else {
      return date.format('D MMMM, YYYY');
    }
  };

  const canEdit = useMemo(() => {
    if (contentType !== 'text' || type !== 'sent') return false;

    const allowedEditDurationInMinutes = 10;
    const editTimeUnix = allowedEditDurationInMinutes * 60 * 1000;

    const nowUnix = new Date().getTime();
    const createdAtUnix = new Date(createdAt).getTime();

    if (nowUnix - editTimeUnix > createdAtUnix) {
      return false;
    } else {
      return true;
    }
  }, [contentType, type, createdAt]);

  return (
    <MessageContainer
      contentType={contentType}
      type={type}
      similarToPrevMessage={similarToPrevMessage}
      canEdit={canEdit}
    >
      {contentType === 'images' ? (
        <img src={content} />
      ) : (
        <>
          {content}
          {createdAt && (
            <div className="time">
              {isEdited && t('common.edited')} {formatTimestamp(createdAt)}
            </div>
          )}
        </>
      )}
      <MessageOptionsMenuContainer className="messageOptionsMenuContainer">
        <MessageOptionsMenu
          messageId={messageId}
          messageContent={content}
          createdAt={createdAt}
          contentType={contentType}
          messageType={type}
          handleMessageActions={handleMessageActions}
        />
      </MessageOptionsMenuContainer>
    </MessageContainer>
  );
};

export const MessageContainer = styled.div<{
  type: 'sent' | 'received';
  similarToPrevMessage: boolean;
  canEdit: boolean;
  contentType?: string;
}>(props => ({
  width: 'fit-content',
  maxWidth: '400px',
  padding: props.contentType === 'images' ? '4px' : '8px 12px 4px 12px',
  borderRadius: '8px',
  marginBottom: props.similarToPrevMessage ? '4px' : '12px',
  fontFamily: 'Roboto',
  fontSize: '0.85rem',
  lineHeight: '20px',
  color:
    props.type === 'sent'
      ? themes?.default?.chatMessageSent
      : themes?.default?.chatMessageRecieved,
  background:
    props.type === 'sent'
      ? themes?.default?.primaryLight1
      : themes?.default?.whisper,
  alignSelf: props.type === 'sent' ? 'flex-end' : 'flex-start',
  display: 'flex',
  gap: '8px',
  '.time': {
    fontSize: '0.71rem',
    lineHeight: '11.72px',
    textAlign: 'right',
    alignSelf: 'flex-end',
    textTransform: 'lowercase',
    color:
      props.type === 'sent'
        ? themes?.default?.strokePrimary
        : themes?.default?.nobel,
  },
  position: 'relative',
  '&:hover': props.canEdit
    ? {
        '.messageOptionsMenuContainer': {
          opacity: 1,
          background:
            props.type === 'sent'
              ? `${themes?.default?.primaryLight1}F2`
              : `${themes?.default?.whisper}F2`,
        },
      }
    : {},
  img: {
    height: 400,
    borderRadius: 6,
    objectFit: 'contain',
  },
}));

export const MessageOptionsMenuContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 42px;
  padding: 0px 8px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  opacity: 0;
  transition-duration: 0.2s;
`;

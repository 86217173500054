import { HeadCell } from 'interfaces/customers';
import { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { IconButton, Stack } from '@mui/material';
import { ColumnPopover } from 'app/components/TableComponent/ColumnPopover';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import SearchField from 'app/components/Search/SearchField';
import { NO_DATA_REGULAR } from 'app/components/const';
import { GRID, MAP, REGULAR } from 'app/components/TableComponent/constants';
import { clearAllAction } from 'functions/clearAllAction';
import { selectAllAction } from 'functions/selectAllAction';
import { changeColumnAction } from 'functions/changeColumnAction';
import { RegularPageTitle } from '../TableComponent/helpers/grid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import If from '../If';
import { themes } from 'styles/theme/themes';
import { ComponentsStack } from './components/ComponentsStack';
import { TableViewPopover } from 'styles/components/muitable/TableViewPopover';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { VerticalDivider } from './components/VerticalDivider';

interface EnhancedTableToolbarProps {
  numSelected?: number;
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  headCells?: HeadCell[];
  heads?: any[];
  filterComponents?: JSX.Element;
  setHeadCells?: any;
  totalRows?: number;
  onAddClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  actionsComponents?: JSX.Element;
  onSelectComponents?: JSX.Element;
  leftMenu?: JSX.Element;
  tabMenuCenter?: JSX.Element;
  disabled?: boolean;
  selected?: string[];
  title?: string;
}
// Hook

export const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
  headCells,
  numSelected = 0,
  setSearch,
  setHeadCells,
  filterComponents,
  totalRows,
  search,
  onAddClick,
  actionsComponents,
  onSelectComponents,
  disabled,
  selected,
  title,
  leftMenu,
  tabMenuCenter,
}) => {
  const { t } = useTranslation();

  const hideActions = totalRows !== undefined && totalRows === 0;
  const theme = useSelector(selectTheme);

  return (
    <Toolbar
      sx={{
        pl: '1rem !important',
        pr: '1rem !important',
        background: 'white',
        minHeight: 'fit-content !important',
        borderBottom: `1px solid ${themes?.default?.lightFill}`,
        height: themes.default.toolBarHeight,
      }}
    >
      <ToolbarContainer>
        <RegularPageTitle>
          <span style={{ marginRight: '16px' }}>{t(title || '')}</span>{' '}
          {leftMenu || null}
        </RegularPageTitle>
        {tabMenuCenter || null}
        <If
          condition={!numSelected}
          otherwise={
            <div
              style={{
                justifySelf: 'flex-end',
                display: 'flex',
                marginLeft: 'auto',
                alignItems: 'center',
              }}
            >
              {onSelectComponents}
            </div>
          }
        >
          <ComponentsStack>
            {!!setSearch && (
              <SearchField
                value={search || ''}
                setValue={setSearch}
                disabled={disabled || false}
                textFieldProps={{
                  placeholder: t('common.place_holders.search'),
                  label: '',
                  margin: 'none',
                }}
              />
            )}

            {!!headCells && (
              <ColumnPopover
                headCells={headCells}
                setHeadCells={setHeadCells}
                changeColumn={(id, value) => {
                  changeColumnAction(id, value, headCells, setHeadCells);
                }}
                clearAll={() => {
                  clearAllAction(headCells, setHeadCells);
                }}
                selectAll={() => {
                  selectAllAction(headCells, setHeadCells);
                }}
                name={disabled ? NO_DATA_REGULAR : REGULAR}
              />
            )}
            <If condition={!!filterComponents}>{filterComponents}</If>

            <If condition={!!actionsComponents}>
              <VerticalDivider />
              {actionsComponents}
            </If>

            <If
              condition={
                !!(
                  onAddClick &&
                  (!search || search === '') &&
                  numSelected === 0 &&
                  !(totalRows === 0)
                )
              }
            >
              <VerticalDivider />
              <IconButton color="primary" onClick={onAddClick}>
                <AddCircleOutlinedIcon sx={{ width: '32px', height: '32px' }} />
              </IconButton>
            </If>
          </ComponentsStack>
        </If>
      </ToolbarContainer>
    </Toolbar>
  );
};

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 100%;
  height: 100%;
`;

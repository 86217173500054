import { useEffect, useState, useMemo, useCallback } from 'react';
import { ItemOrder } from 'app/pages/AddRoundTrips/components/ItemOrder';
import { useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import './Styles/InfoWindow.css';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import MarkerPinBlue from 'assets/img/map/CustomerMap/MarkerPinBlue.png';
import MarkerPinGray from 'assets/img/map/CustomerMap/MarkerPinGray.png';
import MarkerPinOrange from 'assets/img/map/CustomerMap/MarkerPinOrange.png';
import MarkerPinOrangeOutlined from 'assets/img/map/CustomerMap/MarkerPinOrangeOutlined.png';
import MarkerPinBlueOutlined from 'assets/img/map/CustomerMap/MarkerPinBlueOutlined.png';
import MarkerPinGreen from 'assets/img/map/CustomerMap/MarkerPinGreen.png';
import MarkerPinGreenHovered from 'assets/img/map/CustomerMap/MarkerPinGreenHoveredSelected.png';
import MarkerPinGreenHoveredSelected from 'assets/img/map/CustomerMap/MarkerPinGreenHoveredSelected.png';
import MarkerPinRed from 'assets/img/map/CustomerMap/MarkerPinRed.png';
import MarkerPinRedOutlined from 'assets/img/map/CustomerMap/MarkerPinRedOutlined.png';
import { useToaster } from 'hooks/useToaster';
import { useUpdateRoundTripMutation } from 'common/services/roundtripApi';
import Map from '..';
import { RoundtripsType } from 'app/pages/RoundTrips/data/types';
import { Tooltip } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { X, Check } from '@phosphor-icons/react';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import type { Marker } from '@googlemaps/markerclusterer';

interface ClientOrderMarkerProps {
  markerKey: any;
  position: any;
  isSelected?: any;
  type: any;
  clusterer?: any;
  clusterId?: any;
  clientsOrders: any;
  stop?: any;
  index: any;
  onDisplayOrder: any;
  accordionStatus?: any;
  setAccordionStatus?: any;
  roundTripCopy: any;
  requestOrderData?: any;
  orders?: any;
  setOrders?: any;
  setOrderInitCount?: any;
  orderInitCount?: any;
  pinnedLayers?: any;
  setPinnedLayers?: any;
  roundTripsCount?: any;
  selectedOrders?: any;
  handleSelectOrders?: any;
  isDragging?: any;
  setIsDragging?: any;
  setAssignedOrders?: any;
  setDraggingFromRt?: any;
  hoveredRtId?: any;
  setMouseOverOrder?: any;
  orderCardRef?: any;
  viewMode?: boolean;
  viewAssignedOrders?: boolean;
  viewUnassignedOrders?: boolean;
  lastSelectByClick?: boolean;
  handleSetLastSelectByClick?: any;
  showOrderStatusActive?: boolean;
  setMarkerRef?: any;
}
export const ClientOrderMarker: React.FC<ClientOrderMarkerProps> = ({
  markerKey,
  position,
  isSelected,
  type,
  clusterer,
  clusterId,
  clientsOrders,
  stop,
  index,
  onDisplayOrder,
  accordionStatus,
  setAccordionStatus,
  roundTripCopy,
  requestOrderData,
  orders,
  setOrders,
  setOrderInitCount,
  orderInitCount,
  pinnedLayers,
  setPinnedLayers,
  roundTripsCount,
  selectedOrders,
  handleSelectOrders,
  isDragging,
  setIsDragging,
  setAssignedOrders,
  setDraggingFromRt,
  hoveredRtId,
  setMouseOverOrder,
  orderCardRef,
  viewMode,
  viewAssignedOrders,
  viewUnassignedOrders,
  lastSelectByClick,
  handleSetLastSelectByClick,
  showOrderStatusActive,
  setMarkerRef,
}) => {
  const [updateRoundtripData] = useUpdateRoundTripMutation();
  const toast = useToaster();

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const dateValues = JSON.parse(
    searchParams.get('dateValue') as string,
  ) as Dayjs;
  const [dateValue, setDateValue] = useState<Dayjs>(dayjs(dateValues));

  const handleChange2 = useCallback(
    (id, e) => {
      const pinnedIds = [...pinnedLayers];

      if (pinnedLayers.includes(id)) {
        const newPinnedIds = pinnedIds.filter(p => p !== id);
        setPinnedLayers(newPinnedIds);
      } else {
        const newPinnedIds = [...pinnedIds, id];
        setPinnedLayers(newPinnedIds);
      }
    },
    [pinnedLayers],
  );

  const [checked, setChecked] = useState([]);

  const [overlayVisible, setOverlayVisible] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const handlePinnedLayers = useMemo(() => {
    return () => {
      let timeoutId;
      if (mouseOver === false && !pinnedLayers?.includes(clientsOrders?._id)) {
        timeoutId = setTimeout(() => {
          setOverlayVisible(false);
        }, 200);
      } else {
        setOverlayVisible(true);
      }

      return () => clearTimeout(timeoutId);
    };
  }, [mouseOver, pinnedLayers, overlayVisible]);

  useEffect(() => {
    handlePinnedLayers();
  }, [handlePinnedLayers]);

  const [selected, setSelected] = useState(false);
  const [dragOver, setDragOver] = useState(false);

  const handelSelectOrderState = useMemo(() => {
    return () => {
      if (selectedOrders.includes(markerKey)) {
        setSelected(true);
      } else {
        setSelected(false);
        setMouseOver(false);
      }

      if (pinnedLayers?.includes(markerKey)) {
        setOverlayVisible(true);
      } else if (
        selectedOrders[selectedOrders?.length - 1] === markerKey &&
        lastSelectByClick
      ) {
        setOverlayVisible(true);
      } else {
        setOverlayVisible(false);
        setMouseOver(false);
      }
    };
  }, [selectedOrders]);

  useEffect(() => {
    handelSelectOrderState();
  }, [handelSelectOrderState]);

  const handleDragStart = useCallback(
    e => {
      setIsDragging(true);
      var orderData = [
        {
          order: clientsOrders,
          _order: markerKey,
          _place: clientsOrders?._deck?._id ?? clientsOrders?._place?._id,
          type: clientsOrders?.type,
        },
      ];
      if (selectedOrders?.length > 0) {
        orderData = [];
        const selectedFullOrders: any = [];
        clusterer?.roundtrips?.map(trip => {
          trip?.stops?.map(stop => {
            if (selectedOrders.includes(stop?._order?._id)) {
              selectedFullOrders.push(stop?._order);
            }
          });
        });
        orders?.map(order => {
          if (selectedOrders.includes(order?._id)) {
            selectedFullOrders.push(order);
          }
        });
        selectedFullOrders?.map(selectedOrder => {
          orderData.push({
            order: selectedOrder,
            _order: selectedOrder?._id,
            _place: selectedOrder?._deck?._id ?? selectedOrder?._place?._id,
            type: selectedOrder?.type,
          });
        });
      }
      const orderDataString = JSON.stringify(orderData);
      e.dataTransfer.setData('application/json', orderDataString);
    },
    [isDragging, clientsOrders, markerKey, selectedOrders, clusterer, orders],
  );

  const isMarkerHighlighted = useMemo(() => {
    return pinnedLayers?.includes(stop?._id) || overlayVisible;
  }, [pinnedLayers, overlayVisible]);

  const notRespecting = useMemo(() => {
    if (clusterer && clientsOrders) {
      const clusterDeliveryDay = new Date(clusterer?.beginAt)
        .toLocaleDateString('en-EN', { weekday: 'long' })
        .toLowerCase();
      const clientDeliveryDays = clientsOrders?._deck?.deliveryDays;

      if (clientDeliveryDays?.includes(clusterDeliveryDay)) {
        return false;
      } else {
        return true;
      }
    }
  }, [clusterer, clientsOrders, orders]);

  const [rtId, setRtId] = useState('');
  const [rtStops, setRtStops]: any = useState([]);
  const [currentRt, setCurrentRt]: any = useState();

  const handleSetCurrentroundtripInfo = useMemo(() => {
    if (!roundTripCopy) return;
    return () => {
      roundTripCopy.map(rt => {
        rt?.stops.map(st => {
          if (st?._order?._id === clientsOrders?._id) {
            setRtId(rt._id);
            setRtStops(rt.stops);
            setCurrentRt(rt);
          }
        });
      });
    };
  }, [roundTripCopy]);

  useEffect(() => {
    handleSetCurrentroundtripInfo && handleSetCurrentroundtripInfo();
  }, [handleSetCurrentroundtripInfo]);

  const isMarkerSelected = useMemo(() => {
    return (
      isSelected ||
      pinnedLayers?.includes(clientsOrders?._id) ||
      overlayVisible ||
      hoveredRtId === rtId
    );
  }, [
    isSelected,
    pinnedLayers,
    overlayVisible,
    clientsOrders?._id,
    hoveredRtId,
    rtId,
  ]);

  const markerSrc = useMemo(() => {
    const delivered = clientsOrders?.status === 'delivered';
    const canceled = clientsOrders?.status === 'canceled';

    if (dragOver) {
      return MarkerPinBlueOutlined;
    }

    if (!isMarkerSelected) {
      return 'notSelected';
    }

    if (delivered) {
      return isMarkerHighlighted
        ? MarkerPinGreenHoveredSelected
        : MarkerPinGreen;
    }

    if (canceled) {
      return isMarkerHighlighted ? MarkerPinRedOutlined : MarkerPinRed;
    }

    return isMarkerHighlighted ? MarkerPinBlueOutlined : MarkerPinBlue;
  }, [isMarkerSelected, notRespecting, isMarkerHighlighted, dragOver]);

  const handleDragStart_ = useCallback(
    e => {
      setIsDragging(true);
      setDraggingFromRt(rtId);
      var orderData: any = [
        {
          oldIndex: index,
          orderId: markerKey,
          order: clientsOrders,
          _order: markerKey,
          _place: clientsOrders?._deck?._id ?? clientsOrders?._place?._id,
          type: clientsOrders?.type,
          isAssigned: true,
        },
      ];
      if (selectedOrders?.length > 0) {
        orderData = [];
        const selectedFullOrders: any = [];
        clusterer?.roundtrips?.map(trip => {
          trip?.stops?.map(stop => {
            if (selectedOrders.includes(stop?._order?._id)) {
              selectedFullOrders.push(stop?._order);
            }
          });
        });
        orders?.map(order => {
          if (selectedOrders.includes(order?._id)) {
            selectedFullOrders.push(order);
          }
        });
        selectedFullOrders?.map(selectedOrder => {
          orderData.push({
            order: selectedOrder,
            _order: selectedOrder?._id,
            _place: selectedOrder?._deck?._id ?? selectedOrder?._place?._id,
            type: selectedOrder?.type,
            isAssigned: true,
          });
        });
      }
      const orderDataString = JSON.stringify(orderData);
      e.dataTransfer.setData('application/json', orderDataString);
    },
    [
      isDragging,
      index,
      markerKey,
      clientsOrders,
      selectedOrders,
      clusterer,
      orders,
    ],
  );

  const drop = e => {
    e.preventDefault();
    setIsDragging(false);
    setDragOver(false);
    const srcDataString = e.dataTransfer.getData('application/json');
    const srcData = JSON.parse(srcDataString);
    const destIndex = parseInt(e.target.innerText) - 1;
    const srcIndex = srcData[0].oldIndex - 1;
    const srcId = srcData[0].orderId;
    const destId = e.target.offsetParent.id;

    let rtOrdersIds: string[] = [];
    currentRt.stops.map(stop => {
      if (stop.type === 'fuel' || stop.type === 'maintenance') {
        rtOrdersIds.push(stop._id);
      } else {
        rtOrdersIds.push(stop._order._id);
      }
    });

    if (rtOrdersIds.includes(srcId) && rtOrdersIds.includes(destId)) {
      handleSwitchStop(e, srcIndex, destIndex);
    }
  };

  const handleSwitchStop = async (e, oldIndex, newIndex) => {
    e?.stopPropagation();
    if (
      currentRt?.stops &&
      oldIndex >= 0 &&
      newIndex >= 0 &&
      oldIndex !== newIndex
    ) {
      const from = currentRt.stops[oldIndex];
      const to = currentRt.stops[newIndex];
      const newOrder = [...currentRt.stops];

      newOrder[newIndex] = from;
      newOrder[oldIndex] = to;

      setAssignedOrders(newOrder);
      await updateRoundtripData({
        id: rtId,
        data: { stops: newOrder },
      });
      toast(5000, 'success', 'roundtrip.switched_stop');
    }
  };

  const [hoveringOverMarker, setHoveringOverMarker] = useState(false);

  const orderStatus = useMemo(() => {
    return stop?._order?.status?.toLowerCase();
  }, [stop]);

  // const ref = useCallback(
  //   (marker: google.maps.marker.AdvancedMarkerElement) =>
  //     setMarkerRef(marker, stop?._id),
  //   [setMarkerRef, stop?._id],
  // );

  const MarkerComponent = useMemo(() => {
    return type === RoundtripsType.Unassigned ? (
      <AdvancedMarker
        // ref={ref}
        onClick={e => {
          handleSetLastSelectByClick(true);
          handleSelectOrders(e, markerKey, clientsOrders);
          setMouseOver(selected ? false : !mouseOver);
        }}
        clickable={true}
        position={position}
      >
        <MarkerDot
          draggable={!viewMode && clientsOrders?.status !== 'delivered'}
          onDragStart={handleDragStart}
          notRespecting={notRespecting}
          id={markerKey}
          onDoubleClick={e => {
            handleSelectOrders(e, markerKey, clientsOrders);
            handleChange2(markerKey, e);
          }}
          selected={selected}
          viewUnassignedOrders={viewUnassignedOrders}
        >
          <Tooltip
            arrow
            title={
              !overlayVisible &&
              hoveringOverMarker && (
                <p>
                  <span className="clientCode">
                    {clientsOrders?._customer?.code}
                  </span>
                  {` - ${clientsOrders?._customer?.publicName}`}
                </p>
              )
            }
          >
            <div></div>
          </Tooltip>
        </MarkerDot>
      </AdvancedMarker>
    ) : type === RoundtripsType.Assigned && clientsOrders?._id ? (
      <AdvancedMarker
        // ref={ref}
        onClick={e => {
          handleSetLastSelectByClick(true);
          handleSelectOrders(e, markerKey, clientsOrders);
          setMouseOver(selected ? false : !mouseOver);
        }}
        clickable={true}
        position={position}
      >
        <MarkerPinContainer
          id={markerKey}
          selected={selected}
          assigned={markerSrc === 'notSelected'}
          draggable={!viewMode && clientsOrders?.status !== 'delivered'}
          onDragStart={handleDragStart_}
          onDragOver={e => {
            e.preventDefault();
          }}
          onDrop={drop}
          onDragEnter={() => setDragOver(true)}
          onDragLeave={e => {
            const relatedTargetNode = e.relatedTarget as Node;
            if (
              !relatedTargetNode ||
              !e.currentTarget.contains(relatedTargetNode)
            ) {
              setDragOver(false);
            }
          }}
          viewAssignedOrders={viewAssignedOrders}
        >
          {selected ? (
            <MarkerDot
              draggable={!viewMode && clientsOrders?.status !== 'delivered'}
              onDragStart={handleDragStart}
              notRespecting={notRespecting}
              id={markerKey}
              onClick={e => {
                handleSetLastSelectByClick(true);
                handleSelectOrders(e, markerKey, clientsOrders);
                setMouseOver(selected ? false : !mouseOver);
              }}
              selected={selected}
            >
              <Tooltip
                arrow
                title={
                  !overlayVisible &&
                  hoveringOverMarker && (
                    <p>
                      <span className="clientCode">
                        {clientsOrders?._customer?.code}
                      </span>
                      {` - ${clientsOrders?._customer?.publicName}`}
                    </p>
                  )
                }
              >
                <div></div>
              </Tooltip>
            </MarkerDot>
          ) : markerSrc === 'notSelected' ? (
            <Tooltip
              arrow
              title={
                !overlayVisible &&
                hoveringOverMarker && (
                  <p>
                    <span className="clientCode">
                      {clientsOrders?._customer?.code}
                    </span>
                    {` - ${clientsOrders?._customer?.publicName}`}
                  </p>
                )
              }
            >
              {showOrderStatusActive ? (
                <UnselectedMarker
                  color={
                    orderStatus === 'delivered'
                      ? themes?.default?.green800
                      : orderStatus === 'canceled'
                      ? themes?.default?.redA400
                      : null
                  }
                >
                  {orderStatus === 'delivered' ? (
                    <Icon
                      icon={<Check weight="bold" />}
                      size={8}
                      color={themes?.default?.accordionWhiteBg}
                    />
                  ) : orderStatus === 'canceled' ? (
                    <Icon
                      icon={<X weight="bold" />}
                      size={10}
                      color={themes?.default?.accordionWhiteBg}
                    />
                  ) : null}
                </UnselectedMarker>
              ) : (
                <UnselectedMarker />
              )}
            </Tooltip>
          ) : (
            <Tooltip
              arrow
              title={
                !overlayVisible &&
                hoveringOverMarker && (
                  <p>
                    <span className="clientCode">
                      {clientsOrders?._customer?.code}
                    </span>
                    {` - ${clientsOrders?._customer?.publicName}`}
                  </p>
                )
              }
            >
              <div>
                <img
                  src={markerSrc}
                  alt=""
                  style={{
                    width: '22px',
                    height: '26px',
                  }}
                />
                <PinNumber>{stop?.pinNumber || stop?.stopNumber}</PinNumber>
              </div>
            </Tooltip>
          )}
        </MarkerPinContainer>
      </AdvancedMarker>
    ) : null;
  }, [
    type,
    position,
    viewMode,
    markerKey,
    notRespecting,
    clientsOrders,
    selected,
    viewUnassignedOrders,
    overlayVisible,
    hoveringOverMarker,
    markerSrc,
    dragOver,
    viewAssignedOrders,
    stop,
    orderStatus,
    showOrderStatusActive,
  ]);

  if (position?.lat && position?.lng)
    return (
      <div
        onMouseEnter={e => {
          if (!e.shiftKey) {
            // setMouseOver(true);
            setMouseOverOrder && setMouseOverOrder(clientsOrders);
            setHoveringOverMarker(true);
          }
        }}
        onMouseLeave={() => {
          // setMouseOver(false);
          setMouseOverOrder && setMouseOverOrder(null);
          setHoveringOverMarker(false);
        }}
      >
        <div onMouseLeave={() => setMouseOver(false)}>{MarkerComponent}</div>
        {overlayVisible && !isDragging && (
          <InfoWindow
            position={position}
            pixelOffset={
              type === RoundtripsType.Unassigned ? [0, -28] : [0, -26]
            }
            zIndex={101}
          >
            <div
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
            >
              <ItemOrder
                ref={orderCardRef}
                dateValue={dateValue}
                handleChange2={handleChange2}
                data={clientsOrders}
                stop={stop}
                isAssigned={type === RoundtripsType.Assigned}
                checked={checked}
                index={index}
                key={clientsOrders?._id}
                onDisplayOrder={onDisplayOrder}
                accordionStatus={accordionStatus}
                setAccordionStatus={setAccordionStatus}
                roundTripCopy={roundTripCopy}
                clusterId={clusterId}
                requestOrderData={requestOrderData}
                orders={orders}
                setOrders={setOrders}
                setOrderInitCount={setOrderInitCount}
                orderInitCount={orderInitCount}
                disableDragAndDrop={true}
                mapVersion={true}
                pinnedLayers={pinnedLayers}
                roundTripsCount={roundTripsCount}
                setAssignedOrders={setAssignedOrders}
              />
            </div>
          </InfoWindow>
        )}
      </div>
    );
  else return <></>;
};

const MarkerDot = styled.div<{
  selected;
  notRespecting;
  viewUnassignedOrders?: any;
}>`
  height: ${props => (props.selected ? '16px' : '20px')};
  width: ${props => (props.selected ? '16px' : '20px')};
  opacity: ${props =>
    props.viewUnassignedOrders || props.selected ? '1' : '0.4'};
  margin-bottom: -10px;
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;

  ${props => {
    if (props.selected) {
      return `div {
        height: 16px;
        width: 17px;
        border-radius: 17px;
        background: ${themes?.default?.clientMarkerBgSelected};
        border: 1.5px solid
          ${themes?.default?.clientMarkerBorderSelected};
        outline: 1.5px solid ${themes?.default?.clientMarkerBorderSelected}80;
        box-shadow: 0px 0.75px 7.5px 0px #0000001F;
        box-shadow: 0px 3px 3.75px 0px #00000024;
        box-shadow: 0px 1.5px 3px -0.75px #00000033;
      }`;
    } else if (!props.selected && props.notRespecting) {
      return `div {
        height: 14.5px;
        width: 14.5px;
        border-radius: 14.5px;
        background: ${themes?.default?.clientMarkerBg};
        border: 1.5px solid
          ${themes?.default?.clientMarkerBorder};
        box-shadow: 0px 1px 10px 0px #0000001F;
        box-shadow: 0px 4px 5px 0px #00000024;
        box-shadow: 0px 2px 4px -1px #00000033;
        position: relative;
      }
      &::after {
        content: '';
        height: 9px;
        width: 9px;
        border-radius: 9px;
        border: 1.5px solid ${themes.default.clientMarkerBorder};
        position: absolute;
        top: 0;
        right: 0;
        background: ${themes?.default?.mapPink};
        z-index: 1;
      }
      `;
    } else if (!props.selected && !props.notRespecting) {
      return `div {
        height: 14.5px;
        width: 14.5px;
        border-radius: 14.5px;
        background: ${themes?.default?.clientMarkerBg};
        border: 1.5px solid
          ${themes?.default?.clientMarkerBorder};
        box-shadow: 0px 1px 10px 0px #0000001F;
        box-shadow: 0px 4px 5px 0px #00000024;
        box-shadow: 0px 2px 4px -1px #00000033;
      }`;
    }
  }}
`;

export const MarkerPinContainer = styled.div<{
  selected;
  assigned?: any;
  viewAssignedOrders?: any;
}>`
  height: fit-content;
  width: fit-content;
  opacity: ${props =>
    props.viewAssignedOrders || props.selected ? '1' : '0.4'};
  cursor: pointer;
  position: relative;
  z-index: ${props => (props.selected ? '2' : '1')};
  margin-bottom: ${props =>
    props.selected ? '-8px' : !props.assigned ? '0' : '-8px'};
`;

const UnselectedMarker = styled.div<{ color?: any }>`
  height: 14.5px;
  width: 14.5px;
  border-radius: 14.5px;
  background: ${props => (props.color ? props.color : themes?.default?.nobel)};
  border: 1.5px solid ${themes?.default?.clientMarkerBorder};
  box-shadow: 0px 1px 10px 0px #0000001f;
  box-shadow: 0px 4px 5px 0px #00000024;
  box-shadow: 0px 2px 4px -1px #00000033;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const PinNumber = styled.span`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -45%);
  font-size: 0.7rem;
  font-weight: 500;
  color: #fff;
`;

import { IconButton, LinearProgress, Typography } from '@mui/material';
import styled from 'styled-components';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import If from '../If';
import { isUndefined } from 'lodash';
import { Icon } from '../Icon';
import { Eye, X } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export function AcceptedFiles({
  file,
  statusFile,
  hideProgressBar,
  fileProgress,
  deleteFile,
  canView = false,
}) {
  return (
    <>
      <FilesListWrapper>
        <UploadFileIcon
          color="primary"
          sx={{ width: '28px', height: '28px', mr: '16px' }}
        />
        <div
          className="container"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography fontSize="1rem" fontWeight="400">
            {file.name}
          </Typography>
          <div
            className="detailWrapper"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {!isUndefined(file.size) && (
              <>
                <Typography>{(file.size / 1024).toFixed(2)}kb</Typography>
                <Typography sx={{ ml: '8px' }}>•</Typography>
              </>
            )}

            {!isUndefined(file.date) && (
              <>
                <Typography sx={{ ml: '8px' }}>
                  {dayjs(file.date)?.format('DD/MM/YYYY hh:mm A')}
                </Typography>
              </>
            )}
            <Typography sx={{ ml: '8px' }}>{statusFile[file.name]}</Typography>
          </div>
          <If
            condition={hideProgressBar[file.name] === false}
            otherwise={<></>}
          >
            <LinearProgress
              variant="determinate"
              value={fileProgress[file.name]}
              sx={{ width: '200px', mt: '4px' }}
            />
          </If>
        </div>
        <ActionButtons>
          {!!canView && !!file?.url && (
            <Link to={file?.url} target="_blank" rel="noopener noreferrer">
              <IconButton>
                <Icon icon={<Eye />} />
              </IconButton>
            </Link>
          )}
          <IconButton
            onClick={() => {
              deleteFile(file.name);
            }}
          >
            <Icon icon={<X />} />
          </IconButton>
        </ActionButtons>
      </FilesListWrapper>
    </>
  );
}

const FilesListWrapper = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 16px 0px 16px;
  display: flex;
  align-items: center;
`;

const ActionButtons = styled.div`
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
`;

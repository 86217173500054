import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { ChatContext } from 'app/components/Chat';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { TopBar } from './components/TopBar';
import { EmptyState } from './components/EmptyState';
import { AddContacts } from './components/AddContacts';
import { ChatCard } from './components/ChatCard';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { getId } from 'common/helpers/document';
import { StateSetter } from 'types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { ContactCardSkeleton } from './components/ContactCardSkeleton';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface Props {
  filteredChatsList: any;
  setFilteredChatsList: StateSetter<any>;
  handleGetMoreChats: () => void;
  isLoadingChats: boolean;
  hasMore: boolean;
  chatsOffset: number;
}

export const ContactsList: React.FC<Props> = ({
  filteredChatsList,
  setFilteredChatsList,
  handleGetMoreChats,
  isLoadingChats,
  hasMore,
  chatsOffset,
}) => {
  const {
    contacts,
    search,
    setSearch,
    contactsOffset,
    chats,
    lastListMessage,
    currentUserId,
    selectedUser,
    totalChats,
    initialItemsCount,
  } = useContext(ChatContext);
  const [addNewChatMode, setAddNewChatMode] = useState(false);
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const authUser = useSelector(selectAuthUser);
  const listRef: any = useRef(null);

  const formatTimestamp = timestamp => {
    const date = dayjs(timestamp);

    if (date.isToday()) {
      return date.format('HH:mm');
    } else if (date.isYesterday()) {
      return t('yesterday');
    } else {
      return date.format('D MMMM, YYYY');
    }
  };

  useEffect(() => {
    if (lastListMessage) {
      const newMessage = JSON.parse(lastListMessage);

      if (newMessage?.data?.data) {
        setFilteredChatsList(prevMessage => {
          const existingMessage = prevMessage?.some(
            msg => msg?._id === newMessage?.data?.data?._id,
          );
          if (existingMessage) {
            return prevMessage.map(msg => {
              if (msg?._id === newMessage?.data?.data?._id) {
                return newMessage?.data?.data;
              }
              return msg;
            });
          } else {
            return [newMessage?.data?.data, ...prevMessage];
          }
        });
      }
    }
  }, [lastListMessage]);

  useEffect(() => {
    if (selectedUser) setAddNewChatMode(false);
  }, [selectedUser]);

  useEffect(() => {
    if (!listRef.current) return;
    listRef?.current?.addEventListener('scroll', () =>
      handleScroll(isLoadingChats, hasMore),
    );
    return () => {
      listRef?.current?.removeEventListener('scroll', () =>
        handleScroll(isLoadingChats, hasMore),
      );
    };
  }, [listRef.current, isLoadingChats, hasMore]);

  const handleScroll = (isLoadingChats, hasMore) => {
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;
    if (
      clientHeight + scrollTop === scrollHeight &&
      !isLoadingChats &&
      hasMore
    ) {
      handleGetMoreChats();
    }
  };

  return (
    <Wrapper>
      <TopBar
        search={search}
        setSearch={setSearch}
        addNewChatMode={addNewChatMode}
        setAddNewChatMode={setAddNewChatMode}
        chatsCount={filteredChatsList?.length || 0}
      />
      {addNewChatMode &&
      contacts &&
      (contacts?.length > 0 || contactsOffset > 0) ? (
        <>
          <AddContacts />
        </>
      ) : (
        <ChatsListWrapper ref={listRef}>
          {filteredChatsList?.length > 0 ? (
            <>
              {filteredChatsList?.map(chat => {
                return chat?.participantsDetails
                  ?.filter(
                    participant =>
                      chat?.participantsDetails?.length === 1 ||
                      participant?._id !== currentUserId,
                  )
                  .map(listItem => {
                    const user: any =
                      listItem?.cache?.agents[
                        authUser?._currentOrganization?._id
                      ];
                    const chatItem = {
                      _id: chat?._id,
                      pinned: chat?.pinned,
                      chatId: listItem?._id,
                      userId: listItem?._id,
                      user: user,
                      pictureUrl: user?.pictureUrl,
                      fullName: `${user?.firstName} ${user?.lastName}`,
                      role: `${
                        listItem?.cache?.agents?.[
                          getId(authUser?._currentOrganization)
                        ]?._role?.localeName[`${locale}`]
                      }`,
                      lastMessageAt: formatTimestamp(
                        chat?.lastMessage?.createdAt,
                      ),
                      lastMessage: `${
                        chat?.lastMessage?.sender === currentUserId
                          ? `${t('you')}: `
                          : ''
                      } ${
                        chat?.lastMessage?.contentType === 'text'
                          ? chat?.lastMessage?.content
                          : chat?.lastMessage?.contentType === 'text'
                          ? 'Image'
                          : `📁 ${t('common.file')}`
                      }`,
                      unreadMessagesCount: chat?.messages[0]?.unreadCount,
                    };
                    return (
                      <>
                        <ChatCard
                          chat={chatItem}
                          pinned={chatItem?.pinned || false}
                        />
                      </>
                    );
                  });
              })}
              {isLoadingChats && (
                <ContactCardSkeleton
                  length={Math.min(totalChats - chatsOffset, initialItemsCount)}
                />
              )}
            </>
          ) : (
            <EmptyState setAddNewChatMode={setAddNewChatMode} />
          )}
        </ChatsListWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 488px;
  flex: 1;
  border-right: 1px solid ${themes?.default?.gainsboro2};
  position: relative;

  @media screen and (max-width: 1200px) {
    max-width: none;
  }
`;

const ChatsListWrapper = styled.div`
  height: calc(100% - 24px);
  margin-bottom: 20px;
  padding: 1px 17px 1px 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

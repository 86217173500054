import { Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Details } from './components/Details';
import { useTranslation } from 'react-i18next';
import { Departments } from './components/Departments';
import { Warehouses } from './components/Warehouses';
import { CompanyStructure } from './components/CompanyStructure';

export function CompanyProfile() {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { t } = useTranslation();

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const stylingTab = { zIndex: 100 };
  return (
    <>
      <ContentWrapper>
        <Typography fontSize="1.5rem" fontWeight="400">
          {t('settings.companyProfile.title')}
        </Typography>
        <React.Fragment>
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            sx={{ mt: '24px' }}
            TabIndicatorProps={{
              style: { backgroundColor: 'blue', height: '2px', zIndex: 100 },
            }}
          >
            <Tab
              label={t('settings.companyProfile.tab.details')}
              sx={stylingTab}
            />
            <Tab
              label={t('settings.companyProfile.tab.departments')}
              sx={stylingTab}
            />
            <Tab
              label={t('settings.companyProfile.tab.companyStructure')}
              sx={stylingTab}
            />
            <Tab
              label={t('settings.companyProfile.tab.warehouses')}
              sx={stylingTab}
            />
          </Tabs>
        </React.Fragment>
      </ContentWrapper>
      {currentTabIndex === 0 && <Details />}
      {currentTabIndex === 1 && <Departments />}
      {currentTabIndex === 2 && <CompanyStructure />}
      {currentTabIndex === 3 && <Warehouses />}
    </>
  );
}

const ContentWrapper = styled.div`
  height: 98px;
  margin-top: 26px;
  margin-left: 23px;
`;

/**
 * Client Map Component
 * Contained title, autocomplete field and map with a geolocalisation options
 */

import {
  Fade,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  GoogleMap,
  Marker,
  StreetViewPanorama,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { defaultMapStyle } from 'styles/mapStyles';
import { useTranslation } from 'react-i18next';
import { Crosshair, CrosshairSimple } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import {
  Position,
  Suggestion,
} from 'app/pages/Customers/components/Tabs/BranchDrawer';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import MarkerPinBlue from '../../../assets/img/map/CustomerMap/MarkerPrimaryBlue.svg';
import { LocationInput } from '../LocationInput';
interface MapProps {
  mapHeight: string;
  mapWidth: string;
}

export function ClientMap({
  mapHeight,
  latitude,
  longitude,
  title,
  setLatitude,
  setLongitude,
  mainLocationValue,
  setLocationValue,
  mapWidth,
  defaultFieldValue,
}) {
  const [clicked, setClicked] = useState(false);
  const [query, setQuery] = useState(defaultFieldValue?.name ?? '');
  const [defaultValueName, setDefaultValueName] = useState<any>(
    defaultFieldValue?.name,
  );
  const [streetViewVisible, setStreetViewVisible] = useState<boolean>(false);

  const [center, setCenter] = useState({
    lat:
      defaultFieldValue !== undefined
        ? defaultFieldValue?.geometry?.coordinates[1]
        : 0,
    lng:
      defaultFieldValue !== undefined
        ? defaultFieldValue?.geometry?.coordinates[0]
        : 0,
  });

  useEffect(() => {
    if (defaultFieldValue) {
      setQuery(defaultFieldValue?.name);
      setDefaultValueName(defaultFieldValue?.name);
      setCenter({
        lat: defaultFieldValue?.geometry?.coordinates[1],
        lng: defaultFieldValue?.geometry?.coordinates[0],
      });
    }
  }, [defaultFieldValue]);

  const { t } = useTranslation();

  const containerStyle = {
    width: '100%',
    height: '589px',
    transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
    borderRadius: '4px',
  };

  const mapOptions = {
    disableDefaultUI: true,
    styles: defaultMapStyle,
  };

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? '';

  const geocodingClient = mbxGeocoding({
    accessToken:
      'pk.eyJ1IjoianJleW5hdWQiLCJhIjoiY2xkeGN0OTljMDFoODN3azlkM3UyOXA4dyJ9.gzYtNROqJfITHXZMYsoWvA',
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });

  /**
   * handle geolocalisation click event to get the current location
   * @param latitude
   * @param longitude
   * @returns
   */
  const reverseGeocode = async (latitude, longitude) => {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        displayName: data.display_name,
        city:
          data.address.city || data.address.town || data.address.village || '',
        district: data.address.suburb || data.address.neighbourhood || '',
        region: data.address.state || '',
        countryCode: data.address.country_code || '',
        zipCode: data.address.postcode || '',
      };
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  };

  const handleGeoLocationClick = async () => {
    if (!clicked) {
      setClicked(true);
      try {
        const position = await new Promise<Position>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);

        const address = await reverseGeocode(latitude, longitude);
        if (address) {
          setQuery(address.displayName);

          let name = address.displayName;

          const locationValue = {
            ...mainLocationValue,
            name: name,
            city: address.city || '',
            district: address.district || '',
            region: address.region || '',
            countryCode: address.countryCode || '',
            zipCode: address.zipCode || '',
            geometry: {
              type: 'Point',
              coordinates: [longitude, latitude],
            },
          };

          setLocationValue(locationValue);
        } else {
          console.error('No address found for the provided coordinates.');
        }
      } catch (error) {
        console.error('Error getting location:', error);
      }
    } else {
      setClicked(false);
      setQuery('');
    }
  };

  useEffect(() => {
    if (latitude && longitude) {
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [latitude, longitude]);

  return (
    <>
      <Typography
        fontWeight={500}
        sx={{
          marginBottom: '20px',
        }}
      >
        {title}
      </Typography>

      <LocationInput
        label={t('Add_Client_Address')}
        defaultValue={defaultFieldValue}
        size="small"
        onSelect={loc => setLocationValue(loc)}
        style={{ maxWidth: mapWidth }}
      />

      <Fade in={isLoaded}>
        <MapWrapper mapHeight={mapHeight} mapWidth={mapWidth}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            options={mapOptions}
            center={center}
            zoom={15}
          >
            <Marker position={center} icon={MarkerPinBlue} />
            <StreetViewButton
              streetView={streetViewVisible}
              onClick={() => {
                setStreetViewVisible(!streetViewVisible);
              }}
            >
              <Crosshair size={20} color={themes?.default?.iconColor} />
            </StreetViewButton>
            <StreetViewPanorama
              options={{
                position: { lat: latitude, lng: longitude },
                visible: streetViewVisible,
                disableDefaultUI: true,
                enableCloseButton: false,
              }}
            />
          </GoogleMap>
        </MapWrapper>
      </Fade>
    </>
  );
}

const MapWrapper = styled.div<MapProps>`
  display: flex;
  flex-direction: column;
  width: ${props => props.mapWidth};
  height: ${props => props.mapHeight};
  z-index: 2100;
  border-radius: 4px;
  margin-right: 17px;
`;

const MapButton = styled.div(props => ({
  position: 'absolute',
  right: '51px',
  top: '15px',
  cursor: 'pointer',
  color: props.theme.icon,
  padding: '5px',
  backgroundColor: 'rgb(255,255,255)',
  borderRadius: '4px',
  zIndex: '1500',
  boxShadow: '1px 1px 4px 1px rgba(0, 0, 0, 0.25)',
}));

interface StreetViewButtonProps {
  streetView: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export const StreetViewButton = styled(MapButton)<StreetViewButtonProps>`
  right: 15px;
  background-color: ${props =>
    props.streetView ? props.theme.blueToggledButton : ''};
`;

import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@mui/material';
import { Icon } from 'app/components/Icon';
import { Sparkle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import EllipsisText from 'app/components/EllipsisText';
import { useSelector } from 'react-redux';
import { selectConfiguration } from 'common/store/organization/selectors';

interface Props {
  supportUnits: any;
}

export const SupportUnitsTable: React.FC<Props> = ({ supportUnits }) => {
  const { t } = useTranslation();

  const configurationSelector = useSelector(selectConfiguration);
  const supportUnitsList = configurationSelector?.supportUnitsList;

  function getSupportUnitsStringArray(units) {
    if (!units || units?.length === 0) {
      return ['-'];
    } else {
      return units?.map((unit, index) => {
        if (!unit?._supportUnit?.name) {
          return '-';
        }
        if (index < units?.length - 1) {
          return `${unit?.quantity} ${unit?._supportUnit?.name},`;
        } else {
          return `${unit?.quantity} ${unit?._supportUnit?.name}`;
        }
      });
    }
  }
  const AIAEstSupportUnits = useMemo(() => {
    return getSupportUnitsStringArray(supportUnits?.AIAEst);
  }, [supportUnits]);
  const plannedSupportUnits = useMemo(() => {
    return getSupportUnitsStringArray(supportUnits?.planned);
  }, [supportUnits]);
  const loadedSupportUnits = useMemo(() => {
    const allSupportUnits = supportUnitsList?.map(sp => {
      var quantity = 0;
      supportUnits?.loaded?.map(loadedSp => {
        if (loadedSp?._supportUnit?._id === sp?._id) {
          quantity++;
        }
      });
      return { _supportUnit: sp, quantity: quantity };
    });

    return getSupportUnitsStringArray(allSupportUnits);
  }, [supportUnits]);
  const deliveredSupportUnits = useMemo(() => {
    return getSupportUnitsStringArray(supportUnits?.delivered);
  }, [supportUnits]);
  const collectedSupportUnits = useMemo(() => {
    return getSupportUnitsStringArray(supportUnits?.collected);
  }, [supportUnits]);

  const EllipsisTextWidth = useMemo(() => {
    const units = [
      AIAEstSupportUnits[0],
      plannedSupportUnits[0],
      loadedSupportUnits[0],
      deliveredSupportUnits[0],
      collectedSupportUnits[0],
    ];

    const columnsThatHaveDataCount = units.filter(unit => unit !== '-').length;
    return 76 - 4.2 * columnsThatHaveDataCount;
  }, [
    AIAEstSupportUnits,
    plannedSupportUnits,
    loadedSupportUnits,
    deliveredSupportUnits,
    collectedSupportUnits,
  ]);

  return (
    <Wrapper>
      <Table
        sx={{
          maxWidth: '100% !important',
          minWidth: '340px',
        }}
      >
        <TableHead
          sx={{
            '& .MuiTableCell-root': {
              borderRight: `1px solid ${themes?.default?.gainsboro2}`,
              whiteSpace: 'nowrap',
              padding: '3px 4px !important',
              fontSize: '0.75rem',
            },
            '& .MuiTableCell-root:last-child': {
              borderRight: 'none',
            },
          }}
        >
          <TableRow>
            <TableCell>
              AIA Est.{' '}
              <Icon
                icon={<Sparkle />}
                size={12}
                color={themes?.default?.strokePrimary}
              />
            </TableCell>
            <TableCell>{t('orders.status.planned')}</TableCell>
            <TableCell>{t('orders.status.loaded')}</TableCell>
            <TableCell>{t('orders.status.delivered')}</TableCell>
            <TableCell>{t('clients.orderDetails.collected')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': {
              padding: '6px !important',
              border: 'none',
              borderRight: `1px solid ${themes?.default?.gainsboro2}`,
              fontSize: '0.6875rem',
              fontWeight: 400,
              letterSpacing: '0.35px',
              verticalAlign: 'top',
            },
            '& .MuiTableCell-root:last-child': {
              borderRight: 'none',
            },
          }}
        >
          <TableRow>
            <TableCell>
              {AIAEstSupportUnits?.map(sp => {
                return (
                  <div style={{ paddingBottom: '4px' }}>
                    <EllipsisText
                      tooltipVerticalOffset={-10}
                      text={sp}
                      width={EllipsisTextWidth}
                    />
                  </div>
                );
              })}
            </TableCell>
            <TableCell>
              {plannedSupportUnits?.map(sp => {
                return (
                  <div style={{ paddingBottom: '4px' }}>
                    <EllipsisText
                      tooltipVerticalOffset={-10}
                      text={sp}
                      width={EllipsisTextWidth}
                    />
                  </div>
                );
              })}
            </TableCell>
            <TableCell>
              {loadedSupportUnits?.map(sp => {
                return (
                  <div style={{ paddingBottom: '4px' }}>
                    <EllipsisText
                      tooltipVerticalOffset={-10}
                      text={sp}
                      width={EllipsisTextWidth}
                    />
                  </div>
                );
              })}
            </TableCell>
            <TableCell>
              {deliveredSupportUnits?.map(sp => {
                return (
                  <div style={{ paddingBottom: '4px' }}>
                    <EllipsisText
                      tooltipVerticalOffset={-10}
                      text={sp}
                      width={EllipsisTextWidth}
                    />
                  </div>
                );
              })}
            </TableCell>
            <TableCell>
              {collectedSupportUnits?.map(sp => {
                return (
                  <div style={{ paddingBottom: '4px' }}>
                    <EllipsisText
                      tooltipVerticalOffset={-10}
                      text={sp}
                      width={EllipsisTextWidth}
                    />
                  </div>
                );
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-width: 340px;
  border: 1px solid ${themes?.default?.gainsboro2};
  border-radius: 4px;
`;

import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { X } from '@phosphor-icons/react';

interface Props {
  visible: boolean;
  onClick: () => void;
}

const fallTransitionDuration = 0;

export const CloseButton: React.FC<Props> = ({ visible, onClick }) => {
  const [recentlyClicked, setRecentlyClicked] = useState(false);
  useEffect(() => {
    if (recentlyClicked) {
      setTimeout(() => {
        onClick();
        setRecentlyClicked(false);
      }, fallTransitionDuration);
    }
  }, [recentlyClicked]);
  return (
    <>
      {/* {visible ? ( */}
      <AnimatedButtonBase
        sx={{ ...closeButtonSx }}
        onClick={() => setRecentlyClicked(true)}
        recentlyClicked={recentlyClicked}
      >
        <ButtonIconWrapper>
          <Icon icon={<X />} color={themes?.default?.iconColor} size={16} />
        </ButtonIconWrapper>
      </AnimatedButtonBase>
      {/* ) : (
        <div style={{ width: '32px' }}></div>
      )} */}
    </>
  );
};

const fallAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
`;

const AnimatedButtonBase = styled(ButtonBase)<{ recentlyClicked: boolean }>`
  ${props =>
    props.recentlyClicked &&
    css`
      animation: ${fallAnimation} ${fallTransitionDuration}ms forwards;
    `}
`;

const closeButtonSx = {
  width: '32px',
  height: '32px',
  background:
    'linear-gradient(55deg, rgba(255, 0, 255, 0.3) -14.9%, rgba(176, 30, 255, 0.3) 10.07%, rgba(17, 89, 255, 0.3) 93.5%)',
  borderRadius: '20px',
  boxShadow: '-24px 24px 48px -12px #674DAF57',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const ButtonIconWrapper = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  background: ${themes?.default?.accordionWhiteBg};
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.2s;

  &:hover {
    background: ${themes?.default?.backgroundSilver};
  }
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Form, FormSection, FormWrapper } from 'app/components/Form/styles';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Box,
  ThemeProvider,
  createTheme,
  FormGroup,
  Checkbox,
} from '@mui/material';
import {
  ClientInformationTitle,
  ClientInformationTitleContainer,
} from '../styles';
import { FieldComponent } from 'app/components/FieldComponent';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
interface Props {}

export const AddnewForm: React.FC<Props> = ({}) => {
  const methods = useForm({
    defaultValues: {
      FullName: '',
      Job_Role: '',
    },
  });
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('logistics');
  const { control, watch, reset } = methods;

  const ChipWithRadio = ({ value, label, selectedValue }) => (
    <Chip
      label={
        <Box display="flex" alignItems="center" marginRight="12px">
          <Radio value={value} checked={selectedValue === value} />
          <span>{label}</span>
        </Box>
      }
      variant="outlined"
      style={{
        backgroundColor: selectedValue === value ? '#E5EBFF' : 'white',
        borderColor: selectedValue === value ? '#9AB0FF' : 'grey',
        color: selectedValue === value ? '#2A59FF' : 'black',
        fontFamily: 'Roboto',
        marginLeft: '12px',
      }}
      size="medium"
    />
  );
  const handleChange = event => {
    setSelectedValue(event.target.value);
  };
  const themegray = createTheme({
    palette: {
      secondary: {
        main: '#b3a9acd3',
      },
    },
  });
  const StyledWrapper = styled.div`
    .special-label {
      font-size: 11px !important;
      color: #b4b3b3;
      position: absolute;
      left: 10px !important;
    }
  `;
  const inputStyle = {
    width: '446px',
    height: '37.13px',
    borderColor: '#b4b3b3',
    borderWidth: '2px',
    borderStyle: 'solid',
    outline: 'none',
  };
  return (
    <ThemeProvider theme={themegray}>
      <FormSection>
        <ClientInformationTitleContainer>
          <ClientInformationTitle>{t('Job Type')}</ClientInformationTitle>
        </ClientInformationTitleContainer>

        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value="logistics"
            control={
              <ChipWithRadio
                value="logistics"
                label="Logistics"
                selectedValue={selectedValue}
              />
            }
            label=""
          />
          <FormControlLabel
            value="sales"
            control={
              <ChipWithRadio
                value="sales"
                label="Sales"
                selectedValue={selectedValue}
              />
            }
            label=""
          />
        </RadioGroup>
        <FieldComponent
          style={{ width: '446px', marginTop: '25px' }}
          name="FullName"
          control={control}
          size="small"
          label={t('Full Name')}
          color="secondary"
          focused
          placeholder={t('Enter Full Name ')}
          disabled={false}
          variant="outlined"
        />
        <StyledWrapper>
          <PhoneInput
            country="fr"
            specialLabel={t('Add_Client_Sec_Mobile_Phone')}
            inputProps={{
              style: inputStyle,
              onFocus: e => {
                e.target.style.boxShadow = 'none';
                e.target.style.borderColor = 'aaa9a9';
              },
              onMouseOver: e => (e.target.style.cursor = 'default'),
            }}
          />
        </StyledWrapper>
        <FieldComponent
          style={{ width: '446px', marginTop: '25px' }}
          name="Job_Role"
          control={control}
          size="small"
          label={t('Job Role')}
          color="secondary"
          focused
          placeholder={t('Enter Job Role ')}
          disabled={false}
          variant="outlined"
        />
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label="Mark as main" />
        </FormGroup>
      </FormSection>
    </ThemeProvider>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.app || initialState;

export const selectDatePickerStatus = createSelector(
  [selectSlice],
  state => state.isOpenedDatePicker,
);

export const selectCurrentDate = createSelector(
  [selectSlice],
  state => state.currentDate,
);

export const selectCalendarDates = createSelector(
  [selectSlice],
  state => state.calendarDates,
);

export const selectLastPosition = createSelector(
  [selectSlice],
  state => state.lastPosition,
);

export const selectLastToasterNotification = createSelector(
  [selectSlice],
  state => state.lastToasterNotification,
);

export const selectLanguage = createSelector(
  [selectSlice],
  state => state.selectedLanguage,
);

export const selectCurrentApplication = createSelector(
  [selectSlice],
  state => state.currentApplication || initialState.currentApplication,
);

export const selectDropdownStatus = createSelector(
  [selectSlice],
  state => state.dropDownStatus,
);

export const selectSnackbar = createSelector(
  [selectSlice],
  state => state.snackbar,
);

export const selectAppState = createSelector([selectSlice], state => state);

export const selectCachedQueries = (options: any) => {
  return createSelector([selectSlice], state => {
    return state.cachedQueries?.[options]?.data || null;
  });
};

export const selectMutationCache = createSelector(
  [selectSlice],
  state => state.mutationCache || [],
);

export const selectMutationResponseCache = createSelector(
  [selectSlice],
  state => state.mutationResponseCache || {},
);

export const selectConnectionStatus = createSelector(
  [selectSlice],
  state => state.isConnected,
);

export const selectCameraScanKey = createSelector(
  [selectSlice],
  state => state.cameraScanKey,
);

export const selectCameraScan = createSelector(
  [selectSlice],
  state => state.cameraScan,
);

export const selectCodeScanner = (options: any) => {
  return createSelector([selectSlice], state => {
    return state.codeScanner?.[options];
  });
};

export const selectCustomerGridPreferences = createSelector(
  [selectSlice],
  state =>
    state.gridPreferences?.customers || initialState.gridPreferences.customers,
);

export const selectOrdersGridPreferences = createSelector(
  [selectSlice],
  state => {
    return state.gridPreferences?.orders || initialState.gridPreferences.orders;
  },
);

export const selectOrdoriaOrdersGridPreferences = createSelector(
  [selectSlice],
  state =>
    state.gridPreferences?.ordoriaOrders ||
    initialState.gridPreferences.ordoriaOrders,
);

export const selectHasCameraPermission = createSelector(
  [selectSlice],
  state => state.hasCameraPermission,
);

export const selectTermsReaded = createSelector(
  [selectSlice],
  state => state.termsReaded,
);

import React, { useState, useCallback, useContext, useEffect } from 'react';
import { ChatContext } from 'app/components/Chat';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Typography, ButtonBase } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContactCard } from '../ContactCard';
import { LastContactCard } from '../LastContactCard';
import _ from 'lodash';
import { ContactCardSkeleton } from '../ContactCardSkeleton';

interface Props {}

export const AddContacts: React.FC<Props> = ({}) => {
  const {
    contacts,
    setSelectedUser,
    contactsLimit,
    setContactsLimit,
    initialItemsCount,
    totalDocs,
    isFetchingContacts,
    search,
    currentUserId,
  } = useContext(ChatContext);
  const { t } = useTranslation();

  const handleRenderMoreItems = () => {
    if (contacts?.length >= totalDocs) return;
    setContactsLimit(contactsLimit + initialItemsCount);
  };

  return (
    <Wrapper>
      <Typography
        fontSize={'1.07rem'}
        fontWeight={500}
        letterSpacing={'0.15px'}
        color={themes?.default?.black}
      >
        {t('suggestion')}
      </Typography>
      <List>
        {contacts?.map((contact, index) => {
          if (index !== contacts?.length - 1) {
            return (
              <ContactCard
                contact={contact}
                setSelectedUser={setSelectedUser}
                isCurrentUser={currentUserId === contact?._id}
              />
            );
          } else {
            return (
              <LastContactCard
                contact={contact}
                setSelectedUser={setSelectedUser}
                handleRenderMoreItems={handleRenderMoreItems}
                isCurrentUser={currentUserId === contact?._id}
              />
            );
          }
        })}
        {isFetchingContacts && !search && (
          <ContactCardSkeleton length={initialItemsCount} />
        )}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100% - 60px - 24px);
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: calc(100% - 20px - 8px);
  margin-bottom: 20px;
  padding-right: 17px;
`;

import {
  amber,
  cyan,
  deepPurple,
  indigo,
  lightBlue,
  pink,
  teal,
  yellow,
  red,
  orange,
} from '@mui/material/colors';

const commonTheme = {
  settingsNavWidth: '223px',
  topBarHeight: '50px',
  mainNavWidth: 'fit-content',
  mainNavWidthMin: '98px',
  blackPrimary: '#5D5971',
  primaryLight: 'rgba(42, 89, 255, 0.12)',
  primaryLightO: '#EFF2FF',
  primaryLight1: '#E5EBFF',
  smokeGray: '#D4D4D4',
  mapPink: '#FF4D4D',
  // primaryLightO: 'rgba(42, 89, 255, 0.08)',
  // primaryActive: 'rgba(42, 89, 255, 0.04)',
  primaryActive: 'rgba(42, 89, 255, 0.04)',
  primaryActiveColor: '#2A59FF',
  // primaryActiveO: 'rgba(42, 89, 255, 0.5)',
  primaryActiveO: '#95ACFF',
  lightGrey: '#FAFAFA',
  borderGrey: 'rgba(0, 0, 0, 0.23)',
  greyDisabled: '#ABB4D4',
  greenStatus: '#04BC85',
  textDelivered: '#1B5E20',
  bgDelivered: '#EAF2EA',
  bgChecked: '#E4EDFA',
  checkedBorder: '#3B8CE2',
  green: '#2E7D32',
  lightSuccessShade: '#2E7D3280',
  success: '#EAF2EA',
  cultured: '#F4F4F4',
  redStatus: '#DF0000',
  textColorSecondary: '#949494',
  errorBackground: '#FBEAEA',
  errorMain: '#D32F2F',
  warningBackground: '#FDF0E5',
  warningMain: '#ED6C02',
  orangePre: '#E65100',
  orange600: '#FB8C00',
  orange50: '#FFF3E0',
  greenMain: '#1B5E20',
  mediumDarkGrey: '#555555',
  lightOrange: 'rgba(237, 108, 2, 0.04)',
  iconColor: '#757575',
  secondaryIconColor: '#000000CC',
  grey: '#f5f5f5',
  textColorPrimary: '#2A2D33',
  tableBorder: 'rgba(224, 224, 224, 1)',
  shadowGrayWhite: 'rgba(139, 139, 139, 0.25)',
  lightFill: 'rgba(0, 0, 0, 0.12)',
  outlinedBorder: '#F9FAFF',
  lightError50p: 'rgba(211, 47, 47, 0.5)',
  lightSuccess50p: 'rgba(46, 125, 50, 0.5)',
  successDark: '#123214',
  lightTextPrimary: 'rgba(0, 0, 0, 0.87)',
  lightInfoMain: '#0288D1',
  greyBgInput: '#F0F1F7',
  tabsBarHeight: '40px',
  columnsBarHeight: '64px',
  addRoundtripTopBarHeight: '40px',
  addRoundtripBottomBarHeight: '40px',
  toolBarHeight: '42px',
  toolBarContainerHeight: '40px',
  scrollBarColor: '#babac0',
  roundtripGreyBg: '#fafbfc',
  clientMarkerBg: '#616161',
  clientMarkerBgSelected: '#FFFFFF',
  clientMarkerBorder: '#FAFBFC',
  clientMarkerBorderSelected: '#2A59FF',
  accordionWhiteBg: '#ffffff',
  dataTableHeight: 'calc(100vh - 228px)',
  regularDataTableHeight: 'calc(100vh - 154px)',
  regularDataTableHeightFiltered: 'calc(100vh - 183px)',
  roundtripsGrayBackGround: '#fafbfc',
  black: '#000000',
  green30: '#18ad1e',
  purple: '#673AB7',
  purple50: '#EDE7F6',
  darkOrchid: '#9C27B0',
  lasPalmas: '#BBD645',
  lightGreen600: '#7CB342',
  lightGreen55: '#F1F8E9',
  lightblue900: '#E1F5FE',
  lightblue50: '#01579B',
  summerSky: '#29B6F6',
  ghostWhite: '#FBFCFF',
  egyptianBlue: '#1C2499',
  brown: '#5F2B01',
  safetyOrange: '#FF6F00',
  Saffron: '#FBC02D',
  tapa: '#757573',
  grey2: '#757575',
  zircon: '#E7EDED',
  gainsboro: '#D9D9D9',
  carmine: '#9E161B',
  sunflower: '#E5B811',
  solitude: '#EEF2FF',
  suvaGrey: '#8B8B8B',
  dodgerBlue: '#0089FF',
  whiteSmoke: '#F3F3F3',
  darkGray: '#ABABAB',
  darkGray2: '#A3A3A3',
  echoBlue: '#9FA8DA',
  darkOrange: '#FB8C00',
  cornflowerBlue: '#6B8BFE',
  orange: '#FAAD07',
  markerWarning: '#FDD835',
  markerWarning2: '#F57F17',
  textBlack: '#000000DE',
  red: '#E01310',
  atlantis: '#9DC356',
  green2: '#05B005',
  lightGrey2: '#D7D7D7',
  lightGrey3: '#0000001F',
  beige: '#FFF3E0',
  perano: '#AFBCFD',
  veryLightGrey: '#CCCCCC',
  veryLightGrey2: '#CECCCC',
  darkTangerine: '#FDB913',
  gainsboro2: '#E0E0E0',
  lightGrayStroke: '#0000003B',
  Cinnabar: '#E53935',
  fruitSaladGreen: '#43A047',
  orangePeel: '#FF9800',
  deepOrange: '#FF5722',
  greenLaurel: '#388E3C',
  madison: '#2C3E50',
  dimGrey: '#666666',
  whisper: '#EEEEEE',
  rawUmber: '#773601',
  silver: '#BDBDBD',
  linkWater: '#CBD5E1',
  aliceBlue: '#F8FAFC',
  linen: '#FDF0E6',
  denim: '#2261C9',
  nobel: '#9E9E9E',
  strokePrimary: '#829DFF',
  green3: '#4CAF50',
  blue50: '#E3F2FD',
  blue900: '#0d47a1',
  blue700: '#0057B2',
  teal800: '#00695C',
  teal50: '#E0F2F1',
  textPrimary: '#1F1F1F',
  textPending: '#BF360C',
  bgPending: '#FDF0E5',
  textPendingCustomer: '#EF6C00',
  bgPendingCustomer: '#FFF9C4',
  black54: 'rgba(0, 0, 0, 0.54)',
  black60: 'rgba(0, 0, 0, 0.60)',
  lightBlueActive: '#F7F8FF',
  badgeRed: 'rgb(229, 57, 53)',
  blueToggledButton: '#C7D4ED',
  blueGradiant: 'linear-gradient(167deg, #29B6F6 -0.09%, #2A59FF 105.26%)',
  seperatorBg: '#D4DDE4',
  regularTableBg: 'rgba(250,251,252,1)',
  stickyTableCelTranslucent: 'rgba(255, 255, 255, 0.9)',
  successStatusBg: 'rgba(46, 125, 50, 0.04)',
  iconGrey: '#C0C5DD',
  scrollbarColor: '#babac080',
  popoverElementHeight: '37px',
  popoverLabelPadding: '16px',
  subTitleGrey: '#B0B0B0',
  lightBoxShadow: 'rgba(139, 139, 139, 0.10)',
  gridStickyBackGround: 'rgba(250, 251, 252, 0.9)',
  whiteStickyBackGround: 'rgba(255, 255, 255, 0.9)',
  greyStickyBackGround: 'rgba(245, 245, 245, 0.9)',
  addIconSize: '24px',
  iconSize: '16px',
  iconSizeMedium: '20px',
  dotsIconSize: '18px',
  chipRedBg: '#F44336',
  chipRedFont: '#FEEBEE',
  chipGreenFont: '#E8F5E9',
  lightBlue: '#5E81FF',
  fadedBlue: '#A7BAFF',
  fadedGrey: '#F2F4F7',
  sharpGray: '#475467',
  buttonGreyText: '#424242',
  graphGrey: '#EAECF0',
  dashboardSmallCardWidth: '250px',
  dashboardMediumCardWidth: '514px',
  dashboardLargeCardWidth: '1042px',
  dashboardStickySectionWidth: '215px',
  dashboardStickySectionHeight: 'calc(50vh - 120px)',
  dashboardTopSection: '120px',
  lightBlack: '#101828',
  pageGreyBg: '#fafbfc',
  snackBarLeftPosition: 'calc(50% + 140px)',
  snackBarLeftPositionMin: 'calc(50% + 60px)',
  blue600: '#1E88E5',
  deepPurple: '#9575CD',
  teal300: '#4DB6AC',
  grey3: '#ADB9BE',
  redA700: '#D50000',
  redA400: '#FF1744',
  tableNavHeight: '40px',
  grey4: 'rgba(0, 0, 0, 0.04)',
  grey5: '#DCDCDC',
  deepOrange700: '#E64A19',
  tablePaginationHeight: '52px',
  supportStatCardsHeight: '100px',
  tableFilterHeight: '30px',
  lightBlueBg: '#F6F8FF',
  buttonLightGreen: 'rgba(67, 160, 71, 0.05)',
  buttonDarkGreen: 'rgba(76, 175, 80, 0.60)',
  buttonLightRed: 'rgba(244, 67, 54, 0.05)',
  buttonDarkRed: 'rgba(244, 67, 54, 0.60)',
  redLinearGradiant:
    'linear-gradient(90deg, rgba(244, 235, 235, 0) 0%, #f4ebeb 61%)',
  greenLinearGradiant:
    'linear-gradient(90deg, rgba(235, 241, 236, 0) 0%, #ebf1ec 61%)',
  grey400: '#BDBDBD',
  blueA400: '#2979FF',
  deepPurple300: '#9575CD',
  lightBlue300: '#4FC3F7',
  lightGreen400: '#8BC34A',
  orangeA200: '#FFAB40',
  green800: '#2E7D32',
  primaryBackgroundColor: '#E3F2FD',
  actionButtonColor: '#819198',
  darkBlue: '#0128B4',
  progressGreen: '#81C784',
  progressOrange: '#FFA726',
  progressRed: '#EF5350',
  yellow100: '#FFF9C4',
  yellow800: '#F9A825',
  cyan50: '#E0F7FA',
  cyan600: '#00ACC1',
  pink600: '#D81B60',
  pink50: '#FCE4EC',
  teal600: '#00897B',
  blue800: '#1565C0',
  blueA700: '#2962FF',
  grayBorder: 'rgb(224, 224, 224)',
  redRail: '#FECDD2',
  green100: '#C8E6C9',
  orange100: '#FFE0B2',
  indigo50: '#E8EAF6',
  indigo100: '#C5CAE9',
  purple100: '#D1C4E9',
  purple400: '#AB47BC',
  CRMSPrimary: '#439BA7',
  ordoriaPrimary: '#5B26EA',
  noteAlert: '#04BC85',
  callButton: '#009688',
  ordoriaLight: '#AD87F7',
  grey6: '#F9F9F9',
  lightYellow: '#FFECB3',
  borderSilver: '#EFEFEF',
  borderSilverLight: '#EBEEF1',
  iconColorDark: '#343330',
  backgroundSilver: '#F2F5F7',
  skyBlue: '#81D4FA',
  darkRed: '#B71C1C',
  ordoriaLightBackground: '#FAF6FF',
  ordoriaLightBorder: '#B682FF',
  grayBg: '#37474F',
  ordoriaLightIconButton: '#F0E5FF',
  lightGrayBorder: '#E1E1E133',
  gray500: '#506772',
  ordoriaLightActionsBg: '#832AFF0A',
  tooltipReport: '#616161E5',
  textColorDashboard: '#00000099',
  textColorDashboardNoOpacity: '#808080',
  tealA700: '#00BFA5',
  orange700: '#F57C00',
  purpleA700: '#AA00FF',
  lime: '#AFB42B',
  lime100: '#F0F4C3',
  cyan: '#00B8D4',
  darkPurple: '#AA00FF',
  darkPurple50: '#F3E5F5',
  lightGrayLabel: 'rgba(0, 0, 0, 0.6)',
  darkGrayBg: '#ebebeb',
  red800: '#C62828',
  gray50: '#F7F7F7',
  AcidGreen: '#AFB42B',
  Chiffon: '#F0F4C3',
  TurquoiseSurf: '#00B8D4',
  lightgraymagenta: '#F3E5F5',
  chipsBorderGrey: '#D5D5D5',
  whiteSmokeBg: '#ffffffdb',
  errorRed: '#FF0101',
  chatMessageSent: '#5f5f5f',
  chatMessageRecieved: '#5c5e66',
};

const lightTheme = {
  ...commonTheme,
  primary: '#2A59FF',
  secondary: commonTheme.purpleA700,
  text: 'rgb(58,57,56)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  textLight: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.54)',
};

const darkTheme: Theme = {
  ...commonTheme,
  primary: '#2A59FF',
  secondary: commonTheme.purpleA700,
  text: 'rgba(241,233,231,1)',
  textSecondary: 'rgba(241,233,231,0.6)',
  background: 'rgba(0,0,0,1)',
  backgroundVariant: 'rgba(28,26,26,1)',
  border: 'rgba(241,233,231,0.15)',
  borderLight: 'rgba(241,233,231,0.05)',
  textLight: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.54)',
};

const ordoriaTheme: Theme = {
  ...commonTheme,
  primary: '#6600F4',
  primaryActiveColor: '#700ef8',
  secondary: '#2A59FF',
  text: 'rgb(58,57,56)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  textLight: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.54)',
  primaryBackgroundColor: '#EDE7F6',
  actionButtonColor: '#819198',
  callButton: '#009688',
  strokePrimary: '#dac1fa',
};

export const avatarColors = [
  indigo['A200'],
  deepPurple[400],
  amber[900],
  pink[700],
  teal[600],
  yellow[800],
  lightBlue[200],
  indigo[300],
  cyan[600],
  orange[700],
  amber[500],
  '#555',
  '#819198',
  '#EF5350',
];

export function getColorsFromString(id: string): string {
  const idLength = id?.length;

  if (!id) {
    return avatarColors[0];
  }

  if (idLength < 3) {
    const index = id.charCodeAt(idLength - 1) % 10;

    return avatarColors[index % avatarColors.length];
  }

  const colorIndex =
    (id.charCodeAt(idLength - 1) +
      id.charCodeAt(idLength - 2) +
      id.charCodeAt(idLength - 3)) %
    avatarColors.length;

  return avatarColors[colorIndex];
}

export type Theme = typeof lightTheme;

export const themes = {
  bianta: lightTheme,
  ordoria: ordoriaTheme,
  stockee: lightTheme,
  light: lightTheme,
  default: lightTheme,
  dark: darkTheme,
  gescom: lightTheme,
};

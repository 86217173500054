import React, { useState, useEffect, useMemo, useContext } from 'react';
import { ChatContext } from 'app/components/Chat';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase, Divider } from '@mui/material';
import { UserAvatar } from 'app/components/Chat/components/UserAvatar';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import EllipsisText from 'app/components/EllipsisText';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { PushPin } from '@phosphor-icons/react';
import {
  useDeleteChatMutation,
  useTogglePinChatMutation,
} from 'common/services/chatApi';
import { useModal } from 'app/components/Dialog/hooks';

interface Props {
  chat: any;
  pinned: boolean;
}

export const ChatCard: React.FC<Props> = ({ chat, pinned }) => {
  const { selectedUser, setSelectedUser, triggerRefetchChats, currentUserId } =
    useContext(ChatContext);
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { closeModal, openModal } = useModal();

  const isSelectedChat = useMemo(() => {
    return selectedUser?._id === chat?.chatId;
  }, [selectedUser, chat]);

  useEffect(() => {
    if (!menuOpen) setHovered(false);
  }, [menuOpen]);

  const [deleteChat] = useDeleteChatMutation();

  const handleCofirmDeleteDialog = name => {
    openModal({
      action: {
        actionText: t('common.buttons.delete'),
        actionCallback: () => handleDeleteChat(),
      },
      title: t('chats.chat.delete.confirm'),
      deleteModal: true,
      cancel: true,
      content: t('chats.chat.delete.messsage', { name: name }),
    });
  };

  const handleDeleteChat = async () => {
    if (!chat?._id) return;
    await deleteChat({ id: chat._id }).then(() => {
      triggerRefetchChats();
      closeModal();
    });
  };

  const [togglePinChat] = useTogglePinChatMutation();

  const handleTogglePinChat = async () => {
    if (!chat?._id) return;
    await togglePinChat({ id: chat._id }).then(res => {
      triggerRefetchChats();
    });
  };

  return (
    <Wrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      hovered={hovered || menuOpen}
    >
      <ButtonBase
        onClick={() => setSelectedUser({ ...chat?.user, _id: chat?.chatId })}
        sx={{ borderRadius: '4px', flex: 1 }}
      >
        <ButtonContentWrapper selected={isSelectedChat}>
          <ContactCardWrapper
            selected={isSelectedChat}
            hovered={hovered || menuOpen}
          >
            <UserAvatar
              size={32}
              userImagePath={chat?.pictureUrl}
              fullName={chat?.fullName}
              status={chat?.onlineStatus}
            />
            <UserInfo primaryColor={theme.primary}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '2px' }}>
                  <p className="name">{chat?.fullName}</p>
                  <p className="name">{` - `}</p>
                  <p className="role">{chat?.role}</p>
                  {currentUserId === chat?.userId && (
                    <p className="role">{`(${t('you')})`}</p>
                  )}
                </div>
                <p className="time">{chat?.lastMessageAt}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="lastMessage">
                  <EllipsisText width={220} text={chat?.lastMessage} />
                </p>
                {chat?.unreadMessagesCount > 0 ? (
                  <div className="unreadMessagesCount">
                    {chat?.unreadMessagesCount}
                  </div>
                ) : pinned ? (
                  <Icon icon={<PushPin />} size={16} />
                ) : null}
              </div>
            </UserInfo>
          </ContactCardWrapper>
        </ButtonContentWrapper>
      </ButtonBase>
      <ContactActionsButtonWrapper className="ContactActionsButtonWrapper">
        <ActionPopover
          id={chat?.user?._id}
          handleAction={async (e, action, id) => {
            setMenuOpen(false);
          }}
          styling={{ justifyContent: 'end' }}
          iconSize={24}
          setMenuOpen={setMenuOpen}
          actions={[
            pinned
              ? {
                  action: 'unpin',
                  onClick: handleTogglePinChat,
                  element: (
                    <PopoverEl redStatus="" style={{ width: '100%' }}>
                      <div className="popover-item">{t('common.unpin')}</div>
                    </PopoverEl>
                  ),
                }
              : {
                  action: 'pin',
                  onClick: handleTogglePinChat,
                  element: (
                    <PopoverEl redStatus="" style={{ width: '100%' }}>
                      <div className="popover-item">{t('common.pin')}</div>
                    </PopoverEl>
                  ),
                },
            // {
            //   action: 'markAsUnread',
            //   onClick: () => {},
            //   element: (
            //     <PopoverEl redStatus="" style={{ width: '100%' }}>
            //       <div className="popover-item">{t('mark_as_unread')}</div>
            //     </PopoverEl>
            //   ),
            // },
            // {
            //   action: 'clearHistory',
            //   onClick: () => {},
            //   element: (
            //     <PopoverEl redStatus="" style={{ width: '100%' }}>
            //       <div className="popover-item">{t('clear_history')}</div>
            //     </PopoverEl>
            //   ),
            // },
            {
              action: 'divider',
              seperator: true,
              element: (
                <PopoverEl redStatus="" style={{ width: '100%' }}>
                  <Divider />
                </PopoverEl>
              ),
            },
            {
              action: 'delete',
              onClick: () => handleCofirmDeleteDialog(chat?.fullName),
              element: (
                <PopoverEl
                  redStatus={themes.default.redStatus}
                  style={{ width: '100%' }}
                >
                  <div className="delete popover-item">{t('delete')}</div>
                </PopoverEl>
              ),
            },
          ]}
        />
      </ContactActionsButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ hovered: boolean }>`
  position: relative;
  display: flex;
  .ContactActionsButtonWrapper {
    opacity: ${props => (props.hovered ? 1 : 0)};
  }
`;

const ButtonContentWrapper = styled.div<{ selected: boolean }>`
  width: 100%;
  max-height: 63px;
  min-height: 63px;
  background: ${props =>
    props.selected
      ? 'linear-gradient(166.61deg, #29B6F6 -0.09%, #2A59FF 105.26%)'
      : ''};
  padding: ${props => (props.selected ? '1.5px' : '')};
  border: ${props =>
    props.selected ? '' : `1px solid ${themes?.default?.gainsboro2}`};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContactCardWrapper = styled.div<{ selected: boolean; hovered: boolean }>`
  max-height: 61px;
  min-height: 61px;
  width: 100%;
  padding: 12px;
  border-radius: ${props => (props.selected ? '3px' : '4px')};
  display: flex;
  gap: 8px;
  transition: background 0.2s;
  cursor: pointer;
  background: ${props =>
    props.selected
      ? themes?.default?.lightBlueBg
      : props.hovered
      ? themes?.default?.backgroundSilver
      : themes?.default?.accordionWhiteBg};
`;

export const UserInfo = styled.div<{ primaryColor?: string }>`
  flex: 1;
  color: ${themes?.default?.textColorPrimary};
  line-height: 15.23px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    margin: 0;
    text-align: left;
  }
  .name {
    font-size: 0.92rem;
    font-weight: 700;
  }
  .role {
    font-size: 0.92rem;
  }
  .time {
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 14.06px;
    color: ${themes?.default?.textColorSecondary};
  }
  .lastMessage {
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 20px;
    color: ${themes?.default?.textColorDashboardNoOpacity};
  }
  .unreadMessagesCount {
    height: 1rem;
    width: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.primaryColor};
    color: ${themes?.default?.accordionWhiteBg};
    font-size: 0.714rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: center;
  }
`;

const ContactActionsButtonWrapper = styled.div`
  width: 74px;
  border-radius: 0 3px 3px 0;
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  padding: 0 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.2s;
  opacity: 0;
  backdrop-filter: blur(3px);
`;

import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase } from '@mui/material';
import { UserAvatar } from 'app/components/Chat/components/UserAvatar';
import { StateSetter } from 'types';
import { useTranslation } from 'react-i18next';
import { useOnScreen } from 'app/components/ProductCard/hook';
import { ChatContext } from 'app/components/Chat';

interface Props {
  contact: any;
  setSelectedUser: StateSetter<string>;
  handleRenderMoreItems: () => void;
  isCurrentUser: boolean;
}

export const LastContactCard: React.FC<Props> = ({
  contact,
  setSelectedUser,
  handleRenderMoreItems,
  isCurrentUser,
}) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const cardRef = useRef(null);
  const rowIsVisible = useOnScreen(cardRef);
  const { isFetchingContacts } = useContext(ChatContext);

  useEffect(() => {
    if (!isFetchingContacts && rowIsVisible) handleRenderMoreItems();
  }, [rowIsVisible]);

  return (
    <ButtonBase
      onClick={() => setSelectedUser(contact)}
      sx={{ borderRadius: '4px' }}
      ref={cardRef}
    >
      <ContactCardWrapper>
        <UserAvatar
          size={32}
          userImagePath={contact?.pictureUrl}
          fullName={contact?.fullName}
          status={contact?.onlineStatus}
        />
        <UserInfo>
          <p className="name">
            {contact?.fullName} {isCurrentUser && ` (${t('you')})`}
          </p>
          <p className="role">
            {locale === 'fr'
              ? contact?.currentAgent?._role?.localeName?.fr
              : contact?.currentAgent?._role?.localeName?.en}
          </p>
        </UserInfo>
      </ContactCardWrapper>
    </ButtonBase>
  );
};

const ContactCardWrapper = styled.div`
  width: 100%;
  height: 63px;
  padding: 12px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid ${themes?.default?.gainsboro2};
  display: flex;
  gap: 8px;
  transition: background 0.2s;
  cursor: pointer;

  &:hover {
    background: ${themes?.default?.backgroundSilver};
  }
`;

const UserInfo = styled.div`
  flex: 1;
  color: ${themes?.default?.textColorPrimary};
  line-height: 15.23px;
  p {
    margin: 0;
    text-align: left;
  }
  .name {
    font-size: 0.8125rem;
    font-weight: 700;
  }
  .role {
    font-size: 0.8125rem;
  }
`;

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Question, CrosshairSimple, Clock } from '@phosphor-icons/react';
import {
  Form,
  FormSection,
  FormWrapper,
  FormActions,
} from 'app/components/Form/styles';
import KeyColored from 'assets/img/customers/KeyColored.svg';
import KeyNotColored from 'assets/img/customers/KeyNotColored.svg';
import TimePickerForm from 'app/components/Form/TimePickerForm';
import { useGetCarriersQuery } from '../../../../../common/services/carrierApi';
import { useNavigate } from 'react-router-dom';
import { LOGOUT } from 'utils/routes';
import { BranchButton } from 'app/pages/Customers/components/BranchButton';
import { themes } from 'styles/theme/themes';
import { useParams } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import {
  Autocomplete,
  TextField,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { FieldComponent } from 'app/components/FieldComponent';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { InputAdornment } from '@material-ui/core';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import {
  ClientInformationTitle,
  ClientInformationTitleContainer,
} from '../styles';
import BranchMap from 'app/components/BranchMap/BranchMap';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useModal } from 'app/components/Dialog/hooks';
import { AddnewForm } from './AddnewForm';
import { useAddBranchMutation } from 'common/services/customerApi';
interface Props {}
export interface Suggestion {
  name: string;
  coordinates: [number, number];
}
export interface Position {
  coords: {
    latitude: number;
    longitude: number;
    altitude: number | null;
    accuracy: number;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null;
  };
  timestamp: number;
}
interface RouteParams {
  id: string; // The type of the parameter from the URL
}
export const BranchDrawer: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  const DEFAULT_LATITUDE = 49.029045;
  const DEFAULT_LONGITUDE = 2.8033632;

  //usestates
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [StatusValue, setStatusValue] = useState<{
    label: string;
    value: string;
  } | null>({ label: 'Active', value: '' });
  const [CollectionDaysValue, setCollectionDaysValue] = useState<{
    label: string;
    value: string;
  } | null>({ label: '', value: '' });
  const [CarrierValue, setCarrierValue] = useState<{
    label: string;
    value: string;
  } | null>({ label: '', value: '' });
  const [query, setQuery] = useState<string>('');
  const [clicked, setClicked] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [open, setOpen] = useState(true);
  const [latitude, setLatitude] = useState<number | null>(DEFAULT_LATITUDE);
  const [longitude, setLongitude] = useState<number | null>(DEFAULT_LONGITUDE);
  const [loading, setLoading] = useState(false);

  const [mainlocationValue, setLocationvalue] = useState<{
    name: string;
    city: string;
    department: string;
    region: string;
    continent: string;
    zipCode: string;
    formattedAddress: string;
    shortAddress: string;
    countryCode: string;
    geometry: {
      type: string;
      coordinates: number[];
    };
  }>({
    name: '',
    city: '',
    department: '',
    region: '',
    continent: '',
    zipCode: '',
    formattedAddress: '',
    shortAddress: '',
    countryCode: '',
    geometry: {
      type: '',
      coordinates: [],
    },
  });

  //usestates end
  //popover
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosepop = () => {
    setAnchorEl(null);
  };

  const openpop = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  //popover ends

  // const handleClose = () => {
  //   onClose();
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    closeModal();

    handleClose();
    navigate(LOGOUT);
  };
  const handleOpenAddNew = () => {
    openModal({
      title: t('Add New Contact'),
      cancel: true,
      action: {
        actionCallback: handleLogout,
        actionText: t('ADD'),
      },
      content: <AddnewForm />,
    });
  };
  const methods = useForm({
    defaultValues: {
      legalIdAlt: '',
      Branchname: '',
      myTimeField: '',
      PrefferedTimeslotFrom: '',
      PrefferedTimeslotTo: '',
      AvailableTimeslotTo: '',
      AvailableTimeslotFrom: '',
      status: '',
      mainLocation: '',
      vatNumber: '',
      APECode: '',
      Delivery: false,
      Collection: false,
      Third_party_collector: false,
      carrier: 'false',
      MarkAsMain: 'false',
      collectiondays: '',
      Palettes: 'false',
      Vracs: 'false',
      Rolls: 'false',
      PL: 'false',
      VL: 'false',
      MinimumWeight: '',
      MinimumOrderPrice: '',
      MinimumOfBoxes: '',
    },
  });
  //watch
  const { control, watch, reset } = methods;
  const legalIdAlt = watch('legalIdAlt');
  const Branchname = watch('Branchname');
  const status = watch('status');
  const MarkAsMain = watch('MarkAsMain');
  const mainLocation = watch('mainLocation');
  const vatNumber = watch('vatNumber');
  const APECode = watch('APECode');
  const Delivery = watch('Delivery');
  const Collection = watch('Collection');
  const Third_party_collector = watch('Third_party_collector');
  const carrier = watch('carrier');
  const collectiondays = watch('collectiondays');
  const Palettes = watch('Palettes');
  const Vracs = watch('Vracs');
  const Rolls = watch('Rolls');
  const PL = watch('PL');
  const VL = watch('VL');
  const AvailableTimeslotFrom = watch('AvailableTimeslotFrom');
  const AvailableTimeslotTo = watch('AvailableTimeslotTo');
  const PrefferedTimeslotFrom = watch('PrefferedTimeslotFrom');
  const PrefferedTimeslotTo = watch('PrefferedTimeslotTo');
  const MinimumWeight = watch('MinimumWeight');
  const MinimumOrderPrice = watch('MinimumOrderPrice');
  const MinimumOfBoxes = watch('MinimumOfBoxes');
  //watch end

  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const carrierData = useGetCarriersQuery();

  const carriers = carrierData.data
    ? carrierData.data.data.map(item => {
        return { label: item.publicName, value: item._id };
      })
    : [];

  const handleTimeChange = (time: Date | null) => {
    setSelectedTime(time);
  };
  const CustomerStatus = [
    { label: 'Preliminary', value: 'preliminary' },
    { label: 'Pending', value: 'pending' },
    { label: 'Active', value: 'active' },
    { label: 'On-watch', value: 'on-watch' },
    { label: 'Blocked', value: 'blocked' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Stopped', value: 'stopped' },
  ];

  const Days = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
  ];
  const StyledTableCell = styled(TableCell)({
    padding: '4px 8px',
    fontSize: '0.75rem',
    border: '1px solid #EFEFEF',
    cursor: 'pointer', // Make the cell look clickable
  });
  const reverseGeocode = async (latitude, longitude) => {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        displayName: data.display_name,
        city:
          data.address.city || data.address.town || data.address.village || '',
        district: data.address.suburb || data.address.neighbourhood || '',
        region: data.address.state || '',
        countryCode: data.address.country_code || '',
        zipCode: data.address.postcode || '',
      };
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  };
  const handleClick1 = async () => {
    if (!clicked) {
      setClicked(true);
      try {
        const position = await new Promise<Position>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);

        // Reverse geocode using the custom function
        const address = await reverseGeocode(latitude, longitude);
        if (address) {
          setQuery(address.displayName);

          // Construct the name field based on available address components
          let name = address.displayName;

          // Set the location value from the reverse geocode result
          const mainLocationValue = {
            ...mainlocationValue,
            name: name,
            city: address.city || '',
            district: address.district || '',
            region: address.region || '',
            countryCode: address.countryCode || '',
            zipCode: address.zipCode || '',
            geometry: {
              type: 'Point',
              coordinates: [longitude, latitude],
            },
          };
          setLocationvalue(mainLocationValue);
        } else {
          console.error('No address found for the provided coordinates.');
        }
      } catch (error) {
        console.error('Error getting location:', error);
      }
    } else {
      setClicked(false);
      setQuery('');
    }
  };

  const geocodingClient = mbxGeocoding({
    accessToken:
      'pk.eyJ1IjoianJleW5hdWQiLCJhIjoiY2xkeGN0OTljMDFoODN3azlkM3UyOXA4dyJ9.gzYtNROqJfITHXZMYsoWvA',
  });

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;
    setQuery(inputValue);

    try {
      const response = await geocodingClient
        .forwardGeocode({
          query: inputValue,
          limit: 4,
        })
        .send();

      if (response && response.body && response.body.features) {
        const suggestions = response.body.features.map(feature => ({
          name: feature.place_name,
          coordinates: feature.geometry.coordinates,
        }));

        setLocationvalue(prevState => ({
          ...prevState,
          city: inputValue,
        }));

        setSuggestions(suggestions);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };
  const timePickerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (timePickerRef.current) {
      timePickerRef.current.focus();
    }
  }, []);
  const handleSuggestionClick = async (suggestion: Suggestion) => {
    setLatitude(suggestion.coordinates[1]);
    setLongitude(suggestion.coordinates[0]);
    setOpen(true);
    setQuery(suggestion.name);

    const response = await geocodingClient
      .forwardGeocode({
        query: suggestion.name,
        limit: 1,
      })
      .send();

    if (response && response.body && response.body.features) {
      let res = response.body.features[0];
      let countryCode = '';
      let regionText = '';
      let zipCode = '';
      let city = '';
      let type = '';
      let coordinates: number[] = [];

      if (res.context) {
        const countryContext = res.context.find(item =>
          item.id.includes('country'),
        );
        if (countryContext) {
          countryCode = countryContext.short_code || '';
        }

        const regionContext = res.context.find(item =>
          item.id.includes('region'),
        );
        if (regionContext) {
          regionText = regionContext.text;
        }

        const zipCodeContext = res.context.find(item =>
          item.id.includes('postcode'),
        );
        if (zipCodeContext) {
          zipCode = zipCodeContext.text;
        }

        const cityContext = res.context.find(item => item.id.includes('place'));
        if (cityContext) {
          city = cityContext.text;
        }
      }

      if (res.geometry && res.geometry.type && res.geometry.coordinates) {
        type = res.geometry.type;
        coordinates = res.geometry.coordinates;
      }

      setLocationvalue({
        ...mainlocationValue,
        name: res.place_name,
        city: city,
        department: '',
        region: regionText,
        continent: '',
        zipCode: zipCode,
        formattedAddress: '',
        shortAddress: '',
        countryCode: countryCode,
        geometry: {
          type: type,
          coordinates: coordinates,
        },
      });
      setSuggestions([]);
    }
  };

  const themegray = createTheme({
    palette: {
      secondary: {
        main: '#b3a9acd3',
      },
    },
  });
  const [Addbranch] = useAddBranchMutation();

  const { id } = useParams<Record<string, string>>();

  const onSubmit = async () => {
    //ADD Branch
    const result: any = await Addbranch({
      id,
      legalIdAlt,
      Branchname,
      PrefferedTimeslotFrom,
      PrefferedTimeslotTo,
      AvailableTimeslotTo,
      AvailableTimeslotFrom,
      status,
      mainLocation,
      vatNumber,
      APECode,
      Delivery,
      Collection,
      Third_party_collector,
      carrier,
      MarkAsMain,
      collectiondays,
      Palettes,
      Vracs,
      Rolls,
      PL,
      VL,
      MinimumWeight,
      MinimumOrderPrice,
      MinimumOfBoxes,
    }).unwrap();
  };

  return (
    <ThemeProvider theme={themegray}>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <FormWrapper>
          <FormSection>
            <FieldComponent
              style={{
                width: '791px',
                height: '40px',
                marginTop: '8px',
                marginBottom: '8px',
              }}
              name="legalIdAlt"
              control={control}
              size="small"
              label={t('SIRET Number')}
              color="secondary"
              focused
              placeholder={t('Enter SIRET NUMBER')}
              disabled={false}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        textDecoration: 'underline',
                        color: '#2A59FF',
                      }}
                    >
                      {t('Validate')}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </FormSection>
          <FormSection>
            <ClientInformationTitleContainer>
              <ClientInformationTitle>{t('Details')}</ClientInformationTitle>
            </ClientInformationTitleContainer>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '628px', height: '40px' }}>
                <FieldComponent
                  name="Branchname"
                  control={control}
                  size="small"
                  label={t('Name')}
                  color="secondary"
                  focused
                  placeholder={t('Enter Branch Name')}
                  disabled={false}
                  variant="outlined"
                />
              </div>
              <div>
                <Autocomplete
                  options={CustomerStatus}
                  sx={{ width: '155px' }}
                  value={StatusValue}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name="status"
                      label={t('Status')}
                      style={{ width: '155px' }}
                      size="small"
                      color="secondary"
                      focused
                      variant="outlined"
                      defaultValue={'Active'}
                    />
                  )}
                  onChange={(event, newValue: any) => {
                    setStatusValue(newValue);
                  }}
                />
              </div>
            </div>
          </FormSection>
          <FormSection>
            <div style={{ marginBottom: '20px' }}>
              <ClientInformationTitle style={{ marginRight: '-90px' }}>
                {t('Address')}
              </ClientInformationTitle>
              <Question size={12} color="#757575" />
              <UnverifiedText>{t('Unverified')}</UnverifiedText>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                style={{ width: '625px' }}
                name="mainLocation"
                focused
                label={t('Add_Client_Address')}
                color="secondary"
                size="small"
                type="text"
                value={query}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClick1}>
                        <div>
                          {clicked ? (
                            <CrosshairSimple
                              weight="bold"
                              size={25}
                              style={{ color: '#2A59FF' }}
                            />
                          ) : (
                            <CrosshairSimple
                              weight="bold"
                              size={25}
                              style={{ color: '#757575' }}
                            />
                          )}
                        </div>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormGroup style={{ marginLeft: '27px', marginBottom: '15px' }}>
                <FormControlLabel
                  name="MarkAsMain"
                  control={<Checkbox />}
                  label="Mark as main"
                />
              </FormGroup>
            </div>
            {suggestions.length > 0 && (
              <div>
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    style={{
                      cursor: 'pointer',
                      marginBottom: '5px',
                      color: '#000',
                      transition: 'color 0.3s ease',
                    }}
                    onMouseEnter={e =>
                      ((e.target as HTMLDivElement).style.color = '#2A59FF')
                    }
                    onMouseLeave={e =>
                      ((e.target as HTMLDivElement).style.color = '#000')
                    }
                  >
                    {suggestion.name}
                  </div>
                ))}
              </div>
            )}

            {open && (
              <div style={{ zIndex: -1 }}>
                <BranchMap
                  latitude={latitude}
                  longitude={longitude}
                  open={open}
                  handleClose={() => setOpen(false)}
                />
              </div>
            )}
          </FormSection>
          <FormSection>
            <div style={{ display: 'flex', marginTop: '8px' }}>
              <ClientInformationTitle>
                {t('Business Info')}
              </ClientInformationTitle>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '16px' }}>
                <FieldComponent
                  style={{ width: '385.5px' }}
                  name="vatNumber"
                  control={control}
                  size="small"
                  label={t('VAT Number')}
                  color="secondary"
                  focused
                  placeholder={t('Add_Client_Enter_VAT_Number')}
                  disabled={false}
                  variant="outlined"
                />
              </div>
              <div>
                <FieldComponent
                  style={{ width: '385.5px' }}
                  name="APECode"
                  control={control}
                  size="small"
                  label={t('APE Code')}
                  color="secondary"
                  focused
                  placeholder={t('Enter APE Code')}
                  disabled={false}
                  variant="outlined"
                />
              </div>
            </div>
          </FormSection>
          <FormSection>
            <div style={{ width: '678px' }}>
              <div style={{ display: 'flex' }}>
                <ClientInformationTitle>
                  {t('Phone Book')}
                </ClientInformationTitle>
              </div>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  color: '#2A59FF',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={handleOpenAddNew}
              >
                {t('+')}
              </span>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  textDecoration: 'underline',
                  color: '#2A59FF',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
                onClick={handleOpenAddNew}
              >
                {t('Add new')}
              </span>
            </div>
          </FormSection>
          <FormSection>
            <div style={{ display: 'flex', marginTop: '25px' }}>
              <ClientInformationTitle>
                {t('Delivery type')}
              </ClientInformationTitle>
            </div>
            <div style={{ display: 'flex' }}>
              <FormGroup
                style={{
                  marginLeft: '8px',
                  marginBottom: '15px',
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  name="Delivery"
                  control={<Checkbox />}
                  label="Delivery"
                />
              </FormGroup>
              <FormGroup
                style={{
                  marginBottom: '15px',
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  name="Collection"
                  control={<Checkbox />}
                  label="Collection"
                />
              </FormGroup>
            </div>
          </FormSection>
          <FormSection>
            <div style={{ marginLeft: '8px', display: 'flex' }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Third party collector"
                  name="Third_party_collector"
                />
              </FormGroup>
              <Autocomplete
                options={carriers}
                sx={{ width: '298px' }}
                value={CarrierValue}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="carrier"
                    label={t('Carrier')}
                    style={{ width: '298px' }}
                    size="small"
                    color="secondary"
                    focused
                    variant="outlined"
                    placeholder={t('Select Carrier')}
                  />
                )}
                onChange={(event, newValue: any) => {
                  setCarrierValue(newValue);
                }}
              />
              <Autocomplete
                options={Days}
                sx={{ width: '298px', marginLeft: '8px' }}
                value={CollectionDaysValue}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="collectiondays"
                    label={t('Collection days')}
                    style={{ width: '298px' }}
                    size="small"
                    color="secondary"
                    focused
                    variant="outlined"
                    placeholder={t('Select days')}
                  />
                )}
                onChange={(event, newValue: any) => {
                  setCollectionDaysValue(newValue);
                }}
              />
            </div>
          </FormSection>
          <FormSection>
            <div style={{ display: 'flex' }}>
              <ClientInformationTitle>
                {t('Preferred Support Units')}
              </ClientInformationTitle>
            </div>
            <div
              style={{
                marginLeft: '8px',
                marginBottom: '15px',
                display: 'flex',
              }}
            >
              <div style={{ display: 'flex' }}>
                <FormGroup style={{ marginRight: '16px' }}>
                  <FormControlLabel
                    name="Palettes"
                    control={<Checkbox />}
                    label="Palettes"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    name="Vracs"
                    control={<Checkbox />}
                    label="Vracs"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    name="Rolls"
                    control={<Checkbox />}
                    label="Rolls"
                  />
                </FormGroup>
              </div>
            </div>
          </FormSection>
          <FormSection>
            <div style={{ display: 'flex' }}>
              <ClientInformationTitle>
                {t('Access conditions')}
              </ClientInformationTitle>
            </div>
            <div
              style={{
                marginLeft: '8px',
                marginBottom: '15px',
                display: 'flex',
              }}
            >
              <div style={{ display: 'flex' }}>
                <FormGroup style={{ marginRight: '16px' }}>
                  <FormControlLabel
                    name="PL"
                    control={<Checkbox />}
                    label="PL"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    name="VL"
                    control={<Checkbox />}
                    label="VL"
                  />
                </FormGroup>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <ClientInformationTitle>
                {t('Delivery days')}
              </ClientInformationTitle>
            </div>
            <TableContainer component={Paper}>
              <Table size="small" style={{ border: '1px solid #EFEFEF' }}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{ backgroundColor: '#E5EBFF', color: '#37383D' }}
                    >
                      Mon
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Tue
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Wed
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Thu
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Fri
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ backgroundColor: '#E5EBFF', color: '#37383D' }}
                    >
                      Sat
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Sun
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </FormSection>
          <FormSection>
            <div style={{ display: 'flex', marginTop: '25px' }}>
              <ClientInformationTitle>{t('Call days')}</ClientInformationTitle>
            </div>
            <TableContainer component={Paper}>
              <Table size="small" style={{ border: '1px solid #EFEFEF' }}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{ backgroundColor: '#E5EBFF', color: '#37383D' }}
                    >
                      Mon
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Tue
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Wed
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Thu
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Fri
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ backgroundColor: '#E5EBFF', color: '#37383D' }}
                    >
                      Sat
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#A9A9A9' }}>
                      Sun
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell onClick={handleClick}>-</StyledTableCell>
                    <Popover
                      id={popoverId}
                      open={openpop}
                      anchorEl={anchorEl}
                      onClose={handleClosepop}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      style={{ pointerEvents: 'none' }}
                    >
                      <Typography sx={{ p: 2 }}>
                        The content of the Popover.
                      </Typography>
                    </Popover>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: 'flex' }}>
              <FormGroup style={{ marginRight: '16px' }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Show client on call list"
                />
              </FormGroup>
            </div>
          </FormSection>
          <FormSection>
            <div style={{ display: 'flex', marginTop: '25px' }}>
              <ClientInformationTitle>{t('Timeslots')}</ClientInformationTitle>
            </div>
            <div style={{ display: 'flex' }}>
              <ClientInformationTitle>
                {t('Available Timeslot')}
              </ClientInformationTitle>
              <ClientInformationTitle>
                {t('Preffered Timeslot')}
              </ClientInformationTitle>
            </div>
            {/* <div style={{ display: 'flex' }}>
              <div style={{ width: '160px', height: '40px' }}>
                <FieldComponent
                  name="AvailableTime"
                  control={control}
                  size="small"
                  label={t('Available Timeslot')}
                  color="secondary"
                  focused
                  disabled={false}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Clock size={19.5} color="#757575" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ width: '160px', height: '40px' }}>
                <FieldComponent
                  name="AvailableTime"
                  control={control}
                  size="small"
                  label={t('Available Timeslot')}
                  color="secondary"
                  focused
                  disabled={false}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Clock size={19.5} color="#757575" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div> */}
            <div style={{ display: 'flex' }}>
              <FormSection style={{ display: 'flex' }}>
                <form style={{ width: '160px', display: 'flex' }}>
                  <TimePickerForm
                    control={control}
                    name="AvailableTimeslotFrom"
                    label="From"
                  />
                </form>
                <div style={{ width: '160px', height: '40px' }}>
                  <TimePickerForm
                    control={control}
                    name="AvailableTimeslotTo"
                    label="To"
                  />
                </div>
              </FormSection>
              <FormSection style={{ display: 'flex', marginLeft: '70px' }}>
                <form style={{ width: '160px', display: 'flex' }}>
                  <TimePickerForm
                    control={control}
                    name="PrefferedTimeslotFrom"
                    label="From"
                  />
                </form>
                <div style={{ width: '160px', height: '40px' }}>
                  <TimePickerForm
                    control={control}
                    name="PrefferedTimeslotTo"
                    label="To"
                  />
                </div>
              </FormSection>
            </div>
          </FormSection>
          <FormSection>
            <div style={{ display: 'flex' }}>
              <ClientInformationTitle>
                {t('Minimum to Order')}
              </ClientInformationTitle>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '250.33px', height: '40px' }}>
                <FieldComponent
                  name="MinimumWeight"
                  control={control}
                  size="small"
                  label={t('Minimum weight')}
                  color="secondary"
                  focused
                  disabled={false}
                  variant="outlined"
                  placeholder={t('Enter weight')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p>Kg</p>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ width: '250.33px', height: '40px' }}>
                <FieldComponent
                  name="MinimumOrderPrice"
                  control={control}
                  size="small"
                  label={t('Minimum order price')}
                  color="secondary"
                  focused
                  disabled={false}
                  variant="outlined"
                  placeholder={t('Enter price')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p>EUR</p>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ width: '250.33px', height: '40px' }}>
                <FieldComponent
                  name="MinimumOfBoxes"
                  control={control}
                  size="small"
                  label={t('Minimum # of boxes')}
                  color="secondary"
                  focused
                  disabled={false}
                  variant="outlined"
                  placeholder={t('Enter minimum #')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p>Boxes</p>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </FormSection>
          <FormSection>
            <div style={{ display: 'flex', marginTop: '25px' }}>
              <ClientInformationTitle>{t('Doors')}</ClientInformationTitle>
            </div>
            <TableContainer style={{ width: '395px' }} component={Paper}>
              <Table
                size="small"
                style={{ border: '1px solid #EFEFEF', width: '395px' }}
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell style={{ color: '#707070' }}>
                      Door A
                    </StyledTableCell>
                    <StyledTableCell>
                      6373
                      <img
                        src={KeyColored}
                        alt="KeyColored"
                        style={{ float: 'right', marginTop: '3px' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell style={{ color: '#707070' }}>
                      Door B
                    </StyledTableCell>
                    <StyledTableCell>
                      6373
                      <img
                        src={KeyColored}
                        alt="KeyColored"
                        style={{ float: 'right', marginTop: '3px' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell style={{ color: '#707070' }}>
                      Door C
                    </StyledTableCell>
                    <StyledTableCell>
                      --
                      <img
                        src={KeyNotColored}
                        alt="KeyNotColored"
                        style={{ float: 'right', marginTop: '3px' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell style={{ color: '#707070' }}>
                      Door D
                    </StyledTableCell>
                    <StyledTableCell>
                      6373
                      <img
                        src={KeyColored}
                        alt="KeyColored"
                        style={{ float: 'right', marginTop: '3px' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell style={{ color: '#707070' }}>
                      Door E
                    </StyledTableCell>
                    <StyledTableCell>
                      6373
                      <img
                        src={KeyColored}
                        alt="KeyColored"
                        style={{ float: 'right', marginTop: '3px' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell style={{ color: '#707070' }}>
                      Door F
                    </StyledTableCell>
                    <StyledTableCell>
                      6373
                      <img
                        src={KeyColored}
                        alt="KeyColored"
                        style={{ float: 'right', marginTop: '3px' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </FormSection>
          <FormActions>
            <Button
              disableElevation
              sx={{ color: themes.default.black60 }}
              onClick={handleClose}
            >
              {t('common.buttons.cancel')}
            </Button>

            <BranchButton
              loading={loading}
              onSubmit={onSubmit}
              disabled={false}
              isEdit={false}
              withOptions={true}
            />
          </FormActions>
        </FormWrapper>
      </Form>
    </ThemeProvider>
  );
};

const UnverifiedText = styled.span`
  display: inline-block;
  font-size: 10px;
  color: #757575;
`;

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:last-child td, &:last-child th': {
      borderRight: 0,
    },
  },
}))(TableRow);

import React, { useMemo } from 'react';
import { themes } from 'styles/theme/themes';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  messageId: string;
  messageContent: string;
  createdAt: string;
  contentType: string;
  messageType: string;
  handleMessageActions: Function;
}

export const MessageOptionsMenu: React.FC<Props> = ({
  messageId,
  messageContent,
  createdAt,
  contentType,
  messageType,
  handleMessageActions,
}) => {
  const { t } = useTranslation();
  const canEdit = useMemo(() => {
    if (contentType !== 'text' || messageType !== 'sent') return false;

    const allowedEditDurationInMinutes = 10;
    const editTimeUnix = allowedEditDurationInMinutes * 60 * 1000;

    const nowUnix = new Date().getTime();
    const createdAtUnix = new Date(createdAt).getTime();

    if (nowUnix - editTimeUnix > createdAtUnix) {
      return false;
    } else {
      return true;
    }
  }, [contentType, messageType, createdAt]);
  return (
    <>
      <ActionPopover
        id={messageId}
        handleAction={async (e, action, id) =>
          handleMessageActions(action, id, messageContent)
        }
        styling={{ justifyContent: 'end' }}
        iconSize={24}
        actions={
          canEdit
            ? [
                {
                  action: 'edit',
                  onClick: () => {},
                  element: (
                    <PopoverEl redStatus="" style={{ width: '100%' }}>
                      <div className="popover-item">{t('common.edit')}</div>
                    </PopoverEl>
                  ),
                },
                {
                  action: 'divider',
                  seperator: true,
                  element: (
                    <PopoverEl redStatus="" style={{ width: '100%' }}>
                      <Divider />
                    </PopoverEl>
                  ),
                },
                {
                  action: 'delete',
                  onClick: () => {},
                  element: (
                    <PopoverEl
                      redStatus={themes.default.redStatus}
                      style={{ width: '100%' }}
                    >
                      <div className="delete popover-item">{t('delete')}</div>
                    </PopoverEl>
                  ),
                },
              ]
            : []
        }
      />
    </>
  );
};

import { TableComponent } from 'app/components/TableComponent';
import {
  useUpdateOrderStatusBulkMutation,
  useGetOrdersQuery,
  useLazyGetOrdersIdQuery,
} from 'common/services/orderApi';
import { useFormatOrderData } from 'hooks/Orders/useFormatOrderData';
import { useSelectedTable } from 'hooks/useSelectedTable';
import { Data } from 'interfaces/orders';
import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableNavWrapper } from 'styles/components/users';
import { useOrderHeadCells } from '../Orders/data/headCellsConst';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { OrderDocs } from 'common/types/Order';
import { Alert, Drawer, Menu, MenuItem } from '@mui/material';
import { OrderDetail } from 'app/components/OrderDetail';
import styled from 'styled-components';
import { useSetHeads } from '../AddRoundTrips/hooks/useSetHeads';
import { useSelector } from 'react-redux';
import { handleCloseStopMap } from '../AddRoundTrips/functions';
import MapPopover from 'app/components/MapPopover';
import GenericDialog from 'app/components/Dialog/GenericDialog';
import AssignRoundtrip from './components/AssignRoundtrip';
import { useToaster } from 'hooks/useToaster';
import { useQueryParams } from 'hooks/useQueryParams';
import { useDebounce } from 'common/hooks/useDebounce';
import If from 'app/components/If';
import OrderFilter from 'app/components/OrderFilter';
import { getOrderStatusesToShow } from 'app/helpers/helpers';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import NoOrders from 'assets/img/Orders/no_orders_3.svg';
import NoResults from 'app/components/NoData/NoResults';
import { EnhancedTableToolbar } from 'app/components/EnhancedTableToolbar';
import { selectConfiguration } from 'common/store/organization/selectors';
import { handlesetAnchorFunction } from 'functions/setAnchorFunction';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputIcon from '@mui/icons-material/Input';
import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import { selectAuthUser } from 'app/slices/auth/selectors';
import FilterIcon from 'app/components/FilterComponents/components/FilterIcon';
import { useModal } from 'app/components/Dialog/hooks';
import { useExportCSV } from 'hooks/useExportCSV';
import dayjs from 'dayjs';
import { FilterChips } from 'app/components/FilterChips';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import { useDisabledToolbar } from 'app/components/EnhancedTableToolbar/hooks/useDisabledToolbar';
import { ComponentsStack } from 'app/components/EnhancedTableToolbar/components/ComponentsStack';
import { checkUserAcl } from 'common/helpers/acl';
import { useResetPaginaton } from 'hooks/useResetPagniation';
import ExportButton from 'app/components/FilterComponents/components/ExportButton';
import { UploadSimple } from '@phosphor-icons/react';
import { selectOrderFilters } from 'common/store/filters/selectors';
import { OrderFilterType } from './types';
import { useDispatch } from 'react-redux';
import { filterActions } from 'common/store/filters';
import { createContext } from 'react';
import { RoundtripDrawer } from 'app/pages/Dashboard/components/RoundtripList/components/RoundtripDrawer';

export const OrderDrawerContext_orders = createContext<any>(undefined);

export function Orders() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [triggerGetOrder, { data: fullOrder, isFetching: isFetchingOrder }] =
    useLazyGetOrdersIdQuery();

  const { openModal, closeModal } = useModal();
  const { triggerExport } = useExportCSV();

  const orderFilters = useSelector(selectOrderFilters);

  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [rowsDatas, setRowsDatas] = useState<any>([]);
  const [rows, setRows] = useState<{ data: Data; accordion?: JSX.Element }[]>(
    [],
  );
  const [selectedStop, setSelectedStop] = useState<null | any>(null);
  const [totalDocs, setTotalDocs] = useState<null | any>(0);
  const [headCells, setHeadCells] = useOrderHeadCells();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<OrderFilterType>(orderFilters);
  const {
    filterOn,
    handleCloseFilter,
    handleOpenFilter,
    filtersCount,
    debouncedFiltersCount,
  } = useFilterState({ filters });

  const authUser = useSelector(selectAuthUser);
  const [heads, setHeads] = useState<any[]>([]);
  const [selected, setSelected] = useSelectedTable(rows, false);

  const [updateOrders] = useUpdateOrderStatusBulkMutation();

  const toast = useToaster();
  const configuration = useSelector(selectConfiguration);

  const [selectedOrder, setSelectedOrder] = useState<OrderDocs | null>(null);
  const [detailsDrawerPage, setDetailsDrawerPage] = useState(0);
  const [editCustomerDrawerIsOpen, setEditCustomerDrawerIsOpen] =
    useState(false);
  const [selectOrderAssign, setSelectOrderAssign] = useState('');
  const [roundTripsModalOpen, setRoundTripsModalOpen] = useState(false);
  const [selectOrderAssignCode, setSelectOrderAssignCode] = useState('');
  const [selectedOrders, setSelectedOrders] = useState<
    { id: string; placeId: string; type }[]
  >([]);
  const [truckCoordinates, setTruckCoordinates] = useState<null | any>(null);
  const [disabledExport, setDisabledExport] = useState(false);

  const [selectedRoundtrip, setSelectedRoundtrip] = useState<string>('');
  const [showMap, setShowMap] = useState(false);

  const debouncedSearchTerm = useDebounce(search, 500);
  const debouncedFilters = useDebounce(filters, 1000);

  useResetPaginaton(setPage, [debouncedSearchTerm, debouncedFilters]);

  const commonQueryParams = {
    priority: debouncedFilters?.priority
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    status: debouncedFilters?.status
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    type: debouncedFilters?.deliveryType
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    productIds: debouncedFilters?.productIds
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    salesmanIds: debouncedFilters?.salesmanIds
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    createdByIds:
      debouncedFilters?.createdByIds
        ?.map(filter => {
          return filter.value;
        })
        ?.join(',') || '',
    minQuantity: debouncedFilters.quantity.minQuantity || '',
    maxQuantity: debouncedFilters.quantity.maxQuantity || '',
    minProducts: debouncedFilters.lines.minProducts || '',
    maxProducts: debouncedFilters.lines.maxProducts || '',
    text: debouncedSearchTerm,
    withDeleted: debouncedFilters?.withDeleted,
    begin: debouncedFilters.begin || '',
    end: debouncedFilters.end || '',
  };

  const fetchQueryParams = useQueryParams({
    ...commonQueryParams,
  });

  const { data, isFetching, isLoading, refetch } = useGetOrdersQuery(
    `${fetchQueryParams}&page=${page}&limit=${rowsPerPage}`,
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const orderStatusesList = getOrderStatusesToShow(
    configuration?.orderStatusLogistic || [],
  );

  const selectedOrderIds = useMemo(() => {
    const ids = [] as string[];
    if (selectOrderAssign) {
      return ids.concat(selectOrderAssign);
    } else if (selectedOrder) {
      return ids.concat(selectedOrder?._id);
    }
    return ids.concat(selected);
  }, [selectOrderAssign, selected, selectedOrder]);

  useEffect(() => {
    const ordersArray = [] as { id: string; placeId: string; type }[];
    rowsDatas
      .filter(order => selectedOrderIds?.includes(order.id))
      .map(order =>
        ordersArray.push({
          id: order.id,
          placeId: order._deck?._id,
          type: order.type,
        }),
      );
    setSelectedOrders(ordersArray);
  }, [selectedOrderIds, rowsDatas]);

  const handleOpenModal = (orderId?: string, orderCode?: string) => {
    if (orderId) {
      setSelectOrderAssign(orderId);
    }
    if (orderCode) {
      setSelectOrderAssignCode(orderCode);
    }
    setRoundTripsModalOpen(true);
  };

  // TODO: create a custom handler to handle errors
  const handleBulkChangeStatus = async (status: string) => {
    try {
      handleCloseMenu();
      await updateOrders({
        orderIds: selectedOrderIds,
        status,
        queryParams: `${fetchQueryParams}&page=${page}&limit=${rowsPerPage}`,
      }).unwrap();
      toast(3000, 'success', 'order_status_success');
    } catch (e: any) {
      toast(3000, 'error', 'order_status_error');
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setSelectOrderAssign('');
    setRoundTripsModalOpen(false);
    handleCloseMenu();
  };

  const openOrderMap = async order => {
    if (!isFetchingOrder) {
      await triggerGetOrder(order?._id);
    }
  };

  const closeRoundtripDrawer = () => {
    setShowMap(false);
    setSelectedRoundtrip('');
  };

  function handleDisplayMap(roundtripId: string) {
    setShowMap(true);
    setSelectedRoundtrip(roundtripId);
  }

  useEffect(() => {
    if (data?.docs) {
      setRowsDatas(data.docs);
      setTotalPages(data?.totalPages);
      setTotalDocs(data?.totalDocs);
    }
  }, [data]);

  useEffect(() => {
    setTruckCoordinates(
      fullOrder?.roundtrip?._vehicle?.lastPosition?.geometry?.coordinates,
    );
    setSelectedStop(fullOrder);
  }, [fullOrder]);

  useEffect(() => {
    if (
      !totalDocs ||
      (!selected?.length && totalDocs > 25000) ||
      (selected.length && selected.length > 25000)
    ) {
      setDisabledExport(true);
    } else {
      setDisabledExport(false);
    }
  }, [totalDocs, selected?.length]);
  useEffect(() => {
    if (filters) {
      dispatch(filterActions.setOrderFilters({ filters: filters }));
    }
  }, [filters, dispatch]);

  useFormatOrderData({
    orders: rowsDatas,
    setRows,
    setSelectedOrder,
    setOpenDrawer,
    handleOpenModal,
    onDisplayMap: openOrderMap,
    setDetailsDrawerPage,
    queryParams: `${fetchQueryParams}&page=${page}&limit=${rowsPerPage}`,
  });
  useEffect(() => {
    handleCloseStopMap(setSelectedStop);
  }, []);
  function setOpenDrawer() {
    setEditCustomerDrawerIsOpen(true);
  }

  // Function to close the drawer
  const closeDrawer = () => {
    setSelectedOrder(null);
    setEditCustomerDrawerIsOpen(false);
  };

  const exportQueryParams = useQueryParams({
    ...commonQueryParams,
    limit: 25000,
    idsFilters: selected?.length ? selected.join(',') : '',
  });

  const handleExport = () => {
    closeModal();
    triggerExport(
      `orders/${exportQueryParams}`,
      `orders_${dayjs().format('DD/MM/YYYY')}`,
    );
  };

  const onExportClick = () => {
    openModal({
      title: t(selected?.length ? 'export_selected' : 'export'),
      content: (
        <>
          <If
            condition={!disabledExport}
            otherwise={
              <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
                {t('export_data_exceeds_max', { max: '25,000' })}
              </Alert>
            }
          >
            <Alert variant="standard" severity="info" sx={{ width: '100%' }}>
              {t('export_count_rows', { count: selected?.length || totalDocs })}
            </Alert>
          </If>
        </>
      ),
      action: {
        actionText: t('export'),
        actionCallback: handleExport,
        buttonProps: { disabled: disabledExport },
      },
      cancel: true,
    });
  };

  const { disabledToolbar } = useDisabledToolbar({
    total: data?.totalDocs,
    searchTerm: debouncedSearchTerm,
    filterCount: debouncedFiltersCount,
  });

  function switchOrder(to, currentOrderId) {
    var currentOrderIndex: any = null;
    data?.docs?.map((order, index) => {
      if (order?._id === currentOrderId) {
        currentOrderIndex = index;
      }
    });
    if (to === 'next') {
      if (currentOrderIndex === data?.docs?.length - 1) return;
      setSelectedOrder(data?.docs[currentOrderIndex + 1]);
    }
    if (to === 'prev') {
      if (currentOrderIndex === 0) return;
      setSelectedOrder(data?.docs[currentOrderIndex - 1]);
    }
  }

  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disablePrev, setDisablePrev] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOrder === null) return;

    var currentOrderIndex: any = null;
    data?.docs?.map((order, index) => {
      if (order?._id === selectedOrder?._id) {
        currentOrderIndex = index;
      }
    });

    if (currentOrderIndex !== null) {
      if (currentOrderIndex === data?.docs?.length - 1) setDisableNext(true);
      else setDisableNext(false);

      if (currentOrderIndex === 0) setDisablePrev(true);
      else setDisablePrev(false);
    } else {
      setDisablePrev(true);
      setDisableNext(true);
    }
  }, [selectedOrder]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <OrderDrawerContext_orders.Provider
      value={{ switchOrder, disableNext, disablePrev }}
    >
      <Drawer
        anchor="right"
        open={editCustomerDrawerIsOpen}
        onClose={closeDrawer}
      >
        <Wrapper>
          <OrderDetail
            onCloseDrawer={closeDrawer}
            order={selectedOrder}
            isCustomer={false}
            onUpdate={refetch}
            changeStatus={handleBulkChangeStatus}
            defaultTab={detailsDrawerPage}
            handleDisplayMap={handleDisplayMap}
          />
        </Wrapper>
      </Drawer>

      <TableNavWrapper>
        <TableComponent
          checkBox={true}
          isLoading={isLoading} // required
          isFetching={isFetching}
          totalDocs={totalDocs}
          EnhancedTableToolbar={
            <EnhancedTableToolbar
              title={'nav.mainNav.orders'}
              disabled={disabledToolbar}
              headCells={headCells}
              heads={heads}
              numSelected={selected.length}
              search={search}
              setSearch={setSearch}
              setHeadCells={setHeadCells}
              filterComponents={
                <>
                  <FilterIcon
                    filterOn={filterOn}
                    filtersCount={filtersCount}
                    handleOpenFilter={handleOpenFilter}
                    disabled={disabledToolbar}
                    filterComponent={
                      <OrderFilter
                        filterOn={filterOn}
                        handleCloseFilter={handleCloseFilter}
                        filters={filters}
                        setFilters={setFilters}
                        excludedFilters={['vehicleType']}
                        anchorOriginHorizontal="right"
                        transformOriginHorizontal="left"
                      />
                    }
                  />
                </>
              }
              onSelectComponents={
                <>
                  <ComponentsStack>
                    {checkUserAcl(authUser, [
                      'adminOrders',
                      'manageRoundtripsCluster',
                    ]) && (
                      <ActionIconButton
                        title="orders.change_status"
                        onClick={e => {
                          handlesetAnchorFunction(e, setAnchorEl);
                        }}
                        icon={
                          <>
                            <RadioButtonUncheckedIcon />
                            <KeyboardArrowDownIcon />
                          </>
                        }
                      />
                    )}

                    {checkUserAcl(authUser, [
                      'adminOrders',
                      'manageRoundtripsCluster',
                    ]) && (
                      <ActionIconButton
                        title="orders.add_to_roundtrip"
                        onClick={() => handleOpenModal()}
                        icon={<InputIcon />}
                      />
                    )}
                    <ActionIconButton
                      title="export"
                      onClick={onExportClick}
                      icon={<UploadSimple />}
                    />
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {orderStatusesList.map(status => (
                        <MenuItem>
                          <ItemStatusComponent
                            action={() => {
                              handleBulkChangeStatus(status.status);
                            }}
                            label={t(
                              `orders.status.${status.label || status.status}`,
                            )}
                            classNameCircle={status.classNameCircle}
                            color={status.color}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  </ComponentsStack>
                </>
              }
              actionsComponents={
                <>
                  {!!totalDocs && checkUserAcl(authUser, ['exportOrders']) && (
                    <ExportButton
                      onClick={onExportClick}
                      placeholder={t('common.export_all')}
                    />
                  )}
                </>
              }
              totalRows={totalDocs}
            />
          }
          filterSection={
            <FilterChips filters={filters} setFilters={setFilters} />
          }
          filterCount={filtersCount}
          rows={rows} // required
          page={page} // required
          setPage={setPage} // required
          rowsPerPage={rowsPerPage} // required
          setRowsPerPage={setRowsPerPage} // required
          headCells={headCells} // required
          setHeadCells={setHeadCells} // required
          type="regular"
          selected={selected} // required
          setSelected={setSelected} // required
          rowsDatas={rowsDatas}
          totalPages={totalPages}
          mapRowId={selectedStop?._id}
          handleEditTrigger={order => {
            setSelectedOrder(order);
            setOpenDrawer();
          }}
        />
        {!data?.totalDocs && !isFetching && (
          <EmptyCenter>
            <If
              condition={
                (!debouncedSearchTerm || debouncedSearchTerm === '') &&
                !debouncedFiltersCount
              }
              otherwise={<NoResults />}
            >
              <NoDataComponents
                viewMode={true}
                Icon={NoOrders}
                text={t('orders.no_orders')}
                IconButton={<></>}
                textButton={''}
              />
            </If>
          </EmptyCenter>
        )}

        {/* <GenericDialog
          open={exportOpen}
          handleClose={() => { setExportOpen(false) }}
          title={t('export_selected')}
          children={
            <>

            </>
          }
          actionNo={true}
        /> */}
        <MapPopover
          open={selectedStop}
          handleClose={() => {
            handleCloseStopMap(setSelectedStop);
            setTruckCoordinates(null);
          }}
          latitude={selectedStop?._deck?.location?.geometry?.coordinates[1]}
          longitude={selectedStop?._deck?.location?.geometry?.coordinates[0]}
          driverLat={truckCoordinates && truckCoordinates[1]}
          driverLong={truckCoordinates && truckCoordinates[0]}
          editMode={false}
        />
      </TableNavWrapper>
      <RoundtripDrawer
        isOpen={!!showMap && !!selectedRoundtrip}
        roundtripId={selectedRoundtrip}
        handleClose={closeRoundtripDrawer}
      />
      <GenericDialog
        open={roundTripsModalOpen}
        handleClose={handleClose}
        title={
          selectedOrderIds?.length > 1
            ? t('assign_selected_orders_to', {
                count: selectedOrderIds?.length || 2,
              })
            : t('assign_order_code_to', { code: selectOrderAssignCode || '' })
        }
      >
        <AssignRoundtrip
          orderIds={selectedOrderIds}
          selectedOrders={selectedOrders}
          atAssign={() => {
            handleClose();
            toast(3000, 'success', 'order.roundtrip.assign');
          }}
        />
      </GenericDialog>
    </OrderDrawerContext_orders.Provider>
  );
}

const Wrapper = styled.div`
  height: 100%;
`;

const EmptyCenter = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .NoDataComponentText {
    padding-left: 16px !important;
  }
`;

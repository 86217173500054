import React, { useState, useContext } from 'react';
import { ChatContext } from 'app/components/Chat';
import styled from 'styled-components';
import { ChatWindowEmptyState } from './components/ChatWindowEmptyState';
import { ChatWindowTopBar } from './components/ChatWindowTopBar';
import { MessageInput } from './components/MessageInput';
import { ChatMessage } from './components/ChatMessage';
import { LastChatMessage } from './components/LastChatMessage';
import { CircularProgress } from '@material-ui/core';
import { useDebounce } from 'common/hooks/useDebounce';
import { StateSetter } from 'types';
import { useDeleteChatMessageMutation } from 'common/services/chatApi';
import { useModal } from 'app/components/Dialog/hooks';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';

interface Props {
  messages: any;
  setMessages: StateSetter<any>;
  isFetching: boolean;
  isLoadingStartChat: boolean;
  hasMore: boolean;
  visibleElements: string;
}

export const ChatWindow: React.FC<Props> = ({
  messages,
  setMessages,
  isFetching,
  isLoadingStartChat,
  hasMore,
  visibleElements,
}) => {
  /* ----------------------------------- useStates ----------------------------------- */
  const [editMode, setEditMode] = useState(false);
  const [editMessageId, setEditMessageId] = useState('');
  const [editMessageContent, setEditMessageContent] = useState('');

  /* ----------------------------------- Variables ----------------------------------- */
  const { selectedUser, currentUserId, totalChats, triggerRefetchChats } =
    useContext(ChatContext);
  const debouncedIsLoadingStartChat = useDebounce(isLoadingStartChat, 500);
  const { closeModal, openModal } = useModal();
  const { t } = useTranslation();
  const toast = useToaster();

  /* ----------------------------------- Api Calls ----------------------------------- */
  const [deleteChatMessage] = useDeleteChatMessageMutation();

  /* ----------------------------------- Functions ----------------------------------- */
  const handleMessageActions = (action, messageId, messageContent) => {
    if (action === 'edit') {
      setEditMessageId(messageId);
      setEditMessageContent(messageContent);
      setEditMode(true);
    } else if (action === 'delete') {
      openModal({
        action: {
          actionText: t('common.buttons.delete'),
          actionCallback: () => handleDeleteMessage(messageId),
        },
        title: t('chats.message.delete.confirm'),
        deleteModal: true,
        cancel: true,
        content: t('chats.message.delete.messsage'),
      });
    }
  };

  const handleStopEditing = newMessageData => {
    setMessages(prevState =>
      prevState.map(message => {
        if (message?._id === newMessageData?._id) {
          return {
            ...message,
            content: newMessageData?.content,
            updatedAt: newMessageData?.updatedAt,
            isEdited: newMessageData?.isEdited,
          };
        } else {
          return message;
        }
      }),
    );
    triggerRefetchChats();
    setEditMode(false);
    setEditMessageId('');
    setEditMessageContent('');
  };

  const handleDeleteMessage = async messageId => {
    closeModal();
    const res: any = await deleteChatMessage({ id: messageId });
    if (res?.error) {
      toast(5000, 'error', t('chats.message.deleted'));
    }
    toast(5000, 'success', t('chats.message.deleted'));
    triggerRefetchChats();
    setMessages(prevState =>
      prevState.filter(message => message?._id !== messageId),
    );
  };

  /* --------------------------------------------------------------------------------- */

  return (
    <Wrapper>
      {totalChats > 0 || selectedUser ? (
        <>
          {selectedUser ? (
            <>
              <ChatWindowTopBar
                selectedUser={selectedUser}
                isCurrentUser={currentUserId === selectedUser?._id}
                visibleElements={visibleElements}
              />
              <ChatsBody editMode={editMode}>
                {!debouncedIsLoadingStartChat && (
                  <>
                    {!messages || messages?.length === 0 ? (
                      <ChatWindowEmptyState type="noMessages" />
                    ) : (
                      <>
                        {messages?.map((message, index) => {
                          const isByCurrentUser =
                            message?.sender?.id === currentUserId ||
                            message?.sender === currentUserId ||
                            message?._id === currentUserId;
                          const similarToPrevMessage =
                            messages[index - 1]?.sender?.id ===
                              message?.sender?.id ||
                            messages[index - 1]?.sender?.id === message?._id ||
                            messages[index - 1]?._id === message?._id;
                          if (index !== messages?.length - 1) {
                            return (
                              <ChatMessage
                                messageId={message?._id}
                                type={isByCurrentUser ? 'sent' : 'received'}
                                similarToPrevMessage={similarToPrevMessage}
                                content={message?.content}
                                contentType={message?.contentType}
                                createdAt={message?.createdAt}
                                handleMessageActions={handleMessageActions}
                                isEdited={message?.isEdited || false}
                              />
                            );
                          } else {
                            return (
                              <LastChatMessage
                                messageId={message?._id}
                                type={isByCurrentUser ? 'sent' : 'received'}
                                similarToPrevMessage={similarToPrevMessage}
                                content={message?.content}
                                contentType={message?.contentType}
                                createdAt={message?.createdAt}
                                handleMessageActions={handleMessageActions}
                                isEdited={message?.isEdited || false}
                              />
                            );
                          }
                        })}
                      </>
                    )}
                  </>
                )}
                {debouncedIsLoadingStartChat && (
                  <div
                    style={{
                      flex: '1',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '12px',
                    }}
                  >
                    <CircularProgress color="primary" size={32} />
                  </div>
                )}
                {messages?.length > 18 &&
                  hasMore &&
                  !debouncedIsLoadingStartChat && (
                    <div
                      style={{
                        flex: '1',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '12px',
                        height: '32px',
                        padding: '4px 0',
                      }}
                    >
                      <CircularProgress color="primary" size={24} />
                    </div>
                  )}
              </ChatsBody>
              <MessageInput
                isNewChat={messages?.length === 0}
                editMode={editMode}
                editMessageId={editMessageId}
                editMessageContent={editMessageContent}
                handleStopEditing={handleStopEditing}
              />
            </>
          ) : (
            <ChatWindowEmptyState type="regular" />
          )}
        </>
      ) : (
        <ChatWindowEmptyState type="note" />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ChatsBody = styled.div<{ editMode: boolean }>`
  flex: 1;
  margin-top: 24px;
  padding-right: 13px;
  padding-left: 20px;
  height: calc(100% - 60px - 72px ${props => (props.editMode ? '- 46px' : '')});
  overflow-y: auto;

  @supports selector(::-webkit-scrollbar) {
    scrollbar-gutter: stable;
  }
  display: flex;
  flex-direction: column-reverse;
`;

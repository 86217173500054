import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FilterContainer,
  FilterSection,
} from 'app/components/FilterComponents/styles';
import CheckboxGrid from 'app/components/FilterComponents/components/CheckboxGrid';
import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { useCheckValues } from 'app/components/FilterComponents/hooks/useCheckValues';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { useGetUsersQuery } from 'common/services/userApi';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import If from 'app/components/If';
import { useGetWarehousesQuery } from 'common/services/organizationApi';
import { useDebounce } from 'common/hooks/useDebounce';
import { useQueryParams } from 'hooks/useQueryParams';
import { Chip, Tooltip } from '@mui/material';
import { OrderStatuses } from 'app/pages/Orders/data/statuses';
import { getStatusesToShow } from 'app/pages/AddRoundTrips/functions';
import { getOrderStatusesToShow } from 'app/helpers/helpers';
import ValueSlider from 'app/components/FilterComponents/components/ValueSlider';
import { FormControlLabel, Switch } from '@mui/material';
import styled from 'styled-components';
import { selectConfiguration } from 'common/store/organization/selectors';
import { clientStatusColor } from 'common/utils/clientStatusColor';
import { selectCustomerStatus } from 'common/store/organization/selectors';
import {
  handleCheckboxFilterChange,
  handleSelectFilterChange,
} from 'app/helpers/helpers';

interface OrdersFilterProps {
  setFilters: React.Dispatch<
    React.SetStateAction<{
      assigned: { value: string; label: string }[];
      priority: { value: string; label: string }[];
      orderStatus: { value: string; label: string }[];
      clientStatus: { value: string; label: string }[];
      deliveryType: { value: string; label: string }[];
      truckType: { value: string; label: string }[];
    }>
  >;
  filters: {
    assigned: { value: string; label: string }[];
    priority: { value: string; label: string }[];
    orderStatus: { value: string; label: string }[];
    clientStatus: { value: string; label: string }[];
    deliveryType: { value: string; label: string }[];
    truckType: { value: string; label: string }[];
  };
  filterOn: HTMLButtonElement | null;
  handleCloseFilter: () => void;
}

export const OrdersFilter = ({
  filters,
  setFilters,
  filterOn,
  handleCloseFilter,
}: OrdersFilterProps) => {
  const { t } = useTranslation();

const configuration = useSelector(selectConfiguration);

  /* ----------------------- Order Statuses ------------------------- */
  let OrderStatuses: Array<{
    status: string;
    label?: string;
    classNameCircle: string;
    color: string;
  }> = [];

  OrderStatuses = getOrderStatusesToShow(
    configuration?.orderStatusLogistic || [],
  );
  /* ----------------------- ---------------------------------------- */
  const allCustomerStatuses = useSelector(selectCustomerStatus);
  const customerStatuses = allCustomerStatuses.filter(status => status !== "stopped" && status !== "cancelled")

  const { checkedValues, setCheckedValues } = useCheckValues(filters);

  const openFilter = Boolean(filterOn);
  const popOverId = openFilter ? 'filter' : undefined;

  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    handleSelectFilterChange(filterKey, options, setFilters);
  };

  const handleClearAll = (e: React.MouseEvent) => {
    setFilters({
      assigned: [],
      priority: [],
      orderStatus: [],
      clientStatus: [],
      deliveryType: [],
      truckType: []
    });
  };

  const assignedStateFilterItems:any = [{ label: "assigned", value: 'yes' }, { label: "unassigned", value: 'no' }];
  const priorityFilterItems: string[] = ['low', 'medium', 'high'];
  const deliveryTypeFilterItems: string[] = ['delivery', 'collection', 'express', 'repair'];
  const truckTypeFilterItems: any = [{ label: "PL", value: 'pl' }, { label: "VL", value: 'vl' }];

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string,
    filterKey: string,
  ) => {
    handleCheckboxFilterChange(
      event,
      label,
      filterKey,
      checkedValues,
      setCheckedValues,
      setFilters,
    );
  };

  return (
    <Popover
      id={popOverId}
      open={openFilter}
      anchorEl={filterOn}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <FilterContainer>
        <FilterHeader onClearAll={handleClearAll} />
        <FilterSection>
          <CheckboxGrid
            openByDefault={true}
            filterName={t('assigned')}
            checkedValues={checkedValues}
            handleFilterChange={(e, label) => {
              handleCheckboxChange(e, label, 'assigned');
            }}
            filterItems={assignedStateFilterItems?.map(type => {
              return {
                value: type.value,
                label: t(type.label),
                count: undefined,
              };
            })}
          />
        </FilterSection>
        <FilterSection>
          <CheckboxGrid
            openByDefault={false}
            filterName={t('common.priority')}
            checkedValues={checkedValues}
            handleFilterChange={(e, label) => {
              handleCheckboxChange(e, label, 'priority');
            }}
            filterItems={priorityFilterItems?.map(type => {
              return {
                value: type,
                label: t(`common.${type}`),
                count: undefined,
              };
            })}
          />
        </FilterSection>
        <FilterSection>
          <MultiSelect
            openByDefault={false}
            filterName={t('orders.statusTitle')}
            selectedOptions={filters.orderStatus}
            handleFilterChange={options => {
              handleOptionsChange('orderStatus', options);
            }}
            filterItems={OrderStatuses?.map(status => {
              return {
                value: status?.status,
                label: t(`orders.status.${status.status}`),
                disabled: false,
                color: status?.color,
              };
            })}
          />
        </FilterSection>
        <FilterSection>
          <MultiSelect
            openByDefault={false}
            filterName={t('client.statusTitle')}
            selectedOptions={filters.clientStatus}
            handleFilterChange={options => {
              handleOptionsChange('clientStatus', options);
            }}
            filterItems={customerStatuses?.map(status => {
              return {
                value: status,
                label: t(`customer.status.${status}`),
                disabled: false,
                color:
                  clientStatusColor[status || 'other']?.color ||
                  clientStatusColor['other']?.color,
              };
            })}
          />
        </FilterSection>
        <FilterSection>
          <CheckboxGrid
            openByDefault={false}
            filterName={t('clients.orderDetails.delivery_type')}
            checkedValues={checkedValues}
            handleFilterChange={(e, label) => {
              handleCheckboxChange(e, label, 'deliveryType');
            }}
            filterItems={deliveryTypeFilterItems?.map(type => {
              return {
                value: type,
                label: t(`common.${type}`),
                count: undefined,
              };
            })}
          />
        </FilterSection>
        <FilterSection>
          <CheckboxGrid
            openByDefault={false}
            filterName={t('info.truck_type')}
            checkedValues={checkedValues}
            handleFilterChange={(e, label) => {
              handleCheckboxChange(e, label, 'truckType');
            }}
            filterItems={truckTypeFilterItems?.map(type => {
              return {
                value: type.value,
                label: type.label,
                count: undefined,
              };
            })}
          />
        </FilterSection>
      </FilterContainer>
    </Popover>
  );
};

interface CustomControlLabelProps {
  isActive?: boolean;
}
const CustomControlLabel = styled(FormControlLabel)<CustomControlLabelProps>`
  color: ${props => (props.isActive ? props.theme.primaryActiveColor : '')};
`;

import { IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeft, Flag } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useDeleteAccessoryMutation } from 'common/services/accessoryApi';
import { Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CustomerInfoCard } from 'app/pages/Customers/components/CustomerInfoCard';
import { createContext, useEffect } from 'react';
//icon
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { themes } from 'styles/theme/themes';
import { useModal } from 'app/components/Dialog/hooks';
import { FieldErrorsImpl } from 'react-hook-form';
import { Accessory, AssigneeType } from 'app/pages/Accessories/Types';
import _ from 'lodash';
import { StateSetter } from 'types';
import { CustomerEventsTab } from 'app/pages/Customers/components/Tabs/CustomerEventsTab';
import {
  useGetCustomerFinancialsQuery,
  useLazyGetCustomerQuery,
} from 'common/services/customerApi';
import { CreditScoreBadge } from 'app/components/CreditScoreBadge';
import If from 'app/components/If';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

interface ContextProps {
  _assigneeType: AssigneeType | string;
  accessory: Accessory | null;
  assigned: boolean;
  control: any;
  id: string;
  outofservice: boolean;
  setAssigned: StateSetter<boolean>;
  selectedTab: string;
  setSelectedTab: StateSetter<string>;
  errors: Partial<FieldErrorsImpl<any>>;
}

export const ViewCustomercontext = createContext<ContextProps>({
  _assigneeType: '',
  accessory: null,
  assigned: false,
  control: null,
  id: '',
  outofservice: false,
  setAssigned: () => {},
  selectedTab: 'info',
  setSelectedTab: () => {},
  errors: {},
});

export const ViewCustomer = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { id } = useParams();

  const toast = useToaster();

  const [deleteAccessoryMutation] = useDeleteAccessoryMutation();

  const { openModal, closeModal } = useModal();

  const [getCustomer, { data: customerDetail, isLoading: isLoading }] =
    useLazyGetCustomerQuery();

  const { data: customerFinancials } = useGetCustomerFinancialsQuery({
    id: id,
  });

  {
    /**
    const handleDelete = (id: string) => {
    const deleteAccessoryName = customerDetail?.publicName;
    const type = 'client';

    openModal({
      title: t(`${type}.delete`, {
        sectionName: deleteAccessoryName,
      }),
      content: t(`${type}.delete_confirmation`, {
        sectionName: deleteAccessoryName,
      }),
      action: {
        actionText: t('delete'),
        actionCallback: () => {},
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }; */
  }

  {
    /**
    const deleteAccesory = async id => {
    await deleteAccessoryMutation({
      id,
    }).unwrap();
    closeModal();
    handleBack();
    toast(
      5000,
      'success',
      t('support_units.section_deleted', { sectionName: accessory?.name }),
    );
  };
  } */
  }

  const orderFrequency = () => {
    if (customerFinancials?.countActiveOrders === 0) {
      return themes?.bianta?.gainsboro2;
    } else if (
      customerFinancials?.countActiveOrders > 0 &&
      customerFinancials?.countActiveOrders <= 3
    ) {
      return themes?.default?.orange700;
    } else if (customerFinancials?.countActiveOrders > 3) {
      return themes?.default?.greenStatus;
    }
    return themes?.default?.black;
  };

  useEffect(() => {
    if (id) {
      getCustomer(id);
    }
  }, [id]);

  const handleBack = () => {
    navigate('/customers');
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <StyledForm>
      <TopSection>
        <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
          <IconButton
            onClick={() => handleBack()}
            sx={{
              marginRight: '12px',
            }}
          >
            <Icon icon={<ArrowLeft />} size={'24px'} />
          </IconButton>
          <Typography fontWeight={600}>{customerDetail?.publicName}</Typography>
          <If condition={customerDetail?.creditScore}>
            <Tooltip title={t('customer.creditScore')}>
              <div>
                <CreditScoreBadge
                  score={customerDetail?.creditScore?.commonValue}
                  size="small"
                />
              </div>
            </Tooltip>
          </If>
          <Tooltip title="Order Frequency">
            <Flag weight="fill" color={orderFrequency()} />
          </Tooltip>
          <ActionPopover
            id={customerDetail?._id}
            handleAction={async (e, action, id) => {}}
            styling={{ marginLeft: 'auto' }}
            actions={[
              {
                action: 'delete',
                onClick: () => {
                  //handleDelete(customerDetail?._id);
                },
                element: (
                  <PopoverEl
                    redStatus={themes.default.redStatus}
                    style={{ width: '100%' }}
                  >
                    <div className="delete popover-item">{t('delete')}</div>
                  </PopoverEl>
                ),
              },
            ]}
          />
        </Stack>

        <CustomerInfoCard
          customerDetail={customerDetail}
          customerFinancials={customerFinancials}
        />
      </TopSection>

      <CustomerEventsTab customerDetail={customerDetail} />
    </StyledForm>
  );
};

const TopSection = styled.div`
  background-color: white;
  padding: 8px 20px 5px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledForm = styled.form`
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  background-color: ${themes?.default?.pageGreyBg};
`;
const ToastContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateY(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.show {
    opacity: 1;
  }

  &.success {
    background-color: green;
  }

  &.error {
    background-color: red;
  }

  /* Add more styles as needed */
`;

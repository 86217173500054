import React, { useState, useContext, useRef, useEffect } from 'react';
import { ChatContext } from 'app/components/Chat';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { TextField, InputAdornment, ButtonBase, Slide } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { Microphone, PencilSimple, X, Check } from '@phosphor-icons/react';
import PaperPlane from 'assets/img/chat/PaperPlane.svg';
import useWebSocket from 'react-use-websocket';
import { useSelector } from 'react-redux';
import { selectAuthToken } from 'common/store/auth/selectors';
import { AudioRecorder } from 'app/components/Chat/components/AudioRecorder';
import { useUploadMutation } from 'common/services/files';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useDebounce } from 'common/hooks/useDebounce';
import { useEditChatMessageMutation } from 'common/services/chatApi';
import { CircularProgress } from '@material-ui/core';
import { useToaster } from 'hooks/useToaster';
import { ENV } from 'common/constants';

interface Props {
  isNewChat?: boolean;
  editMode: boolean;
  editMessageId: string;
  editMessageContent: string;
  handleStopEditing: Function;
}

export const MessageInput: React.FC<Props> = ({
  isNewChat,
  editMode,
  editMessageId,
  editMessageContent,
  handleStopEditing,
}) => {
  const [message, setMessage] = useState('');
  const [messageInputFocused, setMessageInputFocused] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recorderAudio, setRecorderAudio] = useState<any>(null);
  const inputRef: any = useRef(null);
  const [editedNewMessage, setEditedNewMessage] = useState('');
  const [savingEdits, setSavingEdits] = useState(false);

  const { t } = useTranslation();
  const authToken = useSelector(selectAuthToken);
  const theme = useSelector(selectTheme);
  const { selectedChatId } = useContext(ChatContext);
  const toast = useToaster();

  const [uploadFile] = useUploadMutation();
  const [editMessage] = useEditChatMessageMutation();

  const { sendMessage, lastMessage: wsLastMessage } = useWebSocket(
    ENV.CHAT_WS_URL,
    {
      queryParams: {
        token: authToken || '',
        channel: selectedChatId,
      },
    },
  );

  const debouncedEditMode = useDebounce(editMode, 200);

  const handleSendMessage = () => {
    if (message === '' && !messageInputFocused) return;
    sendMessage(JSON.stringify({ data: message, type: 'text' }));
    setMessage('');
  };

  const handleSaveMessageEdits = async () => {
    try {
      setSavingEdits(true);
      const newMessageData: any = await editMessage({
        id: editMessageId,
        body: { content: editedNewMessage },
      });
      setSavingEdits(false);
      if (newMessageData?.error) {
        toast(5000, 'error', t('chats.message.edit.error'));
      }
      handleStopEditing(newMessageData?.data?.data);
    } catch (error) {
      console.error('error while editing message: ', error);
      setSavingEdits(false);
      return;
    }
  };

  useEffect(() => {
    if (isNewChat && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isNewChat, inputRef, selectedChatId]);

  useEffect(() => {
    if (!editMessageContent) return;
    setEditedNewMessage(editMessageContent);
    if (inputRef.current) inputRef.current.focus();
  }, [editMessageContent]);

  return (
    <Wrapper editMode={editMode}>
      {(editMode || debouncedEditMode) && (
        <Slide in={editMode} direction="up">
          <EditMessageContainer>
            <Icon icon={<PencilSimple />} size={20} color={theme.primary} />
            <EditMessageContentWrapper theme={theme}>
              <p className="title">{t('editing')}</p>
              <p className="text">{editMessageContent}</p>
            </EditMessageContentWrapper>
            <ButtonBase
              sx={{
                borderRadius: '50px',
                padding: '4px',
              }}
              onClick={() => handleStopEditing()}
            >
              <Icon icon={<X />} size={20} />
            </ButtonBase>
          </EditMessageContainer>
        </Slide>
      )}

      <MessageInputContainer editMode={editMode}>
        <TextField
          fullWidth
          size="small"
          placeholder={t('chat.type_new_message')}
          margin="none"
          value={editMode ? editedNewMessage : message}
          onChange={e =>
            editMode
              ? setEditedNewMessage(e.target.value)
              : setMessage(e.target.value)
          }
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (!editMode) {
                handleSendMessage();
              } else {
                handleSaveMessageEdits();
              }
            }
          }}
          sx={{
            '&.MuiFormControl-root': {
              margin: '0 !important',
            },
            fieldset: {
              border: `1px solid ${themes?.default?.gainsboro2}`,
            },
            'input::placeholder': {
              fontFamily: 'Roboto',
              fontSize: '0.75rem',
              fontWeight: '400',
              lineHeight: '20px',
              color: themes?.default?.textColorDashboard,
            },
          }}
          inputRef={inputRef}
          InputProps={{
            onFocus: () => setMessageInputFocused(true),
            onBlur: () => setMessageInputFocused(false),
            sx: { height: '48px', paddingRight: '12px' },
            endAdornment: (
              <InputAdornment position="end">
                {!editMode ? (
                  <InputFieldActions>
                    {!isRecording && (
                      <ButtonBase
                        sx={{
                          borderRadius: '50px',
                          padding: '4px',
                        }}
                        // onClick={() => setIsRecording(true)}
                        onClick={() => {}}
                      >
                        <Icon icon={<Microphone />} size={24} />
                      </ButtonBase>
                    )}
                    <ButtonBase
                      onClick={handleSendMessage}
                      sx={{
                        width: '50px',
                        height: '32px',
                        padding: '8px 9px 8px 7px',
                        borderRadius: '4px',
                        background: themes?.default?.greyDisabled,
                      }}
                    >
                      <Icon icon={<img src={PaperPlane} />} />
                    </ButtonBase>
                  </InputFieldActions>
                ) : (
                  <InputFieldActions>
                    <ButtonBase
                      sx={{
                        borderRadius: '50px',
                        padding: '4px',
                      }}
                      onClick={() => handleSaveMessageEdits()}
                      disabled={savingEdits}
                    >
                      {!savingEdits ? (
                        <Icon
                          icon={<Check weight="bold" />}
                          size={24}
                          color={theme.primary}
                        />
                      ) : (
                        <CircularProgress color="primary" size={24} />
                      )}
                    </ButtonBase>
                  </InputFieldActions>
                )}
              </InputAdornment>
            ),
          }}
        />
        {(isRecording || recorderAudio) && (
          <AudioRecorder
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            recorderAudio={recorderAudio}
            setRecorderAudio={setRecorderAudio}
          />
        )}
      </MessageInputContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ editMode }>`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MessageInputContainer = styled.div<{ editMode: boolean }>`
  background: ${themes?.default?.accordionWhiteBg};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  padding: 12px 20px;
  border-top: 1px solid ${themes?.default?.gainsboro2};
  box-shadow: ${props =>
    props.editMode ? '0px 0px 12px 0px #0000000d' : null};
  position: relative;
  z-index: 1;
`;

const InputFieldActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const EditMessageContainer = styled.div`
  flex: 1;
  min-height: 46px;
  max-height: 46px;
  padding: 4px 28px;
  box-shadow: 0px 0px 12px 0px #0000000d;
  border-top: 1px solid ${themes?.default?.gainsboro2};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const EditMessageContentWrapper = styled.div<{ theme }>`
  flex: 1;
  width: calc(100% - 20px - 8px - 8px - 28px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  p {
    height: 18px;
    margin: 0;
    font-family: Roboto;
    font-size: 0.6875rem;
    line-height: 18px;
    text-align: left;
  }

  .title {
    font-weight: 700;
    color: ${props => props.theme.primary};
  }

  .text {
    color: ${themes?.default?.nobel};
  }
`;

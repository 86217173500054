import { MapPinLine, RoadHorizon, Truck, User } from '@phosphor-icons/react';
import { MetricItem } from '../MetricItem';
import { Stack } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import If from 'app/components/If';
import { useEffect, useMemo, useState } from 'react';
import { HeadCell } from 'app/pages/AddRoundTrips/data/excelHeadCellsConst';
import { Ripper } from 'app/components/RoundTrips/RoundTripData/statuses';

// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./workers/metrics-worker';
import { StopMetrics } from './components/StopMetrics';

interface MatricsProps {
  userContext: string;
  roundtrips: any;
  headCells: HeadCell[];
}

const worker = new Worker();

export const Metrics = ({
  userContext,
  roundtrips,
  headCells,
}: MatricsProps) => {
  const { t } = useTranslation();

  const [metricValues, setMetricValues] = useState<{
    totalRoundtrips: number;
    roleCounts: { driver: number; sales: number; ripper: number };
    assignedVehicles: number;
    stopCount: {
      totalOrders: number;
      totalOrdersCompleted: number;
      totalFuel: number;
      totalGarage: number;
    };
  }>({
    totalRoundtrips: 0,
    roleCounts: { driver: 0, ripper: 0, sales: 0 },
    assignedVehicles: 0,
    stopCount: {
      totalOrders: 0,
      totalOrdersCompleted: 0,
      totalFuel: 0,
      totalGarage: 0,
    },
  });

  const showRipper = useMemo(() => {
    const ripperVisible = headCells?.find(
      headCell => headCell.id === Ripper && !headCell.disableRow,
    );
    return ripperVisible && userContext === AgentCategories.Logistic;
  }, [headCells, userContext]);

  const farDeliveredOrdersCount = useMemo(() => {
    var count = 0;
    roundtrips?.map(trip => {
      trip?.stops?.map(order => {
        if (order?._order?.deliveryInfo?.distance && order?._order?.deliveryInfo?.distance > 500) {
          count++;
        };
      });
    });
    return count;
  }, [roundtrips]);

  useEffect(() => {
    if (roundtrips?.length) {
      worker.postMessage({ roundtrips: roundtrips });
    }
  }, [roundtrips]);

  const isSales = useMemo(() => {
    return userContext === AgentCategories.Sales;
  }, [userContext]);

  worker.addEventListener('message', (event: MessageEvent) => {
    if (event?.data?.metricsValues) {
      setMetricValues(event.data.metricsValues);
    }
  });

  return (
    <Stack direction="row" gap={'7px'}>
      <MetricItem
        icon={<RoadHorizon />}
        value={`${metricValues.totalRoundtrips}`}
        title={`${metricValues.totalRoundtrips} ${t('nav.mainNav.roundtrips')}`}
      />
      <MetricItem
        icon={<Truck />}
        value={`${metricValues.assignedVehicles}/${metricValues.totalRoundtrips}`}
        textSx={{
          color:
            metricValues.assignedVehicles !== metricValues.totalRoundtrips
              ? themes.default.redA700
              : '',
        }}
        title={`${`${metricValues.assignedVehicles}/${metricValues.totalRoundtrips}`} ${t(
          'assigned_vehicles',
        )}`}
      />
      <MetricItem
        icon={<User />}
        value={`${metricValues.roleCounts[isSales ? 'sales' : 'driver']}/${
          metricValues.totalRoundtrips
        }`}
        textSx={{
          color:
            metricValues.roleCounts[isSales ? 'sales' : 'driver'] !==
            metricValues.totalRoundtrips
              ? themes.default.redA700
              : '',
        }}
        title={`${`${metricValues.roleCounts[isSales ? 'sales' : 'driver']}/${
          metricValues.totalRoundtrips
        }`} ${t(isSales ? 'assigned_sales' : 'assigned_drivers')}`}
      />
      <If condition={!!showRipper}>
        <MetricItem
          icon={<User />}
          value={`${metricValues.roleCounts.ripper}/${metricValues.totalRoundtrips}`}
          title={`${`${metricValues.roleCounts.ripper}/${metricValues.totalRoundtrips}`} ${t(
            'assigned_rippers',
          )}`}
          textSx={{
            color:
              metricValues.roleCounts.ripper !== metricValues.totalRoundtrips
                ? themes.default.redA700
                : '',
          }}
        />
      </If>
      <StopMetrics stopCount={metricValues.stopCount} farDeliveredOrdersCount={farDeliveredOrdersCount} />
    </Stack>
  );
};

import {
  Divider,
  MenuItem,
  MenuProps,
  Menu as MuiMenu,
  Stack,
} from '@mui/material';
import { MenuOption } from './types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import If from '../If';

interface Props extends MenuProps {
  options: MenuOption[];
}

export const Menu = ({ options, onClose, ...props }: Props) => {
  const { t } = useTranslation();
  return (
    <MuiMenu
      {...props}
      id="lock-menu"
      MenuListProps={{
        'aria-labelledby': 'lock-button',
        role: 'listbox',
      }}
      onClose={onClose}
    >
      {options.map((option, index) => (
        <Stack width={'100%'} key={index}>
          <If condition={!!option.isDelete && options?.length > 1}>
            <Divider
              orientation="horizontal"
              sx={{ height: '1px', margin: '6px 16px' }}
            />
          </If>
          <Option
            onClick={e => {
              option.action?.();
              onClose?.(e, 'escapeKeyDown');
            }}
            isDelete={option.isDelete}
          >
            {t(option.label)}
          </Option>
        </Stack>
      ))}
    </MuiMenu>
  );
};

interface OptionProps {
  isDelete?: boolean;
}

const Option = styled(MenuItem)<OptionProps>`
  font-size: 0.8125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.01063rem;
  min-width: 175px;
  padding: 0;

  color: ${props =>
    props.isDelete
      ? props.theme.redStatus
      : props.theme.textColorPrimary} !important;
`;

import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableNavContext } from '../..';
import { CustomIOSSWitch } from 'app/components/CustomSwitch';
import { Tooltip } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { themes } from "styles/theme/themes";
import styled from 'styled-components';

export const StatusButton = () => {
  const { status, handleClick, disabled, loadingClusterStatusToggle: isLoading } = useContext(TableNavContext);
  const { t } = useTranslation();

  const tooltip = useMemo(() => {
    return status === "active" ? t("common.buttons.deactivate") : t("activate")
  }, [status]);

  return (
    <Tooltip title={tooltip || ''}>
      <Wrapper>
        {!isLoading ? (
          <CustomIOSSWitch
            onChange={() => handleClick()}
            checked={status === "active"}
            disabled={!!disabled}
          />
        ) : (
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: themes?.default?.primary
            }}
            size={14}
            thickness={2}
            value={30}
          />
        )}
      </Wrapper>
    </Tooltip>
  )
};

const Wrapper = styled.div`
  width: 50px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
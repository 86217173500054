import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Box, IconButton, Popover, SxProps, Tab } from '@mui/material';
import styled from 'styled-components';
import { VerticalDivider } from '../EnhancedTableToolbar/components/VerticalDivider';
import If from '../If';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { themes } from 'styles/theme/themes';
import { Icon } from '../Icon';
import { DotsThree } from '@phosphor-icons/react';

interface TabType {
  value?: string;
  label?: JSX.Element;
  isDivider?: boolean;
}

interface TabsMenuProps {
  selectedTab: string;
  handleOnSwitchTab: (e: any, tab: string) => void;
  tabs: TabType[];
  withDivider?: boolean;
  maxTabs?: number;
}

export const TabsMenu = ({
  selectedTab,
  handleOnSwitchTab,
  tabs,
  withDivider = true,
  maxTabs,
}: TabsMenuProps) => {
  const visibleTabsNumber = Math.min(maxTabs || tabs?.length, tabs?.length);

  const getDefaultVisibleTabs = useCallback(() => {
    const defaultTab = tabs.find(t => t.value === selectedTab);
    const visible = [...tabs.slice(0, visibleTabsNumber)];

    if (visible?.find(t => t.value === selectedTab)) {
      return visible;
    } else if (defaultTab) {
      return [...tabs.slice(0, visibleTabsNumber - 1), defaultTab];
    }

    return visible;
  }, [tabs, selectedTab, visibleTabsNumber]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [visibleTabs, setVisibleTabs] = useState<TabType[]>(
    getDefaultVisibleTabs(),
  );
  const [hiddenTabs, setHiddenTabs] = useState<TabType[]>([
    ...tabs
      .filter(tab => tab.value !== selectedTab)
      .slice(visibleTabsNumber - 1),
  ]);

  const tabItems = useMemo(() => {
    if (!withDivider) {
      return visibleTabs;
    }

    const items: TabType[] = [];
    visibleTabs.forEach((t, index) => {
      items.push(t);
      if (index !== visibleTabs.length - 1) {
        items.push({
          isDivider: true,
        });
      }
    });
    return items;
  }, [visibleTabs, withDivider]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setVisibleTabs(getDefaultVisibleTabs());
  }, [tabs, getDefaultVisibleTabs]);

  const clickHiddenTab = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tab: TabType,
  ) => {
    if (!tab?.value) {
      return;
    }
    const updatedVisibleTabs = [...visibleTabs];
    if (visibleTabs?.length === visibleTabsNumber) {
      const movedTab = updatedVisibleTabs.pop();
      if (!!movedTab) {
        setHiddenTabs(prevHiddenTabs => [
          ...prevHiddenTabs.filter(t => t.value !== tab.value),
          movedTab,
        ]);
        setVisibleTabs([...updatedVisibleTabs, tab]);
      }
    } else {
      const updatedHiddenTabs = hiddenTabs.filter(t => t.value !== tab.value);
      setHiddenTabs(updatedHiddenTabs);

      const updatedVisibleTabs = [...visibleTabs, tab];
      setVisibleTabs(updatedVisibleTabs);
    }

    setAnchorEl(null);
    handleOnSwitchTab(e, tab.value);
  };

  return (
    <>
      <If condition={!!tabs?.length}>
        <TabContext value={selectedTab}>
          <TabList
            onChange={handleOnSwitchTab}
            sx={{
              height: '100%',
              minHeight: '30px',
              '& .MuiTabs-flexContainer': {
                alignItems: 'center',
                height: '100%',
              },
            }}
          >
            {tabItems.map((tab, index) => {
              let tabSx: SxProps = {
                px: 1,
                minHeight: '30px',
              };
              if (tab.value === selectedTab) {
                tabSx = {
                  ...tabSx,
                  '& svg': {
                    fill: themes.default.primaryActiveColor,
                  },
                  background: !withDivider ? themes.default.primaryActive : '',
                };
              }
              if (withDivider) {
                if (index === 0) {
                  tabSx = { ...tabSx, marginRight: '10px' };
                } else if (index === tabItems.length - 1) {
                  tabSx = { ...tabSx, marginLeft: '10px' };
                } else {
                  tabSx = { ...tabSx, mx: '10px' };
                }
              }

              if (tab.isDivider) {
                return (
                  <VerticalDivider
                    key={`divider-${index}`}
                    sx={{ height: 20 }}
                  />
                );
              } else {
                return (
                  <StyledTab
                    label={<TabLabel>{tab.label}</TabLabel>}
                    value={tab.value}
                    sx={{ ...tabSx }}
                    key={index}
                  />
                );
              }
            })}
          </TabList>

          {hiddenTabs.length > 0 && (
            <Box paddingLeft={'10px'}>
              <IconButton onClick={handlePopoverOpen}>
                <Icon icon={<DotsThree />} />
              </IconButton>
              <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {hiddenTabs.map((tab, index) => (
                  <div key={index}>
                    <StyledTab
                      label={<TabLabel>{tab.label}</TabLabel>}
                      value={tab.value}
                      onClick={e => clickHiddenTab(e, tab)}
                    />
                  </div>
                ))}
              </Popover>
            </Box>
          )}
        </TabContext>
      </If>
    </>
  );
};

const TabLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: none;
  font-size: 0.85rem;
`;

const StyledTab = styled(Tab)`
  &:hover {
    background-color: ${props => props.theme.lightBlueActive};
  }
  transition: background-color 0.2s ease;
`;

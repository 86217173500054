import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { Application } from 'common/store/app/types';

export const defineAbilitiesFor = (
  authUser,
  currentApplication: Application,
) => {
  const { can, build } = new AbilityBuilder(createMongoAbility);

  const scopes = authUser?.currentAgent?.privileges?.scopes || [];
  const roleProperties =
    authUser?.currentAgent?.privileges?.roleproperties || [];

  const isAdmin =
    authUser?.isSuperAdmin || authUser?.currentAgent?._role?.key === 'admin';

  const isCustomer = authUser?.currentAgent?._role?.key === 'customer';

  if (isAdmin) {
    can('manage', 'all');
  } else {
    scopes.forEach(scope => {
      const [application, module, action] = scope.split('.');
      if (application === currentApplication?.id) {
        can(action, module);
      }
    });

    roleProperties.forEach(property => {
      const [application, active] = property.split('.');
      if (active === 'active') {
        can('access', application);
      }
    });

    if (isCustomer) {
      can('access', 'ordoria');
    }
  }
  return build();
};

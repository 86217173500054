import React, { createContext, useCallback, useState } from 'react';
import { Column, ContextProps, GridSetupProps, Order } from '../types';
import { useExportCSV } from 'hooks/useExportCSV';
import dayjs from 'dayjs';
import { useDebounce } from 'common/hooks/useDebounce';

export const GridContext = createContext<ContextProps | undefined>(undefined);

export const GridProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { triggerExport } = useExportCSV();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [order, setOrder] = useState<Order>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState<any[]>([]);
  const [withCheckbox, setWithCheckbox] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const debouncedFilters = useDebounce(filters, 300);
  const debouncedSearchTerm = useDebounce(search, 300);

  const setupGrid = useCallback(
    ({ columns, totalRows, rows, withCheckbox = true }: GridSetupProps) => {
      setColumns(columns);
      setTotalRows(totalRows);
      setRows(rows || []);
      setWithCheckbox(withCheckbox);
    },
    [],
  );

  const exportRows = useCallback(
    (url: string, fileName: string) => {
      triggerExport(
        `${url}&limit=25000&idsFilters=${
          selected?.length ? selected.join(',') : ''
        }`,
        `${fileName}_${dayjs().format('DD/MM/YYYY')}`,
      );
    },
    [triggerExport, selected],
  );

  return (
    <>
      <GridContext.Provider
        value={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          order,
          setOrder,
          orderBy,
          setOrderBy,
          selected,
          setSelected,
          columns,
          setColumns,
          totalRows,
          setupGrid,
          search,
          setSearch,
          exportRows,
          rows,
          withCheckbox,
          filters,
          debouncedFilters,
          setFilters,
          debouncedSearchTerm,
        }}
      >
        {children}
      </GridContext.Provider>
    </>
  );
};

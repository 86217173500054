import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { Collapse, IconButton, Badge } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import {
  ChartPieSlice,
  RoadHorizon,
  Package,
  IdentificationBadge,
  Car,
  Shapes,
  Devices,
  Cardholder,
  PresentationChart,
} from '@phosphor-icons/react';
import PalletTruck from 'assets/img/navigtion_left/PalletTruck.svg';

import If from '../../If';
import { MainNavItem } from '../helpers';
import {
  ACCESSORIES,
  ACCESSORYINFO,
  ALL_ROUNDTRIPS,
  ANALYTICS,
  CHECK_DOCUMENTS,
  CUSTOMER,
  DASHBOARD,
  DASHBOARD_ALL,
  DRIVERS,
  INBOX,
  ORDERS,
  PAYMENTCARDS,
  REPORTS,
  REPORTS_ALL,
  ROUNDTRIP,
  SALES,
  SCHEDULED_ROUNDTRIPS,
  SUPPORTUNIT,
  TOOLS,
  UPLOAD_FILES,
  USERS,
  VEHICLES,
  VEHICLES_LOGISTIC,
  VEHICLES_SALES,
} from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { checkUserAcl } from 'common/helpers/acl';

import IfUserAcl from '../../IfUserAcl';
import { useIsLogistic } from 'hooks/acl';
import { AccessoryType } from 'app/pages/Accessories/Types';
import { isActiveNestedRoute, isActiveRoute } from '../index';
import { selectConfiguration } from 'common/store/organization/selectors';
import { useApplication } from 'hooks/useApplication';
import { useSelector } from 'react-redux';

export default function BiantaMenu({
  theme,
  mode,
  authUser,
  organizationActivities,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const displayMainMenu = checkUserAcl(authUser, [
    'adminOrders',
    'manageRoundtripsCluster',
    'viewRoundtripsCluster',
  ]);
  const configuration = useSelector(selectConfiguration);
  const roles = configuration.roles;
  const currentApplication = useApplication(authUser);

  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>(
    displayMainMenu ? [0] : [1],
  );

  const isMinify = mode === 'minify';
  const isDashboardAll = location.pathname === DASHBOARD_ALL;
  const isReportAll = location.pathname === REPORTS_ALL;

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex(prevIndices => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter(i => i !== index);
      } else {
        return [index];
      }
    });
  };

  const isLogistic = useIsLogistic();

  const displayEmployees = useMemo(() => {
    return (
      roles.filter(r =>
        r.privileges?.roleproperties?.includes(
          `${currentApplication?.id}.showinemployee`,
        ),
      )?.length || 0
    );
  }, [currentApplication?.id, roles]);

  if (isDashboardAll || isReportAll) {
    return (
      <ScrollableSection>
        <If condition={displayMainMenu}>
          <MenuSectionWrapper>
            <If condition={!isMinify}>
              <TitleWrapper onClick={() => handleRowToggle(0)}>
                <Title textColorSecondary={theme.textColorSecondary}>
                  {t('nav.mainNav.title1')}
                </Title>
                <IconButton
                  sx={{
                    ml: 'auto',
                    mr: '12px',
                    ':hover': {
                      transform: 'scale(1.1)',
                      transition: 'all 100ms ease-in',
                    },
                  }}
                >
                  {openSectionIndex.includes(0) ? (
                    <KeyboardArrowDownIcon color="action" />
                  ) : (
                    <KeyboardArrowRightIcon color="action" />
                  )}
                </IconButton>
              </TitleWrapper>
            </If>
            <Collapse
              in={openSectionIndex.includes(0) || isMinify}
              timeout="auto"
              unmountOnExit
            >
              <IfUserAcl acl={['manageRoundtripsCluster', 'adminOrders']}>
                <MainNavItem
                  to={DASHBOARD_ALL}
                  text={t('nav.mainNav.dashboard')}
                  mode={mode}
                  icon={
                    <IconWrapper
                      isActive={isActiveRoute(location, DASHBOARD_ALL)}
                    >
                      <ChartPieSlice />
                    </IconWrapper>
                  }
                />
                <MainNavItem
                  to={REPORTS_ALL}
                  text={t('nav.mainNav.reports')}
                  mode={mode}
                  icon={
                    <IconWrapper
                      isActive={isActiveRoute(location, REPORTS_ALL)}
                    >
                      <PresentationChart />
                    </IconWrapper>
                  }
                />
              </IfUserAcl>
            </Collapse>
          </MenuSectionWrapper>
        </If>
      </ScrollableSection>
    );
  }

  return (
    <ScrollableSection>
      <If condition={displayMainMenu}>
        <MenuSectionWrapper>
          <If condition={!isMinify}>
            <TitleWrapper onClick={() => handleRowToggle(0)}>
              <Title textColorSecondary={theme.textColorSecondary}>
                {t('nav.mainNav.title1')}
              </Title>
              <IconButton
                sx={{
                  ml: 'auto',
                  mr: '12px',
                  ':hover': {
                    transform: 'scale(1.1)',
                    transition: 'all 100ms ease-in',
                  },
                }}
              >
                {openSectionIndex.includes(0) ? (
                  <KeyboardArrowDownIcon color="action" />
                ) : (
                  <KeyboardArrowRightIcon color="action" />
                )}
              </IconButton>
            </TitleWrapper>
          </If>
          <Collapse
            in={openSectionIndex.includes(0) || isMinify}
            timeout="auto"
            unmountOnExit
          >
            <IfUserAcl acl={['manageRoundtripsCluster', 'adminOrders']}>
              <MainNavItem
                to={DASHBOARD}
                text={t('nav.mainNav.dashboard')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, DASHBOARD)}>
                    <ChartPieSlice />
                  </IconWrapper>
                }
              />
            </IfUserAcl>

            <IfUserAcl
              acl={[
                'manageRoundtripsCluster',
                'adminOrders',
                'viewRoundtripsCluster',
              ]}
            >
              <MainNavItem
                to={`${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`}
                tabs={[
                  `${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`,
                  `${ROUNDTRIP}/${ALL_ROUNDTRIPS}`,
                ]}
                text={t('nav.mainNav.roundtrips')}
                mode={mode}
                icon={
                  <IconWrapper
                    isActive={isActiveNestedRoute(location, [
                      `${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`,
                      `${ROUNDTRIP}/${ALL_ROUNDTRIPS}`,
                    ])}
                  >
                    <RoadHorizon />
                  </IconWrapper>
                }
              />
            </IfUserAcl>
          </Collapse>
        </MenuSectionWrapper>
      </If>
      <GeneralSectionWrapper>
        <>
          <If condition={!isMinify}>
            <TitleWrapper onClick={() => handleRowToggle(1)}>
              <Title textColorSecondary={theme.textColorSecondary}>
                {t('nav.mainNav.title2')}
              </Title>
              <IconButton
                sx={{
                  ml: 'auto',
                  mr: '12px',
                  ':hover': {
                    transform: 'scale(1.1)',
                    transition: 'all 100ms ease-in',
                  },
                }}
              >
                {openSectionIndex.includes(1) ? (
                  <KeyboardArrowDownIcon color="action" />
                ) : (
                  <KeyboardArrowRightIcon color="action" />
                )}
              </IconButton>
            </TitleWrapper>
          </If>
          <Collapse
            in={openSectionIndex.includes(1) || isMinify}
            timeout="auto"
            unmountOnExit
          >
            <MainNavItem
              to={ORDERS}
              text={t('nav.mainNav.orders')}
              mode={mode}
              icon={
                <IconWrapper isActive={isActiveRoute(location, ORDERS)}>
                  <Package />
                </IconWrapper>
              }
            />

            <MainNavItem
              to={CUSTOMER}
              text={t('nav.mainNav.customers')}
              mode={mode}
              icon={
                <IconWrapper isActive={isActiveRoute(location, CUSTOMER)}>
                  <StarOutlineIcon />
                </IconWrapper>
              }
            />

            <IfUserAcl acl={['inbox']}>
              <MainNavItem
                to={INBOX}
                text={t('nav.mainNav.inbox')}
                mode={mode}
                icon={
                  <Badge
                    color="primary"
                    badgeContent={
                      organizationActivities?.countNewConversations || 0
                    }
                  >
                    <IconWrapper isActive={isActiveRoute(location, INBOX)}>
                      <AllInboxIcon />
                    </IconWrapper>
                  </Badge>
                }
              />
            </IfUserAcl>

            <IfUserAcl acl={['checkDocuments']}>
              <MainNavItem
                to={CHECK_DOCUMENTS}
                text={t('nav.mainNav.checkDocuments')}
                mode={mode}
                icon={
                  <Badge
                    color="primary"
                    badgeContent={
                      organizationActivities?.countCheckDocuments || 0
                    }
                  >
                    <IconWrapper
                      isActive={isActiveRoute(location, CHECK_DOCUMENTS)}
                    >
                      <AssignmentTurnedInIcon />
                    </IconWrapper>
                  </Badge>
                }
              />
            </IfUserAcl>

            <IfUserAcl acl={['uploadFiles']}>
              <MainNavItem
                to={UPLOAD_FILES}
                text={t('nav.mainNav.uploads')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, UPLOAD_FILES)}>
                    <CloudUploadIcon />
                  </IconWrapper>
                }
              />
            </IfUserAcl>

            <IfUserAcl acl={['reports']}>
              <MainNavItem
                to={REPORTS}
                text={t('nav.mainNav.reports')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, REPORTS)}>
                    <PresentationChart />
                  </IconWrapper>
                }
              />
            </IfUserAcl>
          </Collapse>
        </>
      </GeneralSectionWrapper>

      <IfUserAcl
        acl={['manageSales', 'manageRoundtripsCluster', 'manageVehicles']}
      >
        <LastSectionWrapper>
          <If condition={!isMinify}>
            <TitleWrapper onClick={() => handleRowToggle(3)}>
              <Title textColorSecondary={theme.textColorSecondary}>
                {t('nav.mainNav.resources')}
              </Title>
              <IconButton
                sx={{
                  ml: 'auto',
                  mr: '12px',
                  ':hover': {
                    transform: 'scale(1.1)',
                    transition: 'all 100ms ease-in',
                  },
                }}
              >
                {openSectionIndex.includes(3) ? (
                  <KeyboardArrowDownIcon color="action" />
                ) : (
                  <KeyboardArrowRightIcon color="action" />
                )}
              </IconButton>
            </TitleWrapper>
          </If>
          <Collapse
            in={openSectionIndex.includes(3) || isMinify}
            timeout="auto"
            unmountOnExit
          >
            <If condition={!!displayEmployees}>
              <MainNavItem
                to={USERS}
                text={t('nav.mainNav.employees')}
                active={location?.pathname?.includes(USERS)}
                mode={mode}
                icon={
                  <IconWrapper isActive={location?.pathname?.includes(USERS)}>
                    <IdentificationBadge />
                  </IconWrapper>
                }
              />
            </If>

            <IfUserAcl acl={['manageVehicles']}>
              <MainNavItem
                to={`${VEHICLES}/logistic`}
                text={t('nav.mainNav.vehicles')}
                tabs={[VEHICLES_LOGISTIC, VEHICLES_SALES]}
                mode={mode}
                icon={
                  <IconWrapper
                    isActive={isActiveNestedRoute(location, [
                      VEHICLES_LOGISTIC,
                      VEHICLES_SALES,
                    ])}
                  >
                    <Car />
                  </IconWrapper>
                }
              />
            </IfUserAcl>

            <IfUserAcl acl={['manageRoundtripsCluster', 'manageResources']}>
              <MainNavItem
                to={ACCESSORIES}
                text={t('nav.mainNav.accessories')}
                mode={mode}
                active={location.pathname?.includes(ACCESSORIES)}
                icon={
                  <IconWrapper
                    isActive={location.pathname?.includes(ACCESSORIES)}
                  >
                    <Devices />
                  </IconWrapper>
                }
              />
              <MainNavItem
                to={TOOLS}
                text={t('nav.mainNav.tools')}
                mode={mode}
                active={location.pathname?.includes(TOOLS)}
                icon={
                  <IconWrapper isActive={location.pathname?.includes(TOOLS)}>
                    <img src={PalletTruck} alt="" />
                  </IconWrapper>
                }
              />
              <MainNavItem
                to={PAYMENTCARDS}
                text={t('nav.mainNav.paymentcards')}
                mode={mode}
                active={
                  location.pathname?.includes(PAYMENTCARDS) ||
                  location.pathname?.includes(AccessoryType.paymentCards)
                }
                icon={
                  <IconWrapper
                    isActive={
                      location.pathname?.includes(PAYMENTCARDS) ||
                      location.pathname?.includes(AccessoryType.paymentCards)
                    }
                  >
                    <Cardholder />
                  </IconWrapper>
                }
              />
              {(isLogistic || checkUserAcl(authUser, ['manageResources'])) && (
                <MainNavItem
                  to={SUPPORTUNIT}
                  text={t('nav.mainNav.supportUnit')}
                  mode={mode}
                  icon={
                    <IconWrapper
                      isActive={isActiveRoute(location, SUPPORTUNIT)}
                    >
                      <Shapes />
                    </IconWrapper>
                  }
                />
              )}
            </IfUserAcl>
          </Collapse>
        </LastSectionWrapper>
      </IfUserAcl>
    </ScrollableSection>
  );
}

const Title = styled.p<{ textColorSecondary: string }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.813em;
  line-height: 15px;
  text-transform: uppercase;
  margin-left: 19px;
  color: ${props => props?.textColorSecondary};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const MenuSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
  padding-bottom: 8px;
`;

const GeneralSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
  padding: 8px 0;
`;

const ResourcesSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
  padding: 8px 0;
`;

const LastSectionWrapper = styled.div`
  padding-top: 8px;
`;

const ScrollableSection = styled.div`
  max-height: calc(100vh - 85px);
  overflow-y: hidden;
  overflow-x: hidden;

  @supports selector(::-webkit-scrollbar) {
    scrollbar-gutter: stable;
    margin-right: -7px;
  }

  &:hover {
    overflow-y: overlay;
  }
`;

interface IconWrapperProps {
  isActive: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  img,
  svg {
    border-color: ${props =>
      props.isActive
        ? props.theme.primaryActiveColor
        : props.theme.gainsboro2} !important;
    color: ${props =>
      props.isActive
        ? props.theme.primaryActiveColor
        : props.theme.iconColor} !important;
  }
`;

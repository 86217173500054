import styled from 'styled-components';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { themes } from 'styles/theme/themes';

const Position = styled.div<{ color: string }>`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${props => props.color};
  border-radius: 50px;
  width: 25px;
  height: 25px;
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export function StopPosition({ stop }: { stop: any }) {
  const color =
    stop.planningPosition !== stop.deliveryPosition ? '#EF9A9A' : '#A5D6A7';
  const isDelivered = !!stop.deliveryPosition;

  return (
    <Wrapper>
      <Position color={themes.default.gainsboro2}>
        {stop.planningPosition}
      </Position>
      {isDelivered && (
        <>
          <TrendingFlatIcon />
          <Position color={color}>{stop.deliveryPosition}</Position>
        </>
      )}
    </Wrapper>
  );
}

export default StopPosition;

import api from './api';

export const accessoryApis = api.injectEndpoints({
  endpoints: builder => ({
    getAccessories: builder.query<any, string>({
      query: urlQuery => ({
        url: `/accessories${urlQuery}`,
      }),
      providesTags: ['Accessories'],
    }),
    getAccessoryCategories: builder.query<any, string>({
      query: urlQuery => ({
        url: `/accessories/categories/list${urlQuery}`,
      }),
      providesTags: ['Accessories'],
    }),
    getAccessory: builder.query<any, string>({
      query: id => ({
        url: `/accessories/${id}`,
      }),
      providesTags: ['Accessories'],
    }),
    createCategory: builder.mutation<any, any>({
      query: body => ({
        url: `/accessories/categories`,
        method: 'POST',
        body: { ...body },
      }),
    }),
    updateCategory: builder.mutation<any, { sectionId; body }>({
      query: ({ sectionId, body }) => ({
        url: `/accessories/categories/${sectionId}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteCategory: builder.mutation<any, any>({
      query: ({ id }: any) => {
        return {
          url: `/accessories/categories/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Accessories'],
    }),
    deleteAccessory: builder.mutation<any, any>({
      query: ({ id }: any) => {
        return {
          url: `/accessories/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Accessories'],
    }),
    createAccessory: builder.mutation<any, any>({
      query: body => ({
        url: '/accessories',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Accessories'],
    }),
    updateAccessory: builder.mutation<any, any>({
      query: body => ({
        url: `/accessories/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Accessories'],
    }),
    bulkDelete: builder.mutation<any, any>({
      query: body => ({
        url: `/accessories/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Accessories'],
    }),
    deleteFile: builder.mutation<any, any>({
      query: body => ({
        url: `/accessories/${body.id}/files/${body.type}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Accessories'],
    }),
    getEvents: builder.query<any, { id: string; urlQuery: string }>({
      query: ({ id, urlQuery }) => ({
        url: `/accessories/${id}/event/eventlist${urlQuery}`,
      }),
      providesTags: ['AccessoryEvents'],
    }),
    createEvent: builder.mutation<any, any>({
      query: body => ({
        url: `/accessories/${body.id}/event`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AccessoryEvents'],
    }),
    updateEvent: builder.mutation<any, any>({
      query: body => ({
        url: `/accessories/event/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['AccessoryEvents'],
    }),
    deleteEvent: builder.mutation<any, any>({
      query: ({ id }: any) => {
        return {
          url: `/accessories/event/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['AccessoryEvents'],
    }),
    getAccessoryFacets: builder.query<any, string>({
      query: urlQuery => ({
        url: `/accessories/facets${urlQuery}`,
      }),
      providesTags: ['Accessories'],
    }),
    getEventsFacets: builder.query<any, string>({
      query: urlQuery => ({
        url: `/accessories/facetsEvents${urlQuery}`,
      }),
      providesTags: ['AccessoryEvents'],
    }),
  }),
});

export const {
  useGetAccessoriesQuery,
  useGetAccessoryCategoriesQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useCreateCategoryMutation,
  useDeleteAccessoryMutation,
  useGetAccessoryQuery,
  useUpdateAccessoryMutation,
  useCreateAccessoryMutation,
  useBulkDeleteMutation,
  useDeleteFileMutation,
  useGetEventsQuery,
  useCreateEventMutation,
  useDeleteEventMutation,
  useUpdateEventMutation,
  useGetAccessoryFacetsQuery,
  useGetEventsFacetsQuery,
} = accessoryApis;

export default accessoryApis;

import { useCallback, useEffect, useRef, useState } from 'react';
import { TableNavWrapper } from 'styles/components/users';
import { useInviteUserMutation } from 'common/services/userApi';
import { DrawerComponent } from 'app/components/DrawerComponent';
import {
  useGetCustomersWebQuery,
  useLazyCustomersPlacesQuery,
} from 'common/services/customerApi';
import { generatePath, useNavigate } from 'react-router-dom';
import { CUSTOMERBRANCH } from 'utils/routes';
import { VIEWCUSTOMER } from 'utils/routes';
import { useCustomersHeadCells } from '../Customers/data/headCellsConst';
import { Data } from 'interfaces/customers';
import { isEmpty } from 'lodash';
import { useSelectedTable } from 'hooks/useSelectedTable';
import { TableComponent } from 'app/components/TableComponent';
import { useFormatCustomersData } from 'hooks/Customers/useFormatCustomersData';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Drawer, FormControlLabel, FormGroup } from '@mui/material';
import styled from 'styled-components';
import { EditCustomer } from './components/EditCustomer';
// step1 (copy from Edit Customer) and make edit customer 2
import { AddCustomers } from './components/AddCustomers';
import { AddIconButton } from 'app/components/AddIconButton';
import { CustomerDocs } from 'common/types/Customer';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { selectAuthUser } from 'common/store/auth/selectors';
import { selectConfiguration } from 'common/store/organization/selectors';
import { checkUserAcl } from 'common/helpers/acl';
import { useToaster } from 'hooks/useToaster';
import If from 'app/components/If';
import NoResults from 'app/components/NoData/NoResults';
import { EnhancedTableToolbar } from 'app/components/EnhancedTableToolbar';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import { useQueryParams } from 'hooks/useQueryParams';
import { useDebounce } from '@uidotdev/usehooks';
import FilterIcon from 'app/components/FilterComponents/components/FilterIcon';
import { useDisabledToolbar } from 'app/components/EnhancedTableToolbar/hooks/useDisabledToolbar';
import CustomerFilter from './components/CustomerFilter';
import { FilterChips } from 'app/components/FilterChips';
import MapPopover from 'app/components/MapPopover';
import { handleCloseStopMap } from '../AddRoundTrips/functions';
import { CustomerDetails } from './components/CustomerDetails';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import NoCustomers from 'assets/img/customers/no-customers.svg';
import { useResetPaginaton } from 'hooks/useResetPagniation';
import {
  EXCEL,
  GRID,
  MAP,
  REOPTIMIZE,
  TRACK,
} from 'app/components/TableComponent/constants';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { TableViewPopover } from 'styles/components/muitable/TableViewPopover';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { defaultMapStyle } from 'styles/mapStyles';
import WarehouseMarker from 'assets/img/map/marker/WarehousePointMarker.svg';
import { BottomFooterMap } from './components/BottomFooterMap';
import { MarkerClient } from './components/MarkerClient';
import _ from 'lodash';
import { ZoomOptionMap } from './components/ZoomOptionMap';
// import cluster from 'assets/img/map/CustomerMap/cluster.svg';
import { Checkbox } from 'app/components/CustomCheckbox';

let rowsData: { data: any; accordion?: JSX.Element }[] = [];

export function Customers() {
  const initialFacet = {
    status: [],
    salesman: [],
    customerTypes: [],
    creditScores: [],
  };

  const mapBoundsRef = useRef('');

  const theme = useSelector(selectTheme);
  const authUser = useSelector(selectAuthUser);
  const configuration = useSelector(selectConfiguration);

  const [AddCustomerDrawerIsOpen, setAddCustomerDrawerIsOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [rowsDatas, setRowsDatas] = useState<any>([]);
  const [sales, setSales] = useState<any>([]);
  const [type, setType] = useState<
    typeof GRID | typeof TRACK | typeof EXCEL | typeof MAP | typeof REOPTIMIZE
  >(GRID);
  const [headCells, setHeadCells] = useCustomersHeadCells(authUser, type);

  const [customer, setCustomer] = useState<CustomerDocs | null>();
  const [facets, setFacets] = useState<any>({});
  const [resetFacet, setResetFacet] = useState(true);

  const [rows, setRows] =
    useState<{ data: Data; accordion?: JSX.Element }[]>(rowsData);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapZoom, setMapZoom] = useState<any>(11);
  const [selected, setSelected] = useSelectedTable(rows, false);
  const [heads, setHeads] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [EditCustomerDrawerIsOpen, setEditCustomerDrawerIsOpen] =
    useState(false);

  const [loadingInvite, setLoadingInvite] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState<{
    id: String;
    coordinates: number[];
  } | null>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');

  const { t } = useTranslation();
  const [inviteUser] = useInviteUserMutation();
  const toast = useToaster();

  const [sort, setSort] = useState('code');
  const [direction, setDirection] = useState('');

  const warehouses = configuration?.decks.filter(
    deck => deck.location?.geometry?.coordinates,
  );
  const defaultMapCenter = warehouses[0]?.location?.geometry?.coordinates
    ? {
        lat: configuration?.decks[0]?.location?.geometry?.coordinates[1],
        lng: configuration?.decks[0]?.location?.geometry?.coordinates[0],
      }
    : { lat: 48.8566, lng: 2.3522 };

  const [mapCenter, setMapCenter] = useState(defaultMapCenter);

  const [filters, setFilters] = useState<{
    status: { value: string; label: string }[];
    customerTypes: { value: string; label: string }[];
    salesman: { value: string; label: string }[];
    creditScores: { value: string; label: string }[];
  }>({
    status: [],
    customerTypes: [],
    salesman: [],
    creditScores: [],
  });
  const {
    filterOn,
    handleCloseFilter,
    handleOpenFilter,
    filtersCount,
    debouncedFiltersCount,
  } = useFilterState({ filters });
  const debouncedFilters = useDebounce(filters, 1000);

  const queryParams = useQueryParams({
    status: debouncedFilters?.status
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    customerTypes: debouncedFilters?.customerTypes
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    salesman: debouncedFilters?.salesman
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    creditScores: debouncedFilters?.creditScores
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    text: search,
    page,
    limit: rowsPerPage,
    sort: direction + sort,
  });

  const navigate = useNavigate();
  const afterSubmit = (options?: any) => {
    options?.close && setAddCustomerDrawerIsOpen(false);
    if (options?.newId) {
      const path = generatePath(VIEWCUSTOMER, { id: options.newId });
      navigate(path);
    }
  };

  const { data, isFetching, isLoading, refetch } =
    useGetCustomersWebQuery(queryParams);

  useResetPaginaton(setPage, [search, debouncedFilters]);

  const { disabledToolbar } = useDisabledToolbar({
    total: data?.totalDocs,
    searchTerm: search,
    filterCount: debouncedFiltersCount,
  });

  useEffect(() => {
    if (resetFacet && data?.facets?.[0]) {
      setFacets(data.facets[0]);
    }

    if (isEmpty(sales) && !isEmpty(data?.facets[0]._salesman)) {
      setSales(data.facets[0]._salesman || []);
    }

    if (data?.docs) {
      setRowsDatas(data.docs);
      setTotalPages(data?.totalPages);
    }
  }, [data]);

  useEffect(() => {
    setResetFacet(true);

    if (type === MAP && !isFetchingMapData && map) {
      getBoundsFromMap(true);
    }
  }, [search]);

  useEffect(() => {
    if (map && type === MAP && !mapData && !isLoadingMapData) {
      setTimeout(() => {
        getBoundsFromMap(true);
      }, 10);
    }
  }, [map, type]);

  const handleEditCustomer = () => {
    setEditCustomerDrawerIsOpen(true);
  };

  const inviteCustomer = async customer => {
    try {
      setLoadingInvite(customer?._id);

      if (customer.email) {
        const response = await inviteUser({
          customerId: customer._id,
          role: 'customer',
          email: customer?.email,
          companyEmail: customer?.email,
          agent: {
            firstName: 'customer',
            lastName: 'customer',
          },
          source: 'customer',
        }).unwrap();
        refetch();
        setLoadingInvite(null);

        if (!isEmpty(response)) {
          toast(3000, 'success', 'customer_invited_successfully');
        }
      } else {
        toast(3000, 'error', 'customer.invitation.missingEmail');
      }
    } catch (e: any) {
      if (e.status === 400) {
        toast(3000, 'error', e.data?.message);
      } else {
        toast(5000, 'error', 'user_invite_failed');
      }
    }
  };

  const onDisplayMap = (id: string, coordinates: number[]) => {
    setSelectedAddress({ id, coordinates });
  };

  useFormatCustomersData({
    customers: rowsDatas,
    setRows,
    handleEditCustomer,
    setCustomer,
    inviteCustomer,
    loadingInvite,
    onDisplayMap,
    setSelectedCustomerId,
    onRefreshCreditScoreOk: () => refetch(),
  });

  const onLoad = useCallback(map => setMap(map), []);
  const [
    triggerCustomer,
    {
      data: mapData,
      isLoading: isLoadingMapData,
      isFetching: isFetchingMapData,
    },
  ] = useLazyCustomersPlacesQuery();

  const throttledGetBoundsFromMap = _.throttle(() => {
    getBoundsFromMap(false);
  }, 1000);

  const getBoundsFromMap = (forceTrigger = false) => {
    if (map) {
      const bds = map.getBounds();

      if (bds) {
        const South_Lat = bds.getSouthWest().lat();
        const South_Lng = bds.getSouthWest().lng();
        const North_Lat = bds.getNorthEast().lat();
        const North_Lng = bds.getNorthEast().lng();

        var NWCorner_lat = new google.maps.LatLng(North_Lat, South_Lng).lat();
        var NWCorner_lng = new google.maps.LatLng(North_Lat, South_Lng).lng();

        var SECorner_lat = new google.maps.LatLng(South_Lat, North_Lng).lat();
        var SECorner_lng = new google.maps.LatLng(South_Lat, North_Lng).lng();

        const queryParams = [
          [
            [NWCorner_lng, NWCorner_lat],
            [North_Lng, North_Lat],
            [SECorner_lng, SECorner_lat],
            [South_Lng, South_Lat],
            [NWCorner_lng, NWCorner_lat],
          ],
        ];

        const newBoundsQueryParam = `bounds=${encodeURIComponent(
          JSON.stringify(queryParams),
        )}&text=${search}&limit=3500`;

        console.log(mapBoundsRef, newBoundsQueryParam);
        if (forceTrigger || mapBoundsRef.current !== newBoundsQueryParam) {
          triggerCustomer(newBoundsQueryParam);
        }

        mapBoundsRef.current = newBoundsQueryParam;

        if (map.getCenter()?.lat() && map.getCenter()?.lng()) {
          setMapCenter({
            // @ts-ignore
            lat: map.getCenter()?.lat(),
            // @ts-ignore
            lng: map.getCenter()?.lng(),
          });
        }
      }
    }
  };

  const [openClientId, setOpenClientId] = useState(null);
  const handleMarkerClick = clientId => {
    if (openClientId === clientId) {
      setOpenClientId(null);
    } else {
      setOpenClientId(clientId);
    }
  };

  const handleZoomChanged = () => {
    if (map) {
      const newZoom = map.getZoom();

      if (newZoom && newZoom !== mapZoom) {
        // TODO: make sure this is not called too often
        setMapZoom(newZoom);

        if (newZoom < mapZoom) {
          throttledGetBoundsFromMap();
        }
      }
    }
  };

  const handleSetZoom = zoom => {
    if (map && zoom !== mapZoom) {
      setMapZoom(zoom);

      if (zoom < mapZoom) {
        throttledGetBoundsFromMap();
      }
    }
  };

  const [selectedTurnover, setSelectedTurnover] = useState<boolean>(true);
  const [euroByKg, setEuroByKg] = useState<boolean>(true);
  const [nmbOrders, setNmbOrders] = useState<boolean>(true);
  const [names, setNames] = useState<boolean>(true);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    if (EditCustomerDrawerIsOpen && customer?._id) {
      const path = generatePath(VIEWCUSTOMER, { id: customer?._id });
      navigate(path);
    }
  }, [EditCustomerDrawerIsOpen]);

  // step2 inorder to open drawer
  const onAddClickHandler = () => {
    setAddCustomerDrawerIsOpen(true);
  };

  if (isLoading || !configuration) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <DrawerComponent
        title={t('Add_Client_Add_Client')}
        open={AddCustomerDrawerIsOpen}
        handleClose={() => setAddCustomerDrawerIsOpen(false)}
        width={'700px'}
        paddingSize="16px 24px"
      >
        <AddCustomers
          onClose={() => setAddCustomerDrawerIsOpen(false)}
          customer={{}}
          onOk={() => refetch()}
          afterSubmit={afterSubmit}
        />
      </DrawerComponent>

      <Drawer
        anchor="right"
        open={false}
        onClose={() => setSelectedCustomerId('')}
      >
        <Wrapper>
          <CustomerDetails
            customerId={selectedCustomerId}
            onCloseDrawer={() => setSelectedCustomerId('')}
          />
        </Wrapper>
      </Drawer>
      {type === MAP && (
        <div
          style={{
            width: '100%',
          }}
        >
          <EnhancedTableToolbar
            title="nav.mainNav.customers"
            disabled={disabledToolbar}
            headCells={headCells}
            heads={heads}
            numSelected={selected.length}
            search={search}
            setSearch={setSearch}
            setHeadCells={setHeadCells}
            actionsComponents={
              <TableViewPopover
                views={[
                  {
                    type: MAP,
                    icon: (
                      <PinDropIcon
                        sx={{
                          color: theme.icon,
                        }}
                      />
                    ),
                    label: `${t('roundtrips.view_map')}`,
                  },
                  {
                    type: GRID,
                    icon: (
                      <BackupTableIcon
                        sx={{
                          color: theme.icon,
                        }}
                      />
                    ),
                    label: `${t('roundtrips.view_grid')}`,
                  },
                ]}
                tableType={type}
                handleAction={(e, action) => {
                  const selectedCopy = { ...selected };
                  let objectKeys = Object.keys(selectedCopy);
                  for (let i = 0; i < objectKeys.length; i++) {
                    selectedCopy[objectKeys[i]] = [];
                  }
                  if (action === MAP) {
                    setType(MAP);
                  }
                  if (action === GRID) {
                    setType(GRID);
                  }
                }}
              />
            }
          />
          <div
            style={{
              height: 'calc(100% - 46px)',
            }}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={mapZoom}
              center={mapCenter}
              onDragEnd={throttledGetBoundsFromMap}
              onZoomChanged={handleZoomChanged}
              onLoad={onLoad}
              options={{
                styles: defaultMapStyle,
                mapTypeControl: false,
                disableDefaultUI: true,
              }}
            >
              {warehouses?.map(warehouse => {
                return (
                  <MarkerF
                    key={warehouse._id}
                    icon={WarehouseMarker}
                    position={{
                      lat: warehouse.location?.geometry?.coordinates[1],
                      lng: warehouse.location?.geometry?.coordinates[0],
                    }}
                  />
                );
              })}
              {mapData?.docs?.map(client => {
                let color = 'blue';

                if (client?._customer?.cache?.stats?.amountPerWeight < 3) {
                  color = 'red';
                } else if (
                  client?._customer?.cache?.stats?.amountPerWeight >= 3 &&
                  client?._customer?.cache?.stats?.amountPerWeight <= 6
                ) {
                  color = 'orange';
                }

                return (
                  <MarkerClient
                    key={client._id}
                    color={color}
                    // clusterer={clusterer}
                    coordinates={client?.location?.geometry?.coordinates}
                    code={client?.code}
                    name={client?._customer?.legalName}
                    amountPerWeight={
                      client?._customer?.cache?.stats?.amountPerWeight
                    }
                    totalAmount={client?._customer?.cache?.stats?.totalAmount}
                    isSelected={client?.code === openClientId}
                    onToggleOpen={() => handleMarkerClick(client.code)}
                    turnover={selectedTurnover}
                    names={names}
                    nmbOrders={nmbOrders}
                    euroByKg={euroByKg}
                    totalOrders={client?._customer?.cache?.stats?.totalOrders}
                  />
                );
              })}
              <ZoomOptionMap mapZoom={mapZoom} setMapZoom={handleSetZoom} />
              <div
                style={{
                  height: '160px',
                  width: '171px',
                  backgroundColor: '#FFF',
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  boxShadow: '2px 4px 4px rgba(1,1,1,0.3)',
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '2px',
                }}
              >
                <FormGroup
                  sx={{
                    marginLeft: '12px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={true}
                        onChange={() => setSelectedTurnover(!selectedTurnover)}
                      />
                    }
                    label="Turnover"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={true}
                        onChange={() => setEuroByKg(!euroByKg)}
                      />
                    }
                    label="€/kg"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={true}
                        onChange={() => setNmbOrders(!nmbOrders)}
                      />
                    }
                    label="Number of Orders"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={true}
                        onChange={() => setNames(!names)}
                      />
                    }
                    label="Names"
                  />
                </FormGroup>
              </div>
            </GoogleMap>

            <BottomFooterMap />
          </div>
        </div>
      )}
      {type === GRID && (
        <TableNavWrapper>
          <TableComponent
            filterCount={filtersCount}
            isFetching={isFetching}
            isLoading={isLoading}
            setRegularDirection={setDirection}
            setRegularSort={setSort}
            EnhancedTableToolbar={
              <EnhancedTableToolbar
                title="nav.mainNav.customers"
                disabled={disabledToolbar}
                headCells={headCells}
                heads={heads}
                numSelected={selected.length}
                search={search}
                //step3 onAddclick
                // onAddClick={() => onAddClickHandler()}

                setSearch={setSearch}
                setHeadCells={setHeadCells}
                actionsComponents={
                  <>
                    <TableViewPopover
                      views={[
                        {
                          type: MAP,
                          icon: (
                            <PinDropIcon
                              sx={{
                                color: theme.icon,
                              }}
                            />
                          ),
                          label: `${t('roundtrips.view_map')}`,
                        },
                        {
                          type: GRID,
                          icon: (
                            <BackupTableIcon
                              sx={{
                                color: theme.icon,
                              }}
                            />
                          ),
                          label: `${t('roundtrips.view_grid')}`,
                        },
                      ]}
                      tableType={type}
                      handleAction={(e, action) => {
                        const selectedCopy = { ...selected };
                        let objectKeys = Object.keys(selectedCopy);
                        for (let i = 0; i < objectKeys.length; i++) {
                          selectedCopy[objectKeys[i]] = [];
                        }
                        //setSelected(selectedCopy);
                        if (action === MAP) {
                          //setHeadCells(stateHeadCellsConst);
                          setType(MAP);
                          //setExpanded({});
                        }
                        if (action === GRID) {
                          //setHeadCells(excelHeadCellsConst);
                          setType(GRID);
                        }
                      }}
                    />
                    {!disabledToolbar && (
                      <AddIconButton
                        onClick={() => onAddClickHandler()}
                        tooltip={t(`Add_Customer`)}
                      />
                    )}
                  </>
                }
                filterComponents={
                  <>
                    <FilterIcon
                      filterOn={filterOn}
                      filtersCount={filtersCount}
                      handleOpenFilter={handleOpenFilter}
                      disabled={disabledToolbar}
                      filterComponent={
                        <CustomerFilter
                          filterOn={filterOn}
                          handleCloseFilter={handleCloseFilter}
                          filters={filters}
                          setFilters={setFilters}
                        />
                      }
                    />
                  </>
                }
              />
            }
            filterSection={
              <FilterChips filters={filters} setFilters={setFilters} />
            }
            rows={rows}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            headCells={headCells}
            setHeadCells={setHeadCells}
            type="regular"
            selected={selected}
            setSelected={setSelected}
            rowsDatas={rowsDatas}
            totalPages={totalPages || 0}
            totalDocs={data?.totalDocs}
            mapRowId={selectedAddress?.id}
            handleEditTrigger={customer => {
              setCustomer(customer);
              handleEditCustomer();
            }}
          />
          {!data?.totalDocs && !isFetching && (
            <EmptyCenter>
              <If
                condition={(!search || search === '') && !debouncedFiltersCount}
                otherwise={<NoResults />}
              >
                <NoDataComponents
                  viewMode={true}
                  Icon={NoCustomers}
                  text={t('no_clients_yet')}
                  IconButton={<></>}
                  textButton={''}
                />
              </If>
            </EmptyCenter>
          )}
          <MapPopover
            open={!!selectedAddress?.coordinates?.length}
            handleClose={() => {
              handleCloseStopMap(setSelectedAddress);
            }}
            latitude={selectedAddress?.coordinates?.[1]}
            longitude={selectedAddress?.coordinates?.[0]}
            editMode={false}
          />
        </TableNavWrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  height: fit-content;
  min-height: 100%;
  width: 699px;
`;

export const EmptyCenter = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

import { Stack } from '@mui/material';
import { useContext } from 'react';
import { LeftMenuContext } from '../../../../../LeftMenu';
import { StatusPopover } from 'app/components/TableComponent/helpers/Components/StatusPopover';
import If from 'app/components/If';
import TruckIcon from 'app/components/TruckIcon';
import { ActionWrapper, RoundtripItemProps } from '../..';
import { PopoverAction } from 'app/pages/AddRoundTrips/data/RoundTripData/components/PopoverAction';
import { functionRoundTripStatus } from 'app/components/RoundTrips/function/ApiFunctions';

export const RoundtripCollapsed = ({
  roundtrip,
  roundtripInfo,
  isHovered,
}: RoundtripItemProps) => {
  const {
    updateRoundtripStatus,
    viewMode,
    handleClickOpen,
    handleClickOpenDelete,
    setRoundtripEdit,
  } = useContext(LeftMenuContext);
  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        <StatusPopover
          roundtrip={roundtrip}
          functionRoundTripStatus={updateRoundtripStatus}
          notEdit={viewMode}
          minimal={true}
        />
      </Stack>

      <If condition={!viewMode}>
        <ActionWrapper isHovered={true}>
          <PopoverAction
            typeEdit={!viewMode}
            roundtrip={roundtrip}
            functionRoundTripStatus={functionRoundTripStatus}
            handleClickOpen={handleClickOpen}
            handleClickOpenDelete={handleClickOpenDelete}
            setRoundtripEdit={setRoundtripEdit}
          />
        </ActionWrapper>
      </If>
    </>
  );
};

//import sections
import { Form } from 'app/components/Form/styles';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { MenuItem, Switch, Typography } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'common/hooks/useDebounce';
import Box from '@mui/material/Box';
import { IconButton, Tooltip } from '@mui/material';
import { Trash, Flag, PlusCircle, PaperPlaneTilt } from '@phosphor-icons/react';
import {
  useUpdateCustomerMutation,
  useGetCustomerTypesQuery,
} from 'common/services/customerApi';
import { useGetUsersWebQuery } from 'common/services/userApi';
import { FieldComponent } from 'app/components/FieldComponent';
import { themes } from 'styles/theme/themes';
import { ClientMap } from 'app/components/ClientMap';

export const InfoForm = ({ customerDetail }) => {
  const { data: clientCategoryDetail } = useGetCustomerTypesQuery({});
  const { data: usersData } = useGetUsersWebQuery('?roles=sales');
  const [mainEmailAddress, setmainEmailAddress] = useState(
    customerDetail?.email || '',
  );
  const [emailAddresses, setEmailAddresses] = useState<string[]>(
    customerDetail?.otherEmails || [],
  );
  const [newEmailAddress, setNewEmailAddress] = useState<string>('');
  const [flagColors, setFlagColors] = React.useState({});
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isIconClicked, setIsIconClicked] = useState<boolean>(false);
  const [toggle, setToggle] = useState(false);
  const [iconcolor, setIconcolor] = useState(false);
  const [phoneValue, setPhoneValue] = useState(
    customerDetail?.mobilePhone || '',
  );
  const [mobilePhone2Value, setMobilePhone2Value] = useState(
    customerDetail?.mobilePhone2 || '',
  );

  const [selectedValue, setSelectedValue] = useState<string>(
    customerDetail?.type || 'client',
  );
  const [mainlocationValue, setLocationvalue] = useState<{
    name: string;
    city: string;
    department: string;
    region: string;
    continent: string;
    zipCode: string;
    formattedAddress: string;
    shortAddress: string;
    countryCode: string;
    geometry: {
      type: string;
      coordinates: number[];
    };
  }>({
    name: customerDetail?.mainLocation?.name || '',
    city: customerDetail?.mainLocation?.city || '',
    department: customerDetail?.mainLocation?.department || '',
    region: customerDetail?.mainLocation?.region || '',
    continent: customerDetail?.mainLocation?.continent || '',
    zipCode: customerDetail?.mainLocation?.zipCode || '',
    formattedAddress: customerDetail?.mainLocation?.formattedAddress || '',
    shortAddress: customerDetail?.mainLocation?.shortAddress || '',
    countryCode: customerDetail?.mainLocation?.countryCode || '',
    geometry: {
      type: customerDetail?.mainLocation?.geometry?.type || '',
      coordinates: customerDetail?.mainLocation?.geometry?.coordinates || [],
    },
  });

  const { t } = useTranslation();

  const DEFAULT_LATITUDE = 49.029045;
  const DEFAULT_LONGITUDE = 2.8033632;

  const [switchStateValue, setSwitchState] = useState<boolean>(
    customerDetail?.switchState || false,
  );

  const [latitude, setLatitude] = useState<number | null>(
    customerDetail?.mainLocation?.geometry?.coordinates[1] || DEFAULT_LATITUDE,
  );
  const [longitude, setLongitude] = useState<number | null>(
    customerDetail?.mainLocation?.geometry?.coordinates[0] || DEFAULT_LONGITUDE,
  );

  const handleDeleteEmailAddress = index => {
    const updatedEmailAddresses = [...emailAddresses];
    const emailToDelete = updatedEmailAddresses[index];
    if (mainEmailAddress === emailToDelete) {
      setmainEmailAddress('');
    }
    updatedEmailAddresses.splice(index, 1);
    setEmailAddresses(updatedEmailAddresses);
    setFormData(prevFormData => ({
      ...prevFormData,
      email: mainEmailAddress === emailToDelete ? '' : mainEmailAddress,
      other_emails: updatedEmailAddresses,
    }));
  };
  const handleFlagIconClick = clickedEmail => {
    const newFlagColors = {};
    for (const email of emailAddresses) {
      newFlagColors[email] = email === clickedEmail ? 'goldenrod' : '';
    }
    setmainEmailAddress(clickedEmail);
    setFormData(prevFormData => ({
      ...prevFormData,
      email: clickedEmail,
    }));
    setFlagColors(newFlagColors);
  };
  const handleFlagIconClickAfterDelete = clickedEmail => {
    const newFlagColors = {};
    for (const email of emailAddresses) {
      newFlagColors[email] = email === clickedEmail ? 'goldenrod' : '';
    }
    setFlagColors(newFlagColors);
  };

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      code: customerDetail?.code ?? '',
      publicName: customerDetail?.publicName ?? '',
      legalName: customerDetail?.legalName ?? '',
      email: customerDetail?.email ?? '',
      otherEmails: customerDetail?.otherEmails ?? [],
      vatNumber: customerDetail?.vatNumber ?? '',
      invoicePeriod: customerDetail?.invoicePeriod ?? '',
      phone: customerDetail?.phone ?? '',
      mobilePhone2: customerDetail?.mobilePhone2 ?? '',
      _customerType: customerDetail?._customerType ?? '',
      _salesman: customerDetail?._salesman?.id ?? '',
      status: customerDetail?.status ?? '',
      managerName: customerDetail?.managerName ?? '',
      managerName2: customerDetail?.managerName2 ?? '',
      mainLocation: customerDetail?.mainLocation ?? {},
      switchState: customerDetail?.switchState ?? '',
      legalIdAlt: customerDetail?.legalIdAlt ?? '',
      salePhone: customerDetail?.salePhone ?? '',
      type: customerDetail?.type ?? '',
    },
  });

  useEffect(() => {
    setEmailAddresses(customerDetail?.otherEmails || []);
    setPhoneValue(customerDetail?.mobilePhone || '');
    setMobilePhone2Value(customerDetail?.mobilePhone2 || '');
    setSelectedValue(customerDetail?.type);
    setmainEmailAddress(customerDetail?.email || '');
    setSwitchState(customerDetail?.switchState);
    reset({
      code: customerDetail?.code || '',
      publicName: customerDetail?.publicName || '',
      legalName: customerDetail?.legalName || '',
      email: customerDetail?.email || '',
      vatNumber: customerDetail?.vatNumber || '',
      invoicePeriod: customerDetail?.invoicePeriod || '',
      phone: customerDetail?.phone || '',
      mobilePhone2: customerDetail?.mobilePhone2 || '',
      _customerType: customerDetail?._customerType || '',
      _salesman: customerDetail?._salesman?.id || '',
      status: customerDetail?.status || '',
      managerName: customerDetail?.managerName || '',
      managerName2: customerDetail?.managerName2 || '',
      mainLocation: customerDetail?.mainLocation || {},
      legalIdAlt: customerDetail?.legalIdAlt || '',
      salePhone: customerDetail?.salePhone || '',
    });
  }, [customerDetail]);

  const { control, watch, reset, trigger } = methods;

  useEffect(() => {
    if (!!emailAddresses?.length) {
      handleFlagIconClickAfterDelete(customerDetail?.email);
    }
  }, [emailAddresses]);

  const customerStatus = [
    { label: t('customer.status.preliminary'), value: 'preliminary' },
    { label: t('events.status.pending'), value: 'pending' },
    { label: t('user.status.active'), value: 'active' },
    { label: t('customer.status.on-watch'), value: 'on-watch' },
    { label: t('customer.status.blocked'), value: 'blocked' },
    { label: t('vehicles.status.inactive'), value: 'inactive' },
    { label: t('customer.status.stopped'), value: 'stopped' },
  ];

  const code = watch('code');
  const publicName = watch('publicName');
  const legalName = watch('legalName');
  const vatNumber = watch('vatNumber');
  const managerName2 = watch('managerName2');
  const legalIdAlt = watch('legalIdAlt');
  const salesman = watch('_salesman');
  const status = watch('status');
  const managerName = watch('managerName');
  const salePhone = watch('salePhone');
  const customerType = watch('_customerType');

  const handleMouseEnter = index => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleNewEmailAddressChange = event => {
    const { value } = event.target;
    setNewEmailAddress(value);
    setIsValidEmail(validateEmail(value));

    const iconColor =
      value && isValidEmail && isIconClicked ? '#2A59FF' : '#757575';
    updateIconColor(iconColor);
  };

  const handleAddEmailAddress = () => {
    const trimmedEmail = newEmailAddress.trim();
    if (isValidEmail && trimmedEmail !== '') {
      setIsIconClicked(true);
      setEmailAddresses(prevEmailAddresses => [
        ...prevEmailAddresses,
        trimmedEmail,
      ]);
      setNewEmailAddress('');

      setFormData({
        ...formData,

        other_emails: prevOtherEmails => [...prevOtherEmails, trimmedEmail],
      });

      const iconColor = newEmailAddress && isValidEmail ? '#2A59FF' : '#757575';
      updateIconColor(iconColor);
    }
  };

  const updateIconColor = color => {
    const plusCircleElement = document.getElementById('plusCircle');
    if (plusCircleElement) {
      plusCircleElement.style.color = color;
      plusCircleElement.style.cursor =
        color === '#2A59FF' ? 'pointer' : 'default';
    }
  };

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setToggle(isValid);
    setIconcolor(isValid);
    return isValid;
  };

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchState(event.target.checked);
    setFormData({
      ...formData,
      switchState: event.target.checked,
    });
  };

  const [formData, setFormData] = useState(customerDetail);
  useEffect(() => {
    setFormData(customerDetail);
  }, []);

  const [updateCustomer] = useUpdateCustomerMutation();

  const haveSameValues = (obj1, obj2) => {
    const commonKeys = _.intersection(_.keys(obj1), _.keys(obj2));
    const values1 = _.pick(obj1, commonKeys);
    const values2 = _.pick(obj2, commonKeys);
    return _.isEqual(values1, values2);
  };

  const omitEmptyValues = obj => {
    return _.omitBy(
      obj,
      value =>
        _.isNil(value) ||
        value === '' ||
        value?.name === '' ||
        value.length === 0,
    );
  };

  const payload = useMemo(() => {
    return {
      id: customerDetail?._id,
      code: code,
      publicName: publicName,
      email: mainEmailAddress,
      vatNumber: vatNumber,
      managerName2: managerName2,
      legalName: legalName,
      legalIdAlt: legalIdAlt,
      status: status,
      _salesman: salesman,
      _customerType: customerType,
      mobilePhone: phoneValue,
      mobilePhone2: mobilePhone2Value,
      managerName: managerName,
      switchState: switchStateValue,
      otherEmails: emailAddresses,
      mainLocation: mainlocationValue,
      salePhone: salePhone,
      type: selectedValue,
    };
  }, [
    code,
    publicName,
    mainEmailAddress,
    vatNumber,
    managerName2,
    legalName,
    legalIdAlt,
    status,
    salesman,
    customerType,
    phoneValue,
    mobilePhone2Value,
    managerName,
    switchStateValue,
    emailAddresses,
    mainlocationValue,
    salePhone,
    selectedValue,
  ]);

  const debouncedPayload = useDebounce(payload, 300);

  function handleSaveCustomerData() {
    if (
      haveSameValues(control?._defaultValues, customerDetail) &&
      haveSameValues(mainlocationValue, customerDetail?.mainLocation)
    ) {
      return;
    } else {
      const cleanPayload = omitEmptyValues(debouncedPayload);

      updateCustomer(cleanPayload);
    }
  }

  useEffect(() => {
    handleSaveCustomerData();
  }, [debouncedPayload]);

  const ChipWithRadio = ({ value, label, selectedValue }) => {
    const checked = selectedValue === value;
    return (
      <Chip
        label={
          <Box display="flex" alignItems="center" marginRight="12px">
            <Radio
              value={value}
              checked={checked}
              sx={{
                p: 0,
                mr: '4px',
                color: checked
                  ? themes?.default?.primaryActiveColor
                  : themes?.default?.chipsBorderGrey,
                '&.Mui-checked': {
                  color: checked
                    ? themes?.default?.primaryActiveColor
                    : themes?.default?.chipsBorderGrey,
                },
              }}
            />
            <Typography fontWeight={checked ? '500' : '400'} fontSize="1rem">
              {label}
            </Typography>
          </Box>
        }
        variant="outlined"
        style={{
          backgroundColor: checked ? themes?.default?.primaryLight1 : 'white',
          borderColor: checked
            ? themes?.default?.strokePrimary
            : themes?.default?.chipsBorderGrey,
          color: checked
            ? themes?.default?.primaryActiveColor
            : themes?.default?.textBlack,
          marginLeft: '12px',
        }}
        size="medium"
      />
    );
  };

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const tableStyle = {
    width: '100%',
    border: '0.2px solid #adaaaaef',
    borderRadius: '5px',
  };

  return (
    <div
      style={{
        height: '450px',
        minHeight: 'calc(100vh - 210px)',
        overflow: 'auto',
      }}
    >
      <Form>
        <Grid
          container
          spacing={2}
          style={{
            width: '100%',
            height: '985px',
            padding: '17px 0px 21px 17px',
            borderRadius: '4px',
          }}
        >
          <Grid
            item
            sx={{
              borderRadius: '4px',
              width: 'calc(50% - 10px)',
              background: 'white',
              border: '0.5px solid #E0E0E0',
              marginLeft: '10px',
              height: 'max-content',
              paddingBottom: '20px',
              paddingRight: '16px',
            }}
          >
            <Typography fontWeight={500}>{t('Main Details')}</Typography>
            <div
              style={{
                marginBottom: '20px',
              }}
            >
              <FormControl
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: '8px',
                }}
              >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedValue}
                  onChange={handleChange}
                  style={{ display: 'flex' }}
                >
                  <FormControlLabel
                    value="Client"
                    control={
                      <ChipWithRadio
                        value="client"
                        label="Client"
                        selectedValue={selectedValue}
                      />
                    }
                    label=""
                  />
                  <FormControlLabel
                    value="Potential Client"
                    control={
                      <ChipWithRadio
                        value="prospect"
                        label="Potential Client"
                        selectedValue={selectedValue}
                      />
                    }
                    label=""
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <FieldComponent
              style={{ width: '100%' }}
              name="legalIdAlt"
              control={control}
              size="small"
              label={t('Add_Client_SIREN.number')}
              placeholder={t('Add_Client_Enter_SIREN_number')}
              disabled={false}
              variant="outlined"
            />

            <div
              className="clientInfo"
              style={{
                marginTop: '16px',
              }}
            >
              <Typography
                fontWeight={500}
                sx={{
                  mb: '8px',
                }}
              >
                {t('Client Information')}
              </Typography>
              <FieldComponent
                style={{ width: '100%' }}
                name="publicName"
                control={control}
                required
                size="small"
                label={t('Add_Client_Client_Name')}
                placeholder={t('Add_Client_Enter_Public_Name')}
                disabled={false}
                variant="outlined"
              />
              <Grid container alignItems={'center'}>
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}
                  name="code"
                  control={control}
                  size="small"
                  label={t('Add_Client_Enter_Client_Code')}
                  placeholder={t('Add_Client_Enter_Client_Code')}
                  disabled={false}
                  variant="outlined"
                  required
                />
                <FieldComponent
                  control={control}
                  name="status"
                  label={t('Add_Client_Client_Status')}
                  style={{ width: 'calc(50% - 10px)' }}
                  select
                >
                  {customerStatus?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </FieldComponent>
              </Grid>
              <Grid container alignItems={'center'}>
                <FieldComponent
                  control={control}
                  name="_customerType"
                  label={t('Add_Client_Client_Group')}
                  style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}
                  select
                >
                  {clientCategoryDetail?.map(option => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </FieldComponent>
              </Grid>
            </div>

            <div
              className="contactInformation"
              style={{
                marginTop: '20px',
              }}
            >
              <Typography
                fontWeight={500}
                sx={{
                  mb: '12px',
                }}
              >
                {t('Add_Client_Contact_Information')}
              </Typography>
              <Typography
                fontWeight={500}
                fontSize={'1rem'}
                sx={{
                  mb: '16px',
                }}
              >
                Email List
              </Typography>
              <Grid container alignItems={'center'}>
                <FieldComponent
                  style={{
                    width: 'calc(100% - 60px)',
                  }}
                  name="email"
                  control={control}
                  size="small"
                  label={t('Add_Client_Enter_email_address')}
                  color={toggle ? 'primary' : 'secondary'}
                  value={newEmailAddress}
                  onChange={handleNewEmailAddressChange}
                  placeholder={t('Add_Client_Enter_email_address')}
                  disabled={false}
                  variant="outlined"
                />
                <PlusCircle
                  style={{
                    fontSize: '25px',
                    marginLeft: '19px',
                    marginTop: '-20px',
                    color: iconcolor ? '#2A59FF' : '#757575',
                  }}
                  onClick={() => {
                    handleAddEmailAddress();
                  }}
                  weight="fill"
                />
              </Grid>

              <table style={tableStyle}>
                <tbody>
                  {emailAddresses.map((email, index) => (
                    <tr
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      key={index}
                      style={{
                        backgroundColor:
                          hoveredIndex === index ? '#FAFAFA' : 'inherit',
                      }}
                    >
                      <td
                        style={{
                          width: '50%',
                          borderBottom: '0.2px solid #adaaaaef',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            sx={{
                              marginLeft: '4px',
                            }}
                          >
                            {email}
                          </Typography>
                          {hoveredIndex === index && (
                            <IconButton
                              style={{
                                marginRight: '2px',
                                padding: '2px',
                                marginLeft: '4px',
                              }}
                            >
                              <Tooltip
                                title={
                                  flagColors[email] === 'goldenrod'
                                    ? 'Remove main'
                                    : 'Make as Main'
                                }
                              >
                                <Flag
                                  onClick={() => handleFlagIconClick(email)}
                                  style={{
                                    color: flagColors[email] || 'inherit',
                                    fontSize: '15px',
                                  }}
                                />
                              </Tooltip>
                            </IconButton>
                          )}
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: 'right',
                          borderLeft: '0.2px solid #adaaaaef',
                          borderBottom: '0.2px solid #adaaaaef',
                          width: '50%',
                        }}
                      >
                        {hoveredIndex === index && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '4px',
                                fontFamily: 'Roboto',
                                fontSize: '12px',
                                color: '#7d7d7dee',
                              }}
                            >
                              <PaperPlaneTilt
                                size={12}
                                style={{ marginRight: '4px' }}
                              />
                              Invitation sent
                            </span>
                            <IconButton
                              onClick={() => handleDeleteEmailAddress(index)}
                              style={{ color: 'red', padding: '2px' }}
                            >
                              <Tooltip title="Delete">
                                <Trash
                                  size={12}
                                  style={{ marginRight: '4px' }}
                                />
                              </Tooltip>
                            </IconButton>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div
              className="contactsDetails"
              style={{
                marginTop: '20px',
              }}
            >
              <Typography
                fontWeight={500}
                sx={{
                  marginBottom: '16px',
                }}
              >
                Contact Details
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <PhoneInput
                  country="fr"
                  specialLabel={t('Add_Client_Mobile_Phone')}
                  onChange={(value: string) => {
                    setPhoneValue(value);
                  }}
                  containerStyle={{
                    width: '100%',
                    marginRight: '20px',
                  }}
                  inputStyle={{
                    width: '100%',
                    height: '37.13px',
                  }}
                  value={phoneValue}
                />
                <PhoneInput
                  country="fr"
                  specialLabel={t('Add_Client_Sec_Mobile_Phone')}
                  containerStyle={{
                    width: '100%',
                  }}
                  inputStyle={{
                    width: '100%',
                    height: '37.13px',
                  }}
                  onChange={(value: string) => {
                    setMobilePhone2Value(value);
                  }}
                  value={mobilePhone2Value}
                />
              </div>
              <Grid
                container
                alignItems="center"
                sx={{
                  marginTop: '16px',
                }}
              >
                <FieldComponent
                  style={{
                    width: 'calc(50% - 10px)',
                    marginRight: '20px',
                  }}
                  name="managerName"
                  control={control}
                  size="small"
                  label={t('Add_Client_Manager')}
                  placeholder={t('Add_Clients_Manager_Full_Name')}
                  disabled={false}
                  variant="outlined"
                />
                <FieldComponent
                  style={{
                    width: 'calc(50% - 10px)',
                  }}
                  name="managerName2"
                  control={control}
                  size="small"
                  label={t('Add_Client_Secondary_Manager')}
                  placeholder={t('Add_Client_Sec_Man_Full_Name')}
                  disabled={false}
                  variant="outlined"
                />
              </Grid>
              <Grid container alignItems="center">
                <FieldComponent
                  control={control}
                  name="_salesman"
                  label={t('Add_Client_Sales')}
                  defaultValue={customerDetail?._salesman?.id}
                  style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}
                  select
                >
                  {usersData?.docs?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.firstName} {option.lastName}
                    </MenuItem>
                  ))}
                </FieldComponent>
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)' }}
                  name="salePhone"
                  control={control}
                  size="small"
                  label={'Sales phone number'}
                  placeholder={'Enter sales phone number'}
                  disabled={false}
                  variant="outlined"
                />
              </Grid>
            </div>
            <div
              className="businessInfo"
              style={{
                marginTop: '24px',
              }}
            >
              <Typography
                fontWeight={500}
                sx={{
                  mb: '16px',
                }}
              >
                {t('Add_Client_Business_Information')}
              </Typography>
              <Grid container alignItems="center">
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}
                  name="legalName"
                  control={control}
                  required
                  size="small"
                  label={t('Add_Client_Brand_Name')}
                  placeholder={t('Add_Client_Enter_Legal_Name')}
                  disabled={false}
                  variant="outlined"
                />
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)' }}
                  name="vatNumber"
                  control={control}
                  size="small"
                  label={t('Add_Client_Intracommunity_VAT')}
                  placeholder={t('Add_Client_Enter_VAT_Number')}
                  disabled={false}
                  variant="outlined"
                />
              </Grid>
            </div>
            <div className="settings">
              <Typography
                fontWeight={500}
                sx={{
                  mb: '16px',
                }}
              >
                {t('Add_Client_Settings')}
              </Typography>
              <Box style={{ marginTop: '-10px' }}>
                <FormControlLabel
                  label={t('Add_Client_Show_prices_on_delivery_note')}
                  control={
                    <div style={{ marginLeft: '5px' }}>
                      <Switch
                        checked={switchStateValue}
                        onChange={handleChangeSwitch}
                        size="small"
                        color="primary"
                      />
                    </div>
                  }
                />
              </Box>
            </div>
          </Grid>

          <Grid
            item
            sx={{
              width: 'calc(50% - 20px)',
              background: 'white',
              border: '0.5px solid    #E0E0E0',
              marginLeft: '20px',
              height: 'fit-content',
              overflowY: 'hidden',
              borderRadius: '4px',
              paddingBottom: '17px',
              position: 'sticky',
              top: '0',
            }}
          >
            <ClientMap
              latitude={latitude}
              longitude={longitude}
              mapHeight={'500px'}
              mapWidth={'calc(100% - 17px)'}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
              title={t('Address')}
              setLocationValue={setLocationvalue}
              mainLocationValue={mainlocationValue}
              defaultFieldValue={customerDetail?.mainLocation}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

import { DocumentFile } from 'types';

export interface LeaveRequest {
  _id: string;
  createdAt: string;
  _leaveRequestType: LeaveRequestType;
  startDate: string;
  endDate: string;
  days: number;
  description: string;
  documentFile?: DocumentFile | null;
  status: LeaveRequestStatus;
}

export interface LeaveRequestType {
  _id: string;
  name: string;
  icon: string;
  color: string;
  secondaryColor: string;
  requireDocument?: boolean;
}

export enum LeaveRequestStatus {
  accepted = 'accepted',
  pending = 'pending',
  declined = 'declined',
  cancelled = 'cancelled',
}

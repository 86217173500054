import React, { useEffect, useRef, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { useOnScreen } from 'app/components/ProductCard/hook';
import { ChatContext } from 'app/components/Chat';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { MessageContainer, MessageOptionsMenuContainer } from '../ChatMessage';
import { MessageOptionsMenu } from '../MessageOptionsMenu';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface Props {
  messageId: string;
  type: 'sent' | 'received';
  similarToPrevMessage: boolean;
  content: string;
  contentType: string;
  createdAt: string;
  handleMessageActions: Function;
  isEdited: boolean;
}

export const LastChatMessage: React.FC<Props> = ({
  messageId,
  type,
  similarToPrevMessage,
  content,
  contentType,
  createdAt,
  handleMessageActions,
  isEdited,
}) => {
  const { t } = useTranslation();
  const { setIsLastMessageReached } = useContext(ChatContext);

  const formatTimestamp = timestamp => {
    const date = dayjs(timestamp);

    if (date.isToday()) {
      return date.format('HH:mm');
    } else if (date.isYesterday()) {
      return t('yesterday');
    } else {
      return date.format('D MMMM, YYYY');
    }
  };

  const lastMessageRef: any = useRef(null);
  const isOnScreen = useOnScreen(lastMessageRef);

  useEffect(() => {
    setIsLastMessageReached(isOnScreen);
  }, [isOnScreen]);

  const canEdit = useMemo(() => {
    if (contentType !== 'text' || type !== 'sent') return false;

    const allowedEditDurationInMinutes = 10;
    const editTimeUnix = allowedEditDurationInMinutes * 60 * 1000;

    const nowUnix = new Date().getTime();
    const createdAtUnix = new Date(createdAt).getTime();

    if (nowUnix - editTimeUnix > createdAtUnix) {
      return false;
    } else {
      return true;
    }
  }, [contentType, type, createdAt]);

  return (
    <MessageContainer
      ref={lastMessageRef}
      type={type}
      similarToPrevMessage={similarToPrevMessage}
      canEdit={canEdit}
      contentType={contentType}
    >
      {contentType === 'images' ? (
        <img src={content} />
      ) : (
        <>
          {content}
          {createdAt && (
            <div className="time">
              {isEdited && t('common.edited')} {formatTimestamp(createdAt)}
            </div>
          )}
        </>
      )}
      <MessageOptionsMenuContainer className="messageOptionsMenuContainer">
        <MessageOptionsMenu
          messageId={messageId}
          messageContent={content}
          createdAt={createdAt}
          contentType={contentType}
          messageType={type}
          handleMessageActions={handleMessageActions}
        />
      </MessageOptionsMenuContainer>
    </MessageContainer>
  );
};

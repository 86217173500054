import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase } from '@mui/material';
import { Icon } from 'app/components/Icon';

interface Props {
  icon: JSX.Element;
  size: number;
  onClick: () => void;
}

export const CustomIconButton: React.FC<Props> = ({
  icon,
  size = 24,
  onClick,
}) => {
  return (
    <>
      <StyledButtonBase size={size} onClick={onClick}>
        <Icon icon={icon} color={themes?.default?.iconColor} size={size} />
      </StyledButtonBase>
    </>
  );
};

const StyledButtonBase = styled(ButtonBase)<{ size: number }>`
  height: ${props => props.size + 8}px;
  width: ${props => props.size + 8}px;
  padding: 8px;
  border-radius: 20px !important;
  background: ${themes?.default?.accordionWhiteBg};
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.2s;

  &:hover {
    background: ${themes?.default?.backgroundSilver};
  }
`;

import { selectOrganization } from 'common/store/organization/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const usePreferredAgent = ({ user }) => {
  const authOrganization = useSelector(selectOrganization);

  const preferredAgent = useMemo(() => {
    return (
      user?.agents?.find(a => a._organization === authOrganization?._id) ||
      user?.cache?.agents[authOrganization?._id]
    );
  }, [user, authOrganization]);

  const fullNameGridFormat = () => {
    if (preferredAgent?.firstName && preferredAgent?.lastName) {
      return `${preferredAgent.lastName}, ${preferredAgent.firstName}`;
    }

    return user?.companyEmail || '';
  };

  const fullName = () => {
    if (preferredAgent?.firstName && preferredAgent?.lastName) {
      return `${preferredAgent.firstName} ${preferredAgent.lastName}`;
    }

    return user?.companyEmail || '';
  };

  return {
    preferredAgent: {
      ...preferredAgent,
      fullNameGridFormat: fullNameGridFormat(),
      fullName: fullName(),
    },
  };
};

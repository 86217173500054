import React from "react";
import styled from "styled-components";
import { themes } from "styles/theme/themes";
import { Icon } from "app/components/Icon";
import { ButtonBase } from "@mui/material";
import { Question } from "@phosphor-icons/react";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

interface ButtonProps {
  icon: JSX.Element | string;
  action: () => void;
  active: boolean;
  tooltip: string;
  disabled?: boolean;
};

export const MapActionButton: React.FC<ButtonProps> = ({
  icon,
  action,
  active,
  tooltip,
  disabled
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={tooltip || ""}
      arrow={true}
      placement={"left"} 
    >
      <MapActionBtnWrapper active={active} disabled={!!disabled}>
          <ButtonBase
            sx={{
              height: "28px",
              minWidth: "28px",
              borderRadius: "2.9px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transitionDuration: "0.15s"
            }}
            onClick={action}
            disabled={disabled}
          >
            {typeof(icon) === "string" ? (
              <Icon
                tooltipPosition="left"
                tooltipWithArrow={true}
                icon={<img draggable={false} src={icon} alt="isolate rt" />}
                size={20}
                color={themes?.default?.nobel}
              />
            ) : (
              <Icon
                tooltipPosition="left"
                tooltipWithArrow={true}
                icon={icon}
                size={20}
                color={themes?.default?.nobel}
              />
            )}
          </ButtonBase>
      </MapActionBtnWrapper>
    </Tooltip>
  )
};

const MapActionBtnWrapper = styled.div<{ active: boolean, disabled?: boolean }>`
  width: 32px;
  height: 32px;
  padding: 8px;
  gap: 5.23px;
  border-radius: 5.77px;
  border: 1.15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.active
    ? themes?.default?.solitude
    : props.disabled
      ? themes?.default?.greyBgInput
      : themes?.default?.accordionWhiteBg
  };
  border: ${props => props.active
    ? `1px solid ${themes?.default?.primary}`
    : `1.15px solid ${themes?.default?.whisper}`
  };
  outline: ${props => props.active
    ? `2px solid ${themes?.default?.primary}4D`
    : null
  };
  box-shadow: 0px 4px 4px 0px #00000033;
  transition-duration: 0.15s;

  &:hover {
    background: ${themes?.default?.lightBlueBg};
  }
`;
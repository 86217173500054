import api from './api';

export const chatApis = api.injectEndpoints({
  endpoints: builder => ({
    getAllChats: builder.query<
      any,
      { cursor: string; text: string; limit?: number; offset?: number }
    >({
      query: ({ cursor = '', text = '', limit = 15, offset = 0 }) => ({
        url: `/chat?text=${text}&cursor=${cursor}&limit=${limit}&offset=${offset}`,
      }),
      providesTags: ['Chats'],
    }),
    getChatMessages: builder.query<
      any,
      {
        messageId: string;
        chatId: string;
        cursor: any;
        text: string;
        limit?: number;
      }
    >({
      query: ({ messageId = '', chatId, cursor, text, limit }) => ({
        url: `/chat/messages?id=${messageId}&chatId=${chatId}&cursor=${cursor}&text=${text}&limit=${
          limit || ''
        }`,
      }),
      providesTags: ['Chats'],
    }),
    postStartChat: builder.mutation<any, any>({
      query: userId => ({
        url: `/chat/${userId.userId}`,
        method: 'POST',
      }),
    }),
    deleteChat: builder.mutation<any, any>({
      query: ({ id }: any) => {
        return {
          url: `/chat/${id}`,
          method: 'DELETE',
        };
      },
    }),
    togglePinChat: builder.mutation<any, any>({
      query: ({ id }: any) => {
        return {
          url: `/chat/${id}/toggle-pin`,
          method: 'PATCH',
        };
      },
    }),
    editChatMessage: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => {
        return {
          url: `/chat/${id}/edit-message`,
          method: 'PATCH',
          body,
        };
      },
    }),
    deleteChatMessage: builder.mutation<any, any>({
      query: ({ id }: any) => {
        return {
          url: `/chat/messages/${id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetAllChatsQuery,
  useLazyGetAllChatsQuery,
  useGetChatMessagesQuery,
  usePostStartChatMutation,
  useDeleteChatMutation,
  useTogglePinChatMutation,
  useEditChatMessageMutation,
  useDeleteChatMessageMutation,
} = chatApis;

export default chatApis;

import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'common/store/app';
import { BampteeApplications } from 'common/store/app';
import { selectCurrentApplication } from 'common/store/app/selectors';
import { Application } from 'common/store/app/types';
import { themeActions } from '../styles/theme/slice';

export const useApplication = (authUser?: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentApplication: Application = useSelector(selectCurrentApplication);

  useEffect(() => {
    // execute on location change
    const findApp = BampteeApplications.find(
      (app: any) => location.pathname.indexOf(app.url) > -1,
    );

    if (findApp) {
      dispatch(appActions.setCurrentApplication({ application: findApp }));
      // @ts-ignore
      dispatch(themeActions.changeTheme(findApp.id));
    }
  }, [location.pathname, dispatch]);

  return currentApplication;
};

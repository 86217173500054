import { IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import If from 'app/components/If';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';

interface FilterIconProps {
  handleOpenFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  filtersCount: number;
  filterOn: HTMLButtonElement | null;
  filterComponent: JSX.Element;
  showTitle?: boolean;
}

const FilterIcon = ({
  handleOpenFilter,
  disabled,
  filtersCount,
  filterOn,
  filterComponent,
  showTitle,
}: FilterIconProps) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  return (
    <FilterWrapper>
      <IconButtonWrapper>
        <IconButton
          onClick={handleOpenFilter}
          // disableRipple
          disabled={disabled}
          sx={{
            opacity: disabled && !filtersCount ? 0.3 : 1,
          }}
        >
          <FilterListIcon
            sx={{
              color:
                filterOn || (!!filtersCount && filtersCount >= 1)
                  ? theme?.primaryActiveColor
                  : theme?.textColorPrimary,
              height: themes?.default?.iconSize,
              width: themes?.default?.iconSize,
            }}
          />
          <If condition={!!showTitle}>
            <FilterLabel>{t('nav.table_nav.tab_filter')}</FilterLabel>
          </If>
          <CounterLabel
            style={{
              display: !!filtersCount && filtersCount >= 1 ? 'block' : 'none',
              padding: 0,
            }}
          >
            ({filtersCount})
          </CounterLabel>
        </IconButton>
      </IconButtonWrapper>
      {filterComponent}
    </FilterWrapper>
  );
};

export default FilterIcon;

const FilterLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.textColorPrimary};
  padding-left: 5px;
`;
const CounterLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${props => props.theme.primaryActiveColor};
`;
const FilterWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

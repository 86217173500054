import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { UserAvatar } from '../../../UserAvatar';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { getId } from 'common/helpers/document';
import { Icon } from 'app/components/Icon';
import { CaretLeft, Phone } from '@phosphor-icons/react';
import { ButtonBase, Divider } from '@mui/material';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { useTranslation } from 'react-i18next';
import { useModal } from 'app/components/Dialog/hooks';
import { useDeleteChatMutation } from 'common/services/chatApi';
import { ChatContext } from 'app/components/Chat';
import { CustomIconButton } from '../../../CustomIconButton';

interface Props {
  selectedUser: any;
  isCurrentUser: boolean;
  visibleElements: string;
}

export const ChatWindowTopBar: React.FC<Props> = ({
  selectedUser,
  isCurrentUser,
  visibleElements,
}) => {
  const authUser = useSelector(selectAuthUser);
  const theme = useSelector(selectTheme);
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const { closeModal, openModal } = useModal();
  const {
    selectedChatId,
    triggerRefetchChats,
    setSelectedUser,
    setSelectedChatId,
  } = useContext(ChatContext);

  const [deleteChat] = useDeleteChatMutation();

  const handleCofirmDeleteDialog = name => {
    openModal({
      action: {
        actionText: t('common.buttons.delete'),
        actionCallback: () => handleDeleteChat(),
      },
      title: t('chats.chat.delete.confirm'),
      deleteModal: true,
      cancel: true,
      content: t('chats.chat.delete.messsage', { name: name }),
    });
  };

  const handleDeleteChat = async () => {
    if (!selectedChatId) return;
    await deleteChat({ id: selectedChatId }).then(() => {
      triggerRefetchChats();
      closeModal();
    });
  };

  const handleCloseChat = () => {
    setSelectedUser('');
    setSelectedChatId('');
  };

  return (
    <Wrapper>
      <LeftSection window={visibleElements === 'window'}>
        {visibleElements === 'window' && (
          <CustomIconButton
            icon={<CaretLeft />}
            size={24}
            onClick={handleCloseChat}
          />
        )}
        <UserAvatar
          size={32}
          userImagePath={selectedUser?.pictureUrl}
          fullName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
        />
        {visibleElements === 'window' ? (
          <div style={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
            <p className="name">
              {`${selectedUser?.firstName} ${selectedUser?.lastName}`}
              {isCurrentUser && <span className="role">{`(${t('you')})`}</span>}
            </p>
            <p className="role">
              {selectedUser?._role?.localeName?.[`${locale}`]}
            </p>
          </div>
        ) : (
          <div style={{ display: 'flex', gap: '4px' }}>
            <p className="name">{`${selectedUser?.firstName} ${selectedUser?.lastName}`}</p>
            <p className="name">{`-`}</p>
            <p className="role">
              {selectedUser?._role?.localeName?.[`${locale}`]}
            </p>
            {isCurrentUser && <p className="role">{`(${t('you')})`}</p>}
          </div>
        )}
      </LeftSection>
      <RightSection>
        <ButtonBase
          sx={{
            width: '36px',
            height: '28px',
            borderRadius: '13px',
            background: `${theme.primary}14`,
          }}
        >
          <Icon
            icon={<Phone weight="fill" />}
            size={16}
            color={theme.primary}
          />
        </ButtonBase>
        <ButtonBase
          sx={{
            borderRadius: '50px',
          }}
        >
          {/* <Icon icon={<DotsThree />} size={24} />
           */}
          <ActionPopover
            id={selectedUser?._id}
            handleAction={async (e, action, id) => {}}
            styling={{ justifyContent: 'end' }}
            iconSize={24}
            actions={[
              {
                action: 'openProfile',
                onClick: () => {},
                element: (
                  <PopoverEl redStatus="" style={{ width: '100%' }}>
                    <div className="popover-item">{t('open_profile')}</div>
                  </PopoverEl>
                ),
              },
              {
                action: 'divider',
                seperator: true,
                element: (
                  <PopoverEl redStatus="" style={{ width: '100%' }}>
                    <Divider />
                  </PopoverEl>
                ),
              },
              {
                action: 'delete',
                onClick: () =>
                  handleCofirmDeleteDialog(
                    `${selectedUser?.firstName} ${selectedUser?.lastName}`,
                  ),
                element: (
                  <PopoverEl
                    redStatus={themes.default.redStatus}
                    style={{ width: '100%' }}
                  >
                    <div className="delete popover-item">{t('delete')}</div>
                  </PopoverEl>
                ),
              },
            ]}
          />
        </ButtonBase>
      </RightSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid ${themes?.default?.gainsboro2};
`;

const LeftSection = styled.div<{ window: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    margin: 0;
    font-family: Roboto;
    font-size: ${props => (props.window ? 0.92 : 1.14)}rem;
    line-height: ${props => (props.window ? 16 : 28)}px;
    letter-spacing: 0.15px;
  }
  .name {
    font-weight: 600;
  }
  .role {
    font-weight: 400;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

import { Stack } from '@mui/material';
import { InfoCard } from 'app/pages/AccessoryInfo/Components/AccessoryInfoCards/Components/InfoCard';
import { themes } from 'styles/theme/themes';
import { Chip } from '@mui/material';
import {
  CalendarBlank,
  User,
  PencilSimpleLine,
  Cube,
  ShoppingBagOpen,
  PaperPlaneTilt,
} from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StatusCustomer } from 'app/components/StatusCustomer';
import OrdoriaLogo from '../../../../../assets/img/Menu/Logo-Ordoria-lightmode2.svg';
import {
  CustomerInvitationWrapperActive,
  CustomerInvitationWrapperInactive,
  InvitationButton,
} from 'hooks/Customers/useFormatCustomersData';
import { PersonAddAlt1Outlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import InvitationSent from 'app/components/Customers/InvitationSent';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { isEmpty } from 'lodash';
import { useInviteUserMutation } from 'common/services/userApi';
import { useToaster } from 'hooks/useToaster';

export const CustomerInfoCard = ({ customerDetail, customerFinancials }) => {
  const [inviteUser] = useInviteUserMutation();
  const toast = useToaster();

  const oneMonthAgo = dayjs().subtract(1, 'month');

  async function inviteCustomer(customer) {
    try {
      if (customer.email) {
        const response = await inviteUser({
          customerId: customer._id,
          role: 'customer',
          email: customer?.email,
        }).unwrap();

        if (!isEmpty(response)) {
          toast(3000, 'success', 'customer_invited_successfully');
        }
      } else {
        toast(3000, 'error', 'customer.invitation.missingEmail');
      }
    } catch (e: any) {
      if (e.status === 400) {
        toast(3000, 'error', e.data?.message);
      }
    }
  }

  const { t } = useTranslation();
  return (
    <Stack direction={'row'} gap={'16px'} width="100%">
      <InfoCard
        title={customerDetail?.legalIdAlt}
        value={customerDetail?.legalName}
        icon={
          <Icon icon={<User />} color={themes.default?.primaryActiveColor} />
        }
        iconBg={themes.default?.primaryLight1}
      />
      <InfoCard
        title={t('customer_Added_on')}
        value={
          <div>
            {customerDetail
              ? window.dayjs(customerDetail?.createdAt).format('LL')
              : null}
          </div>
        }
        icon={
          <Icon
            icon={<CalendarBlank />}
            color={themes.default?.fruitSaladGreen}
          />
        }
        iconBg={themes.default?.teal50}
      />
      <InfoCard
        title={t('customer_Last_Edited_on')}
        value={
          <div>
            {customerDetail
              ? window.dayjs(customerDetail?.updatedAt).format('LL')
              : null}
          </div>
        }
        icon={
          <Icon icon={<PencilSimpleLine />} color={themes.default?.yellow800} />
        }
        iconBg={themes.default?.yellow100}
      />

      <InfoCard
        title={t('customer_Status')}
        value={<StatusCustomer status={customerDetail?.status} />}
        icon={<Icon icon={<Cube />} color={themes.default?.AcidGreen} />}
        iconBg={themes.default?.Chiffon}
      />
      <InfoCard
        title={t('customer_Active_Orders')}
        value={customerFinancials?.countActiveOrders}
        icon={
          <Icon
            icon={<ShoppingBagOpen />}
            color={themes.default?.TurquoiseSurf}
          />
        }
        iconBg={themes.default?.cyan50}
      />

      <InfoCard
        title={t('customer_Ordoria_Status')}
        value={
          <>
            {customerDetail?.status &&
              !customerDetail?._lastLoggedUser &&
              (!customerDetail?.invitedAt ||
                (customerDetail?.invitedAt &&
                  dayjs(customerDetail?.invitedAt).isBefore(oneMonthAgo))) && (
                <InvitationButton
                  variant="contained"
                  startIcon={
                    <PersonAddAlt1Outlined
                      sx={{ width: '12px', height: '12px' }}
                    />
                  }
                  onClick={() => {
                    inviteCustomer(customerDetail);
                  }}
                >
                  {t('customer.invite')}
                </InvitationButton>
              )}

            {customerDetail?.invitedAt &&
              dayjs(customerDetail?.invitedAt).isAfter(oneMonthAgo) &&
              !customerDetail?._lastLoggedUser && (
                <InvitationSent
                  id={customerDetail?._id}
                  invitedAt={window.dayjs(customerDetail.invitedAt).format('LLL')}
                />
              )}

            {customerDetail?.invitedAt &&
              dayjs(customerDetail?._lastLoggedUser).isBefore(
                dayjs().subtract(6, 'month'),
              ) && (
                <CustomerInvitationWrapperInactive>
                  <CancelIcon sx={{ width: '12px', height: '12px' }} />
                  <div>{t('inactive')}</div>
                </CustomerInvitationWrapperInactive>
              )}
            {customerDetail?.invitedAt && customerDetail?._lastLoggedUser && (
              <CustomerInvitationWrapperActive>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <div>{t('active')}</div>
              </CustomerInvitationWrapperActive>
            )}
          </>
        }
        icon={
          <Icon
            icon={
              <img
                src={OrdoriaLogo}
                aria-label="Ordoria"
                style={{ width: '18px', height: '18px' }}
              />
            }
            color={themes.default?.cyan600}
          />
        }
        iconBg={themes.default?.lightgraymagenta}
      />
    </Stack>
  );
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase } from '@mui/material';
import { UserAvatar } from 'app/components/Chat/components/UserAvatar';
import { StateSetter } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  contact: any;
  setSelectedUser: StateSetter<string>;
  isCurrentUser: boolean;
}

export const ContactCard: React.FC<Props> = ({
  contact,
  setSelectedUser,
  isCurrentUser,
}) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  return (
    <ButtonBase
      onClick={() => setSelectedUser(contact)}
      sx={{ borderRadius: '4px' }}
    >
      <ContactCardWrapper>
        <UserAvatar
          size={32}
          userImagePath={contact?.pictureUrl}
          fullName={contact?.fullName}
          status={contact?.onlineStatus}
        />
        <UserInfo>
          <p className="name">{contact?.fullName}</p>
          <p className="role">
            {locale === 'fr'
              ? contact?.currentAgent?._role?.localeName?.fr
              : contact?.currentAgent?._role?.localeName?.en}
          </p>
        </UserInfo>
      </ContactCardWrapper>
    </ButtonBase>
  );
};

const ContactCardWrapper = styled.div`
  width: 100%;
  height: 63px;
  padding: 12px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid ${themes?.default?.gainsboro2};
  display: flex;
  gap: 8px;
  transition: background 0.2s;
  cursor: pointer;

  &:hover {
    background: ${themes?.default?.backgroundSilver};
  }
`;

const UserInfo = styled.div`
  flex: 1;
  color: ${themes?.default?.textColorPrimary};
  line-height: 15.23px;
  p {
    margin: 0;
    text-align: left;
    font-size: 0.92rem;
  }
  .name {
    font-weight: 700;
  }
`;

import { Button, Chip, Grid, Tab, Tabs, Typography } from '@mui/material';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'common/hooks/router';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { useUpdateOrderMutation } from 'common/services/orderApi';

import { useLazyGetOrdersIdQuery } from 'common/services/orderApi';

import { useLazyGetCustomerPublicOrderQuery } from 'common/services/customerApi';
import { selectAuthUser } from 'common/store/auth/selectors';
import { OrderDocs } from 'common/types/Order';
import { useToaster } from '../../../hooks/useToaster';
import { StatusOrder } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import MapPopover from '../MapPopover';
import { themes } from 'styles/theme/themes';
import OrderInformationTab from './tabs/OrderInformation';
import OrderPriority from '../OrderPriority';
import StopIcon from '../StopIcon';
import { NestedMenuComponent } from '../NestedMenu';
import { PopoverEl } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { PopoverText } from 'hooks/Orders/useFormatOrderData';
import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import AssignRoundtrip from 'app/pages/Orders/components/AssignRoundtrip';
import { DeliveryInformationTab } from 'app/pages/Ordoria/Orders/components/OrderDetailsDrawer/DeliveryInformationTab';
import { TrackOrderTab } from 'app/pages/Ordoria/Orders/components/OrderDetailsDrawer/TrackOrderTab';
import { OrderNotesTab } from 'app/pages/Ordoria/Orders/components/OrderDetailsDrawer/OrderNotesTab';
import { getAddressLabel, getOrderStatusesToShow } from 'app/helpers/helpers';
import { selectConfiguration } from 'common/store/organization/selectors';
import { formatDeliveryHours } from 'common/helpers/place';
import { useModal } from '../Dialog/hooks';
import { checkUserAcl } from 'common/helpers/acl';
import { AddressIcon } from '../AddressIcon';
import { usePrintV2 } from 'hooks/RoundTrips/usePrint';
import { toQueryParams } from 'hooks/useQueryParams';
import {
  ArrowLeft,
  CaretLeft,
  CaretRight,
  DotsThree,
} from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { Checks, Check, X, RoadHorizon } from '@phosphor-icons/react';
import { ChangeOrderButton } from './components/ChangeOrderButton';
import { OrderDrawerContext_rt } from 'app/pages/AddRoundTrips';
import { OrderDrawerContext_orders } from 'app/pages/Orders';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import CarbonFootprint from 'assets/img/Orders/CarbonFootprint.svg';

interface OrderDetailProps {
  order: OrderDocs | null;
  onCloseDrawer: () => void;
  isCustomer: boolean;
  onUpdate?: () => void;
  changeStatus?: (status: string) => void;
  triggerRoundtripDetails?: Function;
  viewMode?: boolean;
  defaultTab?: number;
  handleDisplayMap?: (roundtripId: string) => void;
}

export function OrderDetail({
  order,
  onCloseDrawer,
  isCustomer,
  onUpdate,
  changeStatus,
  triggerRoundtripDetails,
  viewMode,
  defaultTab,
  handleDisplayMap,
}: OrderDetailProps) {
  const theme = useSelector(selectTheme);
  const toast = useToaster();
  const { t, i18n } = useTranslation();
  const [amountRequested, setAmountRequested] = useState(0);
  const { token } = useParams();
  const authUser = useSelector(selectAuthUser);
  const configuration = useSelector(selectConfiguration);
  const urlQuery = useQuery();
  const orderStatusesList = getOrderStatusesToShow(
    configuration?.orderStatusLogistic || [],
  );

  const [openMap, setOpenMap] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<any>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(defaultTab || 0);

  const [triggerGetOrder, { data: fullOrder, isLoading, isFetching }] =
    useLazyGetOrdersIdQuery();
  const [
    triggerGetOrderCustomer,
    { data: fullOrderCustomer, isLoading: isLoadingCustomer },
  ] = useLazyGetCustomerPublicOrderQuery();
  const [updateOrder] = useUpdateOrderMutation();

  useEffect(() => {
    if (order?.id && !isEmpty(order?.id)) {
      // @ts-ignore
      if (!isCustomer && authUser) {
        triggerGetOrder(order?.id);
      } else {
        const password = urlQuery.get('password');

        triggerGetOrderCustomer({
          orderId: order?.id,
          token,
          password,
        });
      }
    }
  }, [order?.id]);

  const [deliveredAt, setDeliveredAt] = useState('');

  useEffect(() => {
    if (!fullOrder?.updatedStatusAt) return;
    if (
      fullOrder?.status?.toLowerCase() === 'delivered' ||
      fullOrder?.status?.toLowerCase() === 'canceled'
    ) {
      const date = new Date(fullOrder?.updatedStatusAt);
      const time = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
      var formattedTime;
      if (time) {
        const splittedTime = time.split(' ');
        formattedTime = `${splittedTime[0]}${splittedTime[1]?.toLowerCase()}`;
      }
      setDeliveredAt(formattedTime || '');
    }
  }, [fullOrder]);

  useEffect(() => {
    if (fullOrder) {
      setAmountRequested(fullOrder?.amountRequested || 0);
    } else if (fullOrderCustomer) {
      setAmountRequested(fullOrderCustomer?.amountRequested || 0);
    }
  }, [fullOrder, fullOrderCustomer]);

  const location = isCustomer
    ? fullOrderCustomer?._deck?.location
    : fullOrder?._deck?.location;

  const { triggerPrint } = usePrintV2();

  function printDeliveryNote() {
    const orderIds = [order?.id];
    const query = toQueryParams({
      format: 'pdf',
      orderIds: orderIds.join(','),
    });
    triggerPrint({ uri: `orders/delivery-notes${query}` });
  }

  const handlePaymentCollectionChange = async newValue => {
    if (authUser && order) {
      try {
        const resp = await updateOrder({
          id: order._id,
          amountRequested: newValue,
        }).unwrap();

        if (resp) {
          toast(
            5000,
            'success',
            'clients.orderDetails.paymentCollectionSuccess',
          );

          if (typeof onUpdate === 'function') {
            onUpdate();
          }

          triggerGetOrder(order?._id);
        }
      } catch (e: any) {
        if (e.status === 401) {
          console.log(e);
        }
      }
    }
  };

  let googleMapUrl = 'https://www.google.com/maps/search/?api=1&query=France';

  if (location?.geometry) {
    const positionCoordinates = location.geometry.coordinates;
    const [longitude, latitude] = positionCoordinates;
    googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  }

  useEffect(() => {
    if (fullOrder) {
      const location = fullOrder?._deck?.location;
      setCoordinates(location?.geometry?.coordinates);
    }
  }, [fullOrder]);

  const { openModal, closeModal } = useModal();

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const locale = i18n?.language ?? 'en';

  let statusActions: Array<{ action: string; element: JSX.Element }> = [];

  orderStatusesList.map(status => {
    statusActions.push({
      action: status.status,
      element: (
        <>
          <ItemStatusComponent
            action={async () => {
              changeStatus && (await changeStatus(status.status));
              order?._id && triggerGetOrder(order._id);
              fullOrder?.roundtrip?._id &&
                triggerRoundtripDetails &&
                triggerRoundtripDetails({
                  roundtripId: fullOrder.roundtrip._id,
                });
            }}
            label={t(`orders.status.${status.label || status.status}`)}
            classNameCircle={status.classNameCircle}
            color={status.color}
          />
        </>
      ),
    });
  });

  const contextValue_rt = useContext(OrderDrawerContext_rt);
  const contextValue_orders = useContext(OrderDrawerContext_orders);

  var switchOrder, disableNext, disablePrev;

  if (contextValue_rt) {
    ({ switchOrder, disableNext, disablePrev } = contextValue_rt);
  } else if (contextValue_orders) {
    ({ switchOrder, disableNext, disablePrev } = contextValue_orders);
  } else {
    switchOrder = () => {};
    disableNext = true;
    disablePrev = true;
  }

  function PrevOrder() {
    if (contextValue_rt)
      switchOrder('prev', order?._id, fullOrder?.roundtrip._id);
    else if (contextValue_orders) switchOrder('prev', order?._id);
    else return;
  }

  function NextOrder() {
    if (contextValue_rt)
      switchOrder('next', order?._id, fullOrder?.roundtrip._id);
    else if (contextValue_orders) switchOrder('next', order?._id);
    else return;
  }

  function handleOpenMap() {
    handleDisplayMap && handleDisplayMap(fullOrder?.roundtrip?._id);
  }

  if (!order) {
    return null;
  }

  return (
    <>
      <Wrapper currentTabIndex={currentTabIndex}>
        <HeaderWrapper>
          <ArrowWrapper>
            <ArrowLeft
              size={18}
              color={themes?.default?.iconColor}
              onClick={onCloseDrawer}
              style={{ cursor: 'pointer', marginTop: '1px' }}
            />
          </ArrowWrapper>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            alignItems="start"
          >
            <Grid item xs={12} md={8} alignItems="end">
              <LeftHeaderWrapperWithPadding>
                {fullOrder?.roundtrip?.code && (
                  <RoundtripCodeContainer onClick={handleOpenMap}>
                    <Icon
                      icon={<RoadHorizon />}
                      color={themes?.default?.primary}
                      size={12}
                    />
                    <RoundtripCode>{fullOrder?.roundtrip?.code}</RoundtripCode>
                  </RoundtripCodeContainer>
                )}
                <Typography
                  variant="h5"
                  fontSize="1rem"
                  lineHeight="0.8"
                  fontWeight="700"
                >
                  {order?._customer?.code} - {order?._customer?.publicName}
                </Typography>
                <span>
                  <StopIcon
                    type={order.type || ''}
                    orderCategory={order?.category}
                    tooltipMessage={t(
                      `common.${order?.type}` ||
                        'roundtrips.tooltip.collection',
                    )}
                  />
                </span>
                <OrderPriority priority={order?.priority} />
                {(fullOrder?.status === 'delivered' ||
                  order?.status === 'delivered') && (
                  <span style={{ marginLeft: 8 }}>
                    {fullOrder?.paymentCollection >=
                    fullOrder?.amountRequested ? (
                      <Icon
                        icon={<Checks />}
                        tooltip={t('tooltip.payment_fully_collected')}
                        color={themes?.default?.fruitSaladGreen}
                      />
                    ) : fullOrder?.paymentCollection <
                        fullOrder?.amountRequested &&
                      fullOrder?.paymentCollection !== 0 ? (
                      <Icon
                        icon={<Check />}
                        tooltip={t('tooltip.payment_partially_collected')}
                        color={themes?.default?.fruitSaladGreen}
                      />
                    ) : (
                      <Icon
                        icon={<X />}
                        tooltip={t('tooltip.payment_not_collected')}
                        color={themes?.default?.redA700}
                      />
                    )}
                  </span>
                )}
                {fullOrder?.roundtrip?.code && fullOrder?.carbonEmission && (
                  <CarbonFootprintChip>
                    <Icon
                      icon={<img src={CarbonFootprint} alt="" />}
                      size={16}
                    />
                    <p>{fullOrder.carbonEmission.toFixed(2)}Kg</p>
                  </CarbonFootprintChip>
                )}
                <div style={{ marginLeft: 2, marginRight: 2 }}>
                  <ManagedTooltip
                    title={order.cancelReason}
                    disabled={
                      order?.status !== 'canceled' || !order.cancelReason
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <StatusOrder
                        status={fullOrder?.status || order?.status}
                        context={token ? 'ordoria' : 'bianta'}
                        deliveredAt={
                          order?.status === 'delivered' ||
                          order?.status === 'canceled'
                            ? deliveredAt
                            : ''
                        }
                        deliveryDistance={
                          fullOrder?.deliveryInfo?.distance ||
                          order?.deliveryInfo?.distance
                        }
                      />
                    </div>
                  </ManagedTooltip>
                </div>
              </LeftHeaderWrapperWithPadding>
              <LeftHeaderWrapper>
                <Typography
                  fontSize="0.813rem"
                  fontWeight="500"
                  sx={{
                    marginLeft: '3px',
                    marginTop: '4px',
                    color: theme.textColorSecondary,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <AddressIcon
                    onClick={() => {
                      setOpenMap(true);
                    }}
                  />
                  {location?.name
                    ? `${fullOrder?._deck?.code || ''} - ${getAddressLabel(
                        location,
                      )?.toUpperCase()}`
                    : t('clients.orderDetails.noDeliveryAddress').toUpperCase()}
                </Typography>
                <Chip
                  label={
                    <span style={{ paddingTop: '2px' }}>
                      {formatDeliveryHours(fullOrder?._deck?.deliveryHours)}
                    </span>
                  }
                  size="small"
                  sx={{
                    marginLeft: 2,
                    backgroundColor: themes?.default?.accordionWhiteBg,
                    border: `solid 1px ${themes?.default?.greyDisabled}`,
                  }}
                />
              </LeftHeaderWrapper>
            </Grid>
            <Grid item xs={12} md={4}>
              <RightHeaderWrapperWithPadding>
                <Typography
                  fontWeight="400"
                  fontSize="1rem"
                  sx={{
                    paddingTop: '2px',
                    paddingRight: 1,
                    color: themes?.default?.darkGray,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('clients.orderDetails.order')} #
                </Typography>
                <Typography
                  variant="h5"
                  fontSize="1.25rem"
                  fontWeight="700"
                  sx={{ lineHeight: '1em' }}
                >
                  {order.code}
                </Typography>
                <TopActionsWrapper>
                  <ChangeOrderButton
                    icon={<CaretLeft />}
                    action={PrevOrder}
                    disabled={disablePrev}
                  />
                  <ChangeOrderButton
                    icon={<CaretRight />}
                    action={NextOrder}
                    disabled={disableNext}
                  />
                  {!!changeStatus &&
                    checkUserAcl(authUser, [
                      'adminOrders',
                      'manageRoundtripsCluster',
                    ]) && (
                      <NestedMenuComponent
                        leftPosition={true}
                        id={order?.id}
                        transformOriginHorizontal={'left'}
                        anchorOriginHorizontal={'right'}
                        iconAction={
                          <Icon
                            icon={<DotsThree />}
                            color={themes?.default?.iconColor}
                            size={20}
                          />
                        }
                        actions={[
                          {
                            action: 'assign_roundtrip',
                            nested: false,
                            actions: [
                              { elements: <></>, action: 'assign_roundtrip' },
                            ],
                            element: (
                              <PopoverText>
                                <PopoverEl>
                                  <div
                                    style={{
                                      marginLeft:
                                        themes.default?.popoverLabelPadding,
                                      paddingTop: '5px',
                                    }}
                                    onClick={() => {
                                      openModal({
                                        title: t('assign_order_code_to', {
                                          code: order.code || '',
                                        }),
                                        content: (
                                          <AssignRoundtrip
                                            orderIds={[order.id]}
                                            selectedOrders={[
                                              {
                                                id: order.id,
                                                placeId: order._deck?._id,
                                                type: order.type,
                                              },
                                            ]}
                                            atAssign={() => {
                                              closeModal();
                                              toast(
                                                3000,
                                                'success',
                                                'order.roundtrip.assign',
                                              );
                                            }}
                                          />
                                        ),
                                      });
                                    }}
                                  >
                                    {t('orders.add_to_roundtrip')}
                                  </div>
                                </PopoverEl>
                              </PopoverText>
                            ),
                          },
                          {
                            action: 'Change_Status',
                            nested: true,
                            actions: statusActions,
                            element: (
                              <PopoverText>
                                <PopoverEl>
                                  <div
                                    style={{
                                      marginLeft:
                                        themes.default.popoverLabelPadding,
                                      paddingTop: '5px',
                                    }}
                                  >
                                    {t('common.change_status')}
                                  </div>
                                </PopoverEl>
                              </PopoverText>
                            ),
                          },
                          {
                            action: 'Print_Delivery',
                            onClick: printDeliveryNote,
                            element: (
                              <PopoverText>
                                <PopoverEl>
                                  <div
                                    style={{
                                      marginLeft:
                                        themes.default.popoverLabelPadding,
                                      paddingTop: '5px',
                                    }}
                                  >
                                    {t('print.delivery-note-button')}
                                  </div>
                                </PopoverEl>
                              </PopoverText>
                            ),
                          },
                        ]}
                      />
                    )}
                </TopActionsWrapper>
              </RightHeaderWrapperWithPadding>
              {/* <RightHeaderWrapper>
                <Typography
                  fontSize="0.75rem"
                  fontWeight="400"
                  color={themes?.default?.textColorSecondary}
                >
                  {t('clients.orderDetails.createdAt')}:{' '}
                  {window.dayjs(order.createdAt).locale(locale).format('LLL')}
                  {!isEmpty(order._createdBy) &&
                    !isUndefined(order._createdBy.fullName) &&
                    ` ${t('common.by').toLowerCase()} ${
                      order._createdBy.fullName
                    }`}
                </Typography>
              </RightHeaderWrapper> */}
            </Grid>
          </Grid>
        </HeaderWrapper>

        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          sx={{
            mt: '10px',
            marginRight: '28px',
            borderBottom: `1px solid ${themes.default.lightFill}`,
          }}
          TabIndicatorProps={{
            style: { backgroundColor: 'blue', height: '2px', zIndex: 100 },
          }}
        >
          <Tab label={t('order_information')} />
          <Tab label={t('delivery_information')} />
          <Tab label={t('track_order')} />
          <Tab label={t('order_notes')} />
        </Tabs>

        {currentTabIndex === 0 && (
          <OrderInformationTab
            isCustomer={isCustomer}
            fullOrder={fullOrder}
            fullOrderCustomer={fullOrderCustomer}
            isFetching={isLoading || isLoadingCustomer || isFetching}
            order={!isCustomer && authUser ? fullOrder : fullOrderCustomer}
            handlePaymentCollectionChange={handlePaymentCollectionChange}
            setAmountRequested={setAmountRequested}
            amountRequested={amountRequested}
            triggerGetOrder={
              !isCustomer && authUser
                ? triggerGetOrder
                : triggerGetOrderCustomer
            }
            viewMode={viewMode ?? false}
          />
        )}

        {currentTabIndex === 1 && <DeliveryInformationTab order={fullOrder} />}
        {currentTabIndex === 2 && (
          <TrackOrderTab orderId={order?._id} orderStatus={fullOrder.status} />
        )}
        {currentTabIndex === 3 && <OrderNotesTab orderId={fullOrder?._id} />}
      </Wrapper>
      <FooterWrapper>
        <ButtonWrapper>
          <Button
            variant="contained"
            onClick={() => {
              onCloseDrawer();
            }}
          >
            {t('common.buttons.done')}
          </Button>
        </ButtonWrapper>
      </FooterWrapper>

      {coordinates && (
        <MapPopover
          open={openMap}
          handleClose={() => {
            setOpenMap(false);
          }}
          latitude={coordinates[1]}
          longitude={coordinates[0]}
          driverLat={
            fullOrder?.roundtrip?._vehicle?.lastPosition?.geometry
              ?.coordinates?.[1]
          }
          driverLong={
            fullOrder?.roundtrip?._vehicle?.lastPosition?.geometry
              ?.coordinates?.[0]
          }
        />
      )}
    </>
  );
}

export const ArrowWrapper = styled.div`
  padding-right: 10px;
`;

interface WrapperProps {
  currentTabIndex?: number;
}

export const Wrapper = styled.div<WrapperProps>`
  padding: 20px 0 92px 28px;
  max-height: 100vh;
  overflow: hidden;
  width: ${props => (props.currentTabIndex === 0 ? '1350px' : '950px')};
  min-width: 40vw;
  max-width: 90vw;
  transition: width 0.5s;

  @media (max-width: 1000px) {
    padding: 20px 0 32px 20px;
  }
  @media (max-width: 700px) {
    overflow: auto;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: start;
`;

export const LeftHeaderWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const LeftHeaderWrapperWithPadding = styled(LeftHeaderWrapper)`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RightHeaderWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    justify-content: flex-start;
    margin-bottom: 24px;
  }
`;

const RightHeaderWrapperWithPadding = styled(RightHeaderWrapper)`
  padding-bottom: 10px;
  padding-right: 28px;
  @media (max-width: 1000px) {
    margin-left: -28px;
  }
`;

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 84px;
  background-color: ${themes?.default?.accordionWhiteBg};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${themes?.default?.lightFill};
  /* @media (max-width: 1000px) {
    display: none;
  } */
`;

export const ButtonWrapper = styled.div`
  margin-right: 51px;
`;

const TopActionsWrapper = styled.div`
  padding: 0 0 0 12px;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const RoundtripCodeContainer = styled.span`
  height: 19.13px;
  padding: 3.07px 6.83px;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 3px;
  border: 0.5px solid ${themes?.default?.gainsboro2};
  cursor: pointer;
`;

const RoundtripCode = styled.span`
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.3526666760444641px;
  color: ${themes?.default?.black}99;
`;

const CarbonFootprintChip = styled.div`
  height: 16px;
  min-width: 50px;
  padding: 1px 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: ${themes?.default?.lightBlueBg};

  p {
    margin: 0;
    padding: 2px 0 0 0;
    font-size: 0.5625rem;
    font-weight: 500;
    letter-spacing: 0.15px;
  }
`;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Navigate, Route, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import DrawIcon from '@mui/icons-material/Draw';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useQuery } from 'common/hooks/router';
import {
  useActivateCustomerSepaMutation,
  useResetCustomerSepaMutation,
  useGetCustomerPublicDetailsQuery,
  useLazyGetCustomerSepaStatusQuery,
} from 'common/services/customerApi';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import { OrdersList } from 'app/components/OrdersList';
import React from 'react';
import { InfosList } from 'app/components/InfosList';
import { DocList } from 'app/components/DocList';
import { selectAuthUser } from '../../slices/auth/selectors';
import { ContactForm } from './components/ContactForm';
import { makeStyles } from '@material-ui/core/styles';
import { usePatchCustomerPublicMutation } from 'common/services/customerApi';
import { Complaint } from 'app/components/Complaint';
import { checkUserAcl } from '../../../common/helpers/acl';
import { SimpleToolbar } from '../../components/SimpleToolbar';
import TermsModal from '../../components/TermsModal';
import {
  selectConfiguration,
  selectConnectors,
  selectOrganizationActivities,
} from 'common/store/organization/selectors';
import { SIGNIN } from '../../../utils/routes';
import { UploadFiles } from 'app/components/UploadFiles';
import If from 'app/components/If';
import { useToaster } from 'hooks/useToaster';

export function CustomerPublic({ defaultToken, defaultPassword }) {
  const { t } = useTranslation();
  const { token: urlToken } = useParams();
  const urlQuery = useQuery();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalPaiement, setOpenModalPaiement] = useState<boolean>(false);
  const [openResetModal, setOpenResetModal] = useState<boolean>(false);

  const useStyles = makeStyles({
    customDialog: {
      width: '110vh',
    },
  });

  const token = defaultToken || urlToken;
  const password =
    !urlQuery.get('password') || urlQuery.get('password') === 'undefined'
      ? 'fedipat'
      : urlQuery.get('password');

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [updateCustomer] = usePatchCustomerPublicMutation();

  const authUser: any = useSelector(selectAuthUser);
  const activities: any = useSelector(selectOrganizationActivities);
  const configuration: any = useSelector(selectConfiguration);

  // TODO: remove before release client
  const displayAllTabs = checkUserAcl(authUser, ['manageCustomers']);

  const paymentLimit = configuration?.paymentLimit || 1000;

  const [modalContactIsVisible, setModalContactIsVisible] = useState(false);

  const connectors = useSelector(selectConnectors);

  const { data: customer, isLoading, refetch: refetchCustomer } = useGetCustomerPublicDetailsQuery({ token, password }, {
    skip: isEmpty(token) || isEmpty(password),
  });

  const [
    getSepaStatus,
    { data: sepaStatus, isFetching: isFetchingSepaStatus },
  ] = useLazyGetCustomerSepaStatusQuery();

  const [activateSepa] = useActivateCustomerSepaMutation();
  const [resetSepa] = useResetCustomerSepaMutation();

  const paynumConnector = connectors?.find(connector => connector.type === 'paynum' && connector.enable);
  const sepaEnable = paynumConnector && authUser?.companyEmail === 'comptabilite.fournisseur@fedipat.com';
  const paymentEnable = paynumConnector?.config?.paymentEnable && customer?.code === 'C1003268';

  ///Set the CGV to today Date
  const handleCGV = async () => {
    await updateCustomer({
      token,
      password,
      termsAcceptedAt: new Date(),
    }).unwrap();

    refetchCustomer()
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseModalPaiement = () => setOpenModalPaiement(false);

  const classes = useStyles();

  const handleActiveSepa = async () => {
    if (sepaStatus?.activeMandate) {
      // Open confirmation modal
      setOpenResetModal(true);
    } else {
      const c = await activateSepa({
        okUrl: `${window.location.href}?sepa=ok`,
        koUrl: `${window.location.href}?sepa=ko`,
      }).unwrap();

      window.open(sepaStatus.signUrl, '_blank');

      setOpenModalPaiement(false);
    }
  };

  const handleResetSepa = async () => {
    const c = await resetSepa({
      okUrl: `${window.location.href}?sepa=ok`,
      koUrl: `${window.location.href}?sepa=ko`,
    }).unwrap();

    window.open(sepaStatus.signUrl, '_blank');

    setOpenResetModal(false);
    setOpenModalPaiement(false);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const toast = useToaster();

  useEffect(() => {
    if (urlQuery?.get('sepa') === 'ok') {
      toast(3000, 'success', 'customer.sepa.activation_success');
      getSepaStatus('');
    } else if (urlQuery?.get('sepa') === 'ko') {
      toast(3000, 'error', 'customer.sepa.activation_error');
    } else if (urlQuery?.get('payment') === 'ko') {
      toast(3000, 'error', 'customer.payment.payment_error');
    } else if (urlQuery?.get('payment') === 'ok') {
      setCurrentTabIndex(displayAllTabs ? 2 : 1);
      toast(3000, 'success', 'customer.payment.payment_success');
    }

    setSearchParams({});
  }, []);

  useEffect(() => {
    getSepaStatus('');
  }, [token]);

  if (!token || (!customer && !isLoading)) {
    return <div></div>;
  }

  if (isLoading || !customer) {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  const isCustomer = authUser?.currentAgent._customer;

  const displayTermModal =
    isCustomer &&
    (!customer?.termsAcceptedAt ||
      (customer?.termsAcceptedAt &&
        customer.termsAcceptedAt < customer._organization.termsUpdatedAt));

  if (!authUser) {
    return <Navigate to={SIGNIN} />;
  }

  return (
    <MainWrapper>
      <SimpleToolbar
        title={customer?.fullName}
        left={
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              height: '40px',
              minWidth: '400px',
              ml: 2,
            }}
          >
            <Tab label="Profil" />
            {displayAllTabs && <Tab label={t('nav.mainNav.orders')} />}
            <Tab label="Documents" />
            <Tab
              label={
                isCustomer && activities.countNewConversations > 0 ? (
                  <div style={{ display: 'flex' }}>
                    <LabelWrapper>{t('complaint.title')}</LabelWrapper>
                    <Badge
                      badgeContent={activities.countNewConversations}
                      color="primary"
                    />
                  </div>
                ) : (
                  t('complaint.title')
                )
              }
            />
          </Tabs>
        }
        right={
          <Grid
            container
            alignItems="center"
            gap="12px"
            sx={{
              mr: 2,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              Justificatifs d'Immatriculation
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModalPaiement(true)}
            >
              Moyen de Paiement
            </Button>
          </Grid>
        }
      />

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth={'sm'}
        classes={{ paper: classes.customDialog }}
      >
        <DialogContent>
          <UploadFiles
            customer={customer}
            paymentType={customer.defaultPaymentType}
            tokenPassword={password}
            type="id"
            onUploadSuccess={() => {
              setOpenModal(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModalPaiement}
        onClose={handleCloseModalPaiement}
        maxWidth={'sm'}
        classes={{ paper: classes.customDialog }}
      >
        <DialogContent>
          <If condition={sepaEnable && !isFetchingSepaStatus}>
            <WrapperSepa>
              <Button
                variant="contained"
                onClick={handleActiveSepa}
                startIcon={<DrawIcon />}
                sx={{
                  width: '300px',
                }}
              >
                {sepaStatus?.activeMandate
                  ? 'Modifier le mandat SEPA'
                  : 'Signer mon mandat SEPA'}
              </Button>

              <Or />
            </WrapperSepa>
          </If>

          <UploadFiles
            customer={customer}
            paymentType={customer.defaultPaymentType}
            tokenPassword={password}
            type="payment"
            onUploadSuccess={() => {
              setOpenModal(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openResetModal}
        maxWidth="xs"
        onClose={() => setOpenResetModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Avertissement modification de mandat !
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            La modification du mandat SEPA entrainera la révocation du mandat en cours et la signature d'un nouveau.
            Si vous n'allez pas au bout du processus vos prélèvements ne seront plus effectifs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResetModal(false)}>Annuler</Button>
          <Button onClick={handleResetSepa} autoFocus variant="contained">
            Modifier le mandat
          </Button>
        </DialogActions>
      </Dialog>

      <Wrapper>
        {!displayTermModal ? (
          <>
            {currentTabIndex == 0 && (
              <InfosList
                customer={customer}
                token={token}
                password={password}
              />
            )}
            {displayAllTabs && currentTabIndex == 1 && (
              <OrdersList token={token} password={password} />
            )}
            {(displayAllTabs && currentTabIndex == 2) ||
            (!displayAllTabs && currentTabIndex === 1) ? (
              <DocList
                authUser={authUser}
                token={token} password={password}
                paymentEnable={paymentEnable}
                paymentLimit={paymentLimit}
              />
            ) : null}
            {(displayAllTabs && currentTabIndex == 3) ||
            (!displayAllTabs && currentTabIndex === 2) ? (
              <Complaint
                token={token}
                password={password}
                customer={customer}
                authUser={authUser}
                onNewMessage={() => setModalContactIsVisible(true)}
              />
            ) : null}
          </>
        ) : null}
      </Wrapper>

      <Dialog open={modalContactIsVisible}>
        <DialogTitle>
          {t('customerPublic.contactForm.title', {
            name: customer?._organization?.name || '',
          })}
        </DialogTitle>
        <DialogContent>
          <ContactForm
            token={token}
            password={password}
            addresses={customer.contactAddresses}
            onOk={() => setModalContactIsVisible(false)}
          />
        </DialogContent>
      </Dialog>

      {displayTermModal && (
        <TermsModal
          open
          title={'Conditions générales de vente'}
          description={
            "Merci d'accepter les conditions générales suivantes pour accéder à votre portail client."
          }
          termsFileUrl={customer._organization.termsUrl}
          acceptLabel="Accepter les CGV"
          onAccept={handleCGV}
        />
      )}
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

const LabelWrapper = styled.div`
  padding-right: 12px;
`;

const Wrapper = styled.div`
  height: 100vh;
  padding-top: 24px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;


const WrapperSepa = styled.div`
  text-align: center;
`;

const Or = styled.div`
  text-align: center;
  margin: 24px 0;
  border-top: 1px solid #EEE;
  
  &:after {
    content: 'ou';
    display: inline-block;
    padding: 0 8px;
    font-size: 14px;
    color: #999;
    background: #FFF;
    top: -12px;
    position: relative;
  }
`;


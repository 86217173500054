import React, {
  useState,
  useMemo,
  createContext,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { CloseButton } from './components/CloseButton';
import { Slide, Grow, Portal } from '@mui/material';
import { ContactsList } from './components/ContactsList';
import { ChatWindow } from './components/ChatWindow';
import { useGetUsersQuery } from 'common/services/userApi';
import {
  useGetAllChatsQuery,
  useLazyGetAllChatsQuery,
  useGetChatMessagesQuery,
  usePostStartChatMutation,
} from 'common/services/chatApi';
import { StateSetter } from 'types';
import { useDebounce } from 'common/hooks/useDebounce';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSubscribeObject } from 'common/hooks/ws';
import { bindShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import { NewMessageNotification } from './components/NewMessageNotification';
import { getId } from 'common/helpers/document';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import BellNotificationSound from 'assets/sounds/chat/BellNotificationSound.webm';

interface ChatContextProps {
  contacts: any[];
  search: string;
  setSearch: StateSetter<string>;
  selectedUser: any;
  setSelectedUser: StateSetter<any>;
  isFetchingContacts: boolean;
  contactsLimit: number;
  setContactsLimit: StateSetter<number>;
  initialItemsCount: number;
  contactsOffset: number;
  setContactsOffset: StateSetter<number>;
  totalDocs: number;
  chats: any;
  currentUserId: string;
  lastListMessage: any;
  selectedChatId: string;
  setSelectedChatId: StateSetter<string>;
  isLastMessageReached: boolean;
  setIsLastMessageReached: StateSetter<boolean>;
  refetchChats: any;
  totalChats: number;
  triggerRefetchChats: Function;
}

export const ChatContext = createContext<ChatContextProps>({
  contacts: [],
  search: '',
  setSearch: () => {},
  selectedUser: '',
  setSelectedUser: () => {},
  isFetchingContacts: false,
  contactsLimit: 15,
  setContactsLimit: () => {},
  initialItemsCount: 15,
  contactsOffset: 0,
  setContactsOffset: () => {},
  totalDocs: 0,
  chats: null,
  currentUserId: '',
  lastListMessage: null,
  selectedChatId: '',
  setSelectedChatId: () => {},
  isLastMessageReached: false,
  setIsLastMessageReached: () => {},
  refetchChats: null,
  totalChats: 0,
  triggerRefetchChats: () => {},
});

interface Props {
  open: boolean;
  setChatOpen: StateSetter<boolean>;
  unreadMessagesCount: number;
  setUnreadMessagesCount: StateSetter<number>;
}

export const Chat: React.FC<Props> = ({
  open,
  setChatOpen,
  unreadMessagesCount,
  setUnreadMessagesCount,
}) => {
  /* ------------------------------- useStates ------------------------------- */
  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedChatId, setSelectedChatId] = useState('');
  const [initialItemsCount, setInitialItemsCount] = useState(15);
  const [contactsLimit, setContactsLimit] = useState(initialItemsCount);
  const [contactsOffset, setContactsOffset] = useState(0);
  const [chatsLimit, setChatsLimit] = useState(initialItemsCount);
  const [chatsOffset, setChatsOffset] = useState(0);
  const [filteredChatsList, setFilteredChatsList] = useState<any[]>([]);
  const [filteredMessages, setFilteredMessages] = useState<any[]>([]);
  const [isLastMessageReached, setIsLastMessageReached] = useState(false);
  const [chatMessagesLimit, setChatMessagesLimit] = useState(30);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [lastRecievedMessage, setLastRecievedMessage] = useState<any>(null);
  const [newMessageToastOpen, setNewMessageToastOpen] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [hasMoreChats, setHasMoreChats] = useState(false);
  const [totalChats, setTotalChats] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  /* ------------------------------- Variables ------------------------------- */
  const authUser: any = useSelector(selectAuthUser);
  const listCursor = useRef('');
  const chatCursor = useRef('');
  const audioRef: any = useRef(null);
  const { t } = useTranslation();

  /* ------------------------------- Api Calls ------------------------------- */
  const debouncedSearch = useDebounce(search, 500);
  const { data: contacts, isFetching: isFetchingContacts } = useGetUsersQuery(
    `limit=${contactsLimit}&offset=${contactsOffset}&text=${debouncedSearch}`,
  );

  const {
    data: chats,
    isFetching: isFetchingChats,
    isLoading: isLoadingChats_,
    refetch: refetchChats,
  } = useGetAllChatsQuery(
    {
      text: '',
      cursor: listCursor.current === '-1' ? '' : listCursor.current,
      limit: chatsLimit,
      offset: chatsOffset,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: messages, isFetching } = useGetChatMessagesQuery(
    {
      messageId: '',
      cursor: chatCursor.current === '-1' ? '' : chatCursor.current,
      chatId: selectedChatId,
      text: '',
      limit: chatMessagesLimit,
    },
    { refetchOnMountOrArgChange: true, skip: !selectedChatId },
  );

  const [handleStartConversation, { isLoading: isLoadingStartChat }] =
    usePostStartChatMutation();

  const [triggerChats, { data: newChats }] = useLazyGetAllChatsQuery();

  /* ------------------------------- Use Memos ------------------------------- */
  const totalDocs = useMemo(() => {
    return contacts?.totalDocs || 0;
  }, [contacts]);

  const currentUserId = useMemo(() => {
    if (authUser) {
      return authUser?._id;
    } else {
      return '';
    }
  }, [authUser]);

  const visibleElements = useMemo(() => {
    if (!windowWidth) return;
    if (windowWidth > 1200) {
      return 'both';
    } else if (windowWidth <= 1200 && selectedUser) {
      return 'window';
    } else {
      return 'list';
    }
  }, [windowWidth, selectedUser]);

  /* ------------------------------ Web Sockets ------------------------------ */
  const lastListMessage = useSubscribeObject('user-chats', `${authUser?._id}`, {
    data: chats?.data,
  });

  const lastChatMessages = useSubscribeObject(
    'channel-message',
    `${selectedChatId}`,
  );

  /* ------------------------------ Use Effects ------------------------------ */
  useEffect(() => {
    if (!lastListMessage) return;
    triggerRefetchChats();

    const newMessage = JSON.parse(lastListMessage);

    if (newMessage?.data?.data?.lastMessage?.sender === currentUserId) return;

    const sentBy = newMessage?.data?.data?.participantsDetails?.filter(
      participant =>
        participant?._id === newMessage?.data?.data?.lastMessage?.sender,
    )[0];
    const sender = sentBy?.cache?.agents[authUser?._currentOrganization?._id];
    setLastRecievedMessage({
      message: newMessage?.data?.data?.messages[0]?.lastMessage,
      sender: sender,
      unreadCount: newMessage?.data?.data?.messages[0]?.unreadCount,
    });
  }, [lastListMessage]);

  useEffect(() => {
    if (!lastRecievedMessage) return;

    setNewMessageToastOpen(true);
    if (userInteracted) {
      audioRef?.current?.pause();
      audioRef.current.currentTime = 0;
      audioRef?.current?.play();
    }

    const timeoutId = setTimeout(() => {
      setNewMessageToastOpen(false);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [lastRecievedMessage]);

  useEffect(() => {
    if (isFetchingChats || !chats || !refetchChats) {
      return;
    }
    setHasMoreChats(chats?.hasMore);

    setTotalChats(chats?.facets?.totalChats);

    // set unread messages count
    let unreadsCount = 0;
    chats?.data?.map(chat => {
      unreadsCount += chat?.messages[0]?.unreadCount || 0;
    });
    setUnreadMessagesCount(unreadsCount);

    if (chats?.data?.length === 0) {
      return;
    } else {
      setFilteredChatsList(prevChats => {
        const newData = chats?.data?.filter(
          item => !prevChats?.some(prevItem => prevItem?._id === item?._id),
        );
        return [...prevChats, ...newData];
      });
    }
  }, [chats, isFetchingChats, refetchChats]);

  useEffect(() => {
    if (!selectedUser) return;
    handleStartConversation({ userId: selectedUser?._id })
      .then((res: any) => {
        setSelectedChatId(res?.data?.chat?._id || res?.data?._id);
      })
      .catch(err => {
        setSelectedChatId('');
      });
  }, [selectedUser]);

  useEffect(() => {
    if (lastChatMessages) {
      const message = JSON.parse(lastChatMessages);

      const newMessage = {
        content: message?.data?.data?.content,
        contentType: message?.data?.data?.type,
        createdAt: message?.data?.data?.createdAt,
        _id: message?.data?.data?.sender,
        messageUuid: message?.data?.data?.messageUuid,
        sender: {
          id: message?.data?.data?.sender,
          pictureUrl: message?.data?.data?.pictureUrl,
          firstName: message?.data?.data?.firstName,
          lastName: message?.data?.data?.lastName,
        },
      };
      setFilteredMessages(prev => [newMessage, ...prev]);
    }
  }, [lastChatMessages]);

  useEffect(() => {
    if (messages?.data) {
      setFilteredMessages(messages?.data);
    }
  }, [messages?.data]);

  useEffect(() => {
    if (!isLastMessageReached || !messages?.hasMore) return;
    setChatMessagesLimit(chatMessagesLimit + 30);
  }, [isLastMessageReached]);

  useEffect(() => {
    setChatMessagesLimit(30);
    if (unreadMessagesCount > 0 && refetchChats) {
      refetchChats();
    }
  }, [selectedChatId]);

  useEffect(() => {
    if (!isInitialRender) return;
    bindShortcut(['escape'], () => handleCloseChat());
    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    if (userInteracted) return;
    const handleUserInteraction = () => {
      setUserInteracted(true);
    };

    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('scroll', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('scroll', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    setIsLoadingChats(isLoadingChats_);
  }, [isLoadingChats_]);

  useEffect(() => {
    if (!newChats?.data) return;
    setFilteredChatsList(newChats.data);
    setTotalChats(newChats.facets.totalChats);
  }, [newChats]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /* -------------------------------- Functions -------------------------------- */
  const formatTimestamp = timestamp => {
    const date = dayjs(timestamp);

    if (date.isToday()) {
      return date.format('HH:mm');
    } else if (date.isYesterday()) {
      return t('yesterday');
    } else {
      return date.format('D MMMM, YYYY');
    }
  };

  const handleGetMoreChats = () => {
    if (chats.hasMore === true && !isLoadingChats && !isFetchingChats) {
      setChatsOffset(prevOffset => prevOffset + chatsLimit);
    }
  };

  const handleCloseChat = () => {
    setSelectedUser('');
    setSelectedChatId('');
    setChatOpen(false);
  };

  const triggerRefetchChats = () => {
    triggerChats({
      cursor: '',
      text: '',
      limit: filteredChatsList?.length || initialItemsCount,
      offset: 0,
    });
  };

  /* ------------------------------------------------------------------------- */

  return (
    <ChatContext.Provider
      value={{
        contacts: contacts?.docs || [],
        search: search,
        setSearch: setSearch,
        selectedUser: selectedUser,
        setSelectedUser: setSelectedUser,
        isFetchingContacts: isFetchingContacts,
        contactsLimit: contactsLimit,
        setContactsLimit: setContactsLimit,
        initialItemsCount: initialItemsCount,
        contactsOffset: contactsOffset,
        setContactsOffset: setContactsOffset,
        totalDocs: totalDocs,
        chats: chats,
        currentUserId: currentUserId,
        lastListMessage: lastListMessage,
        selectedChatId: selectedChatId,
        setSelectedChatId: setSelectedChatId,
        isLastMessageReached: isLastMessageReached,
        setIsLastMessageReached: setIsLastMessageReached,
        refetchChats: refetchChats,
        totalChats: totalChats,
        triggerRefetchChats: triggerRefetchChats,
      }}
    >
      <div style={{ position: 'relative' }}>
        <Grow in={open} timeout={{ enter: 500, exit: 200 }}>
          <Wrapper>
            {open && (
              <Portal>
                <CloseButtonContainer>
                  <CloseButton visible={open} onClick={handleCloseChat} />
                </CloseButtonContainer>
              </Portal>
            )}
            <ChatSectionWrapper>
              <ChatSection>
                {(visibleElements === 'both' || visibleElements === 'list') && (
                  <ContactsList
                    filteredChatsList={filteredChatsList}
                    setFilteredChatsList={setFilteredChatsList}
                    handleGetMoreChats={handleGetMoreChats}
                    isLoadingChats={isLoadingChats || isFetchingChats}
                    hasMore={hasMoreChats}
                    chatsOffset={chatsOffset}
                  />
                )}
                {(visibleElements === 'both' ||
                  visibleElements === 'window') && (
                  <ChatWindow
                    messages={filteredMessages}
                    setMessages={setFilteredMessages}
                    isFetching={isFetching}
                    isLoadingStartChat={isLoadingStartChat}
                    hasMore={messages?.hasMore}
                    visibleElements={visibleElements}
                  />
                )}
              </ChatSection>
            </ChatSectionWrapper>
          </Wrapper>
        </Grow>
      </div>
      <div style={{ position: 'relative' }}>
        <Slide
          direction="left"
          in={!!newMessageToastOpen && !!lastRecievedMessage}
        >
          <div style={{ position: 'relative' }}>
            <audio src={BellNotificationSound} ref={audioRef} />
            <NewMessageNotification
              senderPictureUrl={lastRecievedMessage?.sender?.pictureUrl}
              senderFullName={`${lastRecievedMessage?.sender?.firstName} ${lastRecievedMessage?.sender?.lastName}`}
              sendeRole={`${lastRecievedMessage?.sender?._role?.key}`}
              lastMessageAt={formatTimestamp(
                lastRecievedMessage?.message?.createdAt,
              )}
              lastMessage={lastRecievedMessage?.message?.content}
              sendeUnreadMessagesCount={lastRecievedMessage?.unreadCount}
              handleClose={() => setNewMessageToastOpen(false)}
              onClick={() => {
                setChatOpen(true);
                setSelectedUser({
                  ...lastRecievedMessage?.sender,
                  _id: lastRecievedMessage?.message?.sender,
                });
                setNewMessageToastOpen(false);
              }}
            />
          </div>
        </Slide>
      </div>
    </ChatContext.Provider>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: calc(100vw - 60px - 60px - 300px);
  padding: 20px;
  gap: 12px;
  position: absolute;
  top: -50px;
  right: 0;

  @media screen and (min-width: 720px) and (max-width: 940px) {
    width: calc(100vw - 60px - 60px - 110px);
  }

  @media screen and (max-width: 720px) {
    width: calc(100vw - 60px - 60px);
  }
`;

const ChatSectionWrapper = styled.div`
  flex: 1;
  background: linear-gradient(
    55deg,
    rgba(255, 0, 255, 0.3) -14.9%,
    rgba(176, 30, 255, 0.3) 10.07%,
    rgba(17, 89, 255, 0.3) 93.5%
  );
  padding: 2px;
  display: flex;
  box-shadow: -24px 24px 48px -12px #674daf57;
  border-radius: 20px;
`;

const ChatSection = styled.div`
  flex: 1;
  background: ${themes?.default?.accordionWhiteBg};
  border-radius: 18px;
  display: flex;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 396px;
  z-index: 2;

  @media screen and (min-width: 720px) and (max-width: 940px) {
    left: 206px;
  }

  @media screen and (max-width: 720px) {
    left: 96px;
  }
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from "styles/theme/themes";
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  count: number;
}

export const FarParkedCount: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation();
  if (count > 0) return(
    <Tooltip title={t("orders_status_distance_dot_red")}>
      <Wrapper>
        {count}
      </Wrapper>
    </Tooltip>
  )
  else return <></>
};

const Wrapper = styled.div`
  height: 12px;
  min-width: 12px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5px;
  background: ${themes?.default?.redA700}1A;
  border: 0.2px solid ${themes?.default?.redA700};
  text-align: center;
  color: ${themes?.default?.redA700};
  font-size: 0.625rem;
  white-space: nowrap;
  line-height: 10px;
  padding: 4px 2px;
`;
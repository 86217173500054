import { Marker, InfoWindow } from '@react-google-maps/api';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { useAddStopRoundtripMutation } from 'common/services/roundtripApi';
import { removeItemsFromOrder } from 'app/pages/AddRoundTrips/components/RightBar/function';
import { useToaster } from 'hooks/useToaster';

function drawRoundedRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

function drawStroked(ctx, text, x, y) {
  ctx.font = 'normal 13px Roboto';
  ctx.fillStyle = '#616161E5';

  const textWidth = 38;
  const textHeight = 23;
  const borderRadius = 5;

  // Draw rounded rectangle
  drawRoundedRect(ctx, x, y - textHeight, textWidth, textHeight, borderRadius);
  ctx.fill();

  const metrics = ctx.measureText(text);
  const textX = x + (textWidth - metrics.width) / 2;
  const textY = y - (textHeight - 10) / 2;

  ctx.fillStyle = 'white';
  ctx.fillText(text, textX, textY);
}

export function DropZone({
  text,
  coordinates,
  zoom,
  orders,
  setOrders,
  roundtripId,
  draggingFromRt,
  setDraggingFromRt,
}) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const toast = useToaster();

  canvas.width = 50;
  canvas.height = 30;

  drawStroked(ctx, text, 0, 30);

  const dataUrl = canvas.toDataURL();

  const [addStopRoundtrip] = useAddStopRoundtripMutation();

  const updateOrders = orderId => {
    const selectedStop = orders.filter(order => order._id === orderId)[0];
    const updatedOrders = removeItemsFromOrder(orders, [selectedStop]);
    setOrders(updatedOrders);
  };

  const assignOrder = async (id, data) => {
    data?.map(order => {
      updateOrders(order?._order);
    });
    await addStopRoundtrip({
      id: id,
      data: {
        stops: data,
      },
    }).unwrap();
    toast(5000, 'success', 'alerts.orders.assign_success');
  };

  const drop = async e => {
    e.preventDefault();
    setDraggingFromRt('');
    const srcDataString = e.dataTransfer.getData('application/json');
    const srcData = JSON.parse(srcDataString);
    const destId = e.target.id;

    if (srcData?.rtId === destId) return;

    await assignOrder(destId, srcData);
  };

  return (
    <>
      {draggingFromRt !== roundtripId && (
        <Marker
          position={{ lat: coordinates[1], lng: coordinates[0] }}
          icon={{
            url: dataUrl,
            anchor: new google.maps.Point(25, 15),
            scaledSize: new google.maps.Size(50, 30),
          }}
        >
          <InfoWindow
            position={{ lat: coordinates[1], lng: coordinates[0] }}
            options={{
              pixelOffset: new google.maps.Size(0, 5 * zoom),
              zIndex: 100,
              disableAutoPan: true,
            }}
          >
            <DropZoneWrapper
              id={roundtripId}
              zoom={zoom}
              size={10}
              onDragOver={e => {
                e.preventDefault();
              }}
              onDrop={drop}
            />
          </InfoWindow>
        </Marker>
      )}
    </>
  );
}

const DropZoneWrapper = styled.div<{ zoom; size }>`
  width: ${props => props.size * props.zoom}px;
  height: ${props => props.size * props.zoom}px;
  background: ${themes?.default?.primary}20;
  border-radius: 1000px;
  border: 2px solid ${themes?.default?.strokePrimary}
`;

import { useGrid } from 'app/components/Grid/hooks';
import {
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetRolesQuery,
  useDeleteRoleMutation,
  useBulkDeleteMutation,
  useChangeRoleStatusMutation,
} from 'common/services/roleApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useCallback, useMemo } from 'react';
import { useGetApplicationsQuery } from 'common/services/appApi';

export const useApiCalls = () => {
  const {
    page,
    rowsPerPage,
    debouncedSearchTerm,
    order,
    orderBy,
    debouncedFilters: filters,
    selected,
  } = useGrid();
  const queryParams = useQueryParams({
    page,
    limit: rowsPerPage,
    text: debouncedSearchTerm,
    sort: order + (!!orderBy ? orderBy : 'name'),
    enable:
      filters?.status
        ?.map(filter => {
          return filter.value;
        })
        ?.join(',') || '',
  });
  const { data: roles, isLoading } = useGetRolesQuery(queryParams);
  const [createRoleMutation, { isLoading: isAdding }] = useCreateRoleMutation();
  const [editRoleMutation, { isLoading: isEditing }] = useEditRoleMutation();
  const [deleteRoleMutation, { isLoading: isDeleting }] =
    useDeleteRoleMutation();
  const [bulkDeleteMutation, { isLoading: isBulkDeleting }] =
    useBulkDeleteMutation();
  const [changeRoleStatusMutation, { isLoading: isChangingStatus }] =
    useChangeRoleStatusMutation();

  const { data: applicationsData } = useGetApplicationsQuery('');

  const applications = useMemo(() => {
    return applicationsData?.applications;
  }, [applicationsData]);

  const isSubmitting = useMemo(() => {
    return (
      isAdding || isEditing || isChangingStatus || isDeleting || isBulkDeleting
    );
  }, [isAdding, isEditing, isChangingStatus, isDeleting, isBulkDeleting]);

  const deleteRole = useCallback(
    async (id: string) => {
      await deleteRoleMutation(id).unwrap();
    },
    [deleteRoleMutation],
  );

  const createRole = useCallback(
    async data => {
      await createRoleMutation(data).unwrap();
    },
    [createRoleMutation],
  );

  const editRole = useCallback(
    async data => {
      await editRoleMutation(data).unwrap();
    },
    [editRoleMutation],
  );

  const bulkDelete = useCallback(async () => {
    return await bulkDeleteMutation({ Ids: [...selected] }).unwrap();
  }, [bulkDeleteMutation, selected]);

  const changeStatus = useCallback(
    async (id: string, status: boolean) => {
      await changeRoleStatusMutation({ id, status }).unwrap();
    },
    [changeRoleStatusMutation],
  );

  return {
    isLoading,
    isSubmitting,
    roles,
    deleteRole,
    applications,
    createRole,
    editRole,
    bulkDelete,
    changeStatus,
  };
};

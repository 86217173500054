import { selectOrganization } from 'common/store/organization/selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { LeaveRequestStatus } from 'app/pages/Settings/UserInfo/components/UserTabs/LeaveRequestsTab/types';

export function useUsersData(
  usersData,
  roundTripCopy,
  users,
  data,
  setUsersData,
) {
  const organization = useSelector(selectOrganization);
  const [searchParams] = useSearchParams();

  // State and setters for debounced value
  useEffect(() => {
    let arr = [...users];
    const inRoundtrip: any[] = [];
    const available: any[] = [];

    arr.filter(a => a.isActive);

    arr = arr?.sort((a, b) => {
      return a?.fullName?.localeCompare(b?.fullName);
    });

    if (roundTripCopy) {
      arr?.map(t => {
        const existingRoundtrip =
          roundTripCopy?.find(d => d?.agents[0]?._user?._id === t?._id) ||
          roundTripCopy?.find(d => d?.agents?.[1]?._user?._id === t?._id);
        const existingDataRoundtrip = data?.find(
          d => d?.agents[0]?._user === t?._id,
        );
        const existingCode =
          existingRoundtrip?.code || existingDataRoundtrip?.code;

        const leaveRequests = t?.cache?.agents[organization._id]?.leaveRequests;
        let disabled = false;
        let onLeave = false;
        if (leaveRequests?.length) {
          const requestIdx = leaveRequests.findIndex(r => {
            const isToday = moment(
              searchParams.get('dateValue')?.replaceAll('"', ''),
            ).isBetween(
              moment(r.startDate).startOf('day'),
              moment(r.endDate).endOf('day'),
              undefined,
              '[]',
            );
            const accepted = r.status === LeaveRequestStatus.accepted;
            return isToday && accepted;
          });
          if (requestIdx !== -1) {
            onLeave = true;
            disabled = true;
          }
        }
        if (existingDataRoundtrip || existingRoundtrip) {
          inRoundtrip.push({
            ...t,
            roundtripCode: existingCode,
            disabled: true,
            onLeave,
          });
        } else {
          available.push({
            ...t,
            roundtripCode: existingCode,
            disabled,
            onLeave,
          });
        }
      });

      setUsersData(available.concat(inRoundtrip));
    }
  }, [users, data, roundTripCopy]);
  return 0;
}

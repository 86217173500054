import { Fade, Slide, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../styles/accordion';
import { withStyles, styled as muiStyled } from '@mui/styles';
import styled from 'styled-components';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { LoadingLayer } from '../../RightBar';
import { AccordionCellContent } from 'app/components/TableComponent/helpers/styles';
import { RowDataValue } from 'app/components/RoundTrips/components/RowDataValue';
import {
  Driver,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import RoundtripTruckIcon from 'app/components/RoundtripTruckIcon';
import { themes } from 'styles/theme/themes';
import If from 'app/components/If';
import { LoadingContainer } from 'app/components/TableComponent/components/AccordionItemCluster';
import { Droppable } from 'react-beautiful-dnd';
import TruckIcon from 'app/components/TruckIcon';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { Checkbox } from 'app/components/CustomCheckbox';

export const GridFunction = ({
  handleClickActivate,
  rows,
  isSelected,
  selected,
  setSelected,
  headCells,
  handleClickAccordionEl,
  handleAccordionToggle,
  accordionStatus,
  theme,
  setDropableData,
  isDragged,
  handleAccordionToggleHover,
  handleAccordionToggleHoverLeave,
  setAccordionStatus,
  isLoading,
  roundtripLoading,
  displayTruckMap,
  mapCoordinates,
  userContext,
}) => {
  const [groupIndex, setGroupIndex] = useState(-1);
  const [groupRowIndex, setGroupRowIndex] = useState(-1);

  const keyArrayRef = useRef<string[]>([]);

  const handleMouseLeave = (rowsGroupIndex, index) => {
    setDropableData(null);
    setGroupIndex(-1);
    setGroupRowIndex(-1);
  };
  let tablesData: any = [];
  rows.map((rowsGroup, rowsGroupIndex) => {
    tablesData.push(
      rowsGroup.data.map((row, index) => {
        if (accordionStatus?.[rowsGroupIndex]?.value[index]) {
          keyArrayRef.current = [...keyArrayRef.current, row.data.id.value];
        }
        const isItemSelected = !!isSelected(rowsGroup.key, row.data.id.value);
        const labelId = `enhanced-table-checkbox-${index}`;
        let styles = {};
        if (isItemSelected) {
          styles = { backgroundColor: theme.primaryLightO };
        }
        return (
          <div
            onMouseEnter={() => {
              setGroupIndex(rowsGroupIndex);
              setGroupRowIndex(index);
              setDropableData(row.data.id.value);
            }}
            onMouseLeave={() => {
              handleMouseLeave(rowsGroupIndex, index);
            }}
            key={row.data.id.value}
            style={{
              marginBottom: '15px',
              marginRight: '17px',
            }}
          >
            <React.Fragment key={`${rowsGroup.key}-${row.data.id.value}`}>
              <Droppable
                droppableId={`droppable-${rowsGroup.key}-${row.data.id.value}`}
              >
                {(provided, snapshot) => (
                  <div
                    style={{
                      position: 'sticky',
                      top: '33px',
                      background: themes?.default?.accordionWhiteBg,
                      zIndex: '3',
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <StyledTableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.data.id.value}
                      selected={isItemSelected}
                      sx={{
                        display: 'flex',
                        borderRadius: '4px',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderBottom: `0.063rem solid ${theme.tableBorder}`,
                        position: 'relative',
                        zIndex: 2,
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        style={{ ...styles, paddingLeft: '0px' }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          minWidth: '138px',
                          borderBottom: `none`,
                          zIndex: '2',
                        }}
                      >
                        <div
                          onClick={() => {
                            displayTruckMap({
                              coordinates: row.data['vehicle'].coordinates,
                              status: row.data['vehicle'].status,
                              engineRunning: row.data['vehicle'].running,
                              isTracked: row.data['vehicle'].tracked,
                            });
                          }}
                        >
                          <TruckIcon
                            driver={
                              userContext === AgentCategories.Sales
                                ? row.data[Sales]?.empty
                                : row.data[Driver]?.empty
                            }
                            vehicle={row.data['vehicle']?.empty}
                            running={row.data['vehicle']?.running}
                            notTracked={!row.data['vehicle']?.tracked}
                            mapOpen={
                              mapCoordinates &&
                              mapCoordinates ===
                                row.data['vehicle']?.coordinates
                            }
                            status={row.data['vehicle']?.status}
                            style={{ paddingLeft: '15px' }}
                          />
                        </div>
                        {accordionStatus?.[rowsGroupIndex]?.value[index] ? (
                          <ArrowToggle
                            onClick={e => {
                              handleAccordionToggle(
                                e,
                                rowsGroupIndex,
                                index,
                                accordionStatus,
                                setAccordionStatus,
                                row.data.id.value,
                              );
                            }}
                          >
                            <KeyboardArrowUpIcon />
                          </ArrowToggle>
                        ) : (
                          <>
                            <ArrowToggle
                              onClick={e => {
                                handleAccordionToggle(
                                  e,
                                  rowsGroupIndex,
                                  index,
                                  accordionStatus,
                                  setAccordionStatus,
                                  row.data.id.value,
                                );
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </ArrowToggle>
                          </>
                        )}

                        {isItemSelected}
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          sx={{
                            height: 'fit-content',
                          }}
                          onClick={event => {
                            handleClickAccordionEl(
                              rowsGroup.key,
                              row.data.id.value,
                              selected,
                              setSelected,
                            );
                          }}
                        />
                      </TableCell>
                      {headCells.map(headCell => {
                        if (headCell.disableRow) return null;
                        if (
                          headCell.id === 'vehicle' ||
                          headCell.id === 'driver' ||
                          headCell.id === 'ripper'
                        ) {
                          return (
                            <StyledTableDetailWrapper
                              editable={
                                headCell.id === 'vehicle' ||
                                headCell.id === 'driver' ||
                                headCell.id === 'ripper'
                              }
                            >
                              <TableCell
                                key={headCell.id}
                                align="left"
                                style={{ ...styles }}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  borderBottom: `none`,
                                  flex: 1,
                                  minWidth: headCell.minWidth || 0,
                                  maxWidth: headCell.maxWidth || 'unset',
                                  overflow: 'hidden',
                                  backgroundColor: 'rgba(255, 255, 255)',
                                }}
                                onClick={e => {
                                  if (headCell.isAccordion) {
                                    handleAccordionToggle(
                                      e,
                                      rowsGroupIndex,
                                      index,
                                      accordionStatus,
                                      setAccordionStatus,
                                      row.data.id.value,
                                    );
                                  }
                                }}
                              >
                                {!headCell.isAccordion ? (
                                  row.data[headCell.id]?.value
                                ) : (
                                  <AccordionCellContent>
                                    <RowDataValue
                                      accordionStatus={accordionStatus}
                                      rowsGroupIndex={rowsGroupIndex}
                                      index={index}
                                      row={row}
                                      headCell={headCell}
                                    />
                                  </AccordionCellContent>
                                )}
                              </TableCell>
                            </StyledTableDetailWrapper>
                          );
                        } else {
                          return (
                            <TableCell
                              key={headCell.id}
                              align="left"
                              style={{ ...styles }}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderBottom: `none`,
                                flex: 1,
                                minWidth: headCell.minWidth || 0,
                                maxWidth: headCell.maxWidth || 'unset',
                                overflow: 'hidden',
                                backgroundColor: 'rgba(255, 255, 255)',
                              }}
                              onClick={e => {
                                if (headCell.isAccordion) {
                                  handleAccordionToggle(
                                    e,
                                    rowsGroupIndex,
                                    index,
                                    accordionStatus,
                                    setAccordionStatus,
                                    row.data.id.value,
                                  );
                                }
                              }}
                            >
                              {!headCell.isAccordion ? (
                                row.data[headCell.id]?.value
                              ) : (
                                <AccordionCellContent>
                                  <RowDataValue
                                    accordionStatus={accordionStatus}
                                    rowsGroupIndex={rowsGroupIndex}
                                    index={index}
                                    row={row}
                                    headCell={headCell}
                                  />
                                </AccordionCellContent>
                              )}
                            </TableCell>
                          );
                        }
                      })}
                    </StyledTableRow>
                  </div>
                )}
              </Droppable>

              <Droppable
                droppableId={`droppable-stops-${rowsGroup.key}-${row.data.id.value}`}
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      marginBottom: snapshot?.isDraggingOver ? '58px' : '',
                    }}
                  >
                    <Accordion
                      TransitionProps={{
                        timeout: { appear: 1, enter: 1, exit: 200 },
                      }}
                      expanded={
                        accordionStatus?.[rowsGroupIndex]?.value[index] || false
                      }
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        style={{ display: 'none' }}
                      ></AccordionSummary>

                      <AccordionDetails>
                        <If
                          condition={
                            row.accordion &&
                            (accordionStatus?.[rowsGroupIndex]?.value[index] ||
                              keyArrayRef.current?.includes(row.data.id.value))
                          }
                          otherwise={
                            <LoadingContainer style={{ marginLeft: '20px' }}>
                              <LoadingPulse />
                            </LoadingContainer>
                          }
                        >
                          <Slide
                            direction="down"
                            in={accordionStatus?.[rowsGroupIndex]?.value[index]}
                            mountOnEnter
                            unmountOnExit
                            timeout={300}
                          >
                            <div>{row.accordion}</div>
                          </Slide>
                        </If>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
              </Droppable>
            </React.Fragment>
          </div>
        );
      }),
    );
  });
  return tablesData;
};
export const StyledTableRow = styled(TableRow)(props => ({
  height: '50px',
  '&:hover': {
    backgroundColor: 'rgba(245, 245, 245, 0.9) !important',
    '& td': {
      backgroundColor: 'rgba(245, 245, 245, 0.9)',
    },
  },
  '& td': {
    fontSize: '0.875rem',
    border: '0.4px solid transparent',
    '&:first-child': {
      position: 'sticky',
      left: 0,
      backgroundColor: 'inherit',
      zIndex: 1,
      borderBottomLeftRadius: '4px',
      borderTopLeftRadius: '4px',
    },
    '&:nth-child(2)': {
      position: 'sticky',
      left: '138px',
      backgroundColor: 'inherit',
      zIndex: 1,
      paddingLeft: '8px',
    },
    '&:nth-last-child(2)': {
      position: 'sticky',
      right: '60px',
      backgroundColor: 'inherit',
      zIndex: 1,
    },
    '&:nth-last-child(3)': {
      position: 'sticky',
      right: '225px',
      backgroundColor: 'inherit',
      zIndex: 1,
      marginLeft: 'auto',
    },
    '&:last-child': {
      position: 'sticky',
      right: '0',
      backgroundColor: 'inherit',
      maxWidth: 'fit-content',
      borderBottomRightRadius: '4px',
      borderTopRightRadius: '4px',
    },
  },
}));

const StyledTableDetailWrapper = styled.div<{ editable: any }>`
  position: relative;
  z-index: 0;

  & td {
    background-color: rgba(245, 245, 245, 0.9);
    height: ${props => (props.editable ? '50px' : '')};
    padding: ${props => (props.editable ? '0' : '')};
    &:hover {
      background: ${props => (props.editable ? '#FAFAFA' : '')};
      border: ${props =>
        props.editable ? '0.4px solid #E0E0E0' : '0.4px solid transparent'};
    }
  }
`;

const ArrowToggle = styled.div`
  padding: 22px 23px 22px 50px !important;
  cursor: pointer;
`;

export const RedPulse = styled.div`
  left: 5px;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: ${themes?.default?.redStatus};
  position: absolute;
  box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.15);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(255, 0, 0, 0.15);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.15);
    }
  }
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { UserInfo } from 'app/components/Chat/components/ContactsList/components/ChatCard';
import EllipsisText from 'app/components/EllipsisText';
import { UserAvatar } from 'app/components/Chat/components/UserAvatar';
import { CustomIconButton } from 'app/components/Chat/components/CustomIconButton';
import { X } from '@phosphor-icons/react';

interface Props {
  senderPictureUrl: string;
  senderFullName: string;
  sendeRole: string;
  lastMessageAt: string;
  lastMessage: string;
  sendeUnreadMessagesCount: number;
  handleClose: () => void;
  onClick: () => void;
}

export const NewMessageNotification: React.FC<Props> = ({
  senderPictureUrl,
  senderFullName,
  sendeRole,
  lastMessageAt,
  lastMessage,
  sendeUnreadMessagesCount,
  handleClose,
  onClick,
}) => {
  return (
    <Wrapper onClick={onClick}>
      <MessageBody>
        <div
          style={{
            flex: '1',
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <UserAvatar
            size={36}
            userImagePath={senderPictureUrl}
            fullName={senderFullName}
            count={sendeUnreadMessagesCount || 0}
          />
          <UserInfo>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', gap: '2px' }}>
                <p className="name">{senderFullName}</p>
                <p className="name">{` - `}</p>
                <p className="role">{sendeRole}</p>
              </div>
              <p className="time">{lastMessageAt}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="lastMessage">
                <EllipsisText width={220} text={lastMessage} />
              </p>
            </div>
          </UserInfo>
        </div>
        <div style={{ margin: '-8px -7px 0 0' }}>
          <CustomIconButton icon={<X />} size={16} onClick={handleClose} />
        </div>
      </MessageBody>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-width: 352px;
  width: fit-content;
  height: 54px;
  position: absolute;
  top: 2px;
  right: 16px;
  border-radius: 4px;
  background: linear-gradient(166.61deg, #29b6f6 -0.09%, #2a59ff 105.26%);
  padding: 1px;
  cursor: pointer;
`;

const MessageBody = styled.div`
  flex: 1;
  background: ${themes?.default?.lightBlueBg};
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  gap: 4px;
`;

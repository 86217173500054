import * as React from 'react';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { useThemeSlice } from './slice';
import { selectTheme } from './slice/selectors';

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  useThemeSlice();

  const theme = useSelector(selectTheme);

  const customTheme = createTheme({
    palette: {
      common: {
        black: theme.lightTextPrimary,
      },
      primary: {
        main: theme.primary,
        contrastText: theme.accordionWhiteBg,
      },
      secondary: {
        main: theme.accordionWhiteBg,
        contrastText: theme.accordionWhiteBg,
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiSelect: {
        defaultProps: {
          size: 'small',
        },
      },
    },
  });

  return (
    <OriginalThemeProvider theme={theme}>
      <MuiThemeProvider theme={customTheme}>
        {React.Children.only(props.children)}
      </MuiThemeProvider>
    </OriginalThemeProvider>
  );
};

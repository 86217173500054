import { useDispatch, useSelector } from 'react-redux';

import { checkUserAcl } from 'common/helpers/acl';

import { HeadCell } from '../../../../interfaces/customers';
import { selectCustomerGridPreferences } from 'common/store/app/selectors';
import { appActions } from 'common/store/app';
import { MAP } from '../../../components/TableComponent/constants';
import { useState } from 'react';

export interface Data {
  id: { value: number | string };
  code: { value: JSX.Element; sort?: string };
  createdAt: { value: JSX.Element; sort?: string };
  email: { value: JSX.Element; sort?: string };
  invoicePeriod: { value: JSX.Element; sort?: string };
  paymentPeriod: { value: JSX.Element; sort?: string };
  legalId: { value: JSX.Element; sort?: string };
  legalName: { value: JSX.Element; sort?: string };
  phone: { value: JSX.Element; sort?: string };
  publicName: { value: JSX.Element; sort?: string };
  updatedAt: { value: JSX.Element; sort?: string };
  vatNumber: { value: JSX.Element; sort?: string };
  _salesman: { value: JSX.Element };
  action: { value: JSX.Element };
  status: { value: JSX.Element };
  invitation: { value: JSX.Element };
}

const defaultCustomersHeadCellsConst: HeadCell[] = [
  {
    id: 'code',
    label: 'code',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    isSticky: true,
    stickyLeftPosition: 48,
    isCheckbox: true,
    disableSort: false,
    minWidth: 40,
    sortField: 'code',
    isEditTrigger: true,
  },
  {
    id: 'publicName',
    label: 'customer.publicName',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: false,
    sortField: 'publicName',
    minWidth: 200,
  },
  {
    id: 'address',
    label: 'common.address',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 200,
  },
  {
    id: 'phone',
    label: 'common.phone',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'email',
    label: 'common.email',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: false,
    sortField: 'email',
  },
  {
    id: 'branches',
    label: 'common.branches',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'amountPerWeight',
    label: 'ca_kg',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: false,
    sortField: 'cache.stats.amountPerWeight',
  },
  {
    id: 'createdAt',
    label: 'common.createdAt',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 150,
  },
  {
    id: 'paymentPeriod',
    label: 'customer.paymentPeriod',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 130,
  },
  {
    id: 'legalId',
    label: 'customer.legalId',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'activityCode',
    label: 'customer.activityCode',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 175,
  },
  {
    id: 'legalName',
    label: 'customer.legalName',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 200,
  },
  {
    id: 'updatedAt',
    label: 'updated_at',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 150,
  },
  {
    id: '_salesman',
    label: 'customer.salesman',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: false,
    sortField: 'cache._salesman.email',
  },
  {
    id: '_customerType',
    label: 'customer.type',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 150,
  },
  {
    id: 'vatNumber',
    label: 'common.vat_number',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'score',
    label: 'customer.score',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: false,
    sortField: 'creditScore.commonValue',
  },
  {
    id: 'invitation',
    label: 'customer.ordoria_status',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    minWidth: 175,
    disableSort: true,
  },
  {
    id: 'status',
    label: 'common.status',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
    isSticky: true,
    stickyRightPosition: 48,
  },
  {
    id: 'action',
    label: '',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
    isSticky: true,
    stickyRightPosition: 0,
  },
];

let customersHeadCellsConst: HeadCell[] = [];

const config = localStorage.getItem('tableColumnsConfig');
let tableColumnsConfig: any;
if (config) {
  tableColumnsConfig = JSON.parse(config);
  if (tableColumnsConfig?.bianta?.customers) {
    customersHeadCellsConst = tableColumnsConfig.bianta.customers;
  } else {
    customersHeadCellsConst = defaultCustomersHeadCellsConst;
  }
} else {
  customersHeadCellsConst = defaultCustomersHeadCellsConst;
}

export { customersHeadCellsConst };

export const useCustomersHeadCells = (authUser, type) => {
  const [customersHeadCellsConst_, setCustomersHeadCellsConst_] = useState(
    customersHeadCellsConst,
  );
  const dispatch = useDispatch();
  const enableColumns: string[] = useSelector(selectCustomerGridPreferences);
  const customerHeadCellsFiltered =
    type === MAP
      ? []
      : checkUserAcl(authUser, ['manageCustomers'])
      ? customersHeadCellsConst_
      : customersHeadCellsConst_.filter(
          head => !['invitation'].includes(head.id),
        );

  const headCells: HeadCell[] = customerHeadCellsFiltered.map(headCell => {
    if (enableColumns.includes(headCell.id) !== true) {
      headCell.disableRow = true;
    } else {
      headCell.disableRow = false;
    }

    return headCell;
  });

  const updateHeadCells: any = (
    newHeadCells: HeadCell[],
    reorder?: boolean,
  ) => {
    if (reorder) {
      setCustomersHeadCellsConst_(newHeadCells);
    }
    dispatch(
      appActions.setCustomerGridPreferences({
        columns: newHeadCells
          .filter(c => c.disableRow !== true)
          .map(headCell => headCell.id),
      }),
    );
    let tableColumnsConfigCopy = { ...tableColumnsConfig };
    tableColumnsConfigCopy.bianta = tableColumnsConfigCopy.bianta || {};
    tableColumnsConfigCopy.bianta.customers = newHeadCells;
    localStorage.setItem(
      'tableColumnsConfig',
      JSON.stringify(tableColumnsConfigCopy),
    );
  };

  console.log('HOOK', headCells);

  return [headCells, updateHeadCells];
};

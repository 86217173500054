import {
  Box,
  Checkbox,
  IconButton,
  Pagination,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useAccordionValues } from 'hooks/MuiTable/useAccordionValues';
import {
  GridAccordionSummaryWrapper,
  SectionView,
  TablePaginationWrapper,
  TypesWrapper,
} from './styles';
import { EnhancedTableHead, handleSelectAllClick } from './helpers';
import { EXCEL, GRID, REGULAR, REOPTIMIZE, TRACK } from './constants';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { ExcelFunction } from './helpers/excel';
import { GridFunction } from './helpers/grid';
import { Theme } from '@mui/system';
import {
  cloneSelectedArray,
  handleClick,
  numberOfEl,
  getAccordionGroupInex,
  handleClickAccordionEl,
  descendingComparator,
  getComparator,
  stableSort,
} from './helpers/functions';

import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { handleAccordionToggle } from '../RoundTrips/function';
import styled from 'styled-components';
import { AccordionItemCluster } from './components/AccordionItemCluster';
import { LoadingPulse } from '../LoadingPulse';
import { RegularTableFunction } from 'app/pages/AddRoundTrips/components/TableComponent/helpers/regular';
import { ReOptimizeFunction } from 'app/pages/AddRoundTrips/components/TableComponent/helpers/reoptimize';
import { TrackFunction } from 'app/pages/AddRoundTrips/components/TableComponent/helpers/track';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'app/pages/AddRoundTrips/components/TableComponent/styles/accordion';
import { handleRequestSort } from 'functions/hnadleRequestSort';
import { themes } from 'styles/theme/themes';
import { useAccordionDetailsToggle } from 'hooks/RoundTrips/useAccordionDetailsToggle';
import If from '../If';
import { AgentCategories } from '../RoundTrips/RoundTripData/agentCategories';
import { hashObject } from 'common/utils/hashObject';
export type Order = 'asc' | 'desc';

interface EnhancedTableToolbarProps {
  numSelected: number;
}
interface Props {
  EnhancedTableToolbar?: any;
  isLoading: boolean;
  rows: any;
  page: number;
  setPage: (e) => void;
  rowsPerPage: number;
  setRowsPerPage: (e) => void;
  headCells: any;
  setHeadCells: any;
  setExpanded?: (e) => void;
  setAccordionStatus?: (e) => void;
  expanded?: any;
  accordionStatus?: { key: string; value: boolean[] }[];
  type?: string;
  selected: any;
  setSelected: any;
  stopsAccordion?: any;
  setStopsAccordion?: any;
  sx?: SxProps<Theme> | undefined;
  selectedRoundTrip?: number;
  setSelectedRoundTrip?: React.Dispatch<React.SetStateAction<number>>;
  selectedResult?: number;
  setSelectedResult?: React.Dispatch<React.SetStateAction<number>>;
  tableHeight?: string | number;
  viewCompleted?: boolean;
  rowsDatas?: any;
  totalPages?: number;
  setOrderBy?: React.Dispatch<React.SetStateAction<string>>;
  orderBy?: string;
  order?: Order;
  setOrder?: React.Dispatch<React.SetStateAction<Order>>;
  totalDocs?: number;
  hidePagination?: boolean;
  triggerSingleCluster?: any;
  handleProceedClick?: any;
  handleClickOpenSchedule?: any;
  loadedFlag?: boolean;
  setLoadedFlag?: any;
  setClusterId?: any;
  onClickAdd?: any;
  viewMode?: boolean;
  triggerRoundtripDetails?: any;
  checkBox?: boolean;
  displayTruckMap?: Function;
  mapRowId?: any;
  mapCoordinates?: any;
  filterStatus?: any;
  setFilterStatus?: any;
  search?: string;
  isFetching?: boolean;
  dateBegin?: any;
  regularSort?: string;
  setRegularSort?: any;
  regularDirection?: string;
  setRegularDirection?: any;
  filterSection?: JSX.Element;
  filterCount?: number;
  userContext?: AgentCategories;
  handleEditTrigger?: any;
  ordoria?: boolean;
}

export const TableComponent: React.FC<Props> = ({
  EnhancedTableToolbar,
  rowsDatas,
  isLoading,
  rows,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  headCells,
  setHeadCells,
  viewCompleted,
  setExpanded,
  setAccordionStatus,
  expanded,
  accordionStatus,
  selected,
  setSelected,
  type,
  stopsAccordion,
  setStopsAccordion,
  sx,
  selectedRoundTrip,
  setSelectedRoundTrip,
  selectedResult,
  setSelectedResult,
  tableHeight,
  totalPages,
  setOrderBy,
  orderBy,
  order,
  setOrder,
  totalDocs = 0,
  hidePagination,
  triggerSingleCluster,
  handleProceedClick,
  handleClickOpenSchedule,
  loadedFlag,
  setLoadedFlag,
  setClusterId,
  onClickAdd,
  viewMode,
  triggerRoundtripDetails,
  checkBox,
  displayTruckMap,
  mapRowId,
  mapCoordinates,
  filterStatus,
  setFilterStatus,
  search,
  isFetching,
  dateBegin,
  regularSort,
  setRegularSort,
  regularDirection,
  setRegularDirection,
  filterSection,
  filterCount,
  userContext,
  handleEditTrigger,
  ordoria,
  ...props
}) => {
  useAccordionValues(
    rows,
    setExpanded,
    setAccordionStatus,
    type,
    true,
    loadedFlag,
    setLoadedFlag,
  );
  const [selectedAccordionGroup, setSelectedAccordionGroup] = useState(
    rows[0]?.key,
  );
  const selectedAccordionGroupIndex: number = getAccordionGroupInex(
    rows,
    selectedAccordionGroup,
  );
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  const [clusterLoading, setClusterLoading] = useState<string>('');
  const [sort, setSort] = useState(regularSort || 'code');
  const [direction, setDirection] = useState(regularDirection || '');
  const hashedRows = hashObject(rows);

  React.useEffect(() => {
    if (setStopsAccordion) setStopsAccordion(false);
  }, [type]);

  React.useEffect(() => {
    if (!selectedAccordionGroup) {
      setSelectedAccordionGroup(rows[selectedAccordionGroupIndex]?.key);
    }
  }, [hashedRows]);

  useEffect(() => {
    if (type === REGULAR) {
      setRegularDirection && setRegularDirection(direction);
      setRegularSort && setRegularSort(sort);
    } else {
      setAccordionStatus && setAccordionStatus([{ key: '', value: [] }]);
      // here we check the active cluster or the one that has one roundtrip
      rows.map(row => {
        let activeCluster = row?.clusters?.find(
          cluster => cluster.status === 'active',
        );
        if (!activeCluster && row?.clusters?.length === 1) {
          activeCluster = row?.clusters?.[0];
        }
        if (activeCluster) {
          triggerSingleCluster(
            `/${activeCluster._id}?begin=${dateBegin}&end=${dateBegin}&sort=${direction}${sort}&device=web`,
          );
        }
      });
    }
  }, [sort, direction]);
  const handleChange =
    (panel: string, roundtrip: any) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (clusterLoading) return;
      if (!roundtrip?.roundtrips?.[0]?.agents) getSingleCluster(roundtrip?._id);
      let expandedCopy = { ...expanded };
      expandedCopy[panel] = newExpanded;
      setExpanded?.(expandedCopy);
      let accordionStatusCopy;
      if (Array.isArray(accordionStatus))
        accordionStatusCopy = [...accordionStatus];
      for (let j = 0; j < accordionStatusCopy.length; j++) {
        if (accordionStatusCopy[j].key != panel) continue;
        for (let i = 0; i < accordionStatusCopy[j].value.length; i++) {
          accordionStatusCopy[j].value[i] = false;
        }
      }
      setAccordionStatus?.(accordionStatusCopy);
    };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getSingleCluster = async (roundtripId: string, withLoading = true) => {
    if (withLoading) {
      setClusterLoading(roundtripId);
    }
    await triggerSingleCluster(
      `/${roundtripId}?begin=${dateBegin}&end=${dateBegin}&sort=${direction}${sort}&device=web`,
    );
    setClusterLoading('');
  };

  const { handleAccordionToggleWrapper, roundtripLoading } =
    useAccordionDetailsToggle(rows, triggerRoundtripDetails);

  let isSelected;
  if (type === REGULAR) {
    isSelected = (name: string) => selected.indexOf(name) !== -1;
  } else if (
    type === GRID ||
    type === TRACK ||
    type === EXCEL ||
    type === REOPTIMIZE
  ) {
    isSelected = (group, name: string) => {
      return selected[group] && selected[group].indexOf(name) !== -1;
    };
  }
  const dense = true;
  let tablesData: any[] = [];
  if (type === REGULAR) {
    tablesData = RegularTableFunction({
      getComparator,
      handleClick,
      headCells,
      isSelected,
      order,
      orderBy,
      rows,
      selected,
      setSelected,
      stableSort,
      mapRowId,
      rowsDatas,
      handleEditTrigger,
      ordoria,
    });
  } else if (type === GRID) {
    tablesData = [];
    tablesData = GridFunction({
      accordionStatus,
      handleAccordionToggle: handleAccordionToggleWrapper,
      handleClickAccordionEl,
      headCells,
      isSelected,
      rows,
      selected,
      setSelected,
      theme,
      setAccordionStatus,
      roundtripLoading,
      displayTruckMap,
      mapCoordinates,
      filterStatus,
      userContext,
    });
  } else if (type === EXCEL) {
    tablesData = [];
    tablesData = ExcelFunction({
      rows,
      headCells,
      stopsAccordion,
      setStopsAccordion,
      theme,
      selectedAccordionGroupIndex,
      selected,
      selectedAccordionGroup,
    });
  } else if (type === REOPTIMIZE) {
    tablesData = [];
    tablesData = ReOptimizeFunction({
      rows,
      headCells,
      stopsAccordion,
      setStopsAccordion,
      theme,
      selectedRoundTrip,
      viewCompleted,
    });
  } else if (type === TRACK) {
    tablesData = [];
    tablesData = TrackFunction({
      accordionStatus,
      handleAccordionToggle,
      handleClickAccordionEl,
      headCells,
      isSelected,
      rows,
      selected,
      setSelected,
      theme,
    });
  }

  const TableContainerHeight = tableHeight
    ? tableHeight
    : type !== REGULAR
    ? 'unset'
    : filterCount
    ? themes.default?.regularDataTableHeightFiltered
    : themes.default?.regularDataTableHeight;
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: theme.roundtripsGrayBackGround,
          boxShadow: 'none',
          paddingTop: type === GRID ? '15px' : 0,
        }}
      >
        {EnhancedTableToolbar && EnhancedTableToolbar}

        <If condition={type === REGULAR}>
          <If condition={!!filterCount} otherwise={filterSection}>
            <FilterWrapper>{filterSection}</FilterWrapper>
          </If>
        </If>

        <TableContainer
          sx={{
            minHeight: TableContainerHeight || 'unset',
            maxHeight: TableContainerHeight || 'unset',
            overflowY: 'auto',
            opacity: isFetching ? 0.5 : 1,
            position: 'relative',
          }}
        >
          <Table
            sx={{
              minWidth: 750,
              marginRight: type === GRID ? '18px' : 0,
              marginLeft: type === GRID ? '18px' : 0,
            }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            {!!rows?.length && (
              <EnhancedTableHead
                checkBox={checkBox ?? false}
                selectedResult={selectedResult}
                setSelectedResult={setSelectedResult}
                selectedRoundTrip={selectedRoundTrip}
                type={type}
                numSelected={selected.length}
                order={direction ?? ''}
                orderBy={sort ?? ''}
                setSelected={setSelected}
                group={selectedAccordionGroup}
                isSelected={isSelected}
                onSelectColumn={handleClickAccordionEl}
                onSelectAllClick={e => {
                  handleSelectAllClick(e, type, rows, selected, setSelected);
                }}
                onRequestSort={(event, property) => {
                  handleRequestSort(
                    property,
                    direction,
                    sort,
                    setDirection,
                    setSort,
                  );
                }}
                rowCount={
                  type === GRID || type === TRACK || type === EXCEL
                    ? numberOfEl(rows)
                    : rows.length
                }
                isLoading={isLoading}
                headCells={headCells}
                setHeadCells={setHeadCells}
                selected={selected}
                rows={rows}
                selectedRowIndex={selectedAccordionGroupIndex}
                emptyCellStyle={{ width: '90px' }}
              />
            )}
            {!isLoading && (
              <TableBody>
                {type === TRACK &&
                  tablesData?.map((tableData, index) => {
                    return (
                      <Accordion
                        key={rows[index].id}
                        expanded={!!expanded[rows[index].id]}
                        onChange={handleChange(
                          rows[index].id,
                          rows[index].roundtripData,
                        )}
                      >
                        <div
                          style={{
                            height: 32,
                            background: theme.primaryActiveO,
                            borderBottom: `0.063rem solid ${theme.borderGrey}`,
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            expandIcon={null}
                            sx={{
                              width: 'fit-content',
                              height: '100%',
                              minHeight: 'unset',
                              position: 'sticky',
                              left: 0,
                            }}
                          >
                            <Typography>
                              <SectionView
                                expanded={!!expanded[rows[index].id]}
                              >
                                {!!expanded[rows[index].id] ? (
                                  <ArrowDropUpIcon
                                    sx={{
                                      color: themes?.default?.accordionWhiteBg,
                                    }}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    sx={{
                                      color: themes?.default?.accordionWhiteBg,
                                    }}
                                  />
                                )}
                                <div className="stats-dropdown-text">
                                  {rows[index].key}
                                </div>
                              </SectionView>
                            </Typography>
                          </AccordionSummary>
                        </div>
                        <AccordionDetails>{tableData}</AccordionDetails>
                      </Accordion>
                    );
                  })}
                {(type === EXCEL || type === REOPTIMIZE) && tablesData[0]}
                {type === GRID &&
                  tablesData?.map((tableData, index) => {
                    const roundtripCluster = rows[index].roundtripData;
                    const { _id, _deck, name, roundtripsStatus } =
                      roundtripCluster;
                    return (
                      <div style={{ position: 'relative' }}>
                        <AccordionItemCluster
                          onClickAdd={onClickAdd}
                          setClusterId={setClusterId}
                          handleClickOpenSchedule={handleClickOpenSchedule}
                          index={index}
                          rows={rows}
                          expanded={expanded}
                          handleChange={handleChange}
                          roundtripsStatus={roundtripsStatus}
                          roundtripCluster={roundtripCluster}
                          _deck={_id}
                          handleProceedClick={handleProceedClick}
                          name={name}
                          _id={_id}
                          tableData={tableData}
                          theme={theme}
                          triggerSingleCluster={getSingleCluster}
                          clusterLoading={clusterLoading}
                          viewMode={viewMode}
                          filterStatus={filterStatus}
                          setFilterStatus={setFilterStatus}
                          setExpanded={setExpanded}
                        />
                      </div>
                    );
                  })}
                <If condition={type === REGULAR}>
                  <If condition={isLoading} otherwise={tablesData}>
                    <LoadingContainer>
                      <LoadingPulse />
                    </LoadingContainer>
                  </If>
                </If>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {hidePagination || !totalDocs
          ? null
          : type === REGULAR && (
              <TablePaginationWrapper className="table-pagination-wrapper">
                <TablePagination
                  labelRowsPerPage={t('rows_per_page')}
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                  component="div"
                  count={totalDocs ?? 10}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={(subProps: TablePaginationActionsProps) => {
                    const {
                      page,
                      count,
                      rowsPerPage,
                      backIconButtonProps,
                      nextIconButtonProps,
                      showLastButton,
                      getItemAriaLabel,
                      showFirstButton,
                      onPageChange,
                      ...restSubProps
                    } = subProps;
                    return <></>;
                  }}
                  sx={{ '.MuiTablePagination-select': { paddingTop: '5px' } }}
                />
                <Pagination
                  color="primary"
                  onChange={(e, val) => {
                    setPage(val);
                  }}
                  page={page}
                  count={totalPages}
                  shape="rounded"
                />
              </TablePaginationWrapper>
            )}
        {type === EXCEL && (
          <TypesWrapper>
            {rows.map(row => {
              return (
                <div
                  key={row.key}
                  onClick={() => {
                    setSelectedAccordionGroup(row.key);
                  }}
                  className={`accordion-el${
                    selectedAccordionGroup === row.key ? ' selected' : ''
                  }`}
                >
                  {row.key}
                </div>
              );
            })}
          </TypesWrapper>
        )}
      </Paper>
    </Box>
  );
};

interface ActionButtonsProps {
  viewMode?: boolean;
}

export const ActionButtons = styled.div<ActionButtonsProps>`
  position: sticky;
  right: 16px;
  padding-right: ${props => (props.viewMode ? '35px' : '0')};
`;

const LoadingContainer = styled.div`
  margin-top: 50px;
  width: fit-content;
  position: sticky;
  left: 50%;
`;

const FilterWrapper = styled.div`
  height: 36px;
  padding-top: 5px;
`;

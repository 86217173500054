import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  StreetViewPanorama,
  useJsApiLoader,
  MarkerF,
  OverlayView,
  OverlayViewF,
} from '@react-google-maps/api';
import styled from 'styled-components';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import MyLocationIcon from '@mui/icons-material/MyLocation';

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { toolsActions } from 'app/slices/tools';
import { Fade } from '@mui/material';
import { themes } from 'styles/theme/themes';
import Truck from 'assets/img/roundTrips/truck.svg';
import If from '../If';
import { defaultMapStyle } from 'styles/mapStyles';
import RoundtripTruckIcon, { GreenPulse } from '../RoundtripTruckIcon';
import TruckIcon from '../TruckIcon';
import { Icon } from 'app/components/Icon';
import { ArrowsIn, ArrowsOut, X, Crosshair } from '@phosphor-icons/react';

interface MapPopoverProps {
  latitude: any;
  longitude: any;
  open: boolean;
  handleClose: Function;
  editMode?: boolean;
  editMap?: boolean;
  setSelectedStopCopy?: any;
  isTruck?: boolean;
  driverLat?: any;
  driverLong?: any;
  vehicle?: any;
}

const MapPopover = ({
  latitude,
  longitude,
  open,
  handleClose,
  editMode,
  editMap,
  setSelectedStopCopy,
  isTruck,
  driverLat,
  driverLong,
  vehicle,
}: MapPopoverProps) => {
  const dispatch = useDispatch();

  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [center, setCenter] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [driverPosition, setDriverPosition] = useState({
    lat: driverLat,
    lng: driverLong,
  });
  const [containerStyle, setContainerStyle] = useState({
    width: '603px',
    height: '391px',
    transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
    borderRadius: '4px',
  });

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? '';

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });

  const mapOptions = {
    disableDefaultUI: true,
    styles: defaultMapStyle,
  };

  useEffect(() => {
    if (latitude && longitude) {
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (driverLat && driverLong) {
      setDriverPosition({
        lat: driverLat,
        lng: driverLong,
      });
    }
  }, [driverLat, driverLong]);

  const handleFullscreen = () => {
    setContainerStyle(
      fullScreen
        ? { ...containerStyle, width: '603px', height: '391px' }
        : { ...containerStyle, width: '100vw', height: '100vh' },
    );
    setFullScreen(!fullScreen);
    if (!editMode) {
      dispatch(toolsActions.setZoomIn({ status: !fullScreen }));
    }
  };

  const handleCloseWrapper = () => {
    if (fullScreen && !editMode) {
      dispatch(toolsActions.setZoomIn({ status: false }));
    }
    setFullScreen(false);
    setContainerStyle({ ...containerStyle, width: '603px', height: '391px' });
    closeStreetView();
    handleClose();
  };

  const toggleStreetView = () => {
    setStreetViewVisible(!streetViewVisible);
  };

  const closeStreetView = () => {
    setStreetViewVisible(false);
  };

  return (
    <Fade in={isLoaded && open}>
      <MapWrapper fullScreen={fullScreen} editMode={editMode}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={mapOptions}
          center={center}
          zoom={15}
          onClick={e => {
            if (editMap) {
              setCenter({ lat: e?.latLng?.lat(), lng: e?.latLng?.lng() });
              setSelectedStopCopy([e?.latLng?.lat(), e?.latLng?.lng()]);
            }
          }}
        >
          <>
            <FullScreenButton
              fullScreen={fullScreen}
              onClick={handleFullscreen}
            >
              {fullScreen ? (
                <Icon
                  icon={<ArrowsIn />}
                  size={20}
                  color={themes?.default?.iconColor}
                />
              ) : (
                <Icon
                  icon={<ArrowsOut />}
                  size={20}
                  color={themes?.default?.iconColor}
                />
              )}
            </FullScreenButton>
            <CloseButton onClick={handleCloseWrapper}>
              <Icon icon={<X />} size={20} color={themes?.default?.iconColor} />
            </CloseButton>
            <StreetViewButton
              streetView={streetViewVisible}
              onClick={toggleStreetView}
            >
              <Icon
                icon={<Crosshair />}
                size={20}
                color={themes?.default?.iconColor}
              />
            </StreetViewButton>
            <If condition={!isTruck}>
              <Marker position={center} />
            </If>
            <If
              condition={
                (isTruck && center?.lat && center?.lng) ||
                (!isTruck && driverPosition?.lat && driverPosition?.lng)
              }
            >
              <OverlayViewF
                position={isTruck ? center : driverPosition}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                {/* TODO: check other modules for the usage of this component */}
                {/* This is only fixed in vehicle table */}
                {/* maybe create a new component and use it instead of this one */}
                {vehicle ? (
                  <TruckIcon
                    vehicle={false}
                    running={vehicle?.engineRunning}
                    driver={false}
                    notTracked={!vehicle?.isTracked}
                    iconStyle={{
                      icon: { height: '30px', width: '30px' },
                      img: { height: '15px', width: '15px' },
                    }}
                    status={vehicle.status}
                  />
                ) : (
                  <GreenPulse style={{ height: '30px', width: '30px' }}>
                    <img
                      src={Truck}
                      style={{ height: '15px', width: '15px' }}
                    />
                  </GreenPulse>
                )}
              </OverlayViewF>
            </If>
            {streetViewVisible && (
              <>
                <StreetViewPanorama
                  options={{
                    position: { lat: latitude, lng: longitude },
                    visible: streetViewVisible,
                    disableDefaultUI: true,
                    enableCloseButton: false,
                  }}
                />
              </>
            )}
          </>
        </GoogleMap>
      </MapWrapper>
    </Fade>
  );
};

export default MapPopover;

const MapButton = styled.div(props => ({
  position: 'absolute',
  right: '51px',
  top: '15px',
  cursor: 'pointer',
  color: props.theme.icon,
  padding: '5px',
  backgroundColor: 'rgb(255,255,255)',
  borderRadius: '4px',
  zIndex: '1500',
  boxShadow: '1px 1px 4px 1px rgba(0, 0, 0, 0.25)',
}));

interface FullscreenButtonProps {
  fullScreen?: boolean;
}

const FullScreenButton = styled(MapButton)<FullscreenButtonProps>`
  background-color: ${props =>
    props.fullScreen ? props.theme.blueToggledButton : ''};
`;

const CloseButton = styled(MapButton)(props => ({
  right: '15px',
}));

interface StreetViewButtonProps {
  streetView?: boolean;
}

export const StreetViewButton = styled(CloseButton)<StreetViewButtonProps>`
  right: 87px;
  background-color: ${props =>
    props.streetView ? props.theme.blueToggledButton : ''};
`;

interface MapProps {
  fullScreen?: boolean;
  editMode?: boolean;
}

const MapWrapper = styled.div<MapProps>`
  position: fixed;
  right: ${props => (props.fullScreen ? '0' : '17px')};
  z-index: 2100;
  bottom: ${props =>
    props.fullScreen ? '0' : props.editMode ? '40px' : '22px'};
  box-shadow: 0px 5px 30px 0px ${themes?.default?.suvaGrey}40;
  border-radius: 4px;
`;
